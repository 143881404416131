import React, { useState, useContext, Fragment, useEffect } from 'react';
import { format } from 'date-fns';
import { Autocomplete, TextField, Box, Button, FormGroup, FormControlLabel, Switch } from '@mui/material';
import SimpleBarChartTransactionReview from './simple-bar-chart-transaction-review';
import DateRangePicker, { DateRangeProvider, useDateRange } from './date-range-picker';
import TransactionCategoryCrudGrid from './grid-review_transaction_categories';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';




const DetailReport = ({ selectedCategories, selectedSubcategories, items, onBack, subcategory_options, flag_options, mutateTransaction, handleCategoryRuleCreate, handleFlagRuleCreate, showSummaryView }) => {
  
  const reportHeader=selectedCategories.length===0?"Details For All Transactions":`Details for ${selectedCategories.join(', ')}${selectedSubcategories.length===0?"":" - "+selectedSubcategories.join(', ')}`
  return (
    <Fragment>
      <Box sx={{ display: 'flow' }}>
        {showSummaryView &&
          <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={onBack}>Back to Summary</Button>
        }
        
        <h2>{reportHeader}</h2>

        <TransactionCategoryCrudGrid transactions={items} subcategories={subcategory_options} flags={flag_options} mutateTransaction={mutateTransaction} handleCategoryRuleCreate={handleCategoryRuleCreate} handleFlagRuleCreate={handleFlagRuleCreate} />

      </Box>
    </Fragment>
  );
};

const filterDataByDateRange = (data, dateRange, date_field) => {
  if (!dateRange[0] || !dateRange[1]) return data;
  const [startDate, endDate] = dateRange;
  return data.filter(item => item[date_field] >= startDate && item[date_field] <= endDate);
};

const groupAndSummarizeData = (data, selectedCategories, selectedSubcategories) => {
  const filteredData = data.filter(item => {
    if (selectedCategories.length && !selectedCategories.includes(item.category)) return false;
    if (selectedSubcategories.length && !selectedSubcategories.includes(item.subcategory)) return false;
    return true;
  });

  const groupedData = {};

  filteredData.forEach(item => {
    if (!groupedData[item.category]) {
      groupedData[item.category] = {};
    }
    if (!groupedData[item.category][item.subcategory]) {
      groupedData[item.category][item.subcategory] = {
        totalAmount: 0,
        totalCount: 0,
        items: []
      };
    }
    groupedData[item.category][item.subcategory].totalAmount += item.amount;
    groupedData[item.category][item.subcategory].totalCount += 1;
    groupedData[item.category][item.subcategory].items.push(item);
  });

  return Object.keys(groupedData).flatMap(category =>
    Object.keys(groupedData[category]).map(subcategory => ({
      category,
      subcategory,
      totalAmount: groupedData[category][subcategory].totalAmount,
      totalCount: groupedData[category][subcategory].totalCount,
      label: `${category} - ${subcategory}`,
      items: groupedData[category][subcategory].items
    }))
  );
};

const CategoryReviewReport = (props) => {
  const title = props.title ? props.title : "Review Transactions by Category"
  const data = props.data ? props.data : []
  const date_field = props.date_field ? props.date_field : "date"
  const subcategory_options = props.subcategory_options ? props.subcategory_options : []
  const mutateTransaction = props.mutateTransaction



  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubcategories, setSelectedSubcategories] = useState([]);
  const [selectedCategoriesBackup, setSelectedCategoriesBackup] = useState([]);
  const [selectedSubcategoriesBackup, setSelectedSubcategoriesBackup] = useState([]);
  const { dateRange } = useDateRange(); // Use dateRange from context
  const [showSummaryView, setShowSummaryView] = useState(true)


  const handleBarClick = (event, barItemIdentifier) => {
    const selectedIndex = barItemIdentifier.dataIndex
    const data = summarizedData[selectedIndex]

    setSelectedCategoriesBackup(selectedCategories)
    setSelectedSubcategoriesBackup(selectedSubcategories)

    setSelectedCategories([data.category]);
    setSelectedSubcategories([data.subcategory]);
  };

  const handleBackClick = () => {
    setSelectedCategories(selectedCategoriesBackup);
    setSelectedSubcategories(selectedSubcategoriesBackup);
  };

  const handleShowSummaryChange = () => {

    setShowSummaryView(!showSummaryView)
  }

  const flag_options = Array.from(new Set(data.filter(item => item.flag).map(item => item.flag))).map((item) => ({ flag: item }));
  const categories = Array.from(new Set(data.map(item => item.category)));
  const subcategories = selectedCategories.length
    ? Array.from(new Set(data.filter(item => selectedCategories.includes(item.category)).map(item => item.subcategory)))
    : [];

  const filteredData = filterDataByDateRange(data, dateRange, date_field);
  const summarizedData = groupAndSummarizeData(filteredData, selectedCategories, selectedSubcategories).sort((a, b) => Math.abs(b.totalAmount) - Math.abs(a.totalAmount));

  const selectedItems = summarizedData
    .filter(
      (d) =>
        (selectedCategories.length === 0 || selectedCategories.includes(d.category)) && (selectedSubcategories.length === 0 || selectedSubcategories.includes(d.subcategory))
    )
    .flatMap((d) => d.items);

  return (
    <Fragment>
      <h1>{title}</h1>
      <Box sx={{ display: 'flex', gap: 2, padding: 2 }}>
        

      
        <Autocomplete
          multiple
          options={categories}
          value={selectedCategories}
          onChange={(event, newValue) => {
            setSelectedCategories(newValue);

            // Preserve subcategories that belong to the new selected categories
            const newSubcategories = selectedSubcategories.filter(subcategory =>
              data.some(item => newValue.includes(item.category) && item.subcategory === subcategory)
            );

            setSelectedSubcategories(newSubcategories);
          }}
          renderInput={(params) => <TextField {...params} label="Category" sx={{ minWidth: 300 }} />}
        />
        <Autocomplete
          multiple
          options={subcategories}
          value={selectedSubcategories}
          onChange={(event, newValue) => setSelectedSubcategories(newValue)}
          renderInput={(params) => <TextField {...params} label="Subcategory" sx={{ minWidth: 300 }} />}
          disabled={!selectedCategories.length} // Disable subcategory selection if no category is selected
        />
        <DateRangePicker />
      </Box>
      <Box sx={{ display: 'flex', gap: 2, padding: 2 }}>
        <FormGroup>
          <FormControlLabel control={<Switch checked={showSummaryView} onChange={handleShowSummaryChange} />} label="Summary View" />
        </FormGroup>
      </Box>

      {!showSummaryView || (selectedCategories.length && selectedSubcategories.length === 1) ? (
        <Box sx={{ display: 'flex', gap: 2, padding: 2 }}>
          <DetailReport
            {...props}
            selectedCategories={selectedCategories}
            selectedSubcategories={selectedSubcategories}
            items={selectedItems}
            onBack={handleBackClick}
            subcategory_options={subcategory_options}
            flag_options={flag_options}
            mutateTransaction={mutateTransaction}
            showSummaryView={showSummaryView}


          />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', gap: 2, padding: 2 }}>
          <SimpleBarChartTransactionReview data={summarizedData} categoryField={'label'} valueField={'totalAmount'} onBarClick={handleBarClick} />
        </Box>
      )}
    </Fragment>
  );
};


export default CategoryReviewReport;
