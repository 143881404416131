import React, { createContext, useState } from 'react';
// import { updateReportAPI } from './api'; // Keep the update API as is
// import useReportsForCurrentUser from './useReportsForCurrentUser'; // Your custom hook
import UserProfileContext from '../ui/userprofilecontext.js';
import { useReportsForCurrentUser, useUpdateReportForCurrentUser, useDeleteReportForCurrentUser } from '../../api/swrreportsforcurrentuser.js';
import SnackbarContext from '../ui/snackbar-context.js';
import { useContext } from 'react';

const ReportContext = createContext();

export const ReportProvider = ({ children }) => {
  const snackbarContext = useContext(SnackbarContext);
  const userProfileContext = useContext(UserProfileContext);
  const userProfile = userProfileContext.userProfile
  const { trigger: updateReportTrigger } = useUpdateReportForCurrentUser(userProfile)
  const { trigger: deleteReportTrigger } = useDeleteReportForCurrentUser(userProfile)

  //console.log(`calling useReportsForCurrentUser ${JSON.stringify(userProfile)}, ${!userProfileContext.isLoading}`)
  const { data: reports, isLoading: reportsLoading } = useReportsForCurrentUser(userProfile, !userProfileContext.isLoading)
  const isLoading = userProfileContext.isLoading || reportsLoading

  // console.log(`ReportProvider render:  userProfile=${JSON.stringify(userProfile)}`)
  // console.log(`ReportProvider render:  reports=${JSON.stringify(reports)}`)
  // console.log(`ReportProvider render:  reportsLoading=${reportsLoading}`)
  
  // console.log(`ReportProvider render:  userProfile=${JSON.stringify(userProfileContext.isLoading)}`)

  //const { data: allReports, isLoading } = useReportsForCurrentUser(); // SWR pattern
  //const [reports, setReports] = useState({});
  const [filterHandlers, setFilterHandlers] = useState({});



  // useEffect(() => {
  //   if (allReports && !isLoading) {
  //     const reportMap = allReports.reduce((acc, report) => {
  //       acc[report.reportID] = report;
  //       return acc;
  //     }, {});
  //     console.log(`setting reports ${JSON.stringify(reportMap)}`)
  //     setReports(reportMap);
  //   }
  // }, [allReports, isLoading]);

  const updateReport = async (updatedReport) => {
    //console.log(`updating report ${JSON.stringify(updatedReport)}`)
    const originalReport = getReportByID(updatedReport.reportID)
    //console.log(`original report ${JSON.stringify(originalReport)}`)
    //console.log(`updated report ${JSON.stringify(updatedReport)}`)
    //const updatedReport = { ...reports[reportID], ...updatedData };
    //const optimisticReport={...updatedReport, reportData:JSON.stringify(updatedReport.reportData), reportFilters:JSON.stringify(updatedReport.reportFilters)}
    //console.log(`optimistic report ${JSON.stringify(optimisticReport)}`)
    const optimisticData=reports.map((r)=>r.reportID===updatedReport.reportID?updatedReport:r)

    function onSuccess(data, key, config) {
        snackbarContext.openSnackbarSuccess(`report ${updatedReport.reportName} saved successfully`)
    }

    function onError(error, key, config) {
        snackbarContext.openSnackbarError(`report ${updatedReport.reportName} didn't save: ${JSON.stringify(error)}`)
        console.log(`report ${updatedReport.reportName} didn't save: ${JSON.stringify(error)}`)

    }

    const options = {
        optimisticData: optimisticData,
        rollbackOnError: true,
        throwOnError: false,
        onSuccess: onSuccess,
        onError: onError,
    }

    updateReportTrigger({ userProfile: userProfileContext.userProfile, report: updatedReport }, options)

}

const deleteReport = (report) => {

    const optimisticData=reports.filter((r)=>r.reportID!==report.reportID)

    function onSuccess(data, key, config) {
        snackbarContext.openSnackbarSuccess(`report ${report.reportName} deleted successfully`)
    }

    function onError(error, key, config) {
        snackbarContext.openSnackbarError(`report ${report.reportName} didn't delete: ${JSON.stringify(error)}`)
        console.log(`report ${report.reportName} didn't delete: ${JSON.stringify(error)}`)

    }

    const options = {
        optimisticData: optimisticData,
        rollbackOnError: true,
        throwOnError: false,
        onSuccess: onSuccess,
        onError: onError,
    }

    deleteReportTrigger({ userProfile: userProfileContext.userProfile, report: report }, options)

}

  // Register filter handlers dynamically
  const registerFilterHandler = (filterType, handler) => {
    setFilterHandlers((prevHandlers) => ({
      ...prevHandlers,
      [filterType]: handler,
    }));
  };

  // Update a specific report in the database and local state
  // const updateReport = async (reportID, updatedData) => {
  //   const updatedReport = { ...reports[reportID], ...updatedData, updateDate: new Date().toISOString() };
  //   await updateReportAPI(reportID, updatedReport);
  //   setReports((prevReports) => ({
  //     ...prevReports,
  //     [reportID]: updatedReport,
  //   }));
  // };

  const getReportByID = (reportID) => {
    //  console.log(`getting report by id ${reportID}`)
    //  console.log(`reports ${JSON.stringify(reports)}`)
    //  console.log(`isLoading ${isLoading}`)
    if (isLoading) return null;

    return reports.find( (report) => report.reportID === reportID);
  }

  const updateReportData = async (reportID, newData) => {
    //const updatedData = JSON.stringify(newData);
    let updatedReport = getReportByID(reportID);
    updatedReport = { ...updatedReport,  reportData: newData  };
    await updateReport(updatedReport);
  };


  // Modify filters and save them to the reportFilters in the report
  const updateFilters = async (reportID, newFilters) => {
    const updatedFilters = JSON.stringify(newFilters);
    let updatedReport = getReportByID(reportID);
    updatedReport = { ...updateReport,  reportFilters: updatedFilters  };
    await updateReport(updatedReport);
  };
  

  // Apply filters stored in the reportFilters against reportData using dynamic filter handlers
  const applyFilters = (reportID) => {
    const report = getReportByID(reportID);
    if (!report) return [];

    const filters = JSON.parse(report.reportFilters);
    const data = JSON.parse(report.reportData);

    let filteredData = data;
    filters.forEach((filter) => {
      const handler = filterHandlers[filter.type];
      if (handler) {
        filteredData = handler(filteredData, filter);
      }
    });

    return filteredData;
  };

  return (
    <ReportContext.Provider
      value={{
        reports,
        isLoading,
        getReportByID,
        updateReportData,
        updateReport,
        deleteReport,
        updateFilters,
        applyFilters,
        registerFilterHandler,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};


export default ReportContext;