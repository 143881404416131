import React from 'react';
import PortalFrame from '../ui/portal-frame.js';
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';


import TotalByYearDetailReport from '../ui/report-total-by-year-detail.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import ReportContext from '../ui/user-report-context.js';
import ReactToPrint from 'react-to-print';

export default function TotalByYearReportPage(props) {
    const userProfileContext = useContext(UserProfileContext);

    const title = "Total Spend By Year"
    const reportID = useParams().reportID
    const reportContext = useContext(ReportContext);


    const report=reportContext.getReportByID(reportID)

    if (reportContext.isLoading ) {


        return (
            <PortalFrame title={title}>
                <PageSkeleton />
            </PortalFrame>
        )
    }

    return (
      <PortalFrame title={title}>
        <TotalByYearDetailReport reportID={reportID} reportData={report.reportData} />
      </PortalFrame>
    );
  }