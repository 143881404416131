import * as React from 'react';
import Box from '@mui/material/Box';
//import Button from '@mui/material/Button';
//import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
//import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
//  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
//import { v4 as randomId } from 'uuid'; // Importing uuid to generate unique file names

//import mutateBankAccount from '../../api/mutatebankaccount';
import mutateBankStatement from '../../api/mutatebankstatement';

import { renderSwitch, renderSwitchEditInputCell } from './grid-input-switch-component';

// import { Autocomplete } from '@mui/material';
// import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
// import { useGridApiContext } from '@mui/x-data-grid';
// import {TextField} from '@mui/material';
import { dateGetter, currencyFormatter } from './type-utilities';

//import { Flex } from '@aws-amplify/ui-react';
//import ocrMetadata from '../../ocrmetadata/ocrmetadata.json'




/////////////////////////////////////////////////////
// export function renderSwitch(params) {
//   return <Switch disabled checked={params.value} />;
// }

// const account_categories=Object.keys(ocrMetadata.account_category)

// function AccountCategoryEditInputCell(props) {
//   const { id, value, field, hasFocus } = props;
//   const apiRef = useGridApiContext();
//   const ref = React.useRef(null);

//   const handleChange = (event, newValue) => {
//       apiRef.current.setEditCellValue({ id, field, value: newValue });
//   };

//   useEnhancedEffect(() => {
//       if (hasFocus && ref.current) {
//           const input = ref.current.querySelector(`input[value="${value}"]`);
//           input?.focus();
//       }
//   }, [hasFocus, value]);

//   return (
//       <Box sx={{width: '100vw', alignItems: 'left' }}>
//           <Autocomplete
//               required
//               ref={ref}
//               id={field}
//               value={value}
//               onChange={handleChange}
//               options={account_categories}
//               renderInput={(params) => <TextField {...params} required />}
//           />
//       </Box>
//   );
// }

// export const renderAccountCategoryEditInputCell = (params) => {
//   return <AccountCategoryEditInputCell {...params} />;
//};


//////////////////////////////////////////////////////
// const account_types=Object.keys(ocrMetadata.account_type)

// function AccountTypeEditInputCell(props) {
//   const { id, value, field, hasFocus } = props;
//   const apiRef = useGridApiContext();
//   const ref = React.useRef(null);

//   const handleChange = (event, newValue) => {
//       apiRef.current.setEditCellValue({ id, field, value: newValue });
//   };

//   useEnhancedEffect(() => {
//       if (hasFocus && ref.current) {
//           const input = ref.current.querySelector(`input[value="${value}"]`);
//           input?.focus();
//       }
//   }, [hasFocus, value]);

//   return (
//     <Box sx={{width: '100vw', alignItems: 'left' }}>
//           <Autocomplete
//               required
//               ref={ref}
//               name={field}
//               value={value}
//               onChange={handleChange}
//               options={account_types}
//               renderInput={(params) => <TextField {...params} required />}
//           />
//       </Box>
//   );
// }

// export const renderAccountTypeEditInputCell = (params) => {
//   return <AccountTypeEditInputCell {...params} />;
// };


/////////////////////////////////////////////////////

// import {
//   randomCreatedDate,
//   randomTraderName,
//   randomId,
//   randomArrayItem,
// } from '@mui/x-data-grid-generator';

// const roles = ['Market', 'Finance', 'Development'];
// const randomRole = () => {
//   return randomArrayItem(roles);
// };

/*
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, description: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'txn_date' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}
*/

export default function StatementCrudGrid(props) {


  const parentHandleRowClick = props.handleRowClick ? props.handleRowClick : (statement) => { }

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  React.useEffect(() => {

    const statements = props.statements.map(statement => ({ ...statement, id: statement.pk }));
    setRows(statements)
    

  }, [props.statements]

  )

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const result=mutateBankStatement(props.currentUserProfile,updatedRow)
    
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    //console.log(`row "${JSON.stringify(params.row)}" clicked`);
    parentHandleRowClick(params.row)
  };

  // function dateGetter(value) {
  //   // Check if the value is an instance of Date
  //   if (value instanceof Date) {
  //     return value;
  //   } else {
  //     // Create a new Date object from the value
  //     return new Date(value);
  //   }
  // }

  // function currencyFormatter(value){
  //   if (value == null) {
  //     return '';
  //   }
  //   const currency='USD'
  //   const locale='en-US'
  //   return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value);
  // }
  

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          /*
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          */
        ];
      },
    },
    {
      field: '_verified',
      headerName: 'Verified?',
      //display: 'flex',
      type: 'boolean',
      renderCell: renderSwitch,
      renderEditCell: renderSwitchEditInputCell,
      width: 120,
      align: 'left',
      editable: true,
    },
    {
      field: 'begin_date',
      headerName: 'Begin Date',
      type: 'date',
      valueGetter: dateGetter,
      width: 200,
      align: 'left',
      editable: true,
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      type: 'date',
      valueGetter: dateGetter,
      width: 200,
      align: 'left',
      editable: true,
    },
    {
      field: 'begin_balance',
      headerName: 'Begin Balance',
      type: 'number',
      width: 150,
      valueFormatter: currencyFormatter,
      align: 'right',
      editable: true,
    },
    {
      field: 'end_balance',
      headerName: 'End Balance',
      type: 'number',
      width: 150,
      valueFormatter: currencyFormatter,
      align: 'right',
      editable: true,
    },
    
    
    
  ];

  return (
    <Box
      sx={{
        height: '75vh',
        //width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowClick={handleRowClick}
        slots={{
          //toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}