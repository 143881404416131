import React from "react";
import PortalFrame from '../ui/portal-frame.js';
// import ChartPaper from '../ui/chart-paper';
// import CheckboxesTags from '../ui/checkboxes-tags';
// import CheckboxesTagsSelectAll from "../ui/checkboxes-tags-selectall";
// //import { postNeo4j } from '../../api/pydbcall';

import { applyTransferstoCase } from "../../api/applytransferscall.js";
import mutateConfirmAllCategories from "../../api/mutateconfirmallcategories.js";
import UserProfileContext from '../ui/userprofilecontext.js';
import { Button, Stack, Typography  } from "@mui/material";

export default function CaseActionsPage(props) {

    const userProfileContext = React.useContext(UserProfileContext);

    const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg?.orgID
    const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase?.caseID




    const handleClickApplyTransfers = () => {
        applyTransferstoCase(orgID, caseID)
    }

    const handleClickConfirmCategories = () => {
        mutateConfirmAllCategories(userProfileContext.userProfile)
    }


    const disabled = userProfileContext.isLoading || !orgID || !caseID
    const title = "Case Actions"

    return (
        <PortalFrame title={title}>
            {!caseID && <Typography>You are not assigned to a case for this organization</Typography>}
            {caseID && 
            <Stack direction="column" spacing={2} alignItems="flex-start">
            <Button disabled={disabled} color="primary" onClick={handleClickConfirmCategories}>
                Confirm all Proposed Categories
            </Button>

            <Button disabled={disabled} color="primary" onClick={handleClickApplyTransfers}>
                Apply Transfers to Case
            </Button>
            </Stack>
}
        </PortalFrame>
    );
}

