import React, {Fragment, useState, useEffect} from "react";
import PortalFrame from '../ui/portal-frame';

import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import { findMissingStatements } from "../../api/missingstatementscall.js";
import StatementQualityReport from "../ui/report-statement-quality.js";

export default function Test2() {
  
  



  
  const userProfileContext=useContext(UserProfileContext);

  //const userProfile = userProfileContext.userProfile  // add new way

  const [missingStatements, setMissingStatements] = useState();

  
  
  useEffect(() => {
    async function fetchData(userProfile) {
      const missingStatements = await findMissingStatements(userProfile.currentOrg.orgID, userProfile.currentCase.caseID);
      setMissingStatements(missingStatements);
    }
    if(userProfileContext.isLoading) return;
    fetchData(userProfileContext.userProfile);
  }, [userProfileContext.userProfile]);



  if (userProfileContext.isLoading) {
  
    return (
      <div></div>
    )
  }

  return (
    <PortalFrame title={"Testing Document POST"}>
      <h1>Result:</h1>
      <br/>
      {!userProfileContext.isLoading && 
        <Fragment>
          <StatementQualityReport missingStatements={missingStatements}/>
          {JSON.stringify(missingStatements)}
        </Fragment>
      }
    </PortalFrame>
  );
};