import React from 'react';
import Grid from '@mui/material/Grid';
//import Paper from '@mui/material/Paper';
import Title from '../ui/title';
import DashboardPaper from './dashboard-paper';


function TileSet(props) {
    return (

        <Grid item xs={12} >
            <DashboardPaper>
                <Title>{props.title}</Title>
                <Grid container justifyContent="stretch"  spacing={2} >
                { props.children }
                </Grid>
                </DashboardPaper>
        </Grid>
    );
}

export default TileSet;
