import { CognitoIdentityProviderClient, AdminCreateUserCommand } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import
// const { CognitoIdentityProviderClient, AdminCreateUserCommand } = require("@aws-sdk/client-cognito-identity-provider"); // CommonJS import

//import { Amplify, Auth, API } from 'aws-amplify';
//import { Amplify } from "aws-amplify";
import amplifyConfig from '../amplifyconfiguration.json'
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { fetchAuthSession } from 'aws-amplify/auth';



export default async function authAddUser(data) {

    

    //let session = await Amplify.Auth

    //let awsCredentialsProvider = session 
    //let credentials = awsCredentialsProvider.getAWSCredentials().get()
    //console.log(`CREDENTIALS: ${JSON.stringify(credentials)}`)

        // Do something with the credentials
    
    
    //await Amplify.Auth.currentCredentials
    //.then((credentials) => console.log(`CREDENTIALS: ${JSON.stringify(credentials)}`));

    
    const region = amplifyConfig.aws_project_region

    const { credentials } = await fetchAuthSession();
    // console.log(`CREDENTIALS: ${JSON.stringify(credentials)}`)


    
    const client = new CognitoIdentityProviderClient({
        region: region,
        credentials: credentials
    });

    const input = { // AdminCreateUserRequest
        UserPoolId: amplifyConfig.aws_user_pools_id, // required
        Username: data.userEmail, // required
        UserAttributes: [ // AttributeListType
            { // AttributeType
                Name: "email", // required
                Value: data.userEmail,
            },
        ],
        // ValidationData: [
        //     {
        //         Name: "STRING_VALUE", // required
        //         Value: "STRING_VALUE",
        //     },
        // ],
        //TemporaryPassword: "STRING_VALUE",
        //ForceAliasCreation: true || false,
        //MessageAction: "SUPPRESS",  //"RESEND" || "SUPPRESS"
        DesiredDeliveryMediums: [ // DeliveryMediumListType
            "EMAIL"
        ],
        // ClientMetadata: { // ClientMetadataType
        //     "<keys>": "STRING_VALUE",
        // },
    };
    const command = new AdminCreateUserCommand(input);
    const response = await client.send(command);
    
    //const response={}
    return response;
    // { // AdminCreateUserResponse
    //   User: { // UserType
    //     Username: "STRING_VALUE",
    //     Attributes: [ // AttributeListType
    //       { // AttributeType
    //         Name: "STRING_VALUE", // required
    //         Value: "STRING_VALUE",
    //       },
    //     ],
    //     UserCreateDate: new Date("TIMESTAMP"),
    //     UserLastModifiedDate: new Date("TIMESTAMP"),
    //     Enabled: true || false,
    //     UserStatus: "UNCONFIRMED" || "CONFIRMED" || "ARCHIVED" || "COMPROMISED" || "UNKNOWN" || "RESET_REQUIRED" || "FORCE_CHANGE_PASSWORD",
    //     MFAOptions: [ // MFAOptionListType
    //       { // MFAOptionType
    //         DeliveryMedium: "SMS" || "EMAIL",
    //         AttributeName: "STRING_VALUE",
    //       },
    //     ],
    //   },
    // };

}