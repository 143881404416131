import { useState, useEffect, Fragment } from 'react';
import PortalFrame from '../ui/portal-frame.js';
import * as XLSX from 'xlsx';
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";
import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/material';
//import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { styled } from '@mui/material/styles';
import { useDropzone } from 'react-dropzone';
import { Stepper, Step, StepLabel } from '@mui/material';
import { Typography } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CategoryDataPreview from '../ui/category-data-preview-panel.js';
import { queryCaseNeo4j } from '../../api/pydbcall.js';
import mutateCategoryList from '../../api/mutatecategorylist.js';
import ExportToExcel from '../ui/export-to-excel.js';

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';

import SnackbarContext from '../ui/snackbar-context.js';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const step_index_select_category_file = 0
const step_index_select_worksheet = 1
const step_index_select_subcategory_column = 2
const step_index_select_category_column = 3
const step_index_select_drfa_category_column = 4
const step_index_select_drfa_subcategory_column = 5
const step_index_select_income_expense_column = 6
const step_index_data_quality_check = 7
const step_index_change_review = 8
const steps = ['Select Category File', 'Select Worksheet', 'Select Subcategory Column', 'Select Category Column', 'Select DRFA Category Column', 'Select DRFA Subcategory Column', 'Select Income/Expense/Transfer Column', 'Data Quality Check', 'Review Changes'];

// const alert_severity_success = 'success'
const alert_severity_info = 'info'
const alert_severity_warning = 'warning'
// const alert_severity_error = 'error'



export default function BulkLoadCategories(props) {
    const [file, setFile] = useState()
    const [sheetNames, setSheetNames] = useState([])
    const [sheetName, setSheetName] = useState('')
    const [columnNames, setColumnNames] = useState([])
    const [columnNames2, setColumnNames2] = useState([])
    const [columnNames3, setColumnNames3] = useState([])
    const [columnNames4, setColumnNames4] = useState([])
    const [columnNames5, setColumnNames5] = useState([])
    const [subcategoryColumn, setSubcategoryColumn] = useState('')
    const [categoryColumn, setCategoryColumn] = useState('')
    const [drfaCategoryColumn, setDrfaCategoryColumn] = useState('')
    const [drfaSubcategoryColumn, setDrfaSubcategoryColumn] = useState('')
    const [incomeExpenseColumn, setIncomeExpenseColumn] = useState('')
    const [rawData, setRawData] = useState();
    const [mappedCategoryData, setmappedCategoryData] = useState();
    const [passData, setPassData] = useState([]);
    const [failData, setFailData] = useState([])
    const [caseCategories, setCaseCategories]=useState([])
    //const [adds,setAdds] = useState([])
    const [updates,setUpdates] = useState([])
    //const [deletes,setDeletes] = useState([])
    

    //const [caseCategoryDict, setCaseCategoryDict]=useState({})



    const [activeStep, setActiveStep] = useState(0);
    const [skippedStep, setSkippedStep] = useState(new Set());
    // snackbar
    // const [openSnackbar, setOpenSnackbar] = useState(false);
    // const [snackbarAlertMessage, setSnackbarAlertMessage] = useState('Wow Something Happened')
    // const [snackbarAlertSeverity, setSnackbarAlertSeverity] = useState(alert_severity_success)
    // const [snackbarAction, setSnackbarAction] = useState()


    /// database and user profile
    //const userProfile = useCurrentUserProfile();

//added new way
const userProfileContext=useContext(UserProfileContext);

//add new way
const snackbarContext=useContext(SnackbarContext);

//const userProfile = useCurrentUserProfile();   //comment out old way
//const userProfile = userProfileContext.userProfile  // add new way

    useEffect(() => {

        const formatCategoryResult = (records) => {
            return records.map( record => ({
                subcategory: record.subcat.name,
                category: record.cat.name,
                drfaCategory: record.drfa?record.drfa.category:null,
                drfaSubcategory: record.drfa?record.drfa.subcategory:null,
                incomeExpense: record.subcat.type,
                usage_count: record.usage_count
                
            })

            )
        }

        

             
        // take off data
        const getCaseCategories = async () => {
            const orgID = userProfileContext.userProfile.currentOrg.orgID
            const caseID = userProfileContext.userProfile.currentCase.caseID


            //const categoryQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(subcat:Subcategory:DefinedCategory) OPTIONAL MATCH (subcat)<-[]-(t:Transaction) RETURN subcat, count(t) as usage_count"
            const categoryQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(subcat:Subcategory:DefinedCategory)-[:WITHIN]->(cat:Category) OPTIONAL MATCH (subcat)-[:MAPS_TO]->(drfa:DRFACategory) OPTIONAL MATCH (subcat)<-[]-(t:Transaction) RETURN subcat, cat, drfa, count(t) as usage_count"
            const params = {
                orgID: orgID,
                caseID: caseID
            }
            const caseCategoryResult = await queryCaseNeo4j(categoryQuery, params)
            //console.log(`Case Categories: ${JSON.stringify(caseCategoryResult)}`)
            setCaseCategories(formatCategoryResult(caseCategoryResult.records))
            
            

        }
        // Added context
        if (!userProfileContext.isLoading && userProfileContext.userProfile.currentOrg && userProfileContext.userProfile.currentCase) {
            getCaseCategories()
        }
    }, [userProfileContext.isLoading]
    )

    useEffect( () => {

        
        const createDict = (records) => {
            const dictionary = records.reduce( (acc, item) =>{
                //console.log(`item=${JSON.stringify(item)}`)
                acc[item.subcategory] = item
                return acc
            },{}

            )
            return dictionary
        }
        
        let adds = []
        let updates = []
        let deletes = []

        const deleteDict = createDict(passData)
        const caseCategoryDict=createDict(caseCategories)
            

        passData.map( record => {
            // need to process deletes
            const match=caseCategoryDict[record.subcategory]
            if(match){
                //console.log(`Matched Input Data: ${JSON.stringify(record)}`)
                //console.log(`Matched DB Data: ${JSON.stringify(match)}`)
                
                const exact_match=( (record.category?record.category:'')==(match.category?match.category:'') && (record.drfaCategory?record.drfaCategory:'')==(match.drfaCategory?match.drfaCategory:'') && (record.drfaSubcategory?record.drfaSubcategory:'')==(match.drfaSubcategory?match.drfaSubcategory:'') && (record.incomeExpense?record.incomeExpense:'') == (match.incomeExpense?match.incomeExpense:''))
                const updateRecord={...record,usage_count:match.usage_count, original_category: match.category, original_drfaCategory: match.drfaCategory, original_drfaSubcategory: match.drfaSubcategory}
                updates.push({...updateRecord, action: exact_match?'NO CHANGE':'UPDATE'})
            }else{
                updates.push({...record, action:'ADD'})
            }
        })

        caseCategories.map( record => {
            const match=deleteDict[record.subcategory]
            if(!match){
                updates.push({...record, action:'DELETE'})
            }

        })

        //setAdds(adds)
        setUpdates(updates)
        //setDeletes(deletes)

        },[caseCategories,passData]
    )

    /////////////////////////////////////////
    /// handling stepper
    const isStepOptional = (step) => {
        //return step === 1;
        return false
    };

    const isStepSkipped = (step) => {
        return skippedStep.has(step);
    };

    const handleNext = () => {
        let newSkipped = skippedStep;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkippedStep(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkippedStep((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    /////////////////////////////////////////
    /// handling snackbar

    // const handleSnackbarClose = (event, reason) => {
    //     if (reason === 'clickaway') {
    //         return;
    //     }

    //     setOpenSnackbar(false);
    // };

    // const defaultSnackbarAction = (
    //     <Fragment>
    //         <Button color="secondary" size="small" onClick={handleSnackbarClose}>
    //             UNDO
    //         </Button>
    //         <IconButton
    //             size="small"
    //             aria-label="close"
    //             color="inherit"
    //             onClick={handleSnackbarClose}
    //         >
    //             <CloseIcon fontSize="small" />
    //         </IconButton>
    //     </Fragment>
    // );

    // const handleSnackbarOpen = () => {
    //     //setSnackbarAction(defaultSnackbarAction)
    //     setOpenSnackbar(true);
    // };

    /////////////////////////////////////////
    /// inside Excel file
    const handleSelectSheet = () => {
        if (!sheetName) {
            // setSnackbarAlertMessage("Please select the data worksheet")
            // setSnackbarAlertSeverity(alert_severity_error)
            // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError("Please select the data worksheet")    
        }
        else {
            loadFile()
            handleNext()
        }
    }

    const handleSelectSubcategoryColumn = () => {
        if (!subcategoryColumn || !columnNames.includes(subcategoryColumn)) {
            // setSnackbarAlertMessage("Please select a valid subcategory column")
            // setSnackbarAlertSeverity(alert_severity_error)
            // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError("Please select a valid subcategory column")    
        }
        else {
            // remove the selected column from the list
            const remainingColumnNames = columnNames.filter(column => column !== subcategoryColumn);
            setColumnNames2(remainingColumnNames)
            handleNext()
        }
    }

    const handleSelectCategoryColumn = () => {
        if (!categoryColumn || !columnNames2.includes(categoryColumn)) {
            // setSnackbarAlertMessage("Please select a valid category column")
            // setSnackbarAlertSeverity(alert_severity_error)
            // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError("Please select a valid category column")    

        }
        else {
            // remove the selected column from the list
            const remainingColumnNames = columnNames2.filter(column => column !== categoryColumn);
            setColumnNames3(remainingColumnNames)
            handleNext()
        }
    }

    const handleSelectDrfaCategoryColumn = () => {
        if (!drfaCategoryColumn || !columnNames3.includes(drfaCategoryColumn)) {
            // setSnackbarAlertMessage("Please select a valid DRFA category column")
            // setSnackbarAlertSeverity(alert_severity_error)
            // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError("Please select a valid DRFA category column")    

        }
        else {

            const remainingColumnNames = columnNames3.filter(column => column !== drfaCategoryColumn);
            setColumnNames4(remainingColumnNames)


            handleNext()
        }
    }

    const handleSelectDrfaSubcategoryColumn = () => {
        if (!drfaSubcategoryColumn || !columnNames4.includes(drfaSubcategoryColumn)) {
            // setSnackbarAlertMessage("Please select a valid DRFA category column")
            // setSnackbarAlertSeverity(alert_severity_error)
            // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError("Please select a valid DRFA subcategory column")    

        }
        else {

            const remainingColumnNames = columnNames4.filter(column => column !== drfaSubcategoryColumn);
            setColumnNames5(remainingColumnNames)


            handleNext()
        }
    }

    const handleSelectIncomeExpenseColumn = () => {


        if (!drfaCategoryColumn || !columnNames5.includes(incomeExpenseColumn)) {
            // setSnackbarAlertMessage("Please select a valid Income/Expense/Transfer column")
            // setSnackbarAlertSeverity(alert_severity_error)
            // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError("Please select a valid Income/Expense/Transfer column")    
        }
        else {


            mapCategoryData()
            handleNext()
        }

    }

    const mapCategoryData = () => {
        const mappedData = rawData.map(record => ({ subcategory: record[subcategoryColumn], category: record[categoryColumn], drfaCategory: record[drfaCategoryColumn], drfaSubcategory: record[drfaSubcategoryColumn],incomeExpense: record[incomeExpenseColumn] }))
        setmappedCategoryData(mappedData)
        checkDataQuality(mappedData)
    }



    const checkDataQuality = (mappedData) => {
        const passData = []
        const failData = []

        const dqRules = (record) => {
            const formatPassResult = () => {
                return { pass: true }
            }
            const formatFailResult = (message) => {
                return { pass: false, reason: message }
            }

            function isFieldPresentAndHasValue(record, fieldName) {
                //console.log(`inside isFieldPresentAndHasValue(${JSON.stringify(record)}, ${fieldName})`)
                return fieldName in record && record[fieldName] !== null && record[fieldName] !== undefined && record[fieldName] !== '';
                //console.log(`result=${result}`)

            }

            function checkRequiredValidValues(record, fieldName, validValues) {


                // Check if the field exists and has a value
                if (fieldName in record && record[fieldName] !== null && record[fieldName] !== undefined && record[fieldName] !== '') {
                    // Convert the field value to lowercase and check if it is in the valid values array
                    return validValues.includes(record[fieldName].toLowerCase());
                }
                return false

            }

            function checkExpenseDrfaCategoryRule(record) {
                // check if the income/expense field is "Expense"
                if (record.incomeExpense.toLowerCase() == 'expense') {
                    // expenses need to have a DRFA category (at least in state of Georgia)
                    if (!isFieldPresentAndHasValue(record, "drfaCategory")) {
                        return false
                    }

                }

                return true

            }

            function checkDrfaCategorySubCategoryRule(record) {
                
                if (isFieldPresentAndHasValue(record, "drfaSubcategory")) {
                    if (!isFieldPresentAndHasValue(record, "drfaCategory")) {
                        return false
                    }

                }

                if (isFieldPresentAndHasValue(record, "drfaCategory")) {
                    if (!isFieldPresentAndHasValue(record, "drfaSubcategory")) {
                        return false
                    }

                }

                return true

            }

            //console.log(`Evaluating Record: ${JSON.stringify(record)}`)
            if (!isFieldPresentAndHasValue(record, "subcategory")) {
                return formatFailResult('missing subcategory')
            }

            if (!isFieldPresentAndHasValue(record, "category")) {
                return formatFailResult('missing category')
            }

            const validIncomeExpenseValues = ['income', 'expense', 'transfer', 'business expense', 'excluded expense']
            if (!checkRequiredValidValues(record, "incomeExpense", validIncomeExpenseValues)) {
                return formatFailResult(`Income/Expense/Transfer field must contain [${validIncomeExpenseValues}], found ${record["incomeExpense"] ? record["incomeExpense"].toLowerCase() : "''"}`)
            }

            if (!checkExpenseDrfaCategoryRule(record)) {
                return formatFailResult('DRFA Category must exist when the Category is an Expense')
            }

            if (!checkDrfaCategorySubCategoryRule(record)){
                return formatFailResult('DRFA Category and Subcategory must both be specified if one of them is specified')
            }

            // true is good data, false is bad data
            return formatPassResult()
        }

        mappedData.forEach(record => {
            const result = dqRules(record)
            if (result.pass) {
                passData.push(record);
            } else {
                failData.push({ ...record, _dqError: result.reason });
            }
        });

        setPassData(passData)
        setFailData(failData)
        if (failData.length > 0) {
            // setSnackbarAlertMessage(`We found ${failData.length} records with data quality issues`)
            // setSnackbarAlertSeverity(alert_severity_warning)
            // handleSnackbarOpen(true)
        snackbarContext.openSnackbarWarning(`We found ${failData.length} records with data quality issues`)    

        }
        else {
            // setSnackbarAlertMessage(`Good News! Your data set passed our quality checks!`)
            // setSnackbarAlertSeverity(alert_severity_success)
            // handleSnackbarOpen(true)
            handleNext()
        snackbarContext.openSnackbarSuccess('Good News! Your data set passed our quality checks!')    
        }
    }


    /////////////////////////////////////////
    /// handling file drop zone

    function getExcelSheetNames(file) {
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            const sheetNames = workbook.SheetNames;
            setSheetNames(sheetNames);
            setSheetName(sheetNames[0])
        };

        reader.readAsArrayBuffer(file);
    }



    const loadFile = () => {

        // function stripHtmlTags(str) {
        //     const tempDiv = document.createElement('div');
        //     tempDiv.innerHTML = str;
        //     return tempDiv.textContent || tempDiv.innerText || '';
        // }


        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });

            //const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];

            if (!worksheet) {
                // setSnackbarAlertMessage(`Sheet with name "${sheetName}" not found.`)
                // setSnackbarAlertSeverity(alert_severity_error)
                // handleSnackbarOpen(true)
            snackbarContext.openSnackbarError('Sheet with name "${sheetName}" not found.')    


                return;
            }


            let json = XLSX.utils.sheet_to_json(worksheet);
            // json = json.map(row => {
            //     return Object.fromEntries(
            //         Object.entries(row).map(([key, value]) => [key, typeof value === 'string' ? stripHtmlTags(value) : value])
            //     );
            // });

            setRawData(json);

            // Get the range of the worksheet
            const range = XLSX.utils.decode_range(worksheet['!ref']);

            // Get the first row (header row)
            const headers = [];
            for (let C = range.s.c; C <= range.e.c; ++C) {
                const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
                const cell = worksheet[cellAddress];
                const header = cell ? XLSX.utils.format_cell(cell) : `UNKNOWN ${C}`;
                headers.push(header);
            }

            setColumnNames(headers)
            if (headers.length < 3) {
                // setSnackbarAlertMessage(`Sheet with name "${sheetName}" has less than 3 columns.`)
                // setSnackbarAlertSeverity(alert_severity_error)
                // handleSnackbarOpen(true)

            snackbarContext.openSnackbarError('Sheet with name "${sheetName}" has less than 3 columns.')    
                return;
            }
            setSubcategoryColumn('')
            setCategoryColumn('')
            setDrfaCategoryColumn('')
            setDrfaSubcategoryColumn('')
            setIncomeExpenseColumn('')

        };

        reader.readAsArrayBuffer(file);
    };


    const onDropRejected = (fileRejections) => {

        // setSnackbarAlertMessage("Please select one Excel file")
        // setSnackbarAlertSeverity(alert_severity_error)
        // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError("Please select one Excel file")


    }

    const onDropAccepted = (files) => {

        const file = files[0]
        const fileName = file.name

        // setSnackbarAlertMessage(`found file ${fileName}`)
        // setSnackbarAlertSeverity(alert_severity_success)
        // handleSnackbarOpen(true)
        snackbarContext.openSnackbarSuccess(`found file ${fileName}`)

        setFile(file)
        getExcelSheetNames(file)

        // advance the step
        handleNext()

    }

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        maxFiles: 1,
        accept: {
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            'application/vnd.ms-excel': ['.xls'],
        },
        onDropRejected: onDropRejected,
        onDropAccepted: onDropAccepted
    });









    

    const title = "Load Case Categories"


    if (userProfileContext.isLoading) {
        return (
            <PortalFrame title={title}>
                <PageSkeleton/>

            </PortalFrame>
        )
    }

    if (!userProfileContext.userProfile.currentOrg || !userProfileContext.userProfile.currentCase) {
        return (
            <PortalFrame title={title}>
                You are not assigned to any cases for this organization

            </PortalFrame>
        )
    }

    const handleMutate = () => {
        //console.log('UPLOAD')
        mutateCategoryList(userProfileContext.userProfile,updates)
        handleNext()
    }




    const getCategoryGridColumns = (fieldList) => {
//'action','usage_count','original_category','original_drfaCategory','original_incomeExpense'
        const fieldTitles = { 
            subcategory: 'Subcategory', 
        category: 'Category', 
        drfaCategory: 'DRFA Category',
        drfaSubcategory: 'DRFA Subcategory',
         _dqError: 'Validation Error', 
         incomeExpense: 'Income/Expense/Transfer',
         action: 'Action',
         usage_count: 'Transactions Affected',
         original_category: 'Existing Category',
         original_drfaCategory: 'Existing DRFA Category',
         original_drfaSubcategory: 'Existing DRFA Subcategory',
         original_incomeExpense: 'Existing Income/Expense/Transfer'
         }

        const columns = fieldList.map(field => ({ field: field, headerName: fieldTitles[field], width: 250 }))

        return columns
    }

    
    const orgName = userProfileContext.userProfile.currentOrg.orgName
    const caseName = userProfileContext.userProfile.currentCase.caseName


    return (
        <PortalFrame title={title}>
            


            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                    const stepProps = {};
                    const labelProps = {};
                    if (isStepOptional(index)) {
                        labelProps.optional = (
                            <Typography variant="caption">Optional</Typography>
                        );
                    }
                    if (isStepSkipped(index)) {
                        stepProps.completed = false;
                    }
                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>

            {activeStep === steps.length &&
                <Fragment>
                    <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                    </Typography>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button onClick={handleReset}>Start Over</Button>
                    </Box>
                </Fragment>
            }

            {activeStep == step_index_select_category_file &&
                <Fragment>
                    <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <p>Drop your category Excel file here, or click to select a file</p>
                            <em>(Only *.xlsx and *.xls images will be accepted)</em>
                        </div>
                    </Box>



                </Fragment>
            }
            {activeStep == step_index_select_worksheet &&
                <Fragment>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Typography>File Name: {file.name}</Typography>
                    {sheetNames.length > 1 &&
                        <Fragment>
                            <Typography>Select the data worksheet:</Typography>
                            <Autocomplete
                                required
                                value={sheetName}
                                onChange={(event, newValue) => {
                                    setSheetName(newValue);
                                }}
                                //inputValue={inputValue}
                                // onInputChange={(event, newInputValue) => {
                                //     setInputValue(newInputValue);
                                // }}
                                id="worksheet-selection"
                                options={sheetNames}
                                sx={{ width: 400 }}
                                renderInput={(params) => <TextField {...params} label="Worksheet" />}
                            />
                        </Fragment>
                    }
                    {sheetNames.length == 1 &&
                        <Fragment>
                            <Typography>Only one sheet found, we will use this one: {sheetName}</Typography>
                        </Fragment>
                    }
                    <Button onClick={handleSelectSheet}>
                        Next
                    </Button>
                </Fragment>
            }
            {activeStep == step_index_select_subcategory_column &&
                <Fragment>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Typography>Select the Subcategory column:</Typography>
                    <Autocomplete
                        required
                        value={subcategoryColumn}
                        onChange={(event, newValue) => {
                            setSubcategoryColumn(newValue);
                        }}
                        //inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        id="worksheet-selection"
                        options={columnNames}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="Subcategory" />}
                    />
                    <Button onClick={handleSelectSubcategoryColumn}>
                        Next
                    </Button>
                </Fragment>

            }
            {activeStep == step_index_select_category_column &&
                <Fragment>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Typography>Select the Category column:</Typography>
                    <Autocomplete
                        required
                        value={categoryColumn}
                        onChange={(event, newValue) => {
                            setCategoryColumn(newValue);
                        }}
                        //inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        id="worksheet-selection"
                        options={columnNames2}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="Category" />}
                    />
                    <Button onClick={handleSelectCategoryColumn}>
                        Next
                    </Button>
                </Fragment>

            }

            {activeStep == step_index_select_drfa_category_column &&
                <Fragment>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Typography>Select the DRFA Category column:</Typography>
                    <Autocomplete
                        required
                        value={drfaCategoryColumn}
                        onChange={(event, newValue) => {
                            setDrfaCategoryColumn(newValue);
                        }}
                        //inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        id="worksheet-selection"
                        options={columnNames3}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="DRFA Category" />}
                    />
                    <Button onClick={handleSelectDrfaCategoryColumn}>
                        Next
                    </Button>
                </Fragment>

            }
            {activeStep == step_index_select_drfa_subcategory_column &&
                <Fragment>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Typography>Select the DRFA Subcategory column:</Typography>
                    <Autocomplete
                        required
                        value={drfaSubcategoryColumn}
                        onChange={(event, newValue) => {
                            setDrfaSubcategoryColumn(newValue);
                        }}
                        //inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        id="worksheet-selection"
                        options={columnNames4}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="DRFA Subcategory" />}
                    />
                    <Button onClick={handleSelectDrfaSubcategoryColumn}>
                        Next
                    </Button>
                </Fragment>

            }

            {activeStep == step_index_select_income_expense_column &&
                <Fragment>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Typography>Select the Income/Expense/Transfer Indicator column:</Typography>
                    <Autocomplete
                        required
                        value={incomeExpenseColumn}
                        onChange={(event, newValue) => {
                            setIncomeExpenseColumn(newValue);
                        }}
                        //inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        id="worksheet-selection"
                        options={columnNames5}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="Income/Expense Indicator" />}
                    />
                    <Button onClick={handleSelectIncomeExpenseColumn}>
                        Next
                    </Button>
                </Fragment>

            }
            {   // display data preview grid when selecting columns for use
                (
                    activeStep == step_index_select_subcategory_column ||
                    activeStep == step_index_select_category_column ||
                    activeStep == step_index_select_drfa_category_column ||
                    activeStep == step_index_select_drfa_subcategory_column ||
                    activeStep == step_index_select_income_expense_column) &&
                <Fragment>

                    {/*
                    <Typography>subcategory: {subcategoryColumn}</Typography>
                    <Typography>category: {categoryColumn}</Typography>
                    <Typography>DRFA: {drfaColumn}</Typography>
                */}
                    <CategoryDataPreview data={rawData} columnNames={columnNames} subcategoryColumn={subcategoryColumn} categoryColumn={categoryColumn} drfaCategoryColumn={drfaCategoryColumn} drfaSubcategoryColumn={drfaSubcategoryColumn} incomeExpenseColumn={incomeExpenseColumn} />


                </Fragment>
            }
            {
                activeStep == step_index_data_quality_check &&
                <Fragment>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Button onClick={handleNext}>
                        Next
                    </Button>
                    {failData.length > 0 &&
                        <Fragment>
                            <Alert severity={alert_severity_warning}>If you proceed, the data below will not be loaded, other records will be loaded (we will review those if you proceed).</Alert>
                            <ExportToExcel data={failData} fileName={orgName + " - " + caseName+ " Category Data Issues"} sheetName={"Case Category Data Issues"}/>


                            <CategoryDataPreview data={failData} columns={getCategoryGridColumns(Object.keys(failData[0]))} />
                        </Fragment>
                    }

                </Fragment>
            }
            {
                activeStep == step_index_change_review &&
                <Fragment>
                    <Button
                        color="inherit"
                        disabled={activeStep === 0}
                        onClick={handleBack}
                        sx={{ mr: 1 }}
                    >
                        Back
                    </Button>
                    <Button onClick={handleMutate}>
                        Next
                    </Button>
                    { true &&
                        <Fragment>

                            <Alert severity={alert_severity_info}>Here are the changes that will be applied</Alert>
                            <CategoryDataPreview data={updates} columns={getCategoryGridColumns(['action','usage_count','subcategory','original_category','category','original_drfaCategory','drfaCategory','original_drfaSubcategory','drfaSubcategory','original_incomeExpense','incomeExpense'])} />

 


                        </Fragment>
                    }

                </Fragment>
            }



        </PortalFrame>
    );
}