import { useState, useEffect, Fragment } from 'react';
import PortalFrame from '../ui/portal-frame.js';
import { queryCaseNeo4j } from '../../api/pydbcall.js';
import ExportToExcel from '../ui/export-to-excel.js';
import { Alert } from '@mui/material';
//import CategoryRuleCrudGrid from '../ui/grid-editable-category-rules.js';
import FlagRuleCrudGrid from '../ui/grid-editable-flag-rules.js';
import { Button } from '@mui/material';
import { applyRulestoCase } from '../../api/applyrulescall.js';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
//import CategoryRuleBulkLoadDialog from '../ui/dialog-category-rule-bulk-load.js';
import FlagRuleBulkLoadDialog from '../ui/dialog-flag-rule-bulk-load.js';
import UploadIcon from '@mui/icons-material/Upload';

import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';


import { useFlagsForCurrentCase } from '../../api/swrflagsforcurrentcase.js';
import mutateFlagRule from '../../api/mutateflagrule.js';
import { MUTATE_DELETE } from '../../api/mutate-actions.js';

export default function ManageFlagRules(props) {

    const alert_severity_success = 'success'
    const alert_severity_info = 'info'
    const alert_severity_warning = 'warning'
    const alert_severity_error = 'error'




    const [caseFlagRules, setCaseFlagRules] = useState([])
    const [caseFlagRulesFromDialog, setCaseFlagRulesFromDialog] = useState([])
    const [flagRulesLoading, setFlagRulesLoading] = useState(true)
    const [rule, setRule] = useState()
    const [openBulkLoadDialog, setOpenBulkLoadDialog] = useState(false)

    const title = props.title ? props.title : "Manage Flag Rules"

    const userProfileContext = useContext(UserProfileContext); 

    const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg?.orgID
    const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase?.caseID

    const flagQueryResult = useFlagsForCurrentCase(orgID, caseID, !userProfileContext.isLoading&&orgID&&caseID)




    // const handleGridAdd = (data) => {
    //     //console.log(`in handleGridAdd: ${JSON.stringify(data)}`)

    // }
    const handleGridUpdate = (data) => {
        //console.log(`in handleGridUpdate: ${JSON.stringify(data)}`)
        let found = false
        let newRules = caseFlagRules.map((record) => {
            if (!data.phrase.localeCompare(record.phrase, undefined, { sensitivity: 'accent' })) {
                found = true
                // udpate 
                //console.log(`updated`)
                return data
            }
            return record
        })

        if (!found) {
            // add
            //console.log(`added`)
            newRules.push(data)
        }
        //console.log(`setting new case category rules:${JSON.stringify(newRules)}`)

        setCaseFlagRules(
            newRules
        )

        mutateFlagRule(userProfileContext.userProfile,data)

    }
    const handleGridDelete = (data) => {
        //console.log(`in handleGridDelete: ${JSON.stringify(data)}`)
        setCaseFlagRules(caseFlagRules.filter((record) => data.phrase.localeCompare(record.phrase, undefined, { sensitivity: 'accent' })))
        mutateFlagRule(userProfileContext.userProfile,data,MUTATE_DELETE)
        

    }
    const handleRowClick = (rule) => {
        setRule(rule)
    }

    const handleBulkLoadClick = () => {
        setOpenBulkLoadDialog(true)
    }

    const handleBulkLoadCancel = () => {
        setOpenBulkLoadDialog(false)
    }

    const handleBulkLoadSubmit = (updatedRules) => {
        //console.log(`in handle bulk load submit, updatedRules=${JSON.stringify(updatedRules)}`)
        const updatedDict = {};
        let adds = []
        let deletes = []


        updatedRules.forEach(item => {
            if (item.action == 'ADD') {
                adds.push({ ...item, proposed_count: 0, confirmed_count: 0 })
            }
            else if (item.action == 'UPDATE') {
                updatedDict[item.phrase.toLowerCase()] = item;
            }
            else if (item.action == 'DELETE') {
                deletes.push(item)
            }


        });

        //console.log(`in handle bulk load submit, updatedDict=${JSON.stringify(updatedDict)}`)


        // process updates
        let updatedExisting = caseFlagRules.map((existingRule) =>
            (updatedDict[existingRule.phrase.toLowerCase()] ? updatedDict[existingRule.phrase.toLowerCase()] : existingRule)
        );

        // process deletes
        updatedExisting = updatedExisting.filter((record) =>
            !deletes.find((deleteRecord) => !deleteRecord.phrase.localeCompare(record.phrase, undefined, { sensitivity: 'accent' }))
        )
        // process adds
        //console.log(`adding records: ${JSON.stringify(adds)}`)

        adds.forEach((newRecord) => updatedExisting.push(newRecord))

        setCaseFlagRules(updatedExisting);

        //console.log(`updated records: ${JSON.stringify(updatedExisting)}`)




    }




    useEffect(() => {

        // TODO : Move this code into SWR call

        const formatFlagRuleResult = (records) => {
            return records.map(record => ({
                phrase: record.rule.phrase,
                flag: record.flag.name,
                proposed_count: record.proposed_count,
                confirmed_count: record.confirmed_count
            })

            )

        }



        const getCaseFlagRules = async () => {
            setFlagRulesLoading(true)
            const orgID = userProfileContext.userProfile.currentOrg?.orgID
            const caseID = userProfileContext.userProfile.currentCase?.caseID

            if(!orgID || !caseID){
                return[]
            }

            const flagRuleQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(flag:Flag)<-[:ASSIGNS]-(rule:FlagRule) OPTIONAL MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount)-[:HAS]->(bs:BankStatement)-[rbst:CONTAINS]->(t:Transaction) OPTIONAL MATCH (t)-[rtps:PROPOSED]->(flag) WHERE rtps.matched_basis = rule.phrase  OPTIONAL MATCH (t)-[rtcs:CONFIRMED]->(flag) WHERE rtcs.matched_basis = rule.phrase RETURN flag, rule, count(rtps) as proposed_count, count(rtcs) as confirmed_count"
            const params = {
                orgID: orgID,
                caseID: caseID
            }
            const caseFlagRuleResult = await queryCaseNeo4j(flagRuleQuery, params)
            //console.log(`Case Categories: ${JSON.stringify(caseCategoryResult)}`)
            setCaseFlagRules(formatFlagRuleResult(caseFlagRuleResult.records))
            setFlagRulesLoading(false)


        }

        if (!userProfileContext.isLoading) {
            //console.log(`getting case category rules....`)
            getCaseFlagRules()
        }

    }, [userProfileContext.isLoading, caseFlagRulesFromDialog])




    if (userProfileContext.isLoading || flagQueryResult.isLoading) {
        return (
            <PortalFrame title={title}>
                <PageSkeleton />
            </PortalFrame>
        )
    }

    if (!userProfileContext.userProfile.currentOrg || !userProfileContext.userProfile.currentCase) {
        return (
            <PortalFrame title={title}>
                You are not assigned to any cases for this organization
            </PortalFrame>
        )
    }

    const orgName = userProfileContext.userProfile.currentOrg.orgName
    const caseName = userProfileContext.userProfile.currentCase.caseName


    const handleClickApplyRules = async () => {
        applyRulestoCase(orgID, caseID)
        //console.log(`need to implement function`)
    }




    return (
        <PortalFrame title={title} orgName={orgName} caseName={caseName} >


            {flagQueryResult.data.length >= 0 &&
                <Fragment>
                     
                    <FlagRuleBulkLoadDialog open={openBulkLoadDialog} onSubmit={handleBulkLoadSubmit} onCancel={handleBulkLoadCancel} caseFlagRules={caseFlagRules} caseFlags={flagQueryResult.data}  />
                    <Button color="primary" startIcon={<UploadIcon />} onClick={handleBulkLoadClick}>
                        Upload Rules
                    </Button>
                    
                    {caseFlagRules.length > 0 &&
                        <Fragment>
                            <ExportToExcel data={caseFlagRules} fileName={orgName + " - " + caseName + " Flag Rules"} sheetName={"Case Flag Rules"} />
                            <Button color="primary" startIcon={<CollectionsBookmarkIcon />} onClick={handleClickApplyRules}>
                                Apply Rules to Case
                            </Button>
                        </Fragment>
                    }

                    
                    <FlagRuleCrudGrid rules={caseFlagRules} flags={flagQueryResult.data} handleRowClick={handleRowClick}  onUpdate={handleGridUpdate} onDelete={handleGridDelete} />
                    
                </Fragment>
            }{/*flagQueryResult.data.length == 0 &&
                <Alert severity={alert_severity_warning} variant='filled' sx={{ width: '100%' }}>There are no categories available for this case.  Upload will not be able to complete.  Please add case categories first.</Alert>
            */}

        </PortalFrame>
    );
}