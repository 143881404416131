import React from "react";
import { Box, Typography } from '@mui/material';
import Title from './title';

import { DataGrid } from "@mui/x-data-grid";

//import {postNeo4j} from '../../api/pydbcall';



function preventDefault(event) {
  event.preventDefault();
}
function enableDefault(event) {
  return;
}

export default function DataPreviewGrid(props) {
    const data=props.data?props.data:[]
    let columnNames=props.columnNames

    if(data.length > 0 && !columnNames){
      columnNames=Object.keys(data[0])
    }
    else{
      columnNames=[]
    }
    
    let rows=[];
    let columns=[];
    rows=data.map(
      (row,index) => ({'id':index, ...row})
    );
   
    
    columns=props.columns?props.columns:columnNames.map(
      (col) => ({field: col, headerName: col, width: 200})
    )
  

    
  
  return (
    <React.Fragment>
      <Title>{props.preview_title}</Title>
      
      
      <Box
                sx={{
                  // flex: 1,
                  //       //display: 'flex',
                  //       display: 'inline-block',
                  //       //width: '50vw',
                  //       width: '100%',
                  //       //height: '75vh',
                  //       alignItems: 'center',
                  //       justifyContent: 'center',
                    
                  //   maxHeight: '75vh',
                  
                    display: 'flex',
                    flexDirection: 'column',
                    //height: '70vh',  // Ensure height is set
                    width: '100%',
                  
                }}
            >
      <DataGrid sx={{
                    // flexGrow: 1,
                    // '& .MuiDataGrid-virtualScroller': {
                    //   overflow: 'auto !important',  // Ensure the virtual scroller can overflow
                    // },
                    //maxHeight: '75vh',
                    // '& .mytheme--subcategory-group': {
                    //   backgroundColor: 'rgba(255, 7, 0, 0.55)',
                    // },
                    // '& .mytheme--category-group': {
                    //   backgroundColor: 'rgba(7, 255, 0, 0.55)',
                    // },
                    // '& .mytheme--drfacategory-group': {
                    //   backgroundColor: 'rgba(0, 7, 255, 0.55)',
                    // },
                    // '& .mytheme--incomeexpense-group': {
                    //   backgroundColor: 'rgba(128, 128, 128, 0.55)',
                    // },
                    
                }} rows={rows} columns={columns} 
                //autoHeight={false}  // Ensure autoHeight is false
                //disableExtendRowFullWidth
                />
      </Box>
      
    </React.Fragment>
  );
}