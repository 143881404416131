import { useState, Fragment, useEffect } from 'react';
import { Button, Box, Skeleton } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//import { useCasesForCurrentUser, getCasesForCurrentUser } from "../../api/swrcasesforcurrentuser.js";
import { useOrgsForCurrentUser, getOrgsForCurrentUser } from '../../api/swrorgsforcurrentuser.js';
import { fetchUserAttributes } from 'aws-amplify/auth';
import { AutocompleteElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form-mui';
import { FormContainer } from 'react-hook-form-mui';
import {mutateTouchUserOrgAssignment} from '../../api/mutatetouchorgassignment.js';
import { useSWRConfig } from 'swr'
import UserProfileContext from './userprofilecontext.js';
import { useContext } from 'react';


function getOrgValue(orgID, orgName) {
  return { id: orgID, label: orgName }
}

function getOrgValues(data, current_org) {

  const obj = "org"
  const id_field = "orgID"
  const label_field = "orgName"

  //console.log(`in getOrgValues, data=${JSON.stringify(data)}`)

  let filtered = data.filter((record) => record[obj][id_field] == current_org)
  let selected = filtered[0]

  let values = data.map((record) => {
    //return { id: record[obj][id_field], label: record[obj][label_field] }
    return getOrgValue(record[obj][id_field], record[obj][label_field])
  }
  )
  
  return { selected: selected, values: values }
}

export default function SelectCurrentOrgDialog(props) {

  //const currentOrgID = props.currentOrgID
  //const currentOrgName = props.currentOrgName
  //const currentUserID = props.currentUserID
  const parentOnSubmit = props.onSubmit ? props.onSubmit : () => { }
  const parentOnCancel = props.onCancel ? props.onCancel : () => { }
  const title = props.title ? props.title : "Select Current Organization"


  const { cache, mutate, ...extraConfig } = useSWRConfig()
  const userOrgs = useOrgsForCurrentUser();
  const userProfileContext=useContext(UserProfileContext);

  const _defaultValues = { "current_org": getOrgValue(userProfileContext.userProfile.currentOrg.orgID
    , userProfileContext.userProfile.currentOrg.orgName
  ) }


  const [defaultValues, setDefaultValues] = useState(_defaultValues)
  const [values, setValues] = useState(_defaultValues)
  //const [orgOptions, setOrgOptions] = useState([])
  //const [selectedOrg, setSelectedOrg] = useState()
  //const [userID, setUserID] = useState()

  

  // useEffect(() => {

  //   // const getUserID = () => {
  //   //   //const attrs = await fetchUserAttributes()
      
  //   //   //setUserID(attrs.sub)
  //   //   setUserID(currentUserID)
  //   // }

  //   // getUserID()

  //   if (currentOrgID) {
  //     const _defaultValues = { "current_org": getOrgValue(currentOrgID, currentOrgName) }
  //     //console.log(`defaultValues: ${JSON.stringify(_defaultValues)}`)
  //     setValues(_defaultValues)
  //     setDefaultValues(_defaultValues)
  //   }


  // }, [currentOrgID, currentOrgName])

  
  
  const formContext = useForm({
    defaultValues: defaultValues, values
  });

  const { handleSubmit, reset, control, setValue, formState: { errors } } = formContext;



  const handleCancel = () => {
    parentOnCancel()
  };

  const handleAfterMutate = (mutateResult) => {
    //console.log(`touch case mutate result=${JSON.stringify(mutateResult)}`)
    const newOrg=mutateResult.records[0].o
    const newCase=mutateResult.records[0].c
    //console.log(`touch case new case result=${JSON.stringify(newCase)}`)
    
    const newUserProfile=userProfileContext.userProfile
    newUserProfile.currentCase=newCase
    newUserProfile.currentOrg=newOrg
    //userProfileContext.setUserProfile(newUserProfile)
    //console.log(`new user context: ${JSON.stringify(userProfileContext.userProfile)}`)
  }

  const onSubmit = (data) => {
    const newOrgID=data.current_org.id
    //console.log(`in onSubmit of org select, new value=${JSON.stringify(data)}`)
    setValues(data)
    const userID=userProfileContext.userProfile.currentUser.userID
    
    //userProfileContext.invalidate()
    mutateTouchUserOrgAssignment(newOrgID,userID).then((result)=>handleAfterMutate(result))
    
    parentOnSubmit(data)
    };


  if (userProfileContext.isLoading||userOrgs.isLoading || !userOrgs.data.records || userOrgs.data.records.length == 0) {
    return (
      <Fragment>

        <Dialog open={props.open} onClose={handleCancel}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
          <Skeleton variant="rectangular" sx={{minWidth: 500, minHeight: 300}} />
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }

  
  let options = []

  if (userOrgs.data.records.length > 0) {
    
    let orgs = getOrgValues(userOrgs.data.records, userProfileContext.userProfile.currentOrg.orgID)

    //console.log(`orgs=${JSON.stringify(orgs)}`)
    options = orgs.values

  }



  
  return (
    <Fragment>

      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{p:2, minWidth: 500, minHeight: 300}}>
          <FormContainer values={values} defaultValues={defaultValues} formContext={formContext} handleSubmit={handleSubmit}>
          <Box sx={{pt:1}}>
            <AutocompleteElement name="current_org" options={options} setValue={setValue} label="Current Organization" required />
          </Box>

            <Button
              style={{
                //padding: "20px",
                margin: "10px 0px",
                maxWidth: "75px",
              }}
              onClick={handleSubmit(onSubmit)} variant={"contained"}>
              Save
            </Button>


          </FormContainer>
        </DialogContent>
      </Dialog>
    </Fragment>
  );


}