// MainComponent.jsx
import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
//import PopupComponent from './PopupComponent';


function fixUrlForRule(cssRule) {
    return cssRule.cssText
        .split('url(')
        .map(line => {
            if (line[1] === '/') {
                return `${line.slice(0, 1)}${window.location.origin}${line.slice(1)}`
            }
            return line
        })
        .join('url(')
}

function getKeyFrameText(cssRule) {
    const tokens = ['@keyframes', cssRule.name, '{']
    Array.from(cssRule.cssRules).forEach(cssRule => {
        // type === CSSRule.KEYFRAME_RULE should always be true
        tokens.push(cssRule.keyText, '{', cssRule.style.cssText, '}')
    })
    tokens.push('}')
    return tokens.join(' ')
}

function copyStyles(source, target) {
    // Store style tags, avoid reflow in the loop
    const headFrag = target.createDocumentFragment()

    Array.from(source.styleSheets).forEach(styleSheet => {
        // For <style> elements
        let rules
        try {
            rules = styleSheet.cssRules
        } catch (err) {
            console.error(err)
        }

        // For @font-face rule, it must be loaded via <link href=''> because the
        // rule contains relative path from the css file.
        const isFontFaceRule =
            rules &&
            Object.values(rules).some(r => r instanceof CSSFontFaceRule) &&
            styleSheet.href

        if (rules) {
            // IE11 is very slow for appendChild, so use plain string here
            const ruleText = []

            // Write the text of each rule into the body of the style element
            Array.from(styleSheet.cssRules).forEach(cssRule => {
                const { type } = cssRule

                // Skip unknown rules
                if (type === CSSRule.UNKNOWN_RULE) {
                    return
                }

                let returnText = ''

                if (type === CSSRule.KEYFRAMES_RULE) {
                    // IE11 will throw error when trying to access cssText property, so we
                    // need to assemble them
                    returnText = getKeyFrameText(cssRule)
                } else if (
                    [CSSRule.IMPORT_RULE, CSSRule.FONT_FACE_RULE].includes(type)
                ) {
                    // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5)
                    // to handle local imports on a about:blank page
                    // '/custom.css' turns to 'http://my-site.com/custom.css'
                    returnText = fixUrlForRule(cssRule)
                } else {
                    returnText = cssRule.cssText
                }
                ruleText.push(returnText)
            })

            const newStyleEl = target.createElement('style')
            newStyleEl.textContent = ruleText.join('\n')
            headFrag.appendChild(newStyleEl)
        } else if (styleSheet.href) {
            // for <link> elements loading CSS from a URL
            const newLinkEl = target.createElement('link')

            newLinkEl.rel = 'stylesheet'
            newLinkEl.href = styleSheet.href
            headFrag.appendChild(newLinkEl)
        }
    })

    target.head.appendChild(headFrag)
}

const PopupDialogWindow = ({ children, open, url, title, name, features, onBlock, onOpen, center }) => {
    const popupRef = useRef(null);

    const defaultUrl = ''
    const defaultName = ''
    const defaultFeatures = 'width=600,height=400'
    //const [opened,setOpened]=useState(false)


    // useEffect(()=>{
    //     console.log(`got here`)
    //     if(popupRef.current){
    //         console.log(`also got here`)
    //         console.log(popupRef.current.document.getElementById('new-window-container'))
    //         //ReactDOM.createPortal(children, popupRef.current.document.getElementById('new-window-container'))
    //         ReactDOM.render(children, popupRef.current.document.getElementById('new-window-container'));
    //     }

    // },[opened]

    //)
    useEffect(() => {
        // Handle receiving messages from the popup window
        const handleMessage = (event) => {
            if (event.origin !== window.location.origin) return; // Ensure message comes from the same origin
            //console.log('Message from popup:', event.data);
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        }

    }, []);

    useEffect(() => {




        let checkPopupClosed

        if (open) {
            openPopup()

            checkPopupClosed = setInterval(() => {
                if (popupRef.current && popupRef.current.closed) {
                    clearInterval(checkPopupClosed);
                    popupRef.current = null;
                }
            }, 1000);
        }



        return () => {

            if (checkPopupClosed) {
                clearInterval(checkPopupClosed);
            }

            if (popupRef.current && !popupRef.current.closed) {

                popupRef.current.close();
                popupRef.current = null
            }
        };
    }, [open])

    const openPopup = () => {
        const popupWindow = window.open(
            url ? url : defaultUrl,
            name ? name : defaultName,
            features ? features : defaultFeatures
        );

        if (popupWindow) {
            popupRef.current = popupWindow;

            popupWindow.document.title = title



            // Ensure the popup window's DOM is ready before rendering
            setTimeout(() => {
                const popupDocument = popupWindow.document;

                //const container = popupDocument.createElement('div')

                // container.setAttribute('id', 'new-window-container')
                // popupDocument.body.appendChild(container)

                console.log(`got here 1`)
                let container = popupDocument.getElementById(
                    'new-window-container'
                )
                if (container === null) {
                    //console.log(`got here 2`)
                    container = popupDocument.createElement('div')
                    container.setAttribute('id', 'new-window-container')
                    popupDocument.body.appendChild(container)
                } else {
                    // Remove any existing content
                    //console.log(`got here 3`)
                    const staticContainer = popupDocument.getElementById(
                        'new-window-container-static'
                    )
                    popupDocument.body.removeChild(staticContainer)
                }
                // console.log(`container=${container}`)
                // console.log(container)
                // console.log(`children=${children}`)
                // console.log(children)

                //popupDocument.body.innerHTML = '<div id="popup-root"></div>';
                copyStyles(document, popupDocument)
                //ReactDOM.render(children, popupDocument.getElementById('popup-root'));
                //ReactDOM.createPortal(<p>This child is placed in the document body.</p>, popupDocument.getElementById('new-window-container'))


                
                // Add ResizeObserver to the popup window
                const resizeObserver = new ResizeObserver((entries) => {
                    for (let entry of entries) {
                        console.log('Popup resized:', entry.contentRect);
                    }
                });

                //resizeObserver.observe(container);
                resizeObserver.observe(document.body);

                // Clean up ResizeObserver when the popup is closed
                popupWindow.addEventListener('beforeunload', () => {
                    resizeObserver.disconnect();
                });

                
                
                const root = createRoot(container);
                root.render(children);


            }, 0);

            if (typeof onOpen === 'function') {
                onOpen(popupWindow)
            }

        }
        else {
            // Handle error on opening of new window.
            if (typeof onBlock === 'function') {
                onBlock(null)
            } else {
                console.warn('A new window could not be opened. Maybe it was blocked.')
            }

        }


    };

    return (
        <div>
        </div>
    );
};

export default PopupDialogWindow;
