import React, { useMemo, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { bankAccountFormatter, currencyFormatter } from './type-utilities';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
} from '@mui/material';

const FlaggedTransactionsDetails = ({ reportData = [] }) => {
  // Define the reportRef at the top level
  const reportRef = useRef();



  // Ensure hooks are called before any early returns
  if (reportData.length === 0) {
    return (
      <Container sx={{ flexGrow: 1, padding: 2 }}>
        <Typography variant="h6">No data available.</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ flexGrow: 1, padding: 2 }}>
      {/* Place the Print Button */}
      <Box mt={2}>
        <ReactToPrint
          trigger={() => (
            <Button variant="contained" color="primary">
              Print Report
            </Button>
          )}
          content={() => reportRef.current}
        />
      </Box>

      {/* Wrap the content you want to print with ref={reportRef} */}
      <div ref={reportRef}>
        {/* Report Title */}
        <Paper sx={{ padding: 2, textAlign: 'center', marginBottom: 2 }}>
          <Typography variant="h4" component="h1">
            Spend By Flag Detail Report
          </Typography>
        </Paper>

        {/* Details Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Date</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Description</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Bank Name</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Account Number</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Amount</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Flag</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.map((row, index) => {

                return (
                  <TableRow key={index}>
                    <TableCell>{row.date}</TableCell>
                    <TableCell>{row.description}</TableCell>
                    <TableCell>{row.bank_name}</TableCell>
                    <TableCell>{bankAccountFormatter(row.account_number)}</TableCell>
                    <TableCell style={{ whiteSpace: 'nowrap' }}> {currencyFormatter(row.amount)}</TableCell>
                    <TableCell>{row.flag}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};

export default FlaggedTransactionsDetails;
