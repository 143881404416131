import { useContext } from 'react';
import { queryCaseNeo4j } from './pydbcall';
import useSWR from 'swr'
import { dateGetter } from '../components/ui/type-utilities';




function postProcessResult(records) {

  return records.map(record => ({
    subcategory: record.subcat.name,
    category: record.cat?.name?record.cat?.name:"UNKNOWN",
    drfaCategory: record.drfa?.name,
    incomeExpense: record.subcat.type,


  })
  )

}

async function getSubcategoriesForCurrentCase(orgID, caseID, includeDefault=true) {
  let neoResult


  const defaultLabel=includeDefault?"":":DefinedCategory"
  const qry = `MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(subcat:Subcategory${defaultLabel}) OPTIONAL MATCH (subcat)-[:WITHIN]->(cat:Category) OPTIONAL MATCH (subcat)-[:MAPS_TO]->(drfa:DRFACategory) OPTIONAL MATCH (subcat)<-[]-(t:Transaction) RETURN subcat, cat, drfa, count(t) as usage_count`


  const parameters = { orgID: orgID, caseID: caseID }

  await queryCaseNeo4j(qry, parameters).then((result) => {
    //  console.log(`neo result=${JSON.stringify(result)}`)
    neoResult = postProcessResult(result.records)
    //console.log(`processed result=${JSON.stringify(neoResult)}`)

  })

  return neoResult
}

export function useSubcategoriesForCurrentCase(orgID, caseID, shouldFetch, includeDefault=false) {

  const result = useSWR(shouldFetch ? ['SUBCATEGORIES_FOR_CURRENT_CASE', orgID, caseID, includeDefault] : null, ([path, orgID, caseID, includeDefault]) => getSubcategoriesForCurrentCase(orgID, caseID, includeDefault));
  return result
}
