import React from "react";
//import { postNeo4j } from '../../api/pydbcall';
import {useCaseQuery} from '../../api/swrdbcall';
import cfgs from '../../cfg/tiles.json';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { blue } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import ModalDataPreview from "./modal-data-preview";
import Skeleton from '@mui/material/Skeleton';



const Tile = ({ orgID, caseID, tile_key, locale = 'en-US' }) => {
  //const initialLabel = "";

  //const [tileValue, setTileValue] = useState(initialLabel);
  //const [loading, setLoading] = useState(true);
  //const [first, setFirst] = useState(true);


  //console.log(`got here 1 ${tileValue}`);
 
  let cfg = cfgs[tile_key];
  let format = JSON.parse(cfg.format);
  const formatter = new Intl.NumberFormat(locale, format);
  const parms={
    orgID: orgID,
    caseID: caseID
  }

  const queryResult= useCaseQuery(cfg.query,parms);

  function formatTileValue(data,formatter,label){
    //console.log(`tile loading complete, data=${JSON.stringify(data)}`)
    let result = data["records"][0].Result;
    return formatter.format(result) + " " + label;
  }

  //async function postTileQuery(qry, formatter, label) {
  //  if (first) {
  //    setFirst(false);
  //    var result = 0;
//
//      //console.log('calling postNeo4j');
//      const bodyjson = await postNeo4j(qry);
//
//      //console.log('entering postNeo4j.then');
//      //console.log('results....');
//      result = bodyjson["records"][0].Result;
//      //console.log(`TILE RESULT ${result}`);
//      setTileValue(formatter.format(result) + " " + label);
//      setLoading(false);
//
//    }

  //}

  
  //postTileQuery(cfg.query, formatter, cfg.label);

  

  //console.log(`CFG.PREVIEW_QUERY=${cfg.preview_query}`);


  //console.log(`cfg.format=${cfg.format}`);
  //let publicUrl = process.env.PUBLIC_URL+'/'
  //let imagealt = 'image'

  if (queryResult.isLoading || !queryResult.data) {
    return (
      <Grid item xl={3} lg={4} md={6} xs={12} sx={{
        display: 'flex',
        flexDirection: 'row',
        //minWidth: 200,
        //maxWidth: 300,
        //minHeight: 150,
        //maxHeight: 300,
        //padding: 1,
        //gap: 10
      }}>
        <Skeleton variant="rectangular" width={300} height={200} />
      </Grid>
    );
  }

  return (




    <Grid item xl={3} lg={4} md={6} xs={12} sx={{
      display: 'flex',
      flexDirection: 'row',
      //minWidth: 200,
      //maxWidth: 300,
      //minHeight: 150,
      //maxHeight: 300,
      //padding: 1,
      //gap: 10
    }}>


      <Card elevation={2} sx={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 300,
        maxWidth: 300,
        //minHeight: 150,
        //maxHeight: 300,
        //padding: 1,
        //gap: 10
      }}>
        <CardHeader sx={{ minHeight: 75, maxHeight: 75 }} avatar={
          <Avatar sx={{ bgcolor: blue[500] }} >

            <i className={cfg.icon} />
          </Avatar>
        }
          title={<Typography sx={{ fontSize: 18 }} color="text.primary" gutterBottom>{formatTileValue(queryResult.data,formatter,cfg.label)}</Typography>} />


        <CardContent sx={{
          //minWidth: 300,
          //maxWidth: 300,
          minHeight: 75,
          //maxHeight: 300,
          //padding: 1,
          //gap: 10
        }}>



          <Typography variant="body2">{cfg.description}</Typography>
        </CardContent>
        <CardActions>
          <ModalDataPreview parms={parms} button_title="Learn More" {...cfg} />
        </CardActions>




      </Card>
    </Grid>






  );
}


export default Tile;