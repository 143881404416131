import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";


export default async function mutateFlagRule(currentUserProfile, data, action=MUTATE_UPDATE) {
    
    //console.log(`entering mutateFlagRule, action=${action}`)
    if (action != MUTATE_UPDATE && action != MUTATE_DELETE){
        throw Error(`unsupported action: ${action}`)
    }

    
    if(!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser){
        throw Error(`pass a valid current user profile to this call`)
    }

    const orgID=currentUserProfile.currentOrg.orgID
    const caseID=currentUserProfile.currentCase.caseID
    const email=currentUserProfile.currentUser.email


    //console.log(`in mutateFlagRule, data=${JSON.stringify(data)}`)
    const delete_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(rule:FlagRule {phrase:$phrase}) DETACH DELETE rule'
    const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID}) WITH c,dt MERGE (c)-[:USES]->(flag:Flag {name:$flag}) MERGE (c)-[:USES]->(rule:FlagRule {phrase:$phrase}) SET rule._updateDate=dt, rule._updateUser=$currentUser MERGE (flag)<-[r:ASSIGNS]-(rule) SET r._updateDate=dt, r._updateUser=$currentUser  WITH rule, flag OPTIONAL MATCH (rule)-[r2:ASSIGNS]->(flag2:Flag) where flag2.name<>flag.name DELETE r2 return rule'
    const parms = { 
        "orgID": orgID,
        "caseID": caseID, 
        "currentUser": email,
        "phrase": data.phrase,
        "flag": data.flag
        
    }

      console.log(`in mutateFlagRule, parms=${JSON.stringify(parms)}`)

    // if (action === MUTATE_UPDATE && search_before_create){
    //     //console.log('IN MUTATE SEARCH BEFORE CREATE AREA')
        
    //             const sb4create = 'MATCH (case:Case {caseName:$caseName})-[r:BELONGS_TO]->(org:Organization {orgID:$orgID}) WHERE case.caseID <> $caseID RETURN case'
    //     const sb4result = mutate(['CASE', data.caseID], mutateCaseNeo4j(update_mutation, parms,sb4create))    
    //     //console.log(JSON.stringify(sb4result))
    //     return sb4result
    // }

    
    let mutation = action==MUTATE_UPDATE?update_mutation:delete_mutation
    
    const result = mutate(['FLAGRULE', data.phrase], mutateCaseNeo4j(mutation, parms))
    
    console.log(`in mutateCase: result=${JSON.stringify(result)}`)
    return result
}

export async function mutateFlagRuleList(currentUserProfile, data) {
    data.forEach(async element => {
        let action = MUTATE_UPDATE
        if(element.action == 'DELETE'){
            action = MUTATE_DELETE
        }
        await mutateFlagRule(currentUserProfile,element,action)
    });
}