import React, {Fragment, useState, useEffect} from "react";
import PortalFrame from '../ui/portal-frame.js';

import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import { findMissingStatements } from "../../api/missingstatementscall.js";
import StatementQualityReport from "../ui/report-statement-quality.js";

export default function StatementQualityReportPage() {
  
  



  
  const userProfileContext=useContext(UserProfileContext);

  //const userProfile = userProfileContext.userProfile  // add new way

  const [missingStatements, setMissingStatements] = useState();

  
  
  useEffect(() => {
    async function fetchData(userProfile) {
      const orgID=userProfile.currentOrg?.orgID
      const caseID=userProfile.currentCase?.caseID
      if(!orgID || !caseID){
        return
      }
      const missingStatements = await findMissingStatements(userProfile.currentOrg.orgID, userProfile.currentCase.caseID);
      setMissingStatements(missingStatements);
    }
    if(userProfileContext.isLoading) return;
    fetchData(userProfileContext.userProfile);
  }, [userProfileContext.userProfile]);



  if (userProfileContext.isLoading) {
  
    return (
      <div></div>
    )
  }

  const orgName = userProfileContext.userProfile.currentOrg?.orgName
  const caseName = userProfileContext.userProfile.currentCase?.caseName

  return (
    <PortalFrame title={"Statement Quality Report"} orgName={orgName} caseName={caseName}>
      {!userProfileContext.userProfile.currentCase && <div>You are not assigned to a case for this organization</div>}
      {!userProfileContext.isLoading && userProfileContext.userProfile.currentOrg && userProfileContext.userProfile.currentCase &&
        <Fragment>
          <StatementQualityReport missingStatements={missingStatements}/>
      
        </Fragment>
      }
    </PortalFrame>
  );
};