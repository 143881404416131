import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReportCrudGrid from './grid-editable-report-data';
import DRFAReport from './report-drfa';
import { currencyFormatter } from './type-utilities';
import { Box, Button } from '@mui/material';
import ReactToPrint from 'react-to-print';


// const personalInfo = {
//     name: '',
//     address: '',
//     phone: '',
//     email: ''
//   };

export default function EditableDRFAReport(props){
    const reportData = props.reportData ? props.reportData : [];
    const [data, setData] = useState([]);
    const reportRef = useRef();
    
    useEffect(() => {
        const newData = formatData(reportData);
        //const newData = { personalInfo: personalInfo, categoryData: categoryData };
        setData(newData);
    }, [props.reportData]);

    const formatData = (reportData) => {
        const categoryData = reportData.map((category, index) => ({...category, id: index}));
        //console.log(`categoryData: ${JSON.stringify(categoryData)}`)
        return categoryData;
    }

    const gridColumns = [
        
        {
          field: 'drfa_category',
          headerName: 'DRFA Category',
          width: 400,
          align: 'left',
          editable: false
        },
        {
            field: 'drfa_subcategory',
            headerName: 'DRFA Subcategory',
            width: 400,
            align: 'left',
            editable: false
          },
        {
          field: 'amount',
          headerName: 'Amount',
          type: 'number',
          valueFormatter: currencyFormatter,
          width: 150,
          align: 'left',
          headerAlign: 'left',
          editable: true,
        },
    
    
        
      ];

    return (
        <Fragment>
      <Box 
        display="flex" 
        flexDirection="column" 
        height="100vh" // Use full viewport height
      >
        {/* Top component takes 30% of the height */}
        <Box flex="0 0 30%" overflow="auto">
          <ReportCrudGrid
            rows={data}
            setRows={setData}
            columns={gridColumns}
          />
        </Box>

        {/* Bottom component takes 70% of the height */}
        <Box flex="0 0 70%" overflow="auto" ref={reportRef}>
          <DRFAReport data={data} />
        </Box>
        <Box mt={2}>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary">
                Print Report
              </Button>
            )}
            content={() => reportRef.current} // Specify which component to print
          />
        </Box>
      </Box>
    </Fragment>

    )


}