import React, { useState, useRef, useEffect, useContext } from "react";
import PortalFrame from '../ui/portal-frame';
//import { useCurrentUserProfile, getCurrentUserProfile } from "../../api/swrcurrentuserprofile";
import { useSWRConfig } from 'swr'
//import RenderHighlightAreasExample from "../ui/view-statement-pdf";
//import NewWindow from 'react-new-window';
//import PopupWindow from "../ui/old/popup-window.js";
import PopupDialogWindow from "../ui/popup-window.js";
//import PopupDialogWindow from "../ui/old/popup-dialog.js";
import ReactDOM from 'react-dom';
//import PDFSidePanel from "../ui/pdf-side-panel";
import PDFReviewer from "../ui/review-jump-page-pdf.tsx";
import SnackbarContext from '../ui/snackbar-context.js';

//////// S3 access 
import AWS from 'aws-sdk';
import amplifyConfig from '../../amplifyconfiguration.json'
import { fetchAuthSession } from 'aws-amplify/auth';
import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import { getPage } from "@react-pdf-viewer/core";
//////// S3 access 



// const PopupComponent = ({ data, highlightedTxn, fileUrl }) => {
//     return (
//         <div>
//             <h1>Popup Window</h1>
//             <p>Data passed from main window: {data}</p>
//             <PDFReviewer highlightedTxn={highlightedTxn} fileUrl={fileUrl} />
//         </div>
//     );
// };

// function fixUrlForRule(cssRule) {
//     return cssRule.cssText
//       .split('url(')
//       .map(line => {
//         if (line[1] === '/') {
//           return `${line.slice(0, 1)}${window.location.origin}${line.slice(1)}`
//         }
//         return line
//       })
//       .join('url(')
//   }

// function getKeyFrameText(cssRule) {
//     const tokens = ['@keyframes', cssRule.name, '{']
//     Array.from(cssRule.cssRules).forEach(cssRule => {
//       // type === CSSRule.KEYFRAME_RULE should always be true
//       tokens.push(cssRule.keyText, '{', cssRule.style.cssText, '}')
//     })
//     tokens.push('}')
//     return tokens.join(' ')
//   }

// function copyStyles(source, target) {
//     // Store style tags, avoid reflow in the loop
//     const headFrag = target.createDocumentFragment()

//     Array.from(source.styleSheets).forEach(styleSheet => {
//         // For <style> elements
//         let rules
//         try {
//             rules = styleSheet.cssRules
//         } catch (err) {
//             console.error(err)
//         }

//         // For @font-face rule, it must be loaded via <link href=''> because the
//         // rule contains relative path from the css file.
//         const isFontFaceRule =
//             rules &&
//             Object.values(rules).some(r => r instanceof CSSFontFaceRule) &&
//             styleSheet.href

//         if (rules) {
//             // IE11 is very slow for appendChild, so use plain string here
//             const ruleText = []

//             // Write the text of each rule into the body of the style element
//             Array.from(styleSheet.cssRules).forEach(cssRule => {
//                 const { type } = cssRule

//                 // Skip unknown rules
//                 if (type === CSSRule.UNKNOWN_RULE) {
//                     return
//                 }

//                 let returnText = ''

//                 if (type === CSSRule.KEYFRAMES_RULE) {
//                     // IE11 will throw error when trying to access cssText property, so we
//                     // need to assemble them
//                     returnText = getKeyFrameText(cssRule)
//                 } else if (
//                     [CSSRule.IMPORT_RULE, CSSRule.FONT_FACE_RULE].includes(type)
//                 ) {
//                     // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5)
//                     // to handle local imports on a about:blank page
//                     // '/custom.css' turns to 'http://my-site.com/custom.css'
//                     returnText = fixUrlForRule(cssRule)
//                 } else {
//                     returnText = cssRule.cssText
//                 }
//                 ruleText.push(returnText)
//             })

//             const newStyleEl = target.createElement('style')
//             newStyleEl.textContent = ruleText.join('\n')
//             headFrag.appendChild(newStyleEl)
//         } else if (styleSheet.href) {
//             // for <link> elements loading CSS from a URL
//             const newLinkEl = target.createElement('link')

//             newLinkEl.rel = 'stylesheet'
//             newLinkEl.href = styleSheet.href
//             headFrag.appendChild(newLinkEl)
//         }
//     })

//     target.head.appendChild(headFrag)
// }

export default function Test3() {

    const snackbarContext = useContext(SnackbarContext);
    //const popupRef = useRef(null);
    //const fileUrl = "https://divisibly-statements.s3.us-east-2.amazonaws.com/8ff1db85-4586-42a1-8225-b104250bbb10/b12af5c0-9a1b-494b-97b1-5d869612cbf3/2.pdf/00/2.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=ASIAZPHOHKWBMKQWD4OA%2F20240712%2Fus-east-2%2Fs3%2Faws4_request&X-Amz-Date=20240712T072137Z&X-Amz-Expires=900&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEKf%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLWVhc3QtMiJHMEUCIQCuhCaZx54879nJ7HmK5UQdNdOWvbRtIcgeL%2BCSOp96WwIgbHHiWv7%2FAFfiDIcnHpjhBJZ5X0ceJAOZhC4NjpiWgTkqxAQIcBABGgw2NTExODcxNDgxNjIiDGxQIIodSY4Mu6%2BwTSqhBFacPMomCTp%2BHhLfVRAZBSejBaKJxEjIEaspD3ZESYYOfWeSF8%2BVaYGHaNVcRpG143eQpivTHu64fDkfyuE5Rq8AWwQoKs8H%2Bq4pMypbta3swnmEKnM%2BqgC6QNeYmt%2BY4DRUHFVEg9W7znIUVvQqNkFfjzDopZT3dtaYAuyI8i1SWK6FevvNnU8eXEno7wDHJ6xIUzUd1lLN6ld2yuogffzq2uAhYlJi7bJvyX0uqZ8ZKpCdWO9N0%2BpERDrsEfBfbstABjqZd5SPriummL79tF7fVOTwNbPslLOQZ42qXXO7X599sKkND2fE%2BIf74wG6S5xEq2g79bFebd6TP9R10Eu1ezJPIfSx%2BXGY0YqbonQ2c2QC3NDNK5k2JdD4NTz%2F4HwX0fk3zS2iFX44rA0FW1Tc7z4FBGdZAZwNCoB3Abl2WzQM5ODaQNcpQBStcWILfFZCPnkWZKqCrHiVbXDP263fkNGpdCd7nhmRFbtihFEmaa5xGfhShKe7WTL8MbPQ7C6EN0DeLCdpm%2FxELJ73v%2FAlj%2Byw64q3KnZjOzp3x8QZcxxfWwXd5Hq4qZS1SkqJIgcKpJFgQCtWqkLEZpurrRqij4WkAk2e0gYbmlA4pRFqPaj9nD98GL8kR7WEbvdZalpyjQz%2FvT2gpSmi7xGa7FV4ZstH0Af6lmS76vtoE%2BuKt5IB6hP9dUB6jUEv7HXn0%2BcYcNi%2BMNSbif5tdlT27OekMICzw7QGOoUCwmMCiO8WgLzed82DBq1K7GriuuaXK%2FpvghF6EOyzeZEJ80RuBJaj5AUssS4N4o6agUAQWaRadccU8ydXgeWIO%2F6W5m0ziVbDEBD%2BH0bbIR%2B%2FIf%2B6rbebBXmVEV6nwdgYvhUBem0tWC31uoKlqM7OHHOlG0PSiQjkgtE8ts3CtpKPMrjAzEB6yyra19Tq9E3%2FU8My%2F4kUFxRbPk4XCqY%2Bdle%2BOhV9nRkv9oc6Dp76FYT9EOc6ohwYAFSOHUFn7G%2BsjC2KptqdmgmwRERkF%2FAsSxdmhWooRV5MT9l2Ic%2FaftbLvIZBk5Yg2EGYyhKGsKu3%2BoPGdEdyQML%2BzUI5KyDTT2yyP5Z%2F&X-Amz-Signature=42f03f1a8b1d9f2a8ea4d2062477e57de3ccaea82f068a912990b5f0b2ebbc63&X-Amz-SignedHeaders=host"
    const [fileUrl, setFileUrl] = useState()
    const fileS3Key = "8ff1db85-4586-42a1-8225-b104250bbb10/b12af5c0-9a1b-494b-97b1-5d869612cbf3/2.pdf/00/2.pdf"
    const highlightedTxn = { "bank_account_pk": 18120263, "amount": 3960, "_applied_expense_factor": 1, "_verified": false, "_updateDate": "2024-05-23T01:17:15.183Z", "uploaded_doc_pk": 67910363, "description": "09/27 DEPOSIT 1172241957 S3,960.00", "txn_date": "2022-09-27", "page_idx": 0, "page_doc_pk": 471565535, "has_probable_transfers": false, "comment": "", "_createDate": "2024-05-23T01:17:15.183Z", "pk": 5082490663, "period_pk": 53311897, "id": 5082490663 }

    useEffect(()=>{
        getPageDetails()

    },[])
    const getPageDetails = async () => {

        

        try {

      
            const { credentials } = await fetchAuthSession();
            //console.log(`credentials: ${JSON.stringify(credentials)}`)
            const S3_BUCKET = 'divisibly-statements';
            const region = amplifyConfig.aws_project_region
        
            const client = new CognitoIdentityProviderClient({
                region: region,
                credentials: credentials
            });

            AWS.config.update(
                {
                    region: region,
                    credentials: credentials
                }
            );

            const s3 = new AWS.S3({
                apiVersion: "2006-03-01"
            });


        
            const params = {
                Bucket: S3_BUCKET,
                Key: fileS3Key,
            };

            const signedUrl = await s3.getSignedUrlPromise('getObject', params);
            setFileUrl(signedUrl);
            
            console.log(`file: ${signedUrl}`)

        } catch (error) {
            console.error('Error reading file:');
            console.error(error)
        }
    };


    // useEffect(() => {
    //     // Handle receiving messages from the popup window
    //     const handleMessage = (event) => {
    //         if (event.origin !== window.location.origin) return; // Ensure message comes from the same origin
    //         console.log('Message from popup:', event.data);
    //     };

    //     window.addEventListener('message', handleMessage);

    //     return () => {
    //         window.removeEventListener('message', handleMessage);
    //     };
    // }, []);

    

    //   return (
    //     <div>
    //       <h1>Main Window</h1>
    //       <button onClick={openPopup}>Open Popup</button>
    //     </div>
    //   );

    const onBlock= (e) => {
        snackbarContext.openSnackbarError(`could not open popup window, please ensure popups are enabled for this page`)
        
        
    }

    

    //const userProfile=useCurrentUserProfile();
    return (
        <PortalFrame title={"Popup Test"}>

            
            {true && fileUrl && 
              <PopupDialogWindow open={true} onBlock={onBlock}><PDFReviewer highlightedTxn={highlightedTxn} fileUrl={fileUrl} /></PopupDialogWindow>
            }

        </PortalFrame>
    )
}