import { postHumanLoopTask,putHumanLoopTask, getHumanLoopTasks,getHumanLoopTaskByID,deleteHumanLoopTaskByID } from './hloopcall';
import useSWR from 'swr'
import { mutate } from "swr";

export  function usePostHumanLoopTask(body) {
    const result = useSWR('/hloop4', ()=>postHumanLoopTask(body));
  return result
}

export function createHumanLoopTask(orgID,caseID,step,logicalFile,docPath,status,response) {
  const taskID=crypto.randomUUID()
  const body={
      taskID: taskID,
    orgID: orgID,
    caseID: caseID,
    step: step,
    logicalFile: logicalFile,
    docPath: docPath,
    status: status,
    response: response
    }

    const result=mutate(['taskID', body.taskID], postHumanLoopTask(body))
    return result
}

export  function usePutHumanLoopTask(body) {
  //const result = useSWRMutation('/hloop', putHumanLoopTask, body);
  const result = useSWR('/hloop3',()=>putHumanLoopTask(body));
return result
}


export  function useGetHumanLoopTasks(orgID,caseID) {
  const result = useSWR(['/hloop',orgID,caseID],([path,orgID,caseID])=>getHumanLoopTasks(orgID,caseID));
return result
}

export  function useGetHumanLoopTaskByID(orgID,caseID,taskID) {
  const result = useSWR(['/hloop',orgID,caseID,taskID],([path,orgID,caseID,taskID])=>getHumanLoopTaskByID(orgID,caseID,taskID));
return result
}

export  function useDeleteHumanLoopTaskByID(orgID,caseID,taskID) {
  const result = useSWR(['/hloop2',orgID,caseID,taskID],([path,orgID,caseID,taskID])=>deleteHumanLoopTaskByID(orgID,caseID,taskID));
return result
}