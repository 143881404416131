import React, { useMemo } from 'react';
import { Paper, Stack, Button } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { FormContainer, TextFieldElement } from 'react-hook-form-mui';
import { MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import reportTypes from "../../cfg/report-types.json";

export default function UserReportForm(props) {
  const { data = {}, onSubmit, onCancel } = props;

  const defaultValues = useMemo(() => data, [data]);

  const formContext = useForm({
    defaultValues,
  });

  const { handleSubmit, control, setValue } = formContext;

  const reportTypeOptions = Object.entries(reportTypes).map(([key, value]) => ({
    value: key,
    label: value.title,
  }));

  // Explicitly handle reportType selection
  const handleReportTypeChange = (event) => {
    setValue('reportType', event.target.value);
  };

  return (
    <Paper
      style={{
        display: 'grid',
        gridRowGap: '20px',
        padding: '20px',
        maxWidth: '500px',
      }}
    >
      <FormContainer formContext={formContext} handleSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2}>
          <TextFieldElement name="reportName" label="Report Name" required />

          <FormControl fullWidth>
            <InputLabel id="report-type-label">Report Type</InputLabel>
            <Controller
              name="reportType"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="report-type-label"
                  label="Report Type"
                  onChange={handleReportTypeChange}
                >
                  {reportTypeOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Stack>

        <Button
          style={{ margin: '10px 0px', maxWidth: '75px' }}
          type="submit"
          variant="contained"
        >
          Save
        </Button>
        <Button
          style={{ margin: '10px 10px', maxWidth: '75px' }}
          onClick={onCancel}
          variant="outlined"
        >
          Cancel
        </Button>
      </FormContainer>
    </Paper>
  );
}
