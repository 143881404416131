import React from "react";
import Link from '@mui/material/Link';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../ui/title';
import {useCaseQuery} from '../../api/swrdbcall';
import { DataGrid } from "@mui/x-data-grid";

//import {postNeo4j} from '../../api/pydbcall';



function preventDefault(event) {
  event.preventDefault();
}
function enableDefault(event) {
  return;
}

export default function DataPreview(props) {
    const preview_query = props.preview_query;
    //const [rows, setRows] = useState([]);
    //const [first, setFirst] = useState(true);

    const previewResult = useCaseQuery(preview_query, props.parms);
    let rows=[];
    let columns=[];
    if(previewResult.isLoading === false){
      //console.log('PREVIEW DATA:')
      //console.log(JSON.stringify(previewResult.data))
      rows=previewResult.data['records'].map(
        (row,index) => ({'id':index, ...row})
      );
      //console.log('ROWS:')
      //console.log(JSON.stringify(rows))


      columns=previewResult.data['keys'].map(
        (col) => ({field: col, headerName: col, width: 200})
      )
      //console.log('COLUMNS:')
      //console.log(JSON.stringify(columns))
    }
  
    //async function postPreviewQuery(qry) {
    //    
    //    if (!first) {
    //        return;
    //    }
    //    
    //    setFirst(false);
    //    
    //    //console.log('calling postNeo4j');
    //    var result = await postNeo4j(qry);
    //    setRows(result["records"]);
     //   //console.log('entering postNeo4j.then');
        //console.log('results....');
        //console.log(JSON.stringify(result[0]));
     //   return;

    //}

    //postPreviewQuery(preview_query);
  
  return (
    <React.Fragment>
      <Title>{props.preview_title}</Title>
      <Box
                sx={{
                    
                    maxHeight: '75vh'
                }}
            >
      <DataGrid sx={{
                    
                    maxHeight: '70vh'
                }} rows={rows} columns={columns} />
      {/* 
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Business Name</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row,i) => (
            <TableRow key={i}>
              <TableCell>{String(row.Date)}</TableCell>
              <TableCell>{row["Business Name"]}</TableCell>
              
              
              <TableCell align="right">{`$${row.Amount}`}</TableCell>
              
            </TableRow>
          ))}
        </TableBody>
      </Table>
    */}  
    {props.detail_link&&
      <Link color="primary" href={props.detail_link} onClick={props.detail_link === "#" ? preventDefault:enableDefault} sx={{ mt: 3 }}>
        See more
      </Link>
      }
      </Box>
    </React.Fragment>
  );
}