import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';


export default async function authGetCurrentUserID() {
  //const result = useSWR('getCurrentUser', getCurrentUser);
  let result={}
  await fetchUserAttributes().then((attrs)=>result=attrs.sub);
  
  
  return result
}