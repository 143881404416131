import React from "react";
import PortalFrame from '../ui/portal-frame.js';

import UserProfileForm from "../ui/form-user-profile.js";
//import CaseProfileForm from '../ui/form-case-profile.js';
import mutateUser from "../../api/mutateuser.js";
//import mutateCase from "../../api/mutatecase.js";
import { listUserQuery } from "../../api/mutateuser.js";
//import { listCaseQuery } from "../../api/mutatecase.js";
import EditList from "../ui/list-edit.js";
import UserEditList from "../ui/list-edit-user.js";
//import CaseEditList from "../ui/list-edit-case.js";
//import {useCurrentCaseForCurrentUser} from "../../api/swrcasesforcurrentuser.js";
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from "../ui/pageskeleton.js";

export default function ManageUserPage() {

  //Step 2 added new way
  const userProfileContext=useContext(UserProfileContext);

  const title="Manage Team"
  //const userCase=useCurrentCaseForCurrentUser();
  //const userProfile=useCurrentUserProfile(); // comment out old way

  const userProfile = userProfileContext.userProfile  // add new way

  //if (userProfile.isLoading) {
  if (userProfileContext.isLoading) {
    return (
      <PortalFrame title={title}>
        <PageSkeleton/>
      </PortalFrame>
    )
  }

  //if(!userProfile.data.currentOrg){
  if(!userProfile.currentOrg){  
    return (
      <PortalFrame title={title}>
        You are not assigned to any organizations
      </PortalFrame>
    )
  }
  
  //const orgID=userProfile.data.currentOrg.orgID
  //const orgName=userProfile.data.currentOrg.orgName

  const orgID=userProfile.currentOrg.orgID
  const orgName=userProfile.currentOrg.orgName
  
  return (
    <PortalFrame title={title} orgName={orgName} >
    <EditList listQuery={listUserQuery}  editForm={UserProfileForm} objectLabel="Team Member" editList={UserEditList} mutateHandler={mutateUser} orgID={orgID} orgName={orgName}/>
    </PortalFrame>
  );
};