import * as React from 'react';
import Box from '@mui/material/Box';
import { Switch } from '@mui/material';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { useGridApiContext } from '@mui/x-data-grid';


export function renderSwitch(params) {
    return <Switch disabled checked={params.value} />;
}

function SwitchEditInputCell(props) {
    const { id, value, field, hasFocus } = props;
    const apiRef = useGridApiContext();
    const ref = React.useRef(null);

    const handleChange = (event, newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue });
    };

    useEnhancedEffect(() => {
        if (hasFocus && ref.current) {
            const input = ref.current.querySelector(`input[value="${value}"]`);
            input?.focus();
        }
    }, [hasFocus, value]);

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', pr: 2 }}>
            <Switch
                ref={ref}
                name={field}
                checked={value}
                onChange={handleChange}
            />
        </Box>
    );
}

export const renderSwitchEditInputCell = (params) => {
    return <SwitchEditInputCell {...params} />;
};