import React, { useState } from 'react';
import { Button, Paper } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//import Dropzone from 'react-dropzone'
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import AWS from 'aws-sdk';
import { v4 as uuidv4 } from 'uuid'; // Importing uuid to generate unique file names
import amplifyConfig from '../../amplifyconfiguration.json'
import { fetchAuthSession } from 'aws-amplify/auth';
import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import { createHumanLoopTask } from '../../api/swrhloopcall';




export default function UploadStatementsDialog(props) {

  const parentOnUpload = props.onUpload ? props.onUpload : () => { }
  const parentOnCancel = props.onCancel ? props.onCancel : () => { }
  
  
  const orgID=props.orgID
  const caseID=props.caseID

  if (orgID == null || caseID == null){
    throw new Error("orgID and caseID are required properties")
  }
  //console.log(`orgID=${orgID}`)
  
  // const api_host='nyjwtt0e4a.execute-api.us-east-2.amazonaws.com'
  // const api_path='dev/statements'
  // const api_url='https://'+api_host+api_path
  //const api_endpoint='https://nyjwtt0e4a.execute-api.us-east-2.amazonaws.com/dev'

  //const S3_UPLOAD_URL = api_endpoint+api_path; // Replace with your S3 upload endpoint
  //const region = amplifyConfig.aws_project_region


  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [fileError,setFileError] = useState({});

  const onDrop = async (acceptedFiles) => {
    setFiles(acceptedFiles);
    acceptedFiles.forEach((file) => {
      uploadFile(file);
      
    });
    
  };

  const uploadFile = async (file) => {
    try {
      const { credentials } = await fetchAuthSession();
      const S3_BUCKET = 'divisibly-statements';
      const region = amplifyConfig.aws_project_region
      //const ACCESS_KEY_ID = AWS.config.credentials.accessKeyId;
      //const SECRET_ACCESS_KEY = AWS.config.credentials.secretAccessKey;

      const client = new CognitoIdentityProviderClient({
        region: region,
        credentials: credentials
      });

      AWS.config.update(
        {
          region: region,
          credentials: credentials
        }
      );

      const s3 = new AWS.S3({
        apiVersion: "2006-03-01"
      });

      const step='00';
      //const fileName = uuidv4() + '-' + file.name; // Generating unique file names
      const fileName = file.name;
      const folderPath = orgID+'/'+caseID+'/'+fileName+'/'+step+'/';
      const key=folderPath + fileName;
      
      //console.log(`file.type=${file.type}`)
      if(file.type !== 'application/pdf'){
        setFileError((prevError) => ({
          ...prevError,
          [file.name]: "File is not PDF"
        }))
        return
      }
      const params = {
        Bucket: S3_BUCKET,
        Key: folderPath + fileName,
        ContentType: file.type,
        Body: file,
        //ACL: 'public-read' // Make uploaded file public
      };

      const options = {
        partSize: 10 * 1024 * 1024, // 10 MB part size
        queueSize: 1 // Max number of parallel uploads
      };

      const managedUpload = s3.upload(params, options);

      managedUpload.on('httpUploadProgress', function (progress) {
        const percentCompleted = Math.round((progress.loaded * 100) / progress.total);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [file.name]: percentCompleted
        }));
      });

      await managedUpload.promise();

      // Get the URL of the uploaded file
      const fileUrl = `https://${S3_BUCKET}.s3.${region}.amazonaws.com/${folderPath}`;
      //const step='UPLOAD'
      const logicalFile=fileName
      const docPath=key
      const status='COMPLETED'
      const response='200'
      await createHumanLoopTask(orgID,caseID,step,logicalFile,docPath,status,response);
      parentOnUpload() // notify parent object a file was uploaded
      //console.log('File uploaded successfully. URL:', fileUrl);
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  //if(!props.form){ throw new Error('you must pass a form in the "form" property')}

  const allFilesUploaded = files.length > 0 && files.every((file) => uploadProgress[file.name] === 100 || fileError[file.name] != null);

  //console.log(`allFilesUploaded=${allFilesUploaded}`)

  const handleCancel = () => {
    setFiles([])
    setFileError({})
    setUploadProgress({})
    parentOnCancel()
  };

  
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });


  //console.log(fileError)

  return (
    <React.Fragment>

      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title ? props.title : "Upload Statements"}</DialogTitle>
        <DialogContent>
          <Paper
            style={{
              display: "grid",
              gridRowGap: "20px",
              padding: "20px",
              //margin: "10px 300px",
              maxWidth: "500px",
            }}
          >
            <Paper style={{ padding: "20px" }}>
              <div {...getRootProps({ className: 'dropzone' })}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
            </Paper>
            <Button variant="contained" disabled={allFilesUploaded} onClick={handleCancel}>Cancel Upload</Button>
            <Button variant="contained" disabled={!allFilesUploaded} onClick={handleCancel}>Close</Button>
          </Paper>
          <aside>
            <h4>Files</h4>
            {files.map((file, index) => (
              <div key={index}>
                <p>{file.name}</p>
                {uploadProgress[file.name] && (
                  <progress value={uploadProgress[file.name]} max="100">
                    {uploadProgress[file.name]}%
                  </progress>
                )}
                <i>{fileError[file.name]}</i>
              </div>
            ))}
          </aside>
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}