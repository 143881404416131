import { Snackbar } from "@mui/material";
import {Alert} from "@mui/material";
import { createContext, useState } from "react";

const SnackbarContext = createContext();

const alert_severity_success = 'success'
const alert_severity_info = 'info'
const alert_severity_warning = 'warning'
const alert_severity_error = 'error'

export const SnackbarProvider = ({children}) => {
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarAlertMessage, setSnackbarAlertMessage] = useState()
    const [snackbarAlertSeverity, setSnackbarAlertSeverity] = useState(alert_severity_success)
    const [snackbarAction, setSnackbarAction] = useState()

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    };

    // const defaultSnackbarAction = (
    //     <Fragment>
    //         <Button color="secondary" size="small" onClick={handleSnackbarClose}>
    //             UNDO
    //         </Button>
    //         <IconButton
    //             size="small"
    //             aria-label="close"
    //             color="inherit"
    //             onClick={handleSnackbarClose}
    //         >
    //             <CloseIcon fontSize="small" />
    //         </IconButton>
    //     </Fragment>
    // );

    const handleSnackbarOpen = () => {
        //setSnackbarAction(defaultSnackbarAction)
        setOpenSnackbar(true);
    };

    const _openSnackbar = (message, severity) => {
        setSnackbarAlertMessage(message)
        setSnackbarAlertSeverity(severity)
        handleSnackbarOpen(true)
    }

    const openSnackbarError = (message) => {
        _openSnackbar(message, alert_severity_error)
    }

    const openSnackbarWarning = (message) => {
        _openSnackbar(message, alert_severity_warning)
    }

    const openSnackbarInfo = (message) => {
        _openSnackbar(message, alert_severity_info)
    }

    const openSnackbarSuccess = (message) => {
        _openSnackbar(message, alert_severity_success)
    }

    return (
        <SnackbarContext.Provider value= {{openSnackbarError, openSnackbarWarning, openSnackbarInfo, openSnackbarSuccess}}>
            <Snackbar
                open={openSnackbar}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                //message={snackbarMessage}
                action={snackbarAction}

            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarAlertSeverity}
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    {snackbarAlertMessage}
                </Alert>
            </Snackbar>

            {children}
        </SnackbarContext.Provider>
    )

}

export default SnackbarContext;