import { useState } from "react";
import { Button, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import FormContainer from "./rh_form_elements/rh-form-container";
import TextFieldElement from "./rh_form_elements/rh-text-field-element";
import PhoneFieldElement from "./rh_form_elements/rh-phone-field-element";
import EmailFieldElement from "./rh_form_elements/rh-email-field-element";
import { Stack } from "@mui/material";



export default function CaseProfileForm(props) {
  
  
  const defaultValues = props.data?props.data:{};

 
  const [values, setValues] = useState(defaultValues)
  
  
  const formContext = useForm({
    defaultValues: defaultValues, values
  });

  const { handleSubmit, reset, control, setValue, formState: { errors } } = formContext;


  return (
    


      <Paper
        style={{
          display: "grid",
          gridRowGap: "20px",
          padding: "20px",
          //margin: "10px 300px",
          maxWidth: "500px",
        }}
      >
        <FormContainer formContext={formContext} handleSubmit={handleSubmit} >
          <Stack spacing={2}>
            <TextFieldElement name="caseName" label="Case Name" required />
          </Stack>

          
          <Button
            style={{
              //padding: "20px",
              margin: "10px 0px",
              maxWidth: "75px",
            }}
            onClick={handleSubmit(props.onSubmit)} variant={"contained"}>
            Save
          </Button>
          <Button style={{
            //padding: "20px",
            margin: "10px 10px",
            maxWidth: "75px",
          }}
            onClick={props.onCancel} variant={"outlined"}>
            Cancel
          </Button>


        </FormContainer>
        
      </Paper>
      

    

  );
};