import { post, get, put, del } from 'aws-amplify/api';



export async function applyTransferstoCase(orgID,caseID) {
    
        //transactions is an optional array of transaction objects 

        const reqbody = {
          orgID: orgID,
          caseID: caseID
        }

        const payload={
          apiName: 'applytransfers',
          path: '/applytransfers',
          options: {
            body: reqbody
          }
        }

        //console.log('GOT HERE1 applyRulestoCase')
        console.log(`payload=${JSON.stringify(payload)}`)
        
    
    
        const restOperation = post(payload); 

        
        const { body } = await restOperation.response;
        let json = await body.json();
    
        //console.log('GOT HERE2 applyRulestoCase')
        console.log(`json=${JSON.stringify(json)}`)
        
        json = JSON.parseWithDate(JSON.stringify(json));
    
        return json
    
    

}


