import { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { ResizableBox } from "react-resizable";
import PDFReviewer from "./review-jump-page-pdf.tsx";



const PDFBottomPanel = ({ topChild, highlightedTxn, fileUrl }) => {

    const [initialWidth, setInitialWidth] = useState(window.innerWidth / 2);

    useEffect(() => {
        const handleResize = () => {
            setInitialWidth(window.innerWidth / 2);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // TODO investigate why npm install react-popout didn't work
    /// https://www.npmjs.com/package/react-popout?activeTab=readme

    // const handlePopOut = () => {
    //     const popoutWindow = window.open('', '', 'width=600,height=400,left=200,top=200');
    //     popoutWindow.document.write('<div id="popout-root"></div>');

    //     const isDevelopment = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    //     const script = document.createElement('script');
    //     script.src = isDevelopment ? 'http://localhost:3000/static/js/bundle.js' : `${window.location.origin}/static/js/main.js`;
    //     popoutWindow.document.body.appendChild(script);

    //     popoutWindow.onbeforeunload = () => {
    //       setIsPoppedOut(false);
    //     };

    //     popoutWindow.props = { rightChild }; // Pass props to the popout window

    //     setIsPoppedOut(true);
    //   };

    

    return (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column', // Set flexDirection to column
            //overflowX: 'auto', whiteSpace: 'nowrap'
            //height: '100vh'
          }}
        >
          <ResizableBox
            width={initialWidth}
            height={Infinity}
            minConstraints={[100, Infinity]}
            maxConstraints={[window.innerWidth - 100, Infinity]}
            axis="x"
            resizeHandles={['e']}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderBottom: '2px solid #ccc' // Change borderRight to borderBottom
            }}
          >
            <Box
              sx={{
                flex: 1,
                display: 'inline-block',
                width: '100%',
                alignItems: 'center',
                justifyContent: 'center',
                //backgroundColor: '#d0d0d0'
              }}
            >
              {topChild}
            </Box>
          </ResizableBox>
          <Box
            sx={{
              flex: 1,
              //height: '75vh',
              /*
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#f0f0f0'
              */
            }}
          >
            <PDFReviewer highlightedTxn={highlightedTxn} fileUrl={fileUrl} />
          </Box>
        </Box>
      )
      
}
export default PDFBottomPanel;