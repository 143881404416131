import React from 'react';
import PortalFrame from '../ui/portal-frame.js';
import { useContext } from 'react';
//import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';
//import { useReportsForCurrentUser, useUpdateReportForCurrentUser, useDeleteReportForCurrentUser } from '../../api/swrreportsforcurrentuser.js';
//import { MUTATE_DELETE, MUTATE_UPDATE } from "../../api/mutate-actions.js";
//import SnackbarContext from '../ui/snackbar-context.js';
import EditReportPanel from '../ui/panel-edit-report.js';
import ReportContext from '../ui/user-report-context.js';


export default function ManageReportListPage(props) {

    const reportContext = useContext(ReportContext);

    console.log(`in ManageReportListPage ${JSON.stringify(reportContext.reports)}`)
    

    const title = "Manage Reports"

    if (reportContext.isLoading) {

        return (
            <PortalFrame title={title}>
                <PageSkeleton />
            </PortalFrame>
        )
    }


    
    return (
        <PortalFrame title={title}>
            
            
            <EditReportPanel reportList={reportContext.reports} updateReport={reportContext.updateReport} deleteReport={reportContext.deleteReport}/>


        </PortalFrame>
    );
}