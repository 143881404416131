import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
  useGridApiRef
} from '@mui/x-data-grid';
import { v4 as randomId } from 'uuid'; // Importing uuid to generate unique file names

//import mutateBankAccount from '../../api/mutatebankaccount';
import mutateCategoryRule from '../../api/mutatecategoryrule.js';

import { renderSwitch, renderSwitchEditInputCell } from './grid-input-switch-component.js';

import { Autocomplete } from '@mui/material';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { useGridApiContext } from '@mui/x-data-grid';
import { TextField } from '@mui/material';
import SubcategoryEditInputCell from './grid-input-cell-subcategory.js';

//import { Flex } from '@aws-amplify/ui-react';
import ocrMetadata from '../../ocrmetadata/ocrmetadata.json'
import { propValidatorsDataGrid } from '@mui/x-data-grid/internals';
import ConfirmBeforeDeleteGridDialog from './dialog-confirm-before-delete-grid.js';
import { MUTATE_DELETE } from '../../api/mutate-actions.js';
//import Snackbar from '@mui/material/Snackbar';
//import Alert from '@mui/material/Alert';
import SnackbarContext from './snackbar-context.js';
import UserProfileContext from './userprofilecontext.js';



/////////////////////////////////////////////////////
// export function renderSwitch(params) {
//   return <Switch disabled checked={params.value} />;
// }

// const account_categories = Object.keys(ocrMetadata.account_category)

// function AccountCategoryEditInputCell(props) {
//   const { id, value, field, hasFocus } = props;
//   const apiRef = useGridApiContext();
//   const ref = React.useRef(null);

//   const handleChange = (event, newValue) => {
//     apiRef.current.setEditCellValue({ id, field, value: newValue });
//   };

//   useEnhancedEffect(() => {
//     if (hasFocus && ref.current) {
//       const input = ref.current.querySelector(`input[value="${value}"]`);
//       input?.focus();
//     }
//   }, [hasFocus, value]);

//   return (
//     <Box sx={{ width: '100vw', alignItems: 'left' }}>
//       <Autocomplete
//         required
//         ref={ref}
//         id={field}
//         value={value}
//         onChange={handleChange}
//         options={account_categories}
//         renderInput={(params) => <TextField {...params} required />}
//       />
//     </Box>
//   );
// }

// export const renderAccountCategoryEditInputCell = (params) => {
//   return <AccountCategoryEditInputCell {...params} />;
// };




/////////////////////////////////////////////////////



function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, description: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'phrase' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}


export default function CategoryRuleCrudGrid(props) {


  const alert_severity_success = 'success'
  const alert_severity_info = 'info'
  const alert_severity_warning = 'warning'
  const alert_severity_error = 'error'

  const parentHandleRowClick = props.handleRowClick ? props.handleRowClick : (account) => { }
  const parentOnAdd=props.onAdd?props.onAdd:()=>{}
  const parentOnUpdate=props.onUpdate?props.onUpdate:()=>{}
  const parentOnDelete=props.onDelete?props.onDelete:()=>{}

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [subcategoryOptions, setSubcategoryOptions] = React.useState([])
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = React.useState(false)
  const [recordToDelete, setRecordToDelete] = React.useState()

  
  const snackbarContext=React.useContext(SnackbarContext);
  const userProfileContext = React.useContext(UserProfileContext);

  
  React.useEffect(() => {

    //console.log(`remapping rules in grid`)
    //console.log(`${JSON.stringify(props.rules)}`)
    const rules = props.rules.map((rule,index) => ({ ...rule, id: index }));
    setRows(rules)
    //console.log(`computed the transaction map....`)


  }, [props.rules]

  )




  //////////////////////////////////////////////////////
  //const account_types=Object.keys(ocrMetadata.account_type)
  React.useEffect(() => {
    const subcategories = props.subcategories ? props.subcategories : []
    // const subcategoryOptions = subcategories.map(
    //   //subcategory => subcategory.subcategory
    //   subcategory => subcategory
    // )

    //setSubcategoryOptions(subcategoryOptions)
    setSubcategoryOptions(subcategories)


  }, [props.subcategories]

  )

  

 

  const renderSubcategoryEditInputCell = (params) => {
    //return <SubcategoryEditInputCell {...params} />;
    return <SubcategoryEditInputCell {...params} subcategoryOptions={subcategoryOptions} />;
  };




  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    //console.log('delete clicked')
    const deleteRow = rows.find((row) => row.id === id);
    setRecordToDelete(deleteRow)
    setOpenDeleteConfirmDialog(true)
    

    //setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const apiRef = useGridApiRef ();

  const dqCheck = (newRow) => {
    //// DQ CHECKS
    if(!newRow.phrase){
      apiRef.current.setCellFocus(newRow.id, 'phrase');
      throw `Please specify a phrase`
    }

    // check if phrase already exists 
    const duplicateRow = rows.find(
      (row) => row.id != newRow.id && !row.phrase.localeCompare(newRow.phrase, undefined, { sensitivity: 'accent' }) // case insensitive string compare 
    )
    //console.log(`duplicateRow=${JSON.stringify(duplicateRow)}`)

    if(duplicateRow){
      
      const originalRow=rows.find(row=>row.id===newRow.id)
      
      apiRef.current.setCellFocus(newRow.id, 'phrase');
      throw `You tried to add a phrase that is already in the list: ${newRow.phrase}`
    }

    if(!newRow.subcategory){
      apiRef.current.setCellFocus(newRow.id, 'subcategory');
      throw `Please specify a subcategory`
    }

  }
  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    
    dqCheck(newRow)

    updatedRow.proposed_count =  updatedRow.proposed_count?updatedRow.proposed_count:0
    updatedRow.confirmed_count =  updatedRow.proposed_count?updatedRow.confirmed_count:0
    
    const result = mutateCategoryRule(userProfileContext.userProfile, updatedRow)

    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    parentOnUpdate(updatedRow)
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    //console.log(`row "${JSON.stringify(params.row)}" clicked`);
    parentHandleRowClick(params.row)
  };

  const onCancelDelete = () => {
    // user has been asked to confirm the delete, and cancelled out of the delete

    //close the delete confirm dialog
    setOpenDeleteConfirmDialog(false)
  }

  const onProceedDelete = () => {
    // user has been asked to confirm the delete, and still wants to proceed with delete

    // call handler to delete the data 
    // mutateHandler(props.orgID,props.orgName, props.currentUserID, selectedItem, MUTATE_DELETE).then((response) => {
    //   //after save, reload the list
    //   forceListReload()
    // }

    //)
    //close the delete confirm dialog
    setOpenDeleteConfirmDialog(false)
    const result = mutateCategoryRule(userProfileContext.userProfile, recordToDelete, MUTATE_DELETE)
    setRows(rows.filter((row) => row.id !== recordToDelete.id));
    parentOnDelete(recordToDelete)
  }

  const action_columns=[
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          
        ];
      },
    },
  ]

  const display_columns = [
    
    {
      field: 'phrase',
      headerName: 'Match Phrase',
      //display: 'flex',
      //renderCell: renderSwitch,
      //renderEditCell: renderSwitchEditInputCell,
      width: 200,
      align: 'left',
      editable: true,
      valueSetter: (value, row, column, apiRef) => {
        // make sure to trim spaces off the phrase before setting into row
        return {...row, phrase: value?.trim()};
      },
    },
    {
      field: 'subcategory',
      headerName: 'Subcategory',
      //type: 'date',
      width: 200,
      align: 'left',
      editable: true,
      renderEditCell: renderSubcategoryEditInputCell
    },
    // {
    //   field: 'flag',
    //   headerName: 'Flag Text (Optional)',
    //   width: 400,
    //   align: 'left',
    //   editable: true
    // },
    {
      field: 'proposed_count',
      headerName: 'Rule Proposed Count',
      type: Number,
      width: 200,
      align: 'left',
      editable: false
    },
    {
      field: 'confirmed_count',
      headerName: 'Rule Confirmed Count',
      type: Number,
      width: 200,
      align: 'left',
      editable: false
    },


  ];

  const columns=action_columns.concat(display_columns)

  return (
    <Box
      sx={{
        height: '75vh',
        //width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <ConfirmBeforeDeleteGridDialog open={openDeleteConfirmDialog} data={recordToDelete} columns={display_columns} onProceed={onProceedDelete} onCancel={onCancelDelete}/>
  
      <DataGrid
        apiRef={apiRef}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}    
        onProcessRowUpdateError={(error) =>snackbarContext.openSnackbarError(error)}
        onRowClick={handleRowClick}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}