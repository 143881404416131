import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
//import { v4 as randomId } from 'uuid'; // Importing uuid to generate unique file names

import mutateBankAccount from '../../api/mutatebankaccount';

import { renderSwitch, renderSwitchEditInputCell } from './grid-input-switch-component';

import { Autocomplete } from '@mui/material';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { useGridApiContext } from '@mui/x-data-grid';
import {TextField} from '@mui/material';

//import { Flex } from '@aws-amplify/ui-react';
import ocrMetadata from '../../ocrmetadata/ocrmetadata.json'




/////////////////////////////////////////////////////
// export function renderSwitch(params) {
//   return <Switch disabled checked={params.value} />;
// }

const account_categories=Object.keys(ocrMetadata.account_category)

function AccountCategoryEditInputCell(props) {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef(null);

  const handleChange = (event, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
          const input = ref.current.querySelector(`input[value="${value}"]`);
          input?.focus();
      }
  }, [hasFocus, value]);

  return (
      <Box sx={{width: '100vw', alignItems: 'left' }}>
          <Autocomplete
              required
              ref={ref}
              id={field}
              value={value}
              onChange={handleChange}
              options={account_categories}
              renderInput={(params) => <TextField {...params} required />}
          />
      </Box>
  );
}

export const renderAccountCategoryEditInputCell = (params) => {
  return <AccountCategoryEditInputCell {...params} />;
};


//////////////////////////////////////////////////////
const account_types=Object.keys(ocrMetadata.account_type)

function AccountTypeEditInputCell(props) {
  const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef(null);

  const handleChange = (event, newValue) => {
      apiRef.current.setEditCellValue({ id, field, value: newValue });
  };

  useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
          const input = ref.current.querySelector(`input[value="${value}"]`);
          input?.focus();
      }
  }, [hasFocus, value]);

  return (
    <Box sx={{width: '100vw', alignItems: 'left' }}>
          <Autocomplete
              required
              ref={ref}
              name={field}
              value={value}
              onChange={handleChange}
              options={account_types}
              renderInput={(params) => <TextField {...params} required />}
          />
      </Box>
  );
}

export const renderAccountTypeEditInputCell = (params) => {
  return <AccountTypeEditInputCell {...params} />;
};


/////////////////////////////////////////////////////

// import {
//   randomCreatedDate,
//   randomTraderName,
//   randomId,
//   randomArrayItem,
// } from '@mui/x-data-grid-generator';

// const roles = ['Market', 'Finance', 'Development'];
// const randomRole = () => {
//   return randomArrayItem(roles);
// };

/*
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, description: '', isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'txn_date' },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
    </GridToolbarContainer>
  );
}
*/

export default function AccountCrudGrid(props) {


  const parentHandleRowClick = props.handleRowClick ? props.handleRowClick : (account) => { }

  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  React.useEffect(() => {

    //console.log(`computing the transaction map....`)
    const accounts = props.accounts.map(account => ({ ...account, id: account.pk }));
    setRows(accounts)
    //console.log(`computed the transaction map....`)


  }, [props.accounts]

  )

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    const result=mutateBankAccount(props.currentUserProfile,updatedRow)
    
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    //console.log(`row "${JSON.stringify(params.row)}" clicked`);
    parentHandleRowClick(params.row)
  };

  

  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          /*
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
          */
        ];
      },
    },
    {
      field: '_verified',
      headerName: 'Verified?',
      //display: 'flex',
      type: 'boolean',
      renderCell: renderSwitch,
      renderEditCell: renderSwitchEditInputCell,
      width: 120,
      align: 'left',
      editable: true,
    },
    {
      field: 'name',
      headerName: 'Account Name',
      //type: 'date',
      width: 400,
      align: 'left',
      editable: true,
    },
    {
      field: 'bank_name',
      headerName: 'Bank Name',
      width: 200,
      align: 'left',
      editable: true
    },
    {
      field: 'account_type',
      headerName: 'Account Type',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderEditCell: renderAccountTypeEditInputCell
    },
    {
      field: '_applied_expense_factor',
      headerName: 'Applied Expense Factor',
      width: 200,
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'account_holder',
      headerName: 'Account Holder',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'account_number',
      headerName: 'Account Number',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'account_category',
      headerName: 'Account Category',
      width: 200,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderEditCell: renderAccountCategoryEditInputCell
    },
    {
      field: 'holder_address_1',
      headerName: 'Holder Address 1',
      width: 250,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'holder_address_2',
      headerName: 'Holder Address 2',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'holder_city',
      headerName: 'Holder City',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'holder_state',
      headerName: 'Holder State',
      width: 100,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'holder_zip',
      headerName: 'Holder ZIP Code',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    {
      field: 'holder_country',
      headerName: 'Holder Country',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
    },
    
    
  ];

  return (
    <Box
      sx={{
        height: '75vh',
        //width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowClick={handleRowClick}
        slots={{
          //toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}