import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';

const filter = createFilterOptions();

export default function FreeSoloCreateOptionAutoComplete(props) {
  const { id, value, field, hasFocus, options, valueField, newValueFormatter, getOptionLabel, onChange, isProcessingProps, inputRef, ...otherProps } = props;
  const parentOnChange = props.onChange ? props.onChange : (event, newValue) => {};
  const label = props.label;
  const parentValueField = props.valuefield ? props.valuefield : "flag";  // default field in object to use as value = "value"
  const parentNewValueFormatter = props.newValueFormatter ? props.newValueFormatter : (newValue) => newValue;
  const parentGetOptionLabel = props.getOptionLabel ? props.getOptionLabel : (value) => value;

  return (
    <Autocomplete
      value={value ? value : null}
      onChange={(event, newValue) => {
        if (newValue && newValue.inputValue) {
          const newOption={ [parentValueField]: parentNewValueFormatter(newValue.inputValue) }
          options.push(newOption) // add the option to the option list
          parentOnChange(event, newOption);
        } else if (typeof newValue === 'string') {
          let foundValue = options.find((option) => newValue.trim() === option[parentValueField].toString());
          foundValue = foundValue ? foundValue : { [parentValueField]: parentNewValueFormatter(newValue.trim()) };
          parentOnChange(event, foundValue);
        } else {
          parentOnChange(event, newValue);
        }
      }}
      filterOptions={(options, params) => {
        
        const filtered = filter(options, params);
        const { inputValue } = params;
        const isExisting = options.some((option) => inputValue.trim() === option[parentValueField].toString());
        if (inputValue.trim() !== '' && !isExisting) {
          filtered.push({
            inputValue,
            [parentValueField]: `Add "${inputValue}"`,
          });
        }
        return filtered;
      }}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      id={id}
      options={options}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          //options.push({[parentValueField]: option.inputValue})
          return option.inputValue;

        }
        // Regular option
        //console.log(`DEBUGGING option[valueField]=${JSON.stringify(option[parentValueField])}`)
        
        let label=parentGetOptionLabel(option[parentValueField])
        if (typeof label !== 'string'){
          console.warn(`getOptionLabel returned a non-string result, please provide a string as the return value`)
          label=label.toString()
        }
        return label;
      }}
      renderOption={(props, option) => <li {...props}>{option[parentValueField].toString()}</li>}
      sx={{ width: 300 }}
      freeSolo
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          inputRef={inputRef} // Forward inputRef to TextField
        />
      )}
    />
  );
}
