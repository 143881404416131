import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

const CaseEditList = (props) => {

  const data = props.data ? props.data : []
  const handleEditClick = props.handleEditClick
  const handleDelete = props.handleDelete
  const showEdit = props.showEdit ? props.showEdit : false;
  const showDelete = props.showDelete ? props.showDelete : false;

  if (data.length === 0) {
    return <div></div>
  }

  return (

    <List>
      {data.map(( this_case ) => (
        <React.Fragment key={this_case.caseID + "-DUMMY"}>
          <ListItem key={this_case.caseID}>
            <ListItemText primary={this_case.caseName} />
            <ListItemSecondaryAction>
              {showEdit &&
                <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(this_case)}>
                  <EditIcon />
                </IconButton>
              }
              {showDelete &&
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(this_case)}>
                <DeleteIcon />
              </IconButton>
              }
            </ListItemSecondaryAction>
          </ListItem>
          <Divider></Divider>
        </React.Fragment>
      ))}
    </List>


  );
};

export default CaseEditList;