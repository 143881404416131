export function dateGetter(value) {
    //console.log(`in dateGetter value=${value}`)
    // Check if the value is an instance of Date
    if (value instanceof Date) {
      //console.log(`value is already a date, returning value`)
      return value;
    } 
    else if (typeof value === 'string' || value instanceof String) {
      const [year, month, day] = value.split('-').map(Number);
      const date_value = new Date(year, month - 1, day);
      //console.log(`original value was string = ${value}, returning converted value=${date_value}`)
      return date_value;
    }
    else {
      const date_value=new Date(value)
      //console.log(`original value was ${value}, returning converted value=${date_value}`)
      // Create a new Date object from the value
      return date_value;
    }
  }

  export function currencyFormatter(value){
    if (value == null) {
      return '';
    }
    const currency='USD'
    const locale='en-US'
    return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(value);
  }

  export function bankAccountFormatter(value){
    return "*"+value.slice(-4)
  }

  export function removeNewlines(str) {
    
    return str?.replace(/[\n\r]/g, '');
}