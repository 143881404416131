import React from 'react';
import Grid from '@mui/material/Grid';
import Tile from '../ui/tile';
import GaugeTile from '../ui/tile-gauge.js';
import TileSet from '../ui/tileset';
import PortalFrame from '../ui/portal-frame';
import { Typography } from '@mui/material';
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';

export default function Dashboard(props) {
    //Step 2 added new way
    const userProfileContext = useContext(UserProfileContext);

    //const userProfile = useCurrentUserProfile(); // comment out old way

    const userProfile = userProfileContext.userProfile  // add new way

    const title = "Dashboard"

    //if (userProfile.isLoading) {
    if (userProfileContext.isLoading || !userProfile || !userProfile.currentOrg || !userProfile.currentCase) {

        return (
            <PortalFrame title={title}>
                <PageSkeleton/>

            </PortalFrame>
        )
    }


    //const orgID = userProfile.data.currentOrg.orgID
    //const caseID = userProfile.data.currentCase.caseID
    //console.log(`in dashboard, user Profile=${JSON.stringify(userProfile)}`)
    const orgID = userProfile.currentOrg.orgID
    const caseID = userProfile.currentCase?.caseID


    //const debug=false

    return (
        <PortalFrame title={title}>
            {userProfile.currentCase && 
            <Grid container justify="center" spacing={2} >

                <TileSet title="Data Health" >

                    <GaugeTile orgID={orgID} caseID={caseID} tile_key="MEASURE_PROCESSED_FILES" />


                    <GaugeTile orgID={orgID} caseID={caseID} tile_key="MEASURE_VERIFIED_BANK_ACCOUNTS" />
                    <GaugeTile orgID={orgID} caseID={caseID} tile_key="MEASURE_VERIFIED_BANK_STATEMENTS" />

                    <GaugeTile orgID={orgID} caseID={caseID} tile_key="MEASURE_VERIFIED_BANK_TRANSACTIONS" />



                </TileSet>
                <TileSet title="Core Insights" >
                    <Tile orgID={orgID} caseID={caseID} tile_key="NUM_ACCOUNTS" />
                    <Tile orgID={orgID} caseID={caseID} tile_key="NUM_TRANSACTIONS" />
                    <Tile orgID={orgID} caseID={caseID} tile_key="TOTAL_INCOME" />
                    <Tile orgID={orgID} caseID={caseID} tile_key="TOTAL_EXPENSES" />
                    <Tile orgID={orgID} caseID={caseID} tile_key="NET_SPEND" />
                </TileSet>

                <TileSet title="Other Insights" >
                    <Tile orgID={orgID} caseID={caseID} tile_key="NUM_CATEGORIES" />
                    <Tile orgID={orgID} caseID={caseID} tile_key="NUM_EXT_TO_ACCOUNTS" />
                    <Tile orgID={orgID} caseID={caseID} tile_key="AMT_SENT_TO_EXT_ACCOUNTS" />
                    <Tile orgID={orgID} caseID={caseID} tile_key="NUM_FLAGGED_TRANSACTIONS" />
                </TileSet>

            </Grid>
            }
            {!userProfile.currentCase &&
                <Typography>You are not assigned to any cases for this organization</Typography>
            }


        </PortalFrame>
    );
}