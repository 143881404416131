import reportTypes from '../cfg/report-types';
import { queryCaseNeo4j } from './pydbcall';

function postProcessResult(records) {
  return records.map(record => ({...record, id:crypto.randomUUID()}));
  // return records.map(record => (
  //   {
  //     ...record
  //   }
  // ));
}

export async function getReportDataByReportType(userProfile,reportType) {
  //const reportData = [];
  
  console.log(`getReportDataByReportType ${reportType}`)
  const reportDefinition = reportTypes[reportType];
  console.log(`reportDefinition=${JSON.stringify(reportDefinition)}`)

  const orgID = userProfile.currentOrg.orgID
  const caseID = userProfile.currentCase.caseID
  const userID = userProfile.currentUser.userID

  let neoResult


  const qry = reportDefinition.query
  console.log(`qry=${qry} orgID=${orgID} caseID=${caseID} userID=${userID}`)


  const parameters = { orgID: orgID, caseID: caseID, userID: userID }

  await queryCaseNeo4j(qry, parameters).then((result) => {

    neoResult = postProcessResult(result.records)
    //console.log(neoResult)

  })

  //const jsonResult = JSON.stringify(neoResult)

  return neoResult;

  // switch (reportType) {
  //   case 'transactions':
  //     reportData.push({ "field": "txn_date", "label": "Date", "type": "date" });
  //     reportData.push({ "field": "txn_description", "label": "Description", "type": "text" });
  //     reportData.push({ "field": "txn_amount", "label": "Amount", "type": "number" });
  //     reportData.push({ "field": "txn_category", "label": "Category", "type": "text" });
  //     reportData.push({ "field": "txn_subcategory", "label": "Subcategory", "type": "text" });
  //     reportData.push({ "field": "txn_account", "label": "Account", "type": "text" });
  //     break;
  //   case 'cases':
  //     reportData.push({ "field": "caseID", "label": "Case ID", "type": "text" });
  //     reportData.push({ "field": "caseName", "label": "Case Name", "type": "text" });
  //     break;
  //   case 'organizations':
  //     reportData.push({ "field": "orgID", "label": "Org ID", "type": "text" });
  //     reportData.push({ "field": "orgName", "label": "Org Name", "type": "text" });
  //     break;
  //   case 'users':
  //     reportData.push({ "field": "userID", "label": "User ID", "type": "text" });
  //     reportData.push({ "field": "userName", "label": "User Name", "type": "text" });
  //     break;
  //   default:
  //     break;
  // }
  //return reportData;
}
