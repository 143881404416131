import { useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { ResizableBox } from 'react-resizable';
import PortalFrame from '../ui/portal-frame.js';
import PDFReviewer from "../ui/review-jump-page-pdf.tsx";
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

import AWS from 'aws-sdk';
import amplifyConfig from '../../amplifyconfiguration.json'
import { fetchAuthSession } from 'aws-amplify/auth';
import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";

import { queryCaseNeo4j } from '../../api/pydbcall.js';
//import TransactionCrudGrid from '../ui/grid-editable-transactions.js';
import AccountCrudGrid from '../ui/grid-editable-accounts.js';
import PDFSidePanel from '../ui/pdf-side-panel.js';

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';





const ReviewBankAccounts = (props) => {

//Step 2 added new way
const userProfileContext=useContext(UserProfileContext);

//const userProfileResult = useCurrentUserProfile() // comment out old way



    const [currentUserProfile, setCurrentUserProfile]=useState()
    const [orgID, setOrgID] = useState()
    const [caseID, setCaseID] = useState()
    const [orgName, setOrgName] = useState()
    const [caseName, setCaseName] = useState()
    const [bankAccountList, setBankAccountList] = useState([])
    const [bankAccount, setBankAccount] = useState()
    //const [bankStatementList, setBankStatementList] = useState([])
    const [bankStatement, setBankStatement] = useState()
    const [fileS3Key, setFileS3Key] = useState()
    const [fileUrl, setFileUrl] = useState()
    const [highlightedTxn, setHighlightedTxn] = useState();
    const [transactions, setTransactions] = useState([])
    



    const handleRowClick = (bank_account) => {
        setBankAccount(bank_account)
    }


    const title = props.title ? props.title : "Review Bank Accounts"


    useEffect(() => {
        /// this effect loads the current user profile and then gets the bank account list
        const getBankAccountList = async () => {
            //console.log('refreshing bank account list')

            //const userID = userProfileResult.data.currentUser
            //const currentUser=userProfileResult.data.currentUser
            setCurrentUserProfile(userProfileContext.userProfile)
            //const orgID = userProfileResult.data.currentOrg.orgID
            //const caseID = userProfileResult.data.currentCase.caseID

            const orgID = userProfileContext.userProfile.currentOrg?.orgID
            const caseID = userProfileContext.userProfile.currentCase?.caseID


            if(!orgID || !caseID){
                return []
            }

            
            setOrgID(orgID)
            setCaseID(caseID)
            
            setOrgName(userProfileContext.userProfile.currentOrg?.orgName)
            setCaseName(userProfileContext.userProfile.currentCase?.caseName)

            const parms = {
                orgID: orgID,
                caseID: caseID
            }

            //console.log(`query parms: ${JSON.stringify(parms)}`)

            const accountQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount) return ba order by ba.pk"
            const accountQueryResult = await queryCaseNeo4j(accountQuery, parms)
            const bankAccountList = accountQueryResult.records.map(record => ({ ...record.ba, id: record.ba.pk, label: record.ba.name }))
            //console.log(`bank account list: ${JSON.stringify(bankAccountList)}`)
            setBankAccountList(bankAccountList)

            if (bankAccountList.length >= 1) {
                const bank_account = bankAccountList[0]
                setBankAccount(bank_account)
            }


        }
        if (!userProfileContext.isLoading && userProfileContext.userProfile) {
            getBankAccountList()
        }

    }, [userProfileContext.isLoading]
    )

    useEffect(() => {
        /// when the bank account changes, get the bank statement list
        const getBankStatement = async () => {
            //console.log('refreshing bank statement list')

            const parms = {
                orgID: orgID,
                caseID: caseID,
                bank_account_pk: bankAccount.pk
            }
            //console.log(`query parms: ${JSON.stringify(parms)}`)
            const statementQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement) return bs order by bs.pk LIMIT 1"
            const statementQueryResult = await queryCaseNeo4j(statementQuery, parms)
            const statementList = statementQueryResult.records.map(record => ({ ...record.bs, id: record.bs.pk, label: record.bs.end_date }))
            //console.log(`setting bank statement list, size=${statementList.length}`)
            //setBankStatementList(statementList)

            if (statementList.length >= 1) {
                const bank_statement = statementList[0]
                setBankStatement(bank_statement)
                //setTransactions([])
            }
            // else{
            //     setBankStatement()
            //     setTransactions([])
            // }
        }
        if (bankAccount && bankAccount.pk) {
            getBankStatement()
        }
    }, [bankAccount]

    )

    useEffect(() => {
        /// this effect triggers when bank statement is selected to refresh the bank statement header and transactions
        /// it also reads the pdf s3 key from the database and sets it
        const getStatementDetails = async () => {

            //console.log('refreshing statement details....')
            const txnQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$bank_statement_pk})-[:CONTAINS]->(t:Transaction) return t as txn order by txn.page_idx asc limit 1"
            const pdfS3KeyQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$bank_statement_pk})<-[CONTAINS]-(sd:ScannedDoc)-[:PROCESSED]->(cf:CaseFile) return cf.path as s3key"
            try {
                const parms = {
                    orgID: orgID,
                    caseID: caseID,
                    bank_account_pk: bankAccount.pk,
                    bank_statement_pk: bankStatement.pk
                }

                //console.log(`query parms: ${JSON.stringify(parms)}`)

                // refresh transaction list
                const txnQueryResult = await queryCaseNeo4j(txnQuery, parms)
                const txns = txnQueryResult.records.map((record) => record.txn)
                //console.log(`setting transactions =${JSON.stringify(txns)}`)
                setTransactions(txns)
                const ht={...txns[0], bbox:[0,0,0,0]}
                //console.log(`setting highlighted txn = ${JSON.stringify(ht)}`)
                setHighlightedTxn(ht)

                // refresh bank statement PDF S3 Key
                const fileKeyQueryResult = await queryCaseNeo4j(pdfS3KeyQuery, parms)
                const fileKey = fileKeyQueryResult.records[0].s3key
                //console.log(`setting file key =${fileKey}`)
                setFileS3Key(fileKey)


            }
            catch (error) {
                console.error('Error reading transactions:');
                console.error(error)
            }
        }
        if (bankStatement && bankStatement.pk) {
            getStatementDetails()
        }
    }, [bankStatement]
    )

    useEffect(() => {
        /// this effect triggers when a bank statement is selected
        /// the effect gets the PDF for the selected bank statement

        const getPageDetails = async () => {

            //const pdfS3KeyQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$bank_statement_pk})<-[CONTAINS]-(sd:ScannedDoc)-[:PROCESSED]->(cf:CaseFile) return cf.path as s3key"
            //const txnQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$bank_statement_pk})-[:CONTAINS]->(t:Transaction) return t as txn order by txn.pk asc"


            try {

                // const parms = {
                //     orgID: orgID,
                //     caseID: caseID,
                //     bank_account_pk: bankAccount.pk,
                //     bank_statement_pk: bankStatement.pk
                // }

                // const txnQueryResult = await queryCaseNeo4j(txnQuery, parms)
                // const txns = txnQueryResult.records.map((record) => record.txn)
                // setTransactions(txns)


                //const fileKeyQueryResult = await queryCaseNeo4j(pdfS3KeyQuery, parms)

                //const fileKey = fileKeyQueryResult.records[0].s3key
                const { credentials } = await fetchAuthSession();
                const S3_BUCKET = 'divisibly-statements';
                const region = amplifyConfig.aws_project_region
                //const ACCESS_KEY_ID = AWS.config.credentials.accessKeyId;
                //const SECRET_ACCESS_KEY = AWS.config.credentials.secretAccessKey;

                const client = new CognitoIdentityProviderClient({
                    region: region,
                    credentials: credentials
                });

                AWS.config.update(
                    {
                        region: region,
                        credentials: credentials
                    }
                );

                const s3 = new AWS.S3({
                    apiVersion: "2006-03-01"
                });


                //const fileName = uuidv4() + '-' + file.name; // Generating unique file names

                //console.log(`file.type=${file.type}`)

                const params = {
                    Bucket: S3_BUCKET,
                    Key: fileS3Key,
                };

                const signedUrl = await s3.getSignedUrlPromise('getObject', params);
                setFileUrl(signedUrl);

            } catch (error) {
                console.error('Error reading file:');
                console.error(error)
            }
        };

        //console.log('fileKey changed, refreshing file url')


        if (bankStatement && bankStatement.pk) {
            getPageDetails()
        }



    }, [fileS3Key]
    )

    

    // useEffect(() => {
    //     // effect to compare the start & end balance to the net transaction amounts
    //     if (transactions && bankStatement) {
    //         const netTransactionAmount = Math.round(
    //             transactions.reduce((total, transaction) => {
    //                 return total + transaction.amount;
    //             }, 0)
    //             , 2);

    //         const netStatementAmount = Math.round(bankStatement.end_balance - bankStatement.begin_balance,2)

    //         const delta = Math.round(netTransactionAmount - netStatementAmount,2)
    //         setNetStatementAmount(netStatementAmount)
    //         setNetTransactionAmount(netTransactionAmount)
    //         setStatementTransactionDelta(delta)
    //     }
    // }, [bankStatement, transactions]
    // )


    const onBankAccountChange = (event, value, reason) => {
        //console.log(`event reason: ${reason}`)
        //console.log(`selected bank account: ${JSON.stringify(value)}`)
        setBankAccount(value)

    }

    const onBankStatementChange = (event, value, reason) => {
        //console.log(`event reason: ${reason}`)
        //console.log(`selected bank statement: ${JSON.stringify(value)}`)
        setBankStatement(value)
    }



    //console.log(`fileUrl=${fileUrl}`)

    if (!fileUrl) {
        return (
            <PortalFrame title={title}>
                {!caseID && <Typography>You are not assigned to any cases for this organization</Typography>}
                {caseID && 
                <PageSkeleton/>
            }
            </PortalFrame>
        )
    }

    //console.log(`GOT HERE XXXX `)
    //console.log(`RENDER: transactions=${JSON.stringify(transactions)}`)
    return (
        <PortalFrame title={title} orgName={orgName} caseName={caseName}>
            <PDFSidePanel leftChild={<AccountCrudGrid accounts={bankAccountList} handleRowClick={handleRowClick} currentUserProfile={currentUserProfile}/>} highlightedTxn={highlightedTxn} fileUrl={fileUrl}/>
        </PortalFrame>
    );
};

export default ReviewBankAccounts;
