//import { useContext } from 'react';
import { queryCaseNeo4j } from './pydbcall';
import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { dateGetter } from '../components/ui/type-utilities';
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";
import { getReportDataByReportType } from './get-report-by-type';




function postProcessResult(records) {
  return records.map(record => (
    {
      ...record.r, reportData: record.r.reportData?JSON.parse(record.r.reportData):[], reportFilters: record.r.reportFilters?JSON.parse(record.r.reportFilters):[]
    }
  ));


}

async function getReportsForCurrentUser(userProfile) {
  const orgID = userProfile.currentOrg.orgID
  const caseID = userProfile.currentCase.caseID
  const userID = userProfile.currentUser.userID

  let neoResult

  //console.log(`getReportsForCurrentUser ${orgID} ${caseID} ${userID}`)

  const qry = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})<-[:BELONGS_TO]-(u:User {userID:$userID})-[:HAS]->(r:Report)-[:WITHIN]->(c) return r order by r.updateDate desc"


  const parameters = { orgID: orgID, caseID: caseID, userID: userID }

  await queryCaseNeo4j(qry, parameters).then((result) => {

    neoResult = postProcessResult(result.records)
    //console.log('getReportsForCurrentUser')
    //console.log(neoResult)

  })

  return neoResult
}

export async function forceRefreshTransactionsForCurrentCase(orgID, caseID) {
  //console.log(`forcing revalidate....`)
  mutate(['TRANSACTIONS_FOR_CURRENT_CASE', orgID, caseID]
    //, undefined,   // update cache data to `undefined`
    //{ revalidate: true } // do not revalidate
  )
}

export function useReportsForCurrentUser(userProfile, shouldFetch) {

  //console.log("IN HERE WHATS HAPPENING")

  const result = useSWR(shouldFetch ? ['REPORTS_FOR_CURRENT_USER', userProfile] : null, ([path, userProfile]) => getReportsForCurrentUser(userProfile));
  //console.log(`IN HERE WHATS HAPPENING2`)
  let {data, isLoading, error} = result
  //console.log(`${data} ${isLoading} ${error}`)
  return result
}

export function useUpdateReportForCurrentUser(userProfile) {

  const result = useSWRMutation(['REPORTS_FOR_CURRENT_USER', userProfile], updateReportForCurrentUser)
  return result
}
export async function updateReportForCurrentUser(url, args) {
  const currentUserProfile = args?.arg?.userProfile
  const report = args?.arg?.report
  // const changedFields = args?.arg?.changedFields

  /// sanity check
  if (!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser) {
    throw Error(`pass a valid current user profile to this call`)
  }

  const orgID = currentUserProfile.currentOrg.orgID
  const caseID = currentUserProfile.currentCase.caseID
  const userID = currentUserProfile.currentUser.userID
  const reportID = report.reportID?report.reportID:crypto.randomUUID()
  console.log(`got here 1, reportType: ${report.reportType}`)
  const reportData = JSON.stringify(report.reportData?report.reportData:await getReportDataByReportType(currentUserProfile, report.reportType))
  console.log(`got here 2, reportData: ${JSON.stringify(reportData)}`)
  const reportFilters = JSON.stringify(report.reportFilters?report.reportFilters:[])

  console.log('updating report...')
  const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})<-[:BELONGS_TO]-(u:User {userID:$userID}) WITH dt, u, c MERGE (u)-[:HAS]->(r:Report {reportID:$reportID})-[:WITHIN]->(c) SET r.reportName=$reportName, r.reportType=$reportType, r.reportData=$reportData, r.reportFilters=$reportFilters, r.updateDate=dt return r'
  const parms = {
    "orgID": orgID,
    "caseID": caseID,
    "userID": userID,
    "reportID": reportID,
    "reportName": report.reportName,
    "reportType": report.reportType,
    "reportData": reportData,
    "reportFilters": reportFilters,
  }
  console.log(parms)
  console.log(`calling update now...`)
  await mutateCaseNeo4j(update_mutation, parms)


}

export function useDeleteReportForCurrentUser(userProfile) {

  const result = useSWRMutation(['REPORTS_FOR_CURRENT_USER', userProfile], deleteReportForCurrentUser)
  return result
}

export async function deleteReportForCurrentUser(url, args) {
  const currentUserProfile = args?.arg?.userProfile
  const report = args?.arg?.report
  // const changedFields = args?.arg?.changedFields

  /// sanity check
  if (!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser) {
    throw Error(`pass a valid current user profile to this call`)
  }

  const orgID = currentUserProfile.currentOrg.orgID
  const caseID = currentUserProfile.currentCase.caseID
  const userID = currentUserProfile.currentUser.userID

  console.log('deleting report...')
  const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})<-[:BELONGS_TO]-(u:User {userID:$userID}) WITH * MATCH (u)-[:HAS]->(r:Report {reportID:$reportID})-[:WITHIN]->(c) WITH dt, r DETACH DELETE r return r'
  const parms = {
    "orgID": orgID,
    "caseID": caseID,
    "userID": userID,
    "reportID": report.reportID,
  }

  await mutateCaseNeo4j(update_mutation, parms)


}
