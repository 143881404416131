import React from 'react';
import { Fragment,useState } from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Box,
  Toolbar,
  Button,
  
} from '@mui/material';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ListPaper from './paper-list';
import AddIcon from '@mui/icons-material/Add';
import ConfirmBeforeDeleteDialog from './dialog-confirm-before-delete';
import EditFormDialog from './dialog-edit-form';
import ReportEditList from './list-edit-report';
import UserReportForm from './form-user-report';




export default function EditReportPanel(props) {
    const reportList = props.reportList?props.reportList:[]
    const showEdit = props.showEdit ? props.showEdit : true;
    const showDelete = props.showDelete ? props.showDelete : true;
    const updateReport = props.updateReport?props.updateReport:()=>{}
    const deleteReport = props.deleteReport?props.deleteReport:()=>{}
    const emptyItem = {}
    
    const [selectedItem, setselectedItem] = useState(null);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [editData, setEditData] = useState({})
    const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false)

    

    const handleCreateClick = () => {
      //create is just an edit on a blank (empty) item
      handleEditClick(emptyItem)
    }
  
    const handleEditClick = (data) => {
      // set the selected item
      setselectedItem(data);
      // set the data to edit
      setEditData(data)
      // open the edit item dialog window
      setOpenEditDialog(true);
    };

    const handleLaunchClick = (data) => {
      // launch the selected item
      console.log("launching report", data)
      window.location.href = '/report/' + data.reportType + '/' + data.reportID;
    }
  
    const handleDelete = (data) => {
      // set the selected item
      setselectedItem(data);
      // open the delete confirmation dialog
      setOpenDeleteConfirmDialog(true);
    }

    const onProceedDelete = () => {
      // user has been asked to confirm the delete, and still wants to proceed with delete
  
      // call handler to delete the data 
      deleteReport(selectedItem)
        //after save, reload the list
        //forceListReload()
      
  
      
      //close the delete confirm dialog
      setOpenDeleteConfirmDialog(false)
    }
  
    const onCancelDelete = () => {
      // user has been asked to confirm the delete, and cancelled out of the delete
  
      //close the delete confirm dialog
      setOpenDeleteConfirmDialog(false)
    }

    const handleSave = (data) => {
      console.log("saving data", data)
      // call handler to update the data 
      updateReport(data)
      //close the edit dialog
      setOpenEditDialog(false)
    }

    return (
      <Fragment>
      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
      <ListPaper>
        <Toolbar>
          <Button color="primary" startIcon={<AddIcon />} onClick={handleCreateClick}>
            Add report
          </Button>
        </Toolbar>
        {reportList.length === 0 && <div>No reports found</div>}
        {reportList.length > 0 && 
        <ReportEditList data={reportList} showEdit={true} showDelete={true} showLaunch={true} handleEditClick={handleEditClick} handleDelete={handleDelete} handleLaunchClick={handleLaunchClick}/>
        }
        </ListPaper>
        </Box>
        <EditFormDialog title={"Edit Report"} form={UserReportForm} open={openEditDialog} data={editData} onCancel={() => setOpenEditDialog(false)} onSubmit={handleSave} />
        <ConfirmBeforeDeleteDialog open={openDeleteConfirmDialog} list_element={ReportEditList} data={selectedItem} onProceed={onProceedDelete} onCancel={onCancelDelete} />
        </Fragment>   
    
      );
    
}