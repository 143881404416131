import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

const UserEditList = (props) => {

  const data = props.data ? props.data : []
  const handleEditClick = props.handleEditClick
  const handleDelete = props.handleDelete
  const showEdit = props.showEdit ? props.showEdit : false;
  const showDelete = props.showDelete ? props.showDelete : false;

  if (data.length === 0) {
    return <div></div>
  }

  return (

    <List>
      {data.map(( this_user ) => (
        <React.Fragment key={this_user.userID + "-DUMMY"}>
          <ListItem key={this_user.userID}>
            <ListItemText primary={this_user.firstName + " " + this_user.lastName} secondary={this_user.email + " "+ this_user.phone}/>
            <ListItemSecondaryAction>
              {showEdit &&
                <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(this_user)}>
                  <EditIcon />
                </IconButton>
              }
              {showDelete &&
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(this_user)}>
                <DeleteIcon />
              </IconButton>
              }
            </ListItemSecondaryAction>
          </ListItem>
          <Divider></Divider>
        </React.Fragment>
      ))}
    </List>


  );
};

export default UserEditList;