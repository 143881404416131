import{queryPrimaryNeo4j} from './pydbcall';
import{queryCaseNeo4j} from './pydbcall';
import useSWR from 'swr'


export function useCaseQuery(qry,parameters={},shouldFetch=true) {
  const result = useSWR(shouldFetch?[qry,parameters]:null, ([qry,parameters])=>queryCaseNeo4j(qry,parameters));
  
  return result
}

export function usePrimaryQuery(qry,parameters={},shouldFetch=true) {
  const result = useSWR(shouldFetch?[qry,parameters]:null, ([qry,parameters])=>queryPrimaryNeo4j(qry,parameters));
  
  return result
}
