//import { useContext } from 'react';
import { queryCaseNeo4j } from './pydbcall';
import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import { dateGetter } from '../components/ui/type-utilities';
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";




function postProcessResult(records) {
  
  return  records.map(record => (
    
    {
      
      DRFA_Category: record.dc?record.dc.category:'Uncategorized',
      DRFA_Subcategory: record.dc?record.dc.subcategory:'Uncategorized',
      totalAmount: record.totalAmount,
      totalCount: record.totalCount,
      }
     
));


}

async function getDRFAReportForCurrentCase(orgID, caseID) {
  let neoResult


  

  const qry = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount)-[:HAS]->(bs:BankStatement)-[rbst:CONTAINS]->(txn:Transaction {})-[:ORIGINAL]->(origtxn:ScannedTransaction) OPTIONAL MATCH(txn)-[rsc]->(sc:Subcategory) OPTIONAL MATCH (sc)-[]->(cat:Category) OPTIONAL MATCH (txn)-[rf]->(f:Flag) OPTIONAL MATCH (sc)-[:MAPS_TO]->(dc:DRFACategory) return dc, SUM(txn.amount) as totalAmount, COUNT(txn) as totalCount"

  const parameters = { orgID: orgID, caseID: caseID }

  await queryCaseNeo4j(qry, parameters).then((result) => {
    
    neoResult = postProcessResult(result.records)
  
  })

  return neoResult
}

// export async function forceRefreshTransactionsForCurrentCase(orgID,caseID){
//   console.log(`forcing revalidate....`)
//   mutate(['TRANSACTIONS_FOR_CURRENT_CASE', orgID, caseID]
//     //, undefined,   // update cache data to `undefined`
//     //{ revalidate: true } // do not revalidate
//     )
// }

export function useDRFAReportForCurrentCase(orgID, caseID, shouldFetch) {

  const result = useSWR(shouldFetch ? ['DRFA_FOR_CURRENT_CASE', orgID, caseID] : null, ([path, orgID, caseID]) => getDRFAReportForCurrentCase(orgID, caseID));
  return result
}

// export function useMutateTransactionForCurrentCase(orgID, caseID) {

//   const result = useSWRMutation(['TRANSACTIONS_FOR_CURRENT_CASE', orgID, caseID], mutateTransactionForCurrentCase)
//   return result
// }
// export async function mutateTransactionForCurrentCase(url, args) {
//   const currentUserProfile = args?.arg?.userProfile
//   const transaction = args?.arg?.transaction
//   const changedFields = args?.arg?.changedFields

//   /// sanity check
//   if (!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser) {
//     throw Error(`pass a valid current user profile to this call`)
//   }

//   const orgID = currentUserProfile.currentOrg.orgID
//   const caseID = currentUserProfile.currentCase.caseID
//   const email = currentUserProfile.currentUser.email

//   /// more sanity checks

//   if (transaction.orgID && transaction.orgID !== orgID) {
//     throw Error(`transaction org is different from the current user org, aborting`)
//   }
//   if (transaction.caseID && transaction.caseID !== caseID) {
//     throw Error(`transaction case is different from the current user case, aborting`)
//   }


//   function anyChangedFields(fields) {
//     return fields.some(field => field in changedFields)
//   }

//   function isTransactionUpdated() {
//     const transactionFields = ["_verified","amount","txn_date","description"]
//     return anyChangedFields(transactionFields)
//   }

//   function isSubcategoryUpdated() {
//     const subcategoryFields = ["cat_type","subcategory","category"]
//     return anyChangedFields(subcategoryFields)
//   }

//   function isCategoryTypeUpdated(){
//     const fields = ["cat_type"]
//     return anyChangedFields(fields)
//   }

//   function isFlagUpdated() {
//     const flagFields = ["flag_type","flag"]
//     return anyChangedFields(flagFields)
//   }

//   function isFlagTypeUpdated() {
//     const fields = ["flag_type"]
//     return anyChangedFields(fields)
//   }

//   if (isTransactionUpdated()) {
//     console.log('mutating transaction details...')
//     const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$period_pk})-[:CONTAINS]->(t:Transaction {pk:$pk}) MERGE (t)-[r:AUDIT]->(th:TransactionHistory {_auditDate:dt}) set th=properties(t), th._auditDate=dt, th._auditUser=$currentUser, r._auditDate=dt, r._auditUser=$currentUser, t._updateDate=dt, t._updateUser=$currentUser, t._verified=$_verified, t.amount=$amount, t.txn_date=date(datetime($txn_date)), t.description=$description return t,r,th'
//     const parms = {
//       "orgID": orgID,
//       "caseID": caseID,
//       "currentUser": email,
//       "bank_account_pk": transaction.bank_account_pk,
//       "period_pk": transaction.period_pk,
//       "pk": transaction.pk,
//       "_verified": transaction._verified,
//       "amount": transaction.amount,
//       "txn_date": transaction.txn_date,
//       "description": transaction.description
//     }

//     await mutateCaseNeo4j(update_mutation, parms)
//   }

//   if (isSubcategoryUpdated()){
//     console.log('mutating subcategory details...')
//     //console.log(`transaction=${JSON.stringify(transaction)}`)

//     let newTypeLabel=":CONFIRMED"
//     let oldTypeLabel=":PROPOSED"
    
//     if(isCategoryTypeUpdated() && transaction.cat_type==="PROPOSED"){
//       // user wants to set category back to proposed
//       newTypeLabel=":PROPOSED"
//       oldTypeLabel=":CONFIRMED"

//     }

//     const update_mutation = `MATCH (o:Organization {orgID:$orgID})<-[rco:BELONGS_TO]-(c:Case {caseID:$caseID})-[rcba:USES]->(tc:Subcategory {name:$subcategory}) WITH c,tc MATCH (c)-[rcba:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[rbabs:HAS]->(bs:BankStatement {pk:$period_pk})-[rbst:CONTAINS]->(t:Transaction {pk:$pk})  WITH datetime() as dt, t, tc  MERGE (t)-[rtc${newTypeLabel}]->(tc)   ON CREATE SET  rtc._updateDate=dt, rtc._updateUser=$currentUser, rtc._createDate=dt, rtc._createUser=$currentUser ON MATCH SET rtc._updateDate=dt, rtc._updateUser=$currentUser WITH t,rtc OPTIONAL MATCH (t)-[rtc_exist${newTypeLabel}]->(tc2:Subcategory) WHERE tc2.name <> $subcategory DELETE rtc_exist WITH t,rtc OPTIONAL MATCH (t)-[rtp_exist${oldTypeLabel}]->(tc3:Subcategory) DELETE rtp_exist return rtc`
//     const parms = {
//       "orgID": orgID,
//       "caseID": caseID,
//       "currentUser": email,
//       "bank_account_pk": transaction.bank_account_pk,
//       "period_pk": transaction.period_pk,
//       "pk": transaction.pk,
//       "subcategory": transaction.subcategory
      
//     }

//     await mutateCaseNeo4j(update_mutation, parms)
//   }

//   if (isFlagUpdated()){
//     console.log('mutating flag details...')
//     const flag_type_category_match='CATEGORY MATCH'

//     let newTypeLabel=":CONFIRMED"
//     let oldTypeLabel=":PROPOSED"
    
//     if(isFlagTypeUpdated() && transaction.flag_type==="PROPOSED"){
//       // user wants to set category back to proposed
//       newTypeLabel=":PROPOSED"
//       oldTypeLabel=":CONFIRMED"

//     }
//     const delete_mutation = `MATCH (o:Organization {orgID:$orgID})<-[rco:BELONGS_TO]-(c:Case {caseID:$caseID})-[rcba:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[rbabs:HAS]->(bs:BankStatement {pk:$period_pk})-[rbst:CONTAINS]->(t:Transaction {pk:$pk})-[rtc]->(f:Flag) DELETE rtc return t`
//     const update_mutation = `MATCH (o:Organization {orgID:$orgID})<-[rco:BELONGS_TO]-(c:Case {caseID:$caseID}) MERGE (c)-[rcba:USES]->(f:Flag {name:$flag}) WITH c,f MATCH (c)-[rcba:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[rbabs:HAS]->(bs:BankStatement {pk:$period_pk})-[rbst:CONTAINS]->(t:Transaction {pk:$pk})  WITH datetime() as dt, t, f  MERGE (t)-[rtc${newTypeLabel}]->(f)   ON CREATE SET rtc.type=$flagType, rtc._updateDate=dt, rtc._updateUser=$currentUser, rtc._createDate=dt, rtc._createUser=$currentUser ON MATCH SET rtc.type=$flagType, rtc._updateDate=dt, rtc._updateUser=$currentUser WITH t,rtc OPTIONAL MATCH (t)-[rtc_exist${newTypeLabel}]->(f2:Flag) WHERE f2.name <> $flag DELETE rtc_exist WITH t,rtc OPTIONAL MATCH (t)-[rtp_exist${oldTypeLabel}]->(f3:Flag) DELETE rtp_exist return rtc`
//     const parms = {
//       "orgID": orgID,
//       "caseID": caseID,
//       "currentUser": email,
//       "bank_account_pk": transaction.bank_account_pk,
//       "period_pk": transaction.period_pk,
//       "pk": transaction.pk,
//       "flag": transaction.flag,
//       "flagType": flag_type_category_match
      
//     }
//     console.log(`parms=${JSON.stringify(parms)}`)
//     const mutation = transaction.flag?update_mutation:delete_mutation
//     await mutateCaseNeo4j(mutation, parms)

//   }




//   const result = null
//   //const result = getTransactionsForCurrentCase(orgID, caseID)

//   return result




// }
