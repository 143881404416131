import { useState, useEffect, Fragment } from 'react';
import PortalFrame from '../ui/portal-frame.js';
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";
//import CategoryDataPreview from '../ui/category-data-preview-panel.js';
import { queryCaseNeo4j } from '../../api/pydbcall.js';
import ExportToExcel from '../ui/export-to-excel.js';
import { Alert } from '@mui/material';
import CategoryRuleCrudGrid from '../ui/grid-editable-category-rules.js';
import { Button } from '@mui/material';
import { applyRulestoCase } from '../../api/applyrulescall.js';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import CategoryRuleBulkLoadDialog from '../ui/dialog-category-rule-bulk-load.js';
import UploadIcon from '@mui/icons-material/Upload';

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';
//import { Page } from 'react-pdf';
import { useSubcategoriesForCurrentCase } from '../../api/swrsubcategoriesforcurrentcase';

export default function ManageCategoryRules(props) {

    const alert_severity_success = 'success'
    const alert_severity_info = 'info'
    const alert_severity_warning = 'warning'
    const alert_severity_error = 'error'




    const [caseCategoryRules, setCaseCategoryRules] = useState([])
    const [caseCategoryRulesFromDialog, setCaseCategoryRulesFromDialog] = useState([])
    const [categoryRulesLoading, setCategoryRulesLoading] = useState(true)
    const [rule, setRule] = useState()
    const [openBulkLoadDialog, setOpenBulkLoadDialog] = useState(false)

    const title = props.title ? props.title : "Manage Category Rules"

    const userProfileContext = useContext(UserProfileContext); // step 3 add this new way

    const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg?.orgID
    const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase?.caseID

    

    const subcategoryQueryResult = useSubcategoriesForCurrentCase(orgID, caseID, !userProfileContext.isLoading&&orgID&&caseID, false)




    const handleGridAdd = (data) => {
        //console.log(`in handleGridAdd: ${JSON.stringify(data)}`)

    }
    const handleGridUpdate = (data) => {
        //console.log(`in handleGridUpdate: ${JSON.stringify(data)}`)
        let found = false
        let newRules = caseCategoryRules.map((record) => {
            if (!data.phrase.localeCompare(record.phrase, undefined, { sensitivity: 'accent' })) {
                found = true
                // udpate 
                //console.log(`updated`)
                return data
            }
            return record
        })

        if (!found) {
            // add
            //console.log(`added`)
            newRules.push(data)
        }
        //console.log(`setting new case category rules:${JSON.stringify(newRules)}`)

        setCaseCategoryRules(
            newRules
        )



    }
    const handleGridDelete = (data) => {
        //console.log(`in handleGridDelete: ${JSON.stringify(data)}`)
        setCaseCategoryRules(caseCategoryRules.filter((record) => data.phrase.localeCompare(record.phrase, undefined, { sensitivity: 'accent' })))


    }
    const handleRowClick = (rule) => {
        setRule(rule)
    }

    const handleBulkLoadClick = () => {
        setOpenBulkLoadDialog(true)
    }

    const handleBulkLoadCancel = () => {
        setOpenBulkLoadDialog(false)
    }

    const handleBulkLoadSubmit = (updatedRules) => {
        //console.log(`in handle bulk load submit, updatedRules=${JSON.stringify(updatedRules)}`)
        const updatedDict = {};
        let adds = []
        let deletes = []


        updatedRules.forEach(item => {
            if (item.action == 'ADD') {
                adds.push({ ...item, proposed_count: 0, confirmed_count: 0 })
            }
            else if (item.action == 'UPDATE') {
                updatedDict[item.phrase.toLowerCase()] = item;
            }
            else if (item.action == 'DELETE') {
                deletes.push(item)
            }


        });

        //console.log(`in handle bulk load submit, updatedDict=${JSON.stringify(updatedDict)}`)


        // process updates
        let updatedExisting = caseCategoryRules.map((existingRule) =>
            (updatedDict[existingRule.phrase.toLowerCase()] ? updatedDict[existingRule.phrase.toLowerCase()] : existingRule)
        );

        // process deletes
        updatedExisting = updatedExisting.filter((record) =>
            !deletes.find((deleteRecord) => !deleteRecord.phrase.localeCompare(record.phrase, undefined, { sensitivity: 'accent' }))
        )
        // process adds
        //console.log(`adding records: ${JSON.stringify(adds)}`)

        adds.forEach((newRecord) => updatedExisting.push(newRecord))

        setCaseCategoryRules(updatedExisting);

        //console.log(`updated records: ${JSON.stringify(updatedExisting)}`)




    }




    useEffect(() => {



        const formatCategoryRuleResult = (records) => {
            return records.map(record => ({
                //id: record.rule.rule_id,
                phrase: record.rule.phrase,
                subcategory: record.subcat.name,
                proposed_count: record.proposed_count,
                confirmed_count: record.confirmed_count

            })

            )

        }



        const getCaseCategoryRules = async () => {
            setCategoryRulesLoading(true)
            const orgID = userProfileContext.userProfile.currentOrg?.orgID
            const caseID = userProfileContext.userProfile.currentCase?.caseID

            if (!orgID || !caseID) {
                return []
            }

            const categoryQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(subcat:Subcategory:DefinedCategory)<-[:ASSIGNS]-(rule:CategoryRule) OPTIONAL MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount)-[:HAS]->(bs:BankStatement)-[rbst:CONTAINS]->(t:Transaction) OPTIONAL MATCH (t)-[rtps:PROPOSED]->(subcat) WHERE rtps.matched_basis = rule.phrase  OPTIONAL MATCH (t)-[rtcs:CONFIRMED]->(subcat) WHERE rtcs.matched_basis = rule.phrase RETURN subcat, rule, count(rtps) as proposed_count, count(rtcs) as confirmed_count"
            const params = {
                orgID: orgID,
                caseID: caseID
            }
            const caseCategoryRuleResult = await queryCaseNeo4j(categoryQuery, params)
            //console.log(`Case Categories: ${JSON.stringify(caseCategoryResult)}`)
            setCaseCategoryRules(formatCategoryRuleResult(caseCategoryRuleResult.records))
            setCategoryRulesLoading(false)


        }

        if (!userProfileContext.isLoading) {
            //console.log(`getting case category rules....`)
            getCaseCategoryRules()
        }

    }, [userProfileContext.isLoading, caseCategoryRulesFromDialog])




    if (userProfileContext.isLoading || subcategoryQueryResult.isLoading) {
        return (
            <PortalFrame title={title}>
                <PageSkeleton />
            </PortalFrame>
        )
    }

    if (!userProfileContext.userProfile.currentOrg || !userProfileContext.userProfile.currentCase) {
        return (
            <PortalFrame title={title}>
                You are not assigned to any cases for this organization
            </PortalFrame>
        )
    }

    const orgName = userProfileContext.userProfile.currentOrg.orgName
    const caseName = userProfileContext.userProfile.currentCase.caseName


    const handleClickApplyRules = async () => {
        applyRulestoCase(orgID, caseID)
    }




    return (
        <PortalFrame title={title} orgName={orgName} caseName={caseName} >


            {subcategoryQueryResult.data.length > 0 &&
                <Fragment>
                    <CategoryRuleBulkLoadDialog open={openBulkLoadDialog} onSubmit={handleBulkLoadSubmit} onCancel={handleBulkLoadCancel} caseCategoryRules={caseCategoryRules} caseCategories={subcategoryQueryResult.data} />
                    <Button color="primary" startIcon={<UploadIcon />} onClick={handleBulkLoadClick}>
                        Upload Rules
                    </Button>
                    {caseCategoryRules.length > 0 &&
                        <Fragment>
                            <ExportToExcel data={caseCategoryRules} fileName={orgName + " - " + caseName + " Category Rules"} sheetName={"Case Category Rules"} />
                            <Button color="primary" startIcon={<CollectionsBookmarkIcon />} onClick={handleClickApplyRules}>
                                Apply Rules to Case
                            </Button>

                        </Fragment>

                    }
                    <CategoryRuleCrudGrid rules={caseCategoryRules} subcategories={subcategoryQueryResult.data} handleRowClick={handleRowClick} onAdd={handleGridAdd} onUpdate={handleGridUpdate} onDelete={handleGridDelete} />
                </Fragment>
            }{subcategoryQueryResult.data.length == 0 &&
                <Alert severity={alert_severity_warning} variant='filled' sx={{ width: '100%' }}>There are no categories available for this case.  Upload will not be able to complete.  Please add case categories first.</Alert>
            }

        </PortalFrame>
    );
}