import React, { useState, useEffect, Fragment, useRef, useContext } from 'react';
import ReportCrudGrid from './grid-editable-report-data';
//import DRFAReport from './report-drfa';
import UnmatchedTransfersDetailReport from './report-unmatched-transfers-detail';
//import { currencyFormatter } from './type-utilities';
import { Box, Button } from '@mui/material';
import ReactToPrint from 'react-to-print';
import ReportContext from '../ui/user-report-context.js';
import { currencyFormatter } from './type-utilities';
import { IconButton } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';



export default function UnmatchedTransfersReport(props){
    //const reportData = props.reportData ? props.reportData : [];
    const reportID = props.reportID;

    const reportContext = useContext(ReportContext);
    const report=reportContext.getReportByID(reportID)
    
    //console.log(`in UnmatchedTransfersReport : report ${JSON.stringify(report)}`)

    //const [data, setData] = useState([]);
    
    const handleToggleInclude = (id) => {
      console.log(`handleToggleInclude ${id}`)
      const oldRow = report.reportData.find((row) => (row.id === id))
      const newRow = { ...oldRow, Disposition: oldRow.Disposition === 'INCLUDE' ? 'EXCLUDE' : 'INCLUDE' }
      //console.log(`oldRow=${JSON.stringify(oldRow)}`)
      //console.log(`newRow=${JSON.stringify(newRow)}`)
      const updatedRow = { ...newRow, isNew: false }
      reportContext.updateReportData(reportID,report.reportData.map((row) => (row.id === newRow.id ? updatedRow : row)))
    }
    
    const gridColumns = [
      {
        field: 'Disposition',
        headerName: 'Include?',
        width: 100,
        align: 'center',
        headerAlign: 'center',
        editable: false,
        renderCell: (params) => (
          <IconButton
            onClick={() => handleToggleInclude(params.row.id)}
            color="primary"
          >
            {params.value === 'INCLUDE' ? <CheckBox /> : <CheckBoxOutlineBlank />}
          </IconButton>
        ),
      },
      {
        field: 'Comment',
        headerName: 'Comment',
        width: 400,
        align: 'left',
        editable: true
      },
      {
        field: 'MissingAccount',
        headerName: 'Missing Account',
        width: 200,
        align: 'left',
        editable: true
      },
      {
        field: 'Account',
        headerName: 'Bank Account',
        width: 400,
        align: 'left',
        editable: false
      },
      {
          field: 'Date',
          headerName: 'Date',
          width: 150,
          align: 'left',
          editable: false
        },
        {
          field: 'Category',
          headerName: 'Category',
          width: 150,
          align: 'left',
          editable: false
        },
        {
          field: 'Subcategory',
          headerName: 'Subcategory',
          width: 150,
          align: 'left',
          editable: false
        },
        {
          field: 'Description',
          headerName: 'Description',
          width: 400,
          align: 'left',
          editable: false
        },
      {
        field: 'Amount',
        headerName: 'Amount',
        type: 'number',
        valueFormatter: currencyFormatter,
        width: 150,
        align: 'left',
        headerAlign: 'left',
        editable: false,
      },
      
  
  
      
    ];

    const reportRef = useRef();

    return (
      <Fragment>
        <Box mt={2}>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary">
                Print Report
              </Button>
            )}
            content={() => reportRef.current} // Specify which component to print
          />
        </Box>
      <Box 
        display="flex" 
        flexDirection="column" 
        height="100vh" // Use full viewport height
      >
        {/* Top component takes 30% of the height */}
        <Box flex="0 0 30%" overflow="auto">
          <ReportCrudGrid
            //rows={report.reportData}
            reportID={reportID}
            //setRows={(data)=>reportContext.updateReportData(reportID,data)}
            columns={gridColumns}
          />
        </Box>

        {/* Bottom component takes 70% of the height */}
        <Box flex="0 0 70%" overflow="auto" ref={reportRef}>
        <UnmatchedTransfersDetailReport reportID={reportID} data={report.reportData} />
        </Box>
        
      </Box>
    </Fragment>

      
    )


}