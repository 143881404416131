import { post, get, put, del } from 'aws-amplify/api';
import useSWR from 'swr'




export async function createCaseDatabaseSecret(orgID,caseID) {

  const reqbody = {
    orgID: orgID,
    caseID: caseID
  }

  const payload = {
    apiName: 'casedbsecret',
    path: `/casedbsecret`,
    options: {
      body: reqbody
    }

  }

  const restOperation = post(payload); 


  const { body } = await restOperation.response;
  let json = await body.json();


  json = JSON.parseWithDate(JSON.stringify(json));

  return json

}



