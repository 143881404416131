import { useState } from "react";
import { Button, Paper } from "@mui/material";
import { useForm } from "react-hook-form";
import FormContainer from "./rh_form_elements/rh-form-container.js";
//import TextFieldElement from "./rh_form_elements/rh-text-field-element";
//import PhoneFieldElement from "./rh_form_elements/rh-phone-field-element";
//import EmailFieldElement from "./rh_form_elements/rh-email-field-element";
//import TransferListFormField from "./rh_form_elements/rh-transfer-list-element";
import SelectAllTransferList from "./rh_form_elements/transfer-list-element.js";
import { Stack } from "@mui/material";
import {usePrimaryQuery} from "../../api/swrdbcall.js";
import Skeleton from '@mui/material/Skeleton';
import mutateCaseAssignment from "../../api/mutatecaseassignment.js";
import { MUTATE_DELETE, MUTATE_UPDATE } from "../../api/mutate-actions.js";
import { mutate } from "swr";




export default function CaseAssignmentForm(props) {
  
  const orgID=props.orgID //'3e20158a-660e-41df-bade-99319b7c6658'
  const caseID=props.caseID//"f06eec79-9f5a-4de0-b04e-6b6388937394"
  const usersForOrgQuery=`match (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(u:User) return u`
  const usersForOrgCaseQuery=`match (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(c:Case{caseID: $caseID}) with o,c MATCH (c)-[r]-(u:User) return u`
  const parms = { "orgID": orgID, "caseID": caseID}

  const usersForOrgResult = usePrimaryQuery(usersForOrgQuery,parms);
  const usersForOrgCaseResult = usePrimaryQuery(usersForOrgCaseQuery,parms);

  
  
  const defaultValues = props.data?props.data:{};

 
  const [values, setValues] = useState(defaultValues)
  
  
  const formContext = useForm({
    defaultValues: defaultValues, values
  });

  const { register, handleSubmit, reset, control, setValue, formState: { errors } } = formContext;

  const forceListReload = () => {
    // the following command forces the cache to invalidate and triggers a list query reload    
    mutate(
      key => String(key).startsWith(usersForOrgQuery), // which cache keys are updated
      undefined,   // update cache data to `undefined`
      { revalidate: true } // do not revalidate
    );
    mutate(
      key => String(key).startsWith(usersForOrgCaseQuery), // which cache keys are updated
      undefined,   // update cache data to `undefined`
      { revalidate: true } // do not revalidate
    );
  }

  const handleSave = (userIDs) => {
    try{
      mutateCaseAssignment(orgID,caseID,userIDs,MUTATE_DELETE).then((result)=>{
        console.log(`DELETE result=${JSON.stringify(result)}`)
        mutateCaseAssignment(orgID,caseID,userIDs,MUTATE_UPDATE).then((result)=>{
          console.log(`UPDATE result=${JSON.stringify(result)}`)
          forceListReload()
        }
        )
      }

      )
    } catch (error) {
      console.error('Error updating asssigments:', error);
    }
  }


  const onSubmit = (data) => {
    console.log(data);
    handleSave(data.userList)
  };
  

  if(usersForOrgResult.isLoading || usersForOrgCaseResult.isLoading) {
    return(
      <Paper
        style={{
          display: "grid",
          gridRowGap: "20px",
          padding: "20px",
          //margin: "10px 300px",
          maxWidth: "650px",
        }}
      >
        <Skeleton variant="rectangular" sx={{minHeight: 300}} />
        
        
      </Paper>
    )
  }

  const choices = usersForOrgResult.data.records.map((record)=>record.u)
  const value = usersForOrgCaseResult.data.records.map((record)=>record.u.userID)

  const getItemKey=(item)=>item.userID
  const getItemDisplay=(item)=>item.firstName+ " " + item.lastName


  return (
    


      <Paper
        style={{
          display: "grid",
          gridRowGap: "20px",
          padding: "20px",
          //margin: "10px 300px",
          maxWidth: "650px",
        }}
      >
        
        <FormContainer formContext={formContext} handleSubmit={handleSubmit} >
          <Stack spacing={2}>
            <SelectAllTransferList name="userList" label="Users for Case" choicesTitle="Available Users" chosenTitle="Assigned Users" choices={choices} value={value} getItemKey={getItemKey} getItemDisplay={getItemDisplay} setValue={setValue}/>
          </Stack>

          
          <Button
            style={{
              //padding: "20px",
              margin: "10px 0px",
              maxWidth: "75px",
            }}
            onClick={handleSubmit(onSubmit)} variant={"contained"}>
            Save
          </Button>
          


        </FormContainer>
        
      </Paper>
      

    

  );
};