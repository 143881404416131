import { Box, Skeleton } from "@mui/material"


export default function PageSkeleton  ()  {

    return (
        <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        bgcolor: '#f0f0f0',
      }}
    >
      <Skeleton
        variant="rectangular"
        width="80%"
        sx={{
          minHeight: '50vh',
        }}
      />
    </Box>
    )

}