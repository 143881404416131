import React from "react";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import {useCaseQuery} from '../../api/swrdbcall';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LineChart } from '@mui/x-charts/LineChart';
import { ChartsReferenceLine } from "@mui/x-charts";

export default function SimpleLineChart(props) {


  const min_margin=150

  const x_tick_angle = -45;
  const bottom_margin_buffer = 10;
  const x_label_margin_offset = 50;
  const paperheight = 750
  const paperwidth = '100%'

  function calc_margin() {
    let max_len = Math.max(...categories.map((cat) => String(cat).length));
    const char_size = 7;
    let new_margin = Math.round(Math.abs(max_len * char_size * Math.sin(x_tick_angle * Math.PI / 180))) + bottom_margin_buffer;
    return Math.max(new_margin,min_margin);
}

  function isValidDate(date) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
  }
  function isNumber(value) {
    return value && typeof value === 'number';
  }

  function inferAxisType(dataArray) {
    if (dataArray.length === 0) return "band";
    let test_val=dataArray[0]
    return isValidDate(test_val)?"time":isNumber(test_val)?"linear":"band";
  }

  const parms={
    orgID: props.orgID,
    caseID: props.caseID
  }
  const dataResult = useCaseQuery(props.query,parms);
  

  


  if (dataResult.isLoading) {
    return <Skeleton variant="rectangular" width={paperwidth} height={paperheight} />
  }
  
  let vals=dataResult.data['records'].map((row) => { return row.Result });
  let categories=dataResult.data['records'].map((row) => { return row.Category });
  let bottom_margin=calc_margin();
  
  return (
    <Box sx={{ width: paperwidth, height: paperheight }}>
      <Paper sx={{ width: paperwidth, height: paperheight }} elevation={3} >
        {/* @ts-ignore */}
        <LineChart
          margin={{ left: 150, right: 70, top: 70, bottom: bottom_margin }}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translate(-40px, 0)',
            },
            [`.${axisClasses.right} .${axisClasses.label}`]: {
              transform: 'translate(25px, 0)',
            },
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              transform: `translate(0, ${bottom_margin - x_label_margin_offset}px)`,
            },
            [`.${axisClasses.top} .${axisClasses.label}`]: {
              transform: 'translate(0, -30px)',
            },
          }}
          series={[
            {
              type: 'line',
              data: vals,
              yAxisKey: 'y-axis-id'
            },

          ]}
          xAxis={[
            {
              data: categories,
              scaleType: inferAxisType(categories),
              id: 'x-axis-id',
            },

          ]}
          yAxis={[
            {
              id: 'y-axis-id',
              scaleType: 'linear',
            },

          ]}

          bottomAxis={{ tickLabelStyle: { angle: x_tick_angle, textAnchor: 'end' }, label: props.x_label, labelStyle: { fontSize: 18, fontWeight: "bold" }, position: "bottom", axisId: "x-axis-id" }}
          leftAxis={{ label: props.y_label, labelStyle: { fontSize: 18, fontWeight: "bold" }, position: "left", axisId: "y-axis-id" }}
        >
          <ChartsReferenceLine y={0} />
        </LineChart>


      </Paper>
    </Box>
  );
}