import React from 'react';
import PortalFrame from '../ui/portal-frame.js';
import { Typography } from '@mui/material';
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';
import SampleDRFAReport from '../ui/report-drfa-sample.js';
import { useDRFAReportForCurrentCase } from '../../api/swrdrfareportforcurrentcase.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import ReportContext from '../ui/user-report-context.js';

export default function SampleDRFAReportPage(props) {
    //Step 2 added new way
    const userProfileContext = useContext(UserProfileContext);

    //const userProfile = useCurrentUserProfile(); // comment out old way

    //const userProfile = userProfileContext.userProfile  // add new way
    //const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg.orgID
    //const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase.caseID
    
    const title = "DRFA Editable Report"
    const reportID = useParams().reportID
    const reportContext = useContext(ReportContext);

    
    const report=reportContext.getReportByID(reportID)

    if (reportContext.isLoading ) {


        return (
            <PortalFrame title={title}>
                <PageSkeleton />
            </PortalFrame>
        )
    }


    return (
        <PortalFrame title={title}>

        <SampleDRFAReport reportData={report.reportData}/>
        
        </PortalFrame>
    );
}