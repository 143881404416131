import { mutate } from "swr";
import { mutatePrimaryNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";


export default function mutateTouchCaseAssignment(orgID, caseID, userID) {
    
    
    const update_mutation = 'match (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(c:Case{caseID: $caseID}) with o,c match (o)<-[ro:BELONGS_TO]-(u:User {userID: $userID}) WITH datetime() AS dt,o,c,u,ro MATCH (c)<-[rc:BELONGS_TO]-(u) SET rc.lastDate=dt, ro.lastDate=dt return o,c,rc,u'
    
    const parms = { "orgID": orgID, "caseID": caseID, "userID": userID }


    

    
    let mutation = update_mutation
    
    //console.log(`in mutateCaseAssignment: mutation=${mutation}`)
    //console.log(`in mutateCaseAssignment: parms=${JSON.stringify(parms)}`)

    const result = mutate(['CASEASSIGNTOUCH', caseID], mutatePrimaryNeo4j(mutation, parms))

    //const new_case_query = "MATCH (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(u:User {userID: $userID}) WITH datetime() as dt,o,u MATCH (o)<-[ro:BELONGS_TO]-(c:Case)<-[ru:BELONGS_TO]-(u) return c as case order by ru.lastDate desc limit 1"

    mutate('CURRENT_USER_PROFILE')
    
    //console.log(`in mutateCaseAssignment: result=${JSON.stringify(result)}`)
    return result
}