import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";


export default async function mutateBankStatement(currentUserProfile, data, action=MUTATE_UPDATE) {
    

    if (action != MUTATE_UPDATE){
        throw Error(`unsupported action: ${action}`)
    }

    
    if(!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser){
        throw Error(`pass a valid current user profile to this call`)
    }

    const orgID=currentUserProfile.currentOrg.orgID
    const caseID=currentUserProfile.currentCase.caseID
    const email=currentUserProfile.currentUser.email


    const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$pk}) MERGE (bs)-[r:AUDIT]->(bsh:BankStatementHistory {_auditDate:dt}) set bsh=properties(bs), bsh._auditDate=dt, bsh._auditUser=$currentUser, r._auditDate=dt, r._auditUser=$currentUser, bs._updateDate=dt, bs._updateUser=$currentUser,bs._verified=$_verified, bs.begin_date=date(datetime($begin_date)), bs.end_date=date(datetime($end_date)), bs.begin_balance=$begin_balance, bs.end_balance=$end_balance return bs,r,bsh'
    const parms = { 
        "orgID": orgID,
        "caseID": caseID, 
        "currentUser": email,
        "bank_account_pk": data.bank_account_pk,
        "pk": data.pk, 
        "_verified": data._verified,
        "begin_date": data.begin_date,
        "end_date": data.end_date,
        "begin_balance": data.begin_balance,
        "end_balance": data.end_balance
    }

    

    
    let mutation = update_mutation
    
    const result = mutate(['BANKSTATEMENT', data.pk], mutateCaseNeo4j(mutation, parms))
    
    //console.log(`in mutateCase: result=${JSON.stringify(result)}`)
    return result
}