import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";
import { forceRefreshTransactionsForCurrentCase } from "./swrtransactionsforcurrentcase";


export default async function mutateTransfer(currentUserProfile, txn1, txn2, transferDisposition, action=MUTATE_UPDATE) {
    
    //console.log(`entering mutateCategoryRule, action=${action}`)
    if (action != MUTATE_UPDATE ){
        throw Error(`unsupported action: ${action}`)
    }

    
    if(!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser){
        throw Error(`pass a valid current user profile to this call`)
    }

    const orgID=currentUserProfile.currentOrg.orgID
    const caseID=currentUserProfile.currentCase.caseID
    const email=currentUserProfile.currentUser.email


    const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$txn1_bank_account_pk})-[rba:HAS]->(bs:BankStatement {pk:$txn1_period_pk})-[rbst:CONTAINS]->(t1:Transaction {pk:$txn1_pk})-[r:TRANSFER]-(t2:Transaction {pk:$txn2_pk})<-[rbst2:CONTAINS]-(bs2:BankStatement {pk:$txn2_period_pk})-[rba2:HAS]-(ba2:BankAccount {pk:$txn2_bank_account_pk}) set r.disposition=$transferDisposition, r._updateDate=dt, r._updateUser=$currentUser return r'
    const parms = { 
        "orgID": orgID,
        "caseID": caseID, 
        "currentUser": email,
        "txn1_pk":txn1.pk,
        "txn1_bank_account_pk":txn1.bank_account_pk,
        "txn1_period_pk":txn1.period_pk,
        "txn2_pk":txn2.pk,
        "txn2_bank_account_pk":txn2.bank_account_pk,
        "txn2_period_pk":txn2.period_pk,
        "transferDisposition":transferDisposition    
    }

    //console.log(`in mutateTransfer, parms=${JSON.stringify(parms)}`)

    // if (action === MUTATE_UPDATE && search_before_create){
    //     //console.log('IN MUTATE SEARCH BEFORE CREATE AREA')
        
    //             const sb4create = 'MATCH (case:Case {caseName:$caseName})-[r:BELONGS_TO]->(org:Organization {orgID:$orgID}) WHERE case.caseID <> $caseID RETURN case'
    //     const sb4result = mutate(['CASE', data.caseID], mutateCaseNeo4j(update_mutation, parms,sb4create))    
    //     //console.log(JSON.stringify(sb4result))
    //     return sb4result
    // }

    
    let mutation = update_mutation
    //const result=null
    const result = await mutate(['TRANSFER', txn1, txn2], mutateCaseNeo4j(mutation, parms))
    forceRefreshTransactionsForCurrentCase(orgID,caseID)
    
    //console.log(`in mutateCase: result=${JSON.stringify(result)}`)
    return result
}

