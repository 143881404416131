import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";


export default async function mutateConfirmAllCategories(currentUserProfile) {
    
    
    if(!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser){
        throw Error(`pass a valid current user profile to this call`)
    }

    const orgID=currentUserProfile.currentOrg.orgID
    const caseID=currentUserProfile.currentCase.caseID
    const email=currentUserProfile.currentUser.email


    
    const update_mutation = 'MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount)-[:HAS]->(bs:BankStatement)-[rbst:CONTAINS]->(txn:Transaction)-[rtsc:PROPOSED]->(sc:Subcategory) WITH datetime() as dt, txn, sc, rtsc 	MERGE (txn)-[rtc:CONFIRMED]->(sc)   ON CREATE SET  rtc._updateDate=dt, rtc._updateUser=$currentUser, rtc._createDate=dt, rtc._createUser=$currentUser ON MATCH SET rtc._updateDate=dt, rtc._updateUser=$currentUser DELETE rtsc'

    const parms = { 
        "orgID": orgID,
        "caseID": caseID, 
        "currentUser": email,
        
    }

    
    let mutation = update_mutation
    
    const result = mutate(['CONFIRM_CATEGORIES', parms], mutateCaseNeo4j(mutation, parms))
    
    //console.log(`in mutateCase: result=${JSON.stringify(result)}`)
    return result
}

