import * as React from 'react';
import { useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
// import { useGridApiContext } from '@mui/x-data-grid';
// import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
// import { Autocomplete } from '@mui/material';
// import { TextField } from '@mui/material';

import SubcategoryEditInputCell from './grid-input-cell-subcategory.js';
import FreeSoloCreateOptionAutoCompleteEditInputCell from './grid-input-cell-autocomplete-free-solo-create.js';

import { dateGetter, currencyFormatter, bankAccountFormatter } from './type-utilities';
import { renderSwitch, renderSwitchEditInputCell } from './grid-input-switch-component.js';
import UserProfileContext from './userprofilecontext';
import clsx from 'clsx';
import { deepPurple, green, grey, orange, red, yellow } from '@mui/material/colors';
import { blue } from '@mui/material/colors';
import RestoreIcon from '@mui/icons-material/Restore';
import TransferReviewDialog from './dialog-transfer-review.js';
import RestoreValueActionItem from './grid-restore-value-action.js';
import SendMoneyIcon from './icon-send-money.js';
import ReceiveMoneyIcon from './icon-receive-money.js';
import { findChangedFields } from './update-utils.js';
//import useTransactionReview from './transaction-review-context.js';
import TransactionReviewContext from './transaction-review-context.js';
//import DialogProceedOrCancel from "../ui/dialog-confirm-proceed-cancel.js";



export default function TransactionCategoryCrudGrid(props) {


  const parentHandleRowClick = props.handleRowClick ? props.handleRowClick : (txn) => { }
  const parentHandleCategoryRuleCreate = props.handleCategoryRuleCreate ? props.handleCategoryRuleCreate : (txn) => { }
  const parentHandleFlagRuleCreate = props.handleFlagRuleCreate ? props.handleFlagRuleCreate : (txn) => { }
  const mutateTransaction = props.mutateTransaction ? props.mutateTransaction : (txn) => { }
  const flagOptions = props.flags ? props.flags : []



  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [subcategoryOptions, setSubcategoryOptions] = React.useState([])
  //const [flagOptions, setFlagOptions] = React.useState([])
  //const [openNewRuleDialog, setOpenNewRuleDialog] = React.useState(false)
  //const [rowForNewRule,setRowForNewRule]=React.useState()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverField, setPopoverField] = React.useState(null);
  const [popoverValue, setPopoverValue] = React.useState(null);
  const [openTransferDialog, setOpenTransferDialog] = React.useState(false)
  //const [transferTransaction, setTransferTransaction] = React.useState(null)

  // const {
  //   transaction: transferTransaction, 
  //   setTransaction:setTransferTransaction, 
  //   hasPreviousTransaction: hasPreviousTransferTransaction, 
  //   goPreviousTransaction: goPreviousTransferTransaction, 
  //   goNextTransaction: goNextTransferTransaction
  // } = useTransactionReview()

  const transactionReviewContext=useContext(TransactionReviewContext)
  
  //const [value, setValue] = React.useState('');

  const userProfileContext = useContext(UserProfileContext);

  React.useEffect(() => {

    //console.log(`computing the transaction map....`)
    //console.log(`setting grid rows....`)

    //const transactions = props.transactions.map(transaction => ({ ...transaction.txn, id: transaction.txn.pk, cat_type:transaction.cat_type, subcategory:transaction.cat.name, flag_type:transaction.flag_type, flag:transaction.flag?.name }));
    setRows(props.transactions)
    //console.log(`computed the transaction map....`)


  }, [props.transactions]

  )

  //////////////////////////////////////////////////////

  React.useEffect(() => {
    const subcategories = props.subcategories ? props.subcategories : []

    setSubcategoryOptions(subcategories)
  }, [props.subcategories]

  )

  // React.useEffect(() => {
  //   /// refresh available flags
  //   const flagOptions = Array.from(new Set(rows.filter(item => item.flag).map(item => item.flag))).map((item) => ({ flag: item }));
  //   //console.log(`computing flagOptions, rows=${JSON.stringify(rows.filter(item=>item.flag))}`)
  //   //console.log(`computed flagOptions=${JSON.stringify(flagOptions)}`)
  //   setFlagOptions(flagOptions)
  // }, [rows]

  // )


  // const handleNewRuleDialogOpen = () => {
  //   setOpenNewRuleDialog(true)
  // }

  // const handleNewRuleDialogCancel = () => {
  //   setOpenNewRuleDialog(false)
  // }

  // const handleNewRuleDialogProceed = () => {
  //   setOpenNewRuleDialog(false)

  //   parentHandleRuleCreate(rowForNewRule)
  // }

  const fieldsSubscribedToPopover = ['subcategory', 'flag', 'has_transfer_candidates']

  const shouldDisplayPopover = (row, field) => {

    const value = row ? row[field] : undefined
    if (value == null) {
      return false
    }
    if (field === 'has_transfer_candidates') {
      return value
    }

    return true

  }

  const formatPopoverValue = (row, field) => {

    if (field === 'has_transfer_candidates') {

      const transferCandidates = row.transfer_candidates
      //console.log(`transfer candidates=${JSON.stringify(transferCandidates)}`)

      const confirmedCount = transferCandidates.filter(candidate => candidate.transfer_props.disposition === 'CONFIRMED').length
      const rejectedCount = transferCandidates.filter(candidate => candidate.transfer_props.disposition === 'REJECTED').length
      const proposedCount = transferCandidates.filter(candidate => candidate.transfer_props.disposition === 'PROPOSED' ).length
      const possibleCount = transferCandidates.filter(candidate => candidate.transfer_props.disposition === 'POSSIBLE' ).length
      


      return (
        <React.Fragment>
          {confirmedCount > 1 &&
            <Typography>{confirmedCount} Confirmed Transfers</Typography>
          }
          {confirmedCount === 1 &&
            <Typography>{confirmedCount} Confirmed Transfer</Typography>
          }
          {rejectedCount > 1 &&
            <Typography>{rejectedCount} Rejected Transfers</Typography>
          }
          {rejectedCount === 1 &&
            <Typography>{rejectedCount} Rejected Transfer</Typography>
          }
          {proposedCount > 1 &&
            <Typography>{proposedCount} Proposed Transfers</Typography>
          }
          {proposedCount === 1 &&
            <Typography>{proposedCount} Proposed Transfer</Typography>
          }
          {possibleCount > 1 &&
            <Typography>{possibleCount} Possible Transfers</Typography>
          }
          {possibleCount === 1 &&
            <Typography>{possibleCount} Possible Transfer</Typography>
          }
          {/*<Typography sx={{ p: 1 }}>{JSON.stringify(row.transfer_candidates,null,2)}</Typography>*/}
        </React.Fragment>
      )
    }
    if (field === 'subcategory') {

      if (row['cat_type'] == 'CONFIRMED') {
        return (
          <React.Fragment>

            <Typography sx={{ p: 1 }}>Confirmed Subcategory</Typography>


          </React.Fragment>
        )
      }
      return (
        <React.Fragment>
          {row.cat_basis?.matched_basis &&
            <Typography sx={{ p: 1 }}>{`Match Basis: ${row.cat_basis?.matched_basis}`}</Typography>
          }
          {row.cat_basis?.similarity != null && typeof row.cat_basis?.similarity === 'number' &&
            <Typography sx={{ p: 1 }}>{`Similarity: ${row.cat_basis.similarity.toPrecision(5)}`}</Typography>
          }
          {row.cat_basis?.similarity != null && typeof row.cat_basis?.similarity === 'string' &&
            <Typography sx={{ p: 1 }}>{`Similarity: ${row.cat_basis.similarity}`}</Typography>
          }
          {row.cat_basis?.prediction_model &&
            <Typography sx={{ p: 1 }}>{`Prediction Model: ${row.cat_basis?.prediction_model}`}</Typography>
          }

        </React.Fragment>
      )
    }
    if (field === 'flag') {
      if (row['flag_type'] == 'CONFIRMED') {
        return (
          <React.Fragment>

            <Typography sx={{ p: 1 }}>Confirmed Flag</Typography>


          </React.Fragment>
        )
      }
      return (
        <React.Fragment>
          {row.flag_basis?.matched_basis &&
            <Typography sx={{ p: 1 }}>{`Match Basis: ${row.flag_basis?.matched_basis}`}</Typography>
          }
          {row.flag_basis?.similarity != null &&
            <Typography sx={{ p: 1 }}>{`Similarity: ${row.flag_basis.similarity.toPrecision(5)}`}</Typography>
          }
          {row.flag_basis?.prediction_model &&
            <Typography sx={{ p: 1 }}>{`Prediction Model: ${row.flag_basis?.prediction_model}`}</Typography>
          }

        </React.Fragment>
      )
    }
  }
  const handlePopoverOpen = (event) => {
    const field = event.currentTarget.dataset.field;    // column 
    const id = Number(event.currentTarget.parentElement.dataset.id);  // row ID
    const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit

    if (fieldsSubscribedToPopover.includes(field) && !isInEditMode) {
      const row = rows.find((row) => `${row.id}` === `${id}`);  // row model 

      setPopoverField(field);
      if (shouldDisplayPopover(row, field)) {
        setPopoverValue(formatPopoverValue(row, field));
        setAnchorEl(event.currentTarget);
      }
    }

  };

  const handlePopoverClose = () => {

    setAnchorEl(null);
  };


  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {

    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    handlePopoverClose()
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    //console.log(`id=${id}`)
    //console.log(`typeof id = ${typeof id}`)
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };


  

  const preProcessUpdate = (newRow, oldRow, changedFields) => {
    /// check if subcategory changed, then confirm the subcategory assignment
    if (changedFields.subcategory) {
      newRow.cat_type = 'CONFIRMED'
    }
    if (changedFields.flag) {
      //if (newRow.flag){newRow.flag_type = 'CONFIRMED'}
      //else {newRow.flag_type = null}
      newRow.flag_type = 'CONFIRMED'

    }
    return newRow
  }
  const processRowUpdate = async (newRow, oldRow) => {

    const changedFields = findChangedFields(newRow, oldRow)
    //console.log(`changed fields: ${JSON.stringify(changedFields)}`)



    const updatedRow = preProcessUpdate({ ...newRow, isNew: false }, oldRow, changedFields);
    //console.log(`updatedRow: ${JSON.stringify(updatedRow)}`)

    const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row))

    setRows(newRows);



    if (Object.keys(changedFields).length > 0) {
      //console.log(`calling mutate...`)
      await mutateTransaction(updatedRow, newRows, changedFields)
      if (changedFields.subcategory
        //|| (changedFields.cat_type && updatedRow.cat_type=='CONFIRMED')
      ) {
        parentHandleCategoryRuleCreate(updatedRow)

      }
      if (changedFields.flag
        //|| (changedFields.cat_type && updatedRow.cat_type=='CONFIRMED')
      ) {
        parentHandleFlagRuleCreate(updatedRow)

      }

    }


    return updatedRow;
  };

  const processTransferUpdate = (txn1,txn2,newDisposition) => {
    let t1Row=rows.find(row => row.pk === txn1.pk)
    let t1Candidates = t1Row.transfer_candidates
    let newT1Candidates = t1Candidates.map(candidate => candidate.txn_pk===txn2.pk? {...candidate, transfer_props: {...candidate.transfer_props, disposition: newDisposition}}: candidate)
    t1Row.transfer_candidates=newT1Candidates
    let newRows=rows.map(row => row.pk === txn1.pk? t1Row : row)
    

    let t2Row=rows.find(row => row.pk === txn2.pk )
    let t2Candidates = t2Row.transfer_candidates
    let newT2Candidates = t2Candidates.map(candidate => candidate.txn_pk===txn1.pk? {...candidate, transfer_props: {...candidate.transfer_props, disposition: newDisposition}}: candidate)
    t2Row.transfer_candidates=newT2Candidates
    newRows=newRows.map(row => row.pk === txn2.pk? t2Row : row)
    //console.log(`updated t2Row to ${JSON.stringify(t2Row)}`)
    setRows(newRows)
  }

  const handleRowModesModelChange = (newRowModesModel) => {

    setRowModesModel(newRowModesModel);
    handlePopoverClose()
  };

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    //console.log(`row "${JSON.stringify(params.row)}" clicked`);
    parentHandleRowClick(params.row)
  };

  const handleToggleCategorization = (id) => {
    //console.log(`in handleToggle, ID=${id}`)
    const oldRow = rows.find((row) => (row.id === id))
    const newRow = { ...oldRow, cat_type: oldRow.cat_type === 'PROPOSED' ? 'CONFIRMED' : 'PROPOSED' }
    //console.log(`oldRow=${JSON.stringify(oldRow)}`)
    //console.log(`newRow=${JSON.stringify(newRow)}`)
    processRowUpdate(newRow, oldRow)

  };

  const handleToggleFlagging = (id) => {
    //console.log(`in handleToggle, ID=${id}`)
    const oldRow = rows.find((row) => (row.id === id))
    const newRow = { ...oldRow, flag_type: oldRow.flag_type === 'PROPOSED' ? 'CONFIRMED' : 'PROPOSED' }
    //console.log(`oldRow=${JSON.stringify(oldRow)}`)
    //console.log(`newRow=${JSON.stringify(newRow)}`)
    processRowUpdate(newRow, oldRow)

  };

  const handleToggleTransactionVerified = (id) => {
    //console.log(`in handleToggle, ID=${id}`)
    const oldRow = rows.find((row) => (row.id === id))
    const newRow = { ...oldRow, _verified: !oldRow._verified }
    //console.log(`oldRow=${JSON.stringify(oldRow)}`)
    //console.log(`newRow=${JSON.stringify(newRow)}`)
    processRowUpdate(newRow, oldRow)

  };

  const handleTransferReview = (id) => {
    //console.log(`transfer review clicked, id=${id}`)
    const row = rows.find((row) => (row.id === id))


    //setTransferTransaction(row)
    transactionReviewContext.setTransaction(row)
    setOpenTransferDialog(true)
  }

  const onTransferDialogCancel = () => {
    setOpenTransferDialog(false)
  }


  const renderSubcategoryEditInputCell = (params) => {
    return <SubcategoryEditInputCell {...params} subcategoryOptions={subcategoryOptions} />;
  };


  const renderFreeSoloCreateOptionAutoCompleteEditInputCell = (params) => {
    return <FreeSoloCreateOptionAutoCompleteEditInputCell {...params} options={flagOptions} label={""} valueField={"flag"} />
  }

  function restoreValue(id, currentField, originalField) {
    const oldRow = rows.find((row) => (row.id === id))
    const newRow = { ...oldRow, [currentField]: oldRow[originalField] }
    processRowUpdate(newRow, oldRow)
  }

  function restoreAmount(id) {
    const oldRow = rows.find((row) => (row.id === id))
    const newRow = { ...oldRow, amount: oldRow.original_amount * oldRow._applied_expense_factor }
    processRowUpdate(newRow, oldRow)
  }

  function getTransferIcon(row) {
    const Icon = (row.amount < 0) ? SendMoneyIcon : ReceiveMoneyIcon


    return (<Icon htmlColor={getTransferIconColor(row)} />)
  }

  function getTransferIconColor(row) {
    const transferCandidates = row.transfer_candidates

    return transferCandidates.some(candidate => candidate.transfer_props.disposition === 'CONFIRMED') ? green[700] :
      transferCandidates.every(candidate => candidate.transfer_props.disposition === 'REJECTED') ? red[500] :
      transferCandidates.some(candidate => candidate.transfer_props.type === 'PROPOSED') ? orange[700] :
        "default"


  }



  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ];
      },
    },
    {
      field: '_verified',
      headerName: 'Verified?',
      //display: 'flex',
      type: 'boolean',
      //renderCell: renderSwitch,
      //renderEditCell: renderSwitchEditInputCell,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      //editable: true,
      editable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleToggleTransactionVerified(params.row.id)}
          color="primary"
        >
          {params.value ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </IconButton>
      ),
    },
    {
      field: 'bank_name',
      headerName: 'Bank',
      width: 150,
      align: 'left',
      editable: false,
    },
    {
      field: 'account_number',
      headerName: 'Account',
      width: 75,
      valueGetter: bankAccountFormatter,
      align: 'left',
      editable: false,
    },
    {
      field: 'txn_date',
      headerName: 'Transaction Date',
      type: 'date',
      valueGetter: dateGetter,
      width: 180,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        if (params.value - params.row.original_txn_date === 0) {
          return '';
        }
        return clsx('transaction', 'changed');


      }
    },
    {
      field: 'txn_date_actions',
      type: 'actions',
      width: 10,
      getActions: ({ id, row }) => {
        if (row.original_txn_date - row.txn_date === 0) {
          return []
        }
        return [
          <RestoreValueActionItem
            showInMenu
            icon={<RestoreIcon />}
            label="Restore Original Date"
            sx={{
              color: 'primary.main',
            }}
            restoreValue={() => { restoreValue(id, "txn_date", "original_txn_date") }}
            originalDisplayValue={row.original_txn_date.toLocaleString(undefined, { dateStyle: "short" })}
            currentDisplayValue={row.txn_date.toLocaleString(undefined, { dateStyle: "short" })}
            closeMenuOnClick={false}
          />,
        ]
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      align: 'left',
      editable: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        if (params.value === params.row.original_description) {
          return '';
        }
        return clsx('transaction', 'changed');


      },

    },
    {
      field: 'description_actions',
      type: 'actions',
      width: 30,
      getActions: ({ id, row }) => {
        if (row.original_description === row.description) {
          return []
        }
        return [
          <RestoreValueActionItem
            showInMenu
            icon={<RestoreIcon />}
            label="Restore Original Description"
            sx={{
              color: 'primary.main',
            }}
            restoreValue={() => { restoreValue(id, "description", "original_description") }}
            originalDisplayValue={row.original_description}
            currentDisplayValue={row.description}
            closeMenuOnClick={false}
          />,
        ]
      }
      ,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      valueFormatter: currencyFormatter,
      width: 150,
      align: 'right',
      headerAlign: 'right',
      editable: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        if (params.value === params.row.original_amount * params.row._applied_expense_factor) {
          return '';
        }
        return clsx('transaction', 'changed');


      }
    },
    {
      field: 'amount_actions',
      type: 'actions',
      width: 30,
      getActions: ({ id, row }) => {
        if (row.amount === row.original_amount * row._applied_expense_factor) {
          return []
        }
        return [
          <RestoreValueActionItem
            showInMenu
            icon={<RestoreIcon />}
            label="Restore Original Amount"
            sx={{
              color: 'primary.main',
            }}
            restoreValue={() => { restoreAmount(id) }}
            originalDisplayValue={currencyFormatter(row.original_amount * row._applied_expense_factor)}
            currentDisplayValue={currencyFormatter(row.amount)}
            closeMenuOnClick={false}
          />,
        ]
      },
    },
    {
      field: 'has_transfer_candidates',
      headerName: 'Transfer Match?',
      type: 'boolean',
      width: 100,
      align: 'left',
      editable: false,
      renderCell: (params) => (
        <React.Fragment>
          {params.value &&
            <IconButton
              onClick={() => handleTransferReview(params.row.id)}
            >
              {getTransferIcon(params.row)}

            </IconButton>
          }
        </React.Fragment>

      ),

    },
    // {
    //   field: 'transfer_actions',
    //   type: 'actions',
    //   headerName: 'Transfers',
    //   width: 30,
    //   getActions: ({ id, row }) => {
    //     if (!row.has_transfer_candidates) {
    //       return []
    //     }
    //     return [
    //       <GridActionsCellItem
    //         icon={<CompareArrowsIcon />}
    //         label="Review Transfer Matches"
    //         className="textPrimary"
    //         onClick={handleTransferReview(id)}
    //         color="inherit"
    //       />,
    //     ]
    //   },
    // },
    {
      field: 'cat_type',
      headerName: 'Confirmed?',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleToggleCategorization(params.row.id)}
          color="primary"
        >
          {params.value === 'CONFIRMED' ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </IconButton>
      ),
    },
    {
      field: 'subcategory',
      headerName: 'Subcategory',
      width: 250,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderEditCell: renderSubcategoryEditInputCell,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('category', {
          confirmed: params.row.cat_type === 'CONFIRMED',
          proposed: params.row.cat_type === 'PROPOSED',
        });
      }
    },
    {
      field: 'flag_type',
      headerName: 'Confirmed?',
      width: 100,
      align: 'center',
      headerAlign: 'center',
      editable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleToggleFlagging(params.row.id)}
          color="primary"
        >
          {params.value === 'CONFIRMED' ? <CheckBox /> : <CheckBoxOutlineBlank />}
        </IconButton>
      ),
    },
    {
      field: 'flag',
      headerName: 'Flag',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      renderEditCell: renderFreeSoloCreateOptionAutoCompleteEditInputCell,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }

        return clsx('flag', {
          confirmed: params.row.flag_type === 'CONFIRMED',
          proposed: params.row.flag_type === 'PROPOSED',
        });
      }
    },


  ];

  const columnGroupingModel = [
    {
      groupId: 'Subcategory',
      description: '',
      headerClassName: 'mytheme--subcategory-group',
      children: [{ field: "cat_type" }, { field: "subcategory" }],
    },
    {
      groupId: 'Flag',
      description: '',
      headerClassName: 'mytheme--flag-group',
      children: [{ field: "flag_type" }, { field: "flag" }],
    },
    {
      groupId: 'Transaction',
      description: '',
      headerClassName: 'mytheme--transaction-group',
      children: [{ field: "_verified" }, { field: "bank_name" }, { field: "account_number" }, { field: "txn_date" }, { field: "txn_date_actions" }, { field: "description" }, { field: 'description_actions' }, { field: "amount" }, { field: 'amount_actions' }, { field: 'has_transfer_candidates' }],
    },

  ];

  return (
    <Box
      sx={{
        height: '50vh',
        //width: '100%',
        // '& .actions': {
        //   color: 'text.secondary',
        // },
        // '& .textPrimary': {
        //   color: 'text.primary',
        // },
        // '& .super-app-theme--cell': {
        //   backgroundColor: 'rgba(224, 183, 60, 0.55)',
        //   color: '#1a3e72',
        //   fontWeight: '600',
        // },
        '& .transfer.confirmed': {
          //backgroundColor: grey[200],
          color: green[500],
          //fontWeight: '600',
        },
        '& .transfer.proposed': {
          //backgroundColor: grey[200],
          color: yellow[500],
          //fontWeight: '600',
        },
        '& .transfer.possible': {
          //backgroundColor: grey[200],
          color: grey[500],
          //fontWeight: '600',
        },
        '& .transaction.changed': {
          //backgroundColor: grey[200],
          color: "warning.dark",
          //fontWeight: '600',
        },
        '& .category.proposed': {
          backgroundColor: grey[200],
          //color: '#1a3e72',
          //fontWeight: '600',
        },
        '& .category.confirmed': {
          backgroundColor: deepPurple[100],
          //color: '#1a3e72',
          fontWeight: '600',
        },
        '& .flag.proposed': {
          backgroundColor: grey[200],
          //color: '#1a3e72',
          //fontWeight: '600',
        },
        '& .flag.confirmed': {
          backgroundColor: deepPurple[300],
          color: deepPurple[50],
          fontWeight: '600',
        },
        '& .mytheme--subcategory-group': {
          backgroundColor: deepPurple[400],
          color: deepPurple[50],
        },
        '& .mytheme--flag-group': {
          backgroundColor: deepPurple[600],
          color: deepPurple[50],
        },
        '& .mytheme--transaction-group': {
          backgroundColor: blue[600],
          color: blue[50],
        },

      }}
    >
      {/** 
      <DialogProceedOrCancel open={openNewRuleDialog} title={"Suggestion - Create a Subcategory Rule"} messsage={"You just assigned a subcategory, would you like to create a rule for this?"} onProceed={handleNewRuleDialogProceed} onCancel={handleNewRuleDialogCancel} />
      */}
      <TransferReviewDialog open={openTransferDialog} 
        transactions={rows} 
        processRowUpdate={processRowUpdate} 
        processTransferUpdate={processTransferUpdate} 
        onCancel={onTransferDialogCancel} />
      <DataGrid

        rows={rows}
        columns={columns}
        columnGroupingModel={columnGroupingModel}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowClick={handleRowClick}
        slots={{
          //toolbar: EditToolbar,
        }}
        slotProps={{
          //toolbar: { setRows, setRowModesModel },
          cell: {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
          },
        }}
      />
      <Popover
        sx={{
          pointerEvents: 'none',
        }}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        {popoverValue}
        {/*<Typography sx={{ p: 1 }}>{`${popoverValue?.length} characters.`}</Typography>*/}
      </Popover>
    </Box>
  );
}