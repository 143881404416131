
import { postOcrolusBook } from './ocrbookcall';
import useSWR from 'swr'
import { mutate } from "swr";

export  function usePostOcrolusBook(body) {
    const result = useSWR('/ocrbook', ()=>postOcrolusBook(body));
  return result
}

export function createOcrolusBook(bookName) {
  
  const body={
    bookName: bookName
      
    }

    const result=mutate(['bookName', body.bookName], postOcrolusBook(body))
    return result
}
