import { mutate } from "swr";
import { mutatePrimaryNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";

// the UI is expecting a result object called "record" for this query, do not change!
export const listOrgQuery="MATCH (record:Organization) RETURN record ORDER by record.orgName";

export default function mutateOrg(orgID, orgName, currentUserID, data, action=MUTATE_UPDATE, search_before_create=true) {
    //console.log(`entering mutateOrg, search_before_create=${search_before_create}`)

    //{"orgID":null,"orgName":"Next","orgPhone":"2067519964","orgEmail":"bbelding@umich.edu"}
    /*
    let validation_error = null;
    if(!data.orgName) {
        validation_error = {message:"orgName is required"}
        return { data:null, isLoading: false, error:validation_error}
    }
    if(!data.orgPhone) {
        validation_error = {message:"orgPhone is required"}
        return { data:null, isLoading: false, error:validation_error}
    }
    if(!data.orgEmail) {
        validation_error = {message:"orgEmail is required"}
        return { data:null, isLoading: false, error:validation_error}
    }
    */

    if (!data.orgID) {
        data.orgID = crypto.randomUUID()
    }


    //const mutation='MERGE (org:Organization {orgID:"'+data.orgID+'"}) set org={orgID:"'+data.orgID+'", orgName:"' + data.orgName + '", orgPhone:"' + data.orgPhone + '", orgEmail:"'+data.orgEmail+'"} RETURN org'
    const update_mutation = 'WITH datetime() AS dt MATCH (u:User {userID:$userID}) MERGE (org:Organization {orgID:$orgID}) set org={orgID:$orgID, orgName:$orgName, orgPhone:$orgPhone, orgEmail:$orgEmail} MERGE (org)<-[ru:BELONGS_TO]-(u) set ru.lastDate=dt RETURN org'
    const delete_mutation = 'MATCH (user:User)-[ru]->(org:Organization {orgID:$orgID}) DELETE ru DELETE org'
    const parms = { "orgID": data.orgID, "orgName": data.orgName, "orgPhone": data.orgPhone, "orgEmail": data.orgEmail, "userID": currentUserID }


    if (action === MUTATE_UPDATE && search_before_create){
        //console.log('IN MUTATE SEARCH BEFORE CREATE AREA')
        
        //const sb4create = 'MATCH (org:Organization {orgName:$orgName}) WHERE org.orgID <> $orgID and not exists {match (org2:Organization) where org2.orgID = $orgID } RETURN org'
        const sb4create = 'MATCH (org:Organization {orgName:$orgName}) WHERE org.orgID <> $orgID RETURN org'
        const sb4result = mutate(['ORG', data.orgID], mutatePrimaryNeo4j(update_mutation, parms,sb4create))    
        //console.log(JSON.stringify(sb4result))
        return sb4result
    }

    //console.log(`in mutateOrg: mutation=${mutation}, parms=${parms}`)
    //const result = useSWR(qry, postNeo4j);
    
    
    let mutation = action===MUTATE_DELETE?delete_mutation:update_mutation
    

    const result = mutate(['ORG', data.orgID], mutatePrimaryNeo4j(mutation, parms))
    
    //console.log(`in mutateOrg: result=${JSON.stringify(result)}`)
    return result
}