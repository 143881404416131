import React, { Fragment } from "react";
import PortalFrame from '../ui/portal-frame.js';

import { useContext, useState, useEffect } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from "../ui/pageskeleton.js";
import { DateRangeProvider } from "../ui/date-range-picker.js";
import { getTotalTransactionCount, useMutateTransactionForCurrentCase, forceRefreshTransactionsForCurrentCase, getTransactionsForCurrentCase, useTransactionsForCurrentCase } from '../../api/swrtransactionsforcurrentcase';
import { useSubcategoriesForCurrentCase } from '../../api/swrsubcategoriesforcurrentcase';
import CategoryReviewReport from "../ui/report-transaction-category-review.js";
import SnackbarContext from '../ui/snackbar-context.js';
import mutateCategoryRule from "../../api/mutatecategoryrule.js";
import mutateFlagRule from "../../api/mutateflagrule.js";
import { applyRulestoCase } from "../../api/applyrulescall.js";
import { MUTATE_DELETE, MUTATE_UPDATE } from "../../api/mutate-actions.js";
import {TransactionReviewProvider} from "../ui/transaction-review-context.js";



export default function ReviewStatementCategorization(props) {
    const snackbarContext = useContext(SnackbarContext);
    const userProfileContext = useContext(UserProfileContext);
    const orgID = userProfileContext.isLoading
      ? null
      : userProfileContext.userProfile.currentOrg?.orgID;
    const caseID = userProfileContext.isLoading
      ? null
      : userProfileContext.userProfile.currentCase?.caseID;
    const subcategoryQueryResult = useSubcategoriesForCurrentCase(orgID, caseID, !userProfileContext.isLoading&&orgID&&caseID, true)
    const subcategory_options = subcategoryQueryResult.data ? subcategoryQueryResult.data : []
    const transactionResult = useTransactionsForCurrentCase(orgID, caseID, !userProfileContext.isLoading&&orgID&&caseID)
    const data = transactionResult.data
    //const [data, setData] = useState();
    //const [isFetching, setIsFetching] = useState(false);
    //const [totalRecords, setTotalRecords] = useState(null);
    //const limit = 400;
    const { trigger: mutateTransactionTrigger } = useMutateTransactionForCurrentCase(orgID, caseID)

    // useEffect(() => {
    //   if (orgID && caseID) {
    //     fetchData();
    //   }
    // }, [orgID, caseID]);

    // const fetchData = async () => {
    //   setIsFetching(true);
    //   try {
    //     const total = await getTotalTransactionCount(orgID, caseID);
    //     setTotalRecords(total);

    //     let allData = [];
    //     let skip = 0;

    //     while (skip < total) {
    //       const transactions = await getTransactionsForCurrentCase(orgID, caseID, skip, limit);
    //       allData = [...allData, ...transactions];
    //       skip += limit;
    //     }

    //     setData(allData);
    //   } catch (error) {
    //     console.error('Error fetching transactions:', error);
    //     snackbarContext.openSnackbarError(`Error fetching transactions: ${error.message}`);
    //   } finally {
    //     setIsFetching(false);
    //   }
    // };


    const title = 'Review Transactions by Category';

    // if (userProfileContext.isLoading || isFetching || totalRecords === undefined || data === undefined) {
    //   return (
    //     <PortalFrame title={title}>
    //       {userProfileContext.userProfile?.currentCase && <Fragment><PageSkeleton />
    //       <p>Loading {data?data.length:9999}/{totalRecords} transactions...</p>
    //       </Fragment>}
    //       {!userProfileContext.userProfile?.currentCase && <div>You are not assigned to a case for this organization</div>}
    //     </PortalFrame>
    //   );
    // }

    

    if (!userProfileContext.userProfile || !userProfileContext.userProfile.currentCase) {
      return (
        <PortalFrame title={title}>
          You are not assigned to any cases
        </PortalFrame>
      );
    }

    const orgName = userProfileContext.userProfile.currentOrg.orgName;
    const caseName = userProfileContext.userProfile.currentCase.caseName;
    const date_field = 'txn_date';



    const mutateTransaction = (transaction, optimisticData, changedFields) => {

        function onSuccess(data, key, config) {
            snackbarContext.openSnackbarSuccess(`transaction ${transaction.description} saved successfully`)
        }

        function onError(error, key, config) {
            snackbarContext.openSnackbarError(`transaction ${transaction.description} didn't save: ${JSON.stringify(error)}`)
            console.log(`transaction ${transaction.description} with pk ${transaction.pk} didn't save: ${JSON.stringify(error)}`)

        }

        const options = {
            optimisticData: optimisticData,
            rollbackOnError: true,
            throwOnError: false,
            onSuccess: onSuccess,
            onError: onError,
        }

        mutateTransactionTrigger({ userProfile: userProfileContext.userProfile, transaction: transaction, changedFields: changedFields }, options)

    }

    function removeNewlines(str) {
        return str.replace(/\n/g, '');
    }

    const handleCategoryRuleCreate = async (transaction) => {
        //console.log(`in handleRuleCreate, transaction=${JSON.stringify(transaction)}`)
        //snackbarContext.openSnackbarInfo(`in handleRuleCreate, transaction=${JSON.stringify(transaction)}`)

        // const newRule = {
        //     subcategory: transaction.subcategory,
        //     phrase: removeNewlines(transaction.description).trim(),
        //     //flag: transaction.flag
        // }
        // await mutateCategoryRule(userProfileContext.userProfile, newRule)
        // console.log(`applying rules to case...`)
        // const orgID=userProfileContext.userProfile.currentOrg.orgID
        // const caseID=userProfileContext.userProfile.currentCase.caseID
        // await applyRulestoCase(orgID,caseID)
        // console.log(`applied rules to case...`)

        //forceRefreshTransactionsForCurrentCase(orgID, caseID)
    }

    const handleFlagRuleCreate = async (transaction) => {
        //console.log(`in handleFlagRuleCreate, transaction=${JSON.stringify(transaction)}`)
        //snackbarContext.openSnackbarInfo(`in handleRuleCreate, transaction=${JSON.stringify(transaction)}`)

        // const newRule = {
        //     flag: transaction.flag,
        //     phrase: removeNewlines(transaction.description).trim(),

        // }
        // await mutateFlagRule(userProfileContext.userProfile, newRule, transaction.flag ? MUTATE_UPDATE : MUTATE_DELETE)

        // console.log(`applying rules to case...`)
        // const orgID=userProfileContext.userProfile.currentOrg.orgID
        // const caseID=userProfileContext.userProfile.currentCase.caseID
        // await applyRulestoCase(orgID,caseID)
        // console.log(`applied rules to case...`)

        //forceRefreshTransactionsForCurrentCase(orgID, caseID)
    }

    if(userProfileContext.isLoading || transactionResult.isLoading || subcategoryQueryResult.isLoading){
      return (
        <PortalFrame title={title}>
          <PageSkeleton />
        </PortalFrame>
      );
    }

    return (
        <PortalFrame title={title} orgName={orgName} caseName={caseName}>
          <DateRangeProvider {...props} data={data} date_field={date_field}>
            <TransactionReviewProvider>
              <CategoryReviewReport
                {...props}
                data={data}
                date_field={date_field}
                subcategory_options={subcategory_options}
                handleCategoryRuleCreate={handleCategoryRuleCreate}
                handleFlagRuleCreate={handleFlagRuleCreate}
                mutateTransaction={mutateTransaction}
                />
            </TransactionReviewProvider>
          </DateRangeProvider>
        </PortalFrame>
      );
    }
