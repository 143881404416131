import { queryPrimaryNeo4j } from './pydbcall';
import useSWR from 'swr'
//import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';
import { getCurrentOrgForCurrentUser } from './swrorgsforcurrentuser';
import authGetCurrentUserID from './authcurrentuserid';
import { getCurrentCaseForCurrentUser } from './swrcasesforcurrentuser';

//

export async function getCurrentUserRecord() {
// this is just returning the user record, use the user profile function below to get the full user profile

    let neoResult
    const userID = await authGetCurrentUserID()
    //console.log(`got userID: ${userID}`)

    const qry = 'MATCH (u:User {userID:$userID}) return u as user limit 1'

    const parameters = { userID: userID }

    //console.log(`calling queryPrimaryNeo4j with qry=${qry}, parms=${JSON.stringify(parameters)}`)
    await queryPrimaryNeo4j(qry, parameters).then((result) => {
        
        
        neoResult = result.records[0].user

        
    })
    //console.log(`got neo4j user record: ${JSON.stringify(neoResult)}`)

    return neoResult
}

export async function getCurrentUserProfile() {
    
    let curCase
    const curUser = await getCurrentUserRecord()
    //console.log(`IN getCurrentUserProfile: curUser=${JSON.stringify(curUser)}`)
    const curOrg = await getCurrentOrgForCurrentUser()
    //console.log(`IN getCurrentUserProfile: curOrg=${JSON.stringify(curOrg)}`)
    if(curOrg){
       curCase = await getCurrentCaseForCurrentUser(curOrg.orgID)
    }
    //console.log(`IN getCurrentUserProfile: curCase=${JSON.stringify(curCase)}`)
    if(!curUser){
        throw new Error("User record not found in the database");
    }

    const result = {currentUser:curUser, currentOrg:curOrg, currentCase:curCase}
    //console.log(`IN getCurrentUserProfile: result=${JSON.stringify(result)}`)
    return result

    
}

export function useCurrentUserProfile(){
    const result = useSWR('CURRENT_USER_PROFILE', getCurrentUserProfile);
    return result
}