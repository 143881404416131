import { queryPrimaryNeo4j } from './pydbcall';
import useSWR from 'swr'
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

export async function getCasesForCurrentUserbyOrg(orgID, limit = 1000) {
  let neoResult
  await fetchUserAttributes().then(async (attrs) => {
    //console.log(`useOrgsForCurrentUser`)
    //console.log(`userID=${attrs.sub}`)

    if(!orgID) {
      console.warn('missing orgID when trying to get cases for current user')
      throw new Error('missing orgID when trying to get cases for current user')
    }

    const qry = 'MATCH (user:User {userID:$userID})-[:BELONGS_TO]->(org:Organization {orgID:$orgID}) WITH user, org MATCH (user)-[r]-(case:Case)-[:BELONGS_TO]->(org) RETURN case order by r.lastDate desc LIMIT $limit'
    const parameters = { userID: attrs.sub, orgID: orgID, limit: limit }

    //console.log(`calling queryPrimaryNeo4j with qry=${qry}, parms=${JSON.stringify(parameters)}`)
    await queryPrimaryNeo4j(qry, parameters).then((result) => {
      //console.log(`got queryPrimaryNeo4j result=${JSON.stringify(result)}`)
      neoResult = result
    })

  }
  )
  return neoResult
}

export function useCasesForCurrentUserbyOrg(orgID, shouldFetch=true, limit = 1000) {

  

  //const userID=authGetCurrentUserID();

  const result = useSWR(shouldFetch?['CURRENT_USER_CASES_FOR_ORG', orgID, limit]:null, ([path, orgID, limit]) => getCasesForCurrentUserbyOrg(orgID, limit));
  //const result = useSWR('CURRENT_USER_CASES_FOR_ORG', getData);

  //console.log(`in useOrgsForCurrentUser: final result=${JSON.stringify(result)}`)
  return result
}

export async function getCasesForCurrentUser(limit = 1000) {
  let neoResult
  await fetchUserAttributes().then(async (attrs) => {
    //console.log(`useOrgsForCurrentUser`)
    //console.log(`userID=${attrs.sub}`)

    const qry = 'MATCH (u:User {userID:$userID})-[ru:BELONGS_TO]->(o:Organization)<-[rc:BELONGS_TO]-(c:Case) WITH u,o,c MATCH (u)-[ruc:BELONGS_TO]->(c) return o as org, c as case, u as user order by ruc.lastDate desc limit $limit'
    const parameters = { userID: attrs.sub, limit: limit }

    //console.log(`calling queryPrimaryNeo4j with qry=${qry}, parms=${JSON.stringify(parameters)}`)
    await queryPrimaryNeo4j(qry, parameters).then((result) => {
      //console.log(`got queryPrimaryNeo4j result=${JSON.stringify(result)}`)
      neoResult = result
    })

  }
  )
  return neoResult
}

export function useCasesForCurrentUser(limit = 1000) {

  
  const result = useSWR(['CURRENT_USER_ALL_CASES', limit], ([path, limit]) => getCasesForCurrentUser(limit));

  return result
}

export async function getCurrentCaseForCurrentUser(orgID) {
  let  neoResult

  console.log(`getCurrentCaseForCurrentUser: orgID=${orgID}`)
  const result=await getCasesForCurrentUserbyOrg(orgID,1)
  console.log(`getCurrentCaseForCurrentUser: result=${JSON.stringify(result)}`)

  if (result.records.length > 0){
    neoResult = result.records[0].case
  }  
  console.log(`getCurrentCaseForCurrentUser: neoResult=${JSON.stringify(neoResult)}`)

  return neoResult
}

export function useCurrentCaseForCurrentUser(orgID) {
  
  const result = useSWR(['CURRENT_CASE_FOR_CURRENT_USER',orgID], (path,orgID)=>getCurrentCaseForCurrentUser(orgID));
  return result
}