import React from 'react';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import LaunchIcon from '@mui/icons-material/Launch';

const ReportEditList = (props) => {

  const data = props.data ? props.data : []
  const handleEditClick = props.handleEditClick
  const handleDelete = props.handleDelete
  const handleLaunchClick = props.handleLaunchClick
  const showEdit = props.showEdit ? props.showEdit : false;
  const showDelete = props.showDelete ? props.showDelete : false;
  const showLaunch = props.showLaunch ? props.showLaunch : false;

  if (data.length === 0) {
    return <div></div>
  }

  return (

    <List>
      {data.map((report) => (
        <React.Fragment key={report.reportID + "-DUMMY"}>
          <ListItem key={report.reportID}>
            <ListItemText primary={report.reportName} secondary={report.reportType + " " + report.updateDate} />
            <ListItemSecondaryAction>
              {showLaunch &&
                <IconButton edge="end" aria-label="launch" onClick={() => handleLaunchClick(report)}>
                  <LaunchIcon />
                </IconButton>
              }
              {showEdit &&
                <IconButton edge="end" aria-label="edit" onClick={() => handleEditClick(report)}>
                  <EditIcon />
                </IconButton>
              }
              {showDelete &&
                <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(report)}>
                  <DeleteIcon />
                </IconButton>
              }
            </ListItemSecondaryAction>
          </ListItem>
          <Divider></Divider>
        </React.Fragment>
      ))}
    </List>


  );
};

export default ReportEditList;


