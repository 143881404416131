import React, { Fragment, useState, useEffect, useContext } from 'react';
//import { TextField } from '@mui/material';
import { currencyFormatter } from './type-utilities';
import { 
  Container, 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow ,
  Card,
  CardContent,
  CardHeader
} from '@mui/material';
import ReportContext from '../ui/user-report-context.js';

//import { useForm, FormProvider } from 'react-hook-form';
//import CurrencyTextField from './rh_form_elements/rh-currency-field-element';

// const personalInfo = {
//   name: 'Bob Smith',
//   address: '123 Main St',
//   phone: '212-555-1212',
//   email: 'bob.smith@foo.bar'
// };

// const initialData = {
//   personalInfo: {
//     name: '',
//     address: '',
//     phone: '',
//     email: ''
//   },
//   categoryData: {
    
//   }
// };

const UnmatchedTransfersDetailReport = (props) => {

  

  //console.log('rendering EditableDRFAReport');

  const data = props.data ? props.data : [];
  const reportID = props.reportID;

  const reportContext = useContext(ReportContext);

  const report=reportContext.getReportByID(reportID)
  //console.log(`categoryData ${JSON.stringify(categoryData)}`)
  //console.log(`totals ${JSON.stringify(totals)}`)
  //const [data, setData] = useState({ personalInfo: personalInfo, categoryData: categoryData});


  const summarizeByMissingAccount = (data) => {
    return data.reduce((acc, record) => {
      const { MissingAccount, Amount } = record;
      
      // Initialize if MissingAccount doesn't exist in accumulator
      if (!acc[MissingAccount]) {
        acc[MissingAccount] = {
          count: 0,
          totalAmount: 0
        };
      }

      // Increment count and add to totalAmount
      acc[MissingAccount].count += 1;
      acc[MissingAccount].totalAmount += Amount;

      return acc;
    }, {});
  };

  

  

  

  
  if (report.isLoading) return null;
  const filteredData=report.reportData.filter((row)=>row.Disposition=='INCLUDE')
  
  const missingAccountSummary = summarizeByMissingAccount(filteredData);
  

  //console.log(`categoryData ${JSON.stringify(data.categoryData)}`)

  return (
    <Container sx={{ flexGrow: 1, padding: 2 }}>
      {/* Report Title */}
      <Paper sx={{ padding: 2, textAlign: 'center', color: 'text.secondary', marginBottom: 2 }}>
        <Typography variant="h4" component="h1">
          Unmatched Transfers Detail Report
        </Typography>
      </Paper>
  
      {/* Summary Title */}
      <Typography variant="h6" component="h2" sx={{ marginBottom: 2, marginTop: 2 }}>
        Summary of Missing Accounts
      </Typography>
  
      {/* Summary Table */}
      <TableContainer component={Paper} sx={{ flexGrow: 1, padding: 2, marginBottom: 4 }}>
        <Table>
          <TableHead>
            <TableRow key={'summary_sheader'}>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Missing Account</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Transaction Count</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(missingAccountSummary).map((key) => (
              <Fragment key={`sumdummy-${key}`}>
                <TableRow key={`sumrow-${key}`}>
                  <TableCell align="right">{key}</TableCell>
                  <TableCell align="right">{missingAccountSummary[key].count}</TableCell>
                  <TableCell align="right">{currencyFormatter(missingAccountSummary[key].totalAmount)}</TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  
      {/* Detail Title */}
      <Typography variant="h6" component="h2" sx={{ marginBottom: 2 }}>
        Detailed Transactions
      </Typography>
  
      {/* Details Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow key={'detail_sheader'}>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Comment</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Missing Account</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Bank Account</TableCell>
              <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Date</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Category</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Subcategory</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Description</TableCell>
              <TableCell style={{ fontWeight: 'bold' }} align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <Fragment key={`rowdummy-${row.id}`}>
                <TableRow key={`row-${row.id}`}>
                  <TableCell align="right">{row.Comment}</TableCell>
                  <TableCell align="right">{row.MissingAccount}</TableCell>
                  <TableCell>{row.Account}</TableCell>
                  <TableCell style={{ whiteSpace: 'nowrap' }}>{row.Date}</TableCell>
                  <TableCell align="right">{row.Category}</TableCell>
                  <TableCell align="right">{row.Subcategory}</TableCell>
                  <TableCell align="right">{row.Description}</TableCell>
                  <TableCell align="right">{currencyFormatter(row.Amount)}</TableCell>
                </TableRow>
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
  
};

export default UnmatchedTransfersDetailReport;
