import * as React from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { Controller } from 'react-hook-form';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

export default function SelectAllTransferList(props) {

  /// props mapping
  const choices = props.choices ? props.choices : [
    { key: "0", value: "value 0" },
    { key: "1", value: "value 1" },
    { key: "2", value: "value 2" },
    { key: "3", value: "value 3" },
    { key: "4", value: "value 4" },
    { key: "5", value: "value 5" },
    { key: "6", value: "value 6" },
    { key: "7", value: "value 7" },
    { key: "foo", value: "value foo" },
  ]

  const getItemKey = props.getItemKey ? props.getItemKey : (item) => item.key;
  const getItemDisplay = props.getItemDisplay ? props.getItemDisplay : (item) => item.value;

  const choicesTitle = props.choicesTitle ? props.choicesTitle : "Choices"
  const chosenTitle = props.chosenTitle ? props.chosenTitle : "Chosen"

  const value = props.value ? props.value : ["4", "5", "6", "7"]
  

  const name = props.name ? props.name : "transfer-list"
  

  //const register= props.register
  const setValue=props.setValue

  /// end props mapping

  const choicekeys = choices.map(getItemKey)
  const choicevalues = choices.map(getItemDisplay)
  const choicearr = choices.map((item)=>[getItemKey(item) , getItemDisplay(item)])
  const choicemap = new Map(choicearr)
  //console.log(choicemap)

  const l = not(choicekeys, value)
  const r = value
  const [checked, setChecked] = React.useState([]);
  const [left, setLeft] = React.useState(l);
  const [right, setRight] = React.useState(r);

  // this effect keeps track of the react hook form value, maintain the value to match the right hand list
  React.useEffect(()=>{setValue(name,right)}  )

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    const updatedRight = right.concat(leftChecked);
    setRight(updatedRight);
    setLeft(not(left, leftChecked));
    setChecked(not(checked, leftChecked));
    
    
  };

  const handleCheckedLeft = () => {
    const updatedLeft = left.concat(rightChecked);
    setLeft(updatedLeft);
    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));
    
  };

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={
              numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List


        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={choicemap.get(value)} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center">
      <Grid item>{customList(choicesTitle, left)}</Grid>
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0}
            aria-label="move selected left"
          >
            &lt;
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList(chosenTitle, right)}</Grid>
    </Grid>
  );
}
