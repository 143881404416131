import React from "react";
import { useState } from "react";
//import Link from '@mui/material/Link';
import { Box, Typography } from '@mui/material';
import { Button } from "@mui/material";
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell from '@mui/material/TableCell';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
import Title from './title';
import PageSkeleton from "./pageskeleton";
import { queryCaseNeo4j } from "../../api/pydbcall";
import { DataGrid, GridActionsCellItem, GridToolbarContainer } from "@mui/x-data-grid";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ReplayIcon from '@mui/icons-material/Replay';
import ViewStatementDialog from "./dialog-view-statement-pdf";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { propValidatorsDataGrid } from "@mui/x-data-grid/internals";
import { postDocument } from "../../api/ocrdocumentcall";
import { useDocumentsForCurrentCase } from "../../api/swrdocumentsforcase";


//import {postNeo4j} from '../../api/pydbcall';



function preventDefault(event) {
  event.preventDefault();
}
function enableDefault(event) {
  return;
}



export default function DocumentPreview(props) {

  const handleUploadClick = props.handleUploadClick ? props.handleUploadClick : () => {console.log('DEFAULT UPLOAD HANDLER') }
  //const [rows, setRows] = useState([]);
  //const [first, setFirst] = useState(true);
  //const [s3keys, setS3Keys]=useState({})
  const [openViewDialog, setOpenViewDialog] = useState(false);
  const [viewFileKey, setViewFileKey] = useState();
  //const [rows, setRows] = useState([])
  //const [columns, setColumns] = useState([])
  //const [s3keys, setS3Keys] = useState({})
  //const [s3scannedkeys, setS3scannedKeys] = useState({})

  const shouldFetch=props.parms.orgID && props.parms.caseID
  const previewResult=useDocumentsForCurrentCase(props.parms.orgID, props.parms.caseID, shouldFetch)



  //let rows=[];
  //let columns=[];
  //let s3keys={};

  //////////////////////////////////
  // React.useEffect(() => {
  //   /// this effect loads the uploaded documents 
  //   const getUploadedDocs = async () => {

  //     // const preview_query = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})<-[:BELONGS_TO]-(f:CaseFile) OPTIONAL MATCH (f)<-[:PROCESSED]-(sd:ScannedDoc) return f.path as `File Path`, f.scanned_path as scanned_path, f.logicalFile as `Uploaded File`, f.created as `Uploaded Date`,sd.status as `Processing Status`, sd.uploaded_doc_type as `Document Type`, sd.sub_doc_type as  `Specific Document Type`, sd.pages as `Page Count`, sd._updateDate as `Processed Date` order by `Uploaded Date`"
  //     // const parms = props.parms

  //     // //console.log(`query parms: ${JSON.stringify(parms)}`)


  //     // const previewResult = await queryCaseNeo4j(preview_query, parms)

  //     // const rows = previewResult['records'].map(
  //     //   (row, index) => ({ 'id': index, ...row })
  //     // );
  //     // setRows(rows)

  //     const s3keyfield = `File Path`
  //     const s3keys = rows.reduce(
  //       (acc, row) => {
  //         //console.log(`processing row: ${JSON.stringify(row)}`)
  //         acc[row.id] = row[s3keyfield]
  //         return acc
  //       }, {}
  //     )
  //     //console.log(`result s3keys: ${JSON.stringify(s3keys)}`)
  //     setS3Keys(s3keys)

  //     const s3scankeyfield = `scanned_path`
  //     const s3scannedkeys = rows.reduce(
  //       (acc, row) => {
  //         console.log(`processing row: ${JSON.stringify(row)}`)
  //         acc[row.id] = row[s3scankeyfield]
  //         return acc
  //       }, {}
  //     )
  //     console.log(`result s3scannedkeys: ${JSON.stringify(s3scannedkeys)}`)
  //     setS3scannedKeys(s3scannedkeys)


  //     let columns = previewResult['keys'].map(
  //       (col) => ({ field: col, headerName: col, width: 200 })
  //     )

  //     const action_column =
  //     {
  //       field: 'actions',
  //       type: 'actions',
  //       headerName: 'Actions',
  //       width: 100,
  //       cellClassName: 'actions',
  //       getActions: ({ id }) => {


  //         return [
  //           <GridActionsCellItem
  //             icon={<PictureAsPdfIcon />}
  //             label="View PDF"
  //             className="textPrimary"
  //             onClick={handlePDFClick(id)}
  //             color="inherit"
  //           />,
            
             
  //           //(rows[id][s3scankeyfield]) && 
  //           <GridActionsCellItem
  //             icon={<ReplayIcon />}
  //             label="Reprocess"
  //             onClick={handleReprocess(id)}
  //             color="inherit"
  //             disabled={s3scannedkeys[id.toString()] ? false : true}
  //           />,
            
  //         ];
  //       },
  //     }
  //     columns = [action_column, ...columns]



  //     // filter out the s3 key path from the view, it's for PDF preview use only
  //     columns = columns.filter(column => column.field != s3keyfield && column.field != s3scankeyfield)
  //     setColumns(columns)


  //     //const bankAccountList = accountQueryResult.records.map(record => ({ ...record.ba, id: record.ba.pk, label: record.ba.name }))
  //     //console.log(`bank account list: ${JSON.stringify(bankAccountList)}`)
  //     //setBankAccountList(bankAccountList)

  //     // if (bankAccountList.length >= 1) {
  //     //   const bank_account = bankAccountList[0]
  //     //   setBankAccount(bank_account)
  //     // }


  //   }

  //   getUploadedDocs()


  // }, [props.uploadCount, props.parms]
  // )
  //////////////////////////////////




  //if (previewResult.isLoading === false) {
  //console.log('PREVIEW DATA:')
  //console.log(JSON.stringify(previewResult.data))
  // rows=previewResult.data['records'].map(
  //   (row,index) => ({'id':index, ...row})
  // );





  //setS3Keys(s3keys)

  //console.log('ROWS:')
  //console.log(JSON.stringify(rows))


  //   columns = previewResult.data['keys'].map(
  //     (col) => ({ field: col, headerName: col, width: 200 })
  //   )

  //   const action_column =
  //   {
  //     field: 'actions',
  //     type: 'actions',
  //     headerName: 'Actions',
  //     width: 100,
  //     cellClassName: 'actions',
  //     getActions: ({ id }) => {


  //       return [
  //         <GridActionsCellItem
  //           icon={<PictureAsPdfIcon />}
  //           label="View PDF"
  //           className="textPrimary"
  //           onClick={handlePDFClick(id)}
  //           color="inherit"
  //         />,
  //         /*
  //         <GridActionsCellItem
  //           icon={<DeleteIcon />}
  //           label="Delete"
  //           onClick={handleDeleteClick(id)}
  //           color="inherit"
  //         />,
  //         */
  //       ];
  //     },
  //   }
  //   columns = [action_column, ...columns]



  //   // filter out the s3 key path from the view, it's for PDF preview use only
  //   columns = columns.filter(column => column.field != s3keyfield)



  //   //console.log('COLUMNS:')
  //   //console.log(JSON.stringify(columns))
  // }

  if(previewResult.isLoading){
    return <PageSkeleton/>
  }

  ///////////////////////////////////
  const rows = previewResult.data['records'].map(
    (row, index) => ({ 'id': index, ...row })
  );
  
  console.log(`rows: ${JSON.stringify(rows)}`)

  const s3keyfield = `File Path`
  const s3keys = rows.reduce(
    (acc, row) => {
      //console.log(`processing row: ${JSON.stringify(row)}`)
      acc[row.id] = row[s3keyfield]
      return acc
    }, {}
  )
  //console.log(`result s3keys: ${JSON.stringify(s3keys)}`)
  //setS3Keys(s3keys)

  const s3scankeyfield = `scanned_path`
  const s3scannedkeys = rows.reduce(
    (acc, row) => {
      console.log(`processing row: ${JSON.stringify(row)}`)
      acc[row.id] = row[s3scankeyfield]
      return acc
    }, {}
  )
  console.log(`result s3scannedkeys: ${JSON.stringify(s3scannedkeys)}`)
  //setS3scannedKeys(s3scannedkeys)


  let columns = previewResult.data['keys'].map(
    (col) => ({ field: col, headerName: col, width: 200 })
  )

  const action_column =
  {
    field: 'actions',
    type: 'actions',
    headerName: 'Actions',
    width: 100,
    cellClassName: 'actions',
    getActions: ({ id }) => {


      return [
        <GridActionsCellItem
          icon={<PictureAsPdfIcon />}
          label="View PDF"
          className="textPrimary"
          onClick={handlePDFClick(id)}
          color="inherit"
        />,
        
         
        //(rows[id][s3scankeyfield]) && 
        <GridActionsCellItem
          icon={<ReplayIcon />}
          label="Reprocess"
          onClick={handleReprocess(id)}
          color="inherit"
          disabled={s3scannedkeys[id.toString()] ? false : true}
        />,
        
      ];
    },
  }
  columns = [action_column, ...columns]



  // filter out the s3 key path from the view, it's for PDF preview use only
  columns = columns.filter(column => column.field != s3keyfield && column.field != s3scankeyfield)
  //setColumns(columns)
  ///////////////////////////////////


  function EditToolbar(props) {
    const { setRows, setRowModesModel } = props;



    return (
      <GridToolbarContainer>
        <Button color="primary" startIcon={<FileUploadIcon />} onClick={handleUploadClick}>
          Upload File
        </Button>
      </GridToolbarContainer>
    );
  }

  const handlePDFClick = (id) => () => {
    //console.log(`clicked PDF, available keys=${JSON.stringify(s3keys)}`)
    setViewFileKey(s3keys[id.toString()])
    //console.log(`clicked PDF, file key = ${s3keys[id.toString()]}`)
    setOpenViewDialog(true)
  }

  const handleReprocess = (id) => () => {
    console.log(`clicked Reprocess, available keys=${JSON.stringify(s3scannedkeys)}`)
    const filekey=s3scannedkeys[id.toString()]
    console.log(`clicked Reprocess, file key = ${filekey}`)
    const body= {
      orgID : props.parms.orgID,
      caseID: props.parms.caseID,
      s3_document_key: filekey
    }
    console.log(`reprocess body: ${JSON.stringify(body)}`)
    postDocument(body)
  }

  function onCancelView() {
    setOpenViewDialog(false);
  };


  return (
    <React.Fragment>
      <Title>{props.preview_title}</Title>
      <Box
        sx={{

          maxHeight: '75vh'
        }}
      >
        <DataGrid
          sx={{

            maxHeight: '70vh'
          }}
          rows={rows}
          columns={columns}
          slots={{
            toolbar: EditToolbar,
          }}
        />

      </Box>
      <ViewStatementDialog open={openViewDialog} fileKey={viewFileKey} onCancel={onCancelView} />
    </React.Fragment>
  );
}