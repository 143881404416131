import{queryPrimaryNeo4j} from './pydbcall';
import useSWR from 'swr'
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

export async function getCurrentOrgForCurrentUser(){
  let neoResult
    await fetchUserAttributes().then(async (attrs)=>{
       //console.log(`useOrgsForCurrentUser`)
       //console.log(`userID=${attrs.sub}`)
      const userID = attrs.sub
      const limit=1
      const qry='MATCH (user:User {userID:$userID})-[r:BELONGS_TO]->(org:Organization) RETURN org, user ORDER BY r.lastDate desc LIMIT $limit'
      const parameters={userID: userID, limit:limit}

       //console.log(`calling queryPrimaryNeo4j with qry=${qry}, parms=${JSON.stringify(parameters)}`)
      await queryPrimaryNeo4j(qry,parameters).then((result)=>{
         //console.log(`got queryPrimaryNeo4j result=${JSON.stringify(result)}`)

         if(result.records.length>0){
          neoResult=result.records[0].org
         }

        //  const orgID=result.records[0].org.orgID
        // const orgName=result.records[0].org.orgName
        // neoResult={orgID: orgID, orgName: orgName, userID: userID}
        
      })
      
    }
    )
    return neoResult
}

export function useCurrentOrgForCurrentUser() {
  //console.log('entering useCurrentOrgForCurrentUser')
  

  //const userID=authGetCurrentUserID();
  const result = useSWR('CURRENT_USER_ORG', getCurrentOrgForCurrentUser);

//console.log(`in useOrgsForCurrentUser: final result=${JSON.stringify(result)}`)
return result
}

export async function getOrgsForCurrentUser(limit=1000) {
  let neoResult
      await fetchUserAttributes().then(async (attrs)=>{
         //console.log(`useOrgsForCurrentUser`)
         //console.log(`userID=${attrs.sub}`)
        const userID = attrs.sub
        const qry='MATCH (user:User {userID:$userID})-[r:BELONGS_TO]->(org:Organization) RETURN org, user ORDER BY r.lastDate desc LIMIT $limit'
        const parameters={userID: userID, limit:limit}

         //console.log(`calling queryPrimaryNeo4j with qry=${qry}, parms=${JSON.stringify(parameters)}`)
        await queryPrimaryNeo4j(qry,parameters).then((result)=>{
           //console.log(`got queryPrimaryNeo4j result=${JSON.stringify(result)}`)

           // fix this and the other function above!!
           neoResult=result
          
        })
        
      }
      )
      return neoResult
    
}
export function useOrgsForCurrentUser(limit=1000) {

    

    //const userID=authGetCurrentUserID();
    const result = useSWR(['CURRENT_USER_ORGS',limit],([path,limit])=>getOrgsForCurrentUser(limit));
    //const result = useSWR('CURRENT_USER_ORGS', getData);

  //console.log(`in useOrgsForCurrentUser: final result=${JSON.stringify(result)}`)
  return result
}