import React from "react";
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
//import Checkbox from '@mui/material/Checkbox';
//import FormControlLabel from '@mui/material/FormControlLabel';
//import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { ResponsiveChartContainer } from '@mui/x-charts/ResponsiveChartContainer';
import { BarPlot } from '@mui/x-charts/BarChart';
import {ChartsTooltip } from '@mui/x-charts/ChartsTooltip';
//import { LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { getCurrentUser } from 'aws-amplify/auth';
import { Typography } from "@mui/material";
import { currencyFormatter } from "./type-utilities";
import { green, red } from "@mui/material/colors";

export default function SimpleBarChartTransactionReview(props) {
  const min_margin=150

  const x_tick_angle = -45;
  const bottom_margin_buffer = 10;
  const x_label_margin_offset = 50;
  const paperheight = 750
  const paperwidth = '100%'


  function calc_margin() {
      let max_len = Math.max(...categories.map((cat) => String(cat).length));
      const char_size = 7;
      let new_margin = Math.round(Math.abs(max_len * char_size * Math.sin(x_tick_angle * Math.PI / 180))) + bottom_margin_buffer;
      return Math.max(new_margin,min_margin);
  }

  // async function currentAuthenticatedUser() {
  //   try {
  //     const user = await getCurrentUser();
  //     console.log(`The username: ${user.username}`);
  //     console.log(`The userId: ${user.userId}`);
      
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  
  
  

  // if (dataResult.isLoading) {
  //   return <Skeleton variant="rectangular" width={paperwidth} height={paperheight} />
  // }

  const data=props.data
  const categoryField=props.categoryField
  const valueField=props.valueField
  const onBarClick=props.onBarClick
  

  if (data.length === 0){
    return (<Typography>No data found for this case, please make sure there are statements uploaded and processed for this case</Typography>)
  }

  //console.log(`GOT HERE, data=${JSON.stringify(data)}`)
  const Container = ResponsiveChartContainer;
  let vals=data.map((row) => { return row[valueField] });
  let categories=data.map((row) => { return row[categoryField] });
  let bottom_margin=calc_margin();
  return (
    <Box sx={{ width: paperwidth, height: paperheight }}>
      <Paper sx={{ width: paperwidth, height: paperheight }} elevation={3} >
        {/* @ts-ignore */}
        <Container
          margin={{ left: 150, right: 70, top: 70, bottom: bottom_margin }}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translate(-40px, 0)',
            },
            [`.${axisClasses.right} .${axisClasses.label}`]: {
              transform: 'translate(25px, 0)',
            },
            [`.${axisClasses.bottom} .${axisClasses.label}`]: {
              transform: `translate(0, ${bottom_margin-x_label_margin_offset}px)`,
            },
            [`.${axisClasses.top} .${axisClasses.label}`]: {
              transform: 'translate(0, -30px)',
            },
          }}
          series={[
            {
              type: 'bar',
              data: vals,
              yAxisKey: 'y-axis-id',
              //valueFormatter: currencyFormatter,
              valueFormatter: (v, { dataIndex }) => {
                const { totalCount } = data[dataIndex];
                return `${currencyFormatter(v)} over ${totalCount} transaction${totalCount>1?'s':''}`;
              },
            },

          ]}
          xAxis={[
            {
              data: categories,
              scaleType: 'band',
              id: 'x-axis-id',
            },

          ]}
          yAxis={[
            {
              id: 'y-axis-id',
              scaleType: 'linear',
              valueFormatter: currencyFormatter,
              colorMap: {
                type: 'piecewise',
                thresholds: [0],
                //colors: ['red', 'green'],
                colors: [red[400], green[400]]
              },
            },

          ]}

        >
          <BarPlot onItemClick={onBarClick}/>
          <ChartsTooltip tooltip={{ trigger: 'item' }} />
          <ChartsXAxis tickLabelStyle={{ angle: x_tick_angle, textAnchor: 'end' }} label={props.x_label} labelStyle={{ fontSize: 18, fontWeight: "bold" }} position="bottom" axisId="x-axis-id" />
          <ChartsYAxis label={props.y_label} labelStyle={{ fontSize: 18, fontWeight: "bold" }} position="left" axisId="y-axis-id" />

        </Container>

      </Paper>
    </Box>
  );
}