import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";


export async function mutateCategory(currentUserProfile, data, action=MUTATE_UPDATE) {
    

    

    
    if(!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser){
        throw Error(`pass a valid current user profile to this call`)
    }

    const orgID=currentUserProfile.currentOrg.orgID
    const caseID=currentUserProfile.currentCase.caseID
    const email=currentUserProfile.currentUser.email

    const incomeMap={'income':'Income','expense':'Expense','transfer':'Transfer','business expense': 'Business Expense', 'excluded expense': 'Excluded Expense'}

    const incomeKey=data.incomeExpense.toLowerCase();

    const incomeExpense=incomeMap[incomeKey]

    //const update_mutation_with_drfa = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID}) WITH c,dt MERGE (c)-[:USES]->(drfa:DRFACategory {name:$drfaCategory}) ON CREATE set drfa._updateDate=dt, drfa._createDate=dt, drfa._createUser=$currentUser, drfa._updateUser=$currentUser ON MATCH set drfa._updateDate=dt, drfa._updateUser=$currentUser MERGE (c)-[:USES]->(cat:Category:DefinedCategory {name:$category}) ON CREATE set cat._updateDate=dt, cat._createDate=dt, cat._createUser=$currentUser, cat._updateUser=$currentUser ON MATCH set cat._updateDate=dt, cat._updateUser=$currentUser MERGE(c)-[:USES]->(subcat:DefinedCategory:Subcategory {name:$subcategory})ON CREATE set subcat._updateDate=dt, subcat._createDate=dt, subcat._createUser=$currentUser, subcat._updateUser=$currentUser, subcat.type=$incomeExpense ON MATCH set subcat._updateDate=dt, subcat._updateUser=$currentUser, subcat.type=$incomeExpense MERGE (subcat)-[rc2:WITHIN]->(cat) MERGE (subcat)-[:MAPS_TO]->(drfa) WITH subcat, cat, drfa MATCH (subcat)-[rc:WITHIN]->(c:Category:DefinedCategory) WHERE c.name <> cat.name  DELETE rc WITH subcat,cat,drfa MATCH (subcat)-[rd:MAPS_TO]->(d:DRFACategory) WHERE d.name <> drfa.name DELETE d return subcat, cat, drfa'
    const update_mutation_with_drfa = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID}) WITH c,dt MERGE (c)-[:USES]->(drfa:DRFACategory {name:$drfaName}) ON CREATE set drfa.category=$drfaCategory, drfa.subcategory=$drfaSubcategory, drfa._updateDate=dt, drfa._createDate=dt, drfa._createUser=$currentUser, drfa._updateUser=$currentUser ON MATCH set drfa.category=$drfaCategory, drfa.subcategory=$drfaSubcategory, drfa._updateDate=dt, drfa._updateUser=$currentUser MERGE (c)-[:USES]->(cat:Category:DefinedCategory {name:$category}) ON CREATE set cat._updateDate=dt, cat._createDate=dt, cat._createUser=$currentUser, cat._updateUser=$currentUser ON MATCH set cat._updateDate=dt, cat._updateUser=$currentUser MERGE(c)-[:USES]->(subcat:DefinedCategory:Subcategory {name:$subcategory})ON CREATE set subcat._updateDate=dt, subcat._createDate=dt, subcat._createUser=$currentUser, subcat._updateUser=$currentUser, subcat.type=$incomeExpense ON MATCH set subcat._updateDate=dt, subcat._updateUser=$currentUser, subcat.type=$incomeExpense MERGE (subcat)-[rc2:WITHIN]->(cat) MERGE (subcat)-[:MAPS_TO]->(drfa) WITH subcat, cat, drfa MATCH (subcat)-[rc:WITHIN]->(c:Category:DefinedCategory) WHERE c.name <> cat.name  DELETE rc WITH subcat,cat,drfa MATCH (subcat)-[rd:MAPS_TO]->(d:DRFACategory) WHERE d.name <> drfa.name DELETE d return subcat, cat, drfa'
    
    
    //const update_mutation_without_drfa = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID}) WITH c,dt  MERGE (c)-[:USES]->(cat:Category:DefinedCategory {name:$category}) ON CREATE set cat._updateDate=dt, cat._createDate=dt, cat._createUser=$currentUser, cat._updateUser=$currentUser ON MATCH set cat._updateDate=dt, cat._updateUser=$currentUser MERGE(c)-[:USES]->(subcat:DefinedCategory:Subcategory {name:$subcategory})-[rc2:WITHIN]->(cat) ON CREATE set subcat._updateDate=dt, subcat._createDate=dt, subcat._createUser=$currentUser, subcat._updateUser=$currentUser, subcat.type=$incomeExpense ON MATCH set subcat._updateDate=dt, subcat._updateUser=$currentUser, subcat.type=$incomeExpense WITH subcat, cat MATCH (subcat)-[rc:WITHIN]->(c:Category:DefinedCategory) WHERE c.name <> cat.name  DELETE rc return subcat, cat'
    const update_mutation_without_drfa = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID}) WITH c,dt  MERGE (c)-[:USES]->(cat:Category:DefinedCategory {name:$category}) ON CREATE set cat._updateDate=dt, cat._createDate=dt, cat._createUser=$currentUser, cat._updateUser=$currentUser ON MATCH set cat._updateDate=dt, cat._updateUser=$currentUser MERGE(c)-[:USES]->(subcat:DefinedCategory:Subcategory {name:$subcategory}) ON CREATE set subcat._updateDate=dt, subcat._createDate=dt, subcat._createUser=$currentUser, subcat._updateUser=$currentUser, subcat.type=$incomeExpense ON MATCH set subcat._updateDate=dt, subcat._updateUser=$currentUser, subcat.type=$incomeExpense MERGE (subcat)-[rc2:WITHIN]->(cat) WITH subcat, cat OPTIONAL MATCH (subcat)-[rc:WITHIN]->(c:Category:DefinedCategory) WHERE c.name <> cat.name  DELETE rc WITH subcat, cat OPTIONAL MATCH (subcat)-[rscd:MAPS_TO]->(drfa:DRFACategory) DELETE rscd  return subcat, cat'
    
    const delete_merge = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID}) WITH c,dt MERGE (c)-[:USES]->(default:DefaultCategory:Subcategory {name:"UNKNOWN"}) WITH c, dt, default MATCH (c)-[:USES]->(subcat:DefinedCategory:Subcategory {name:$subcategory})<-[]-(t:Transaction) MERGE (t)-[r2:PROPOSED]->(default)'
    const delete_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(subcat:DefinedCategory:Subcategory {name:$subcategory}) DETACH DELETE subcat return subcat'
    const parms = { 
        "orgID": orgID,
        "caseID": caseID, 
        "currentUser": email,
        "subcategory": data.subcategory,
        "category": data.category,
        "incomeExpense":incomeExpense,
        "drfaCategory":data.drfaCategory,
        "drfaSubcategory":data.drfaSubcategory,
        "drfaName":data.drfaCategory + " - " + data.drfaSubcategory,


        // "bank_account_pk": data.bank_account_pk,
        // "period_pk": data.period_pk,
        // "pk": data.pk, 
        // "_verified": data._verified,
        // "amount": data.amount,
        // "txn_date": data.txn_date,
        // "description": data.description
    }

    

    // if (action === MUTATE_UPDATE && search_before_create){
    //     //console.log('IN MUTATE SEARCH BEFORE CREATE AREA')
        
    //             const sb4create = 'MATCH (case:Case {caseName:$caseName})-[r:BELONGS_TO]->(org:Organization {orgID:$orgID}) WHERE case.caseID <> $caseID RETURN case'
    //     const sb4result = mutate(['CASE', data.caseID], mutateCaseNeo4j(update_mutation, parms,sb4create))    
    //     //console.log(JSON.stringify(sb4result))
    //     return sb4result
    // }

    if (action==MUTATE_DELETE){
        //console.log('gonna delete')
        //console.log(`in mutate, parms=${JSON.stringify(parms)}`)
        let mutation = delete_merge
        let result = mutate(['CATEGORY', data.subcategory], mutateCaseNeo4j(mutation, parms))
        mutation = delete_mutation
        result = mutate(['CATEGORY', data.subcategory], mutateCaseNeo4j(mutation, parms))
        return result
    }
    
    let mutation = update_mutation_with_drfa
    
    if (!data.drfaCategory) {
        mutation = update_mutation_without_drfa
    }

    console.log(`mutating`)
    console.log(mutation)
    console.log(`${JSON.stringify(parms)}`)

    
    const result = mutate(['CATEGORY', data.subcategory], mutateCaseNeo4j(mutation, parms))
    
    // if (action==MUTATE_DELETE){
    // console.log(`in mutateCase: result=${JSON.stringify(result)}`)
    // }
    return result
}

export default async function mutateCategoryList(currentUserProfile, data) {
    data.forEach(async element => {
        let action = MUTATE_UPDATE
        if(element.action == 'DELETE'){
            action = MUTATE_DELETE
        }
        await mutateCategory(currentUserProfile,element,action)
    });
}