import React, { useState, useRef, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { Button, Paper, IconButton } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

//import Dropzone from 'react-dropzone'
import AWS from 'aws-sdk';
//import { v4 as uuidv4 } from 'uuid'; // Importing uuid to generate unique file names
import amplifyConfig from '../../amplifyconfiguration.json'
import { fetchAuthSession } from 'aws-amplify/auth';
import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
//import { createHumanLoopTask } from '../../api/swrhloopcall';
//import { pdfjs } from 'react-pdf';
//import 'react-pdf/dist/Page/TextLayer.css';
//import 'react-pdf/dist/Page/AnnotationLayer.css';
import CloseIcon from '@mui/icons-material/Close';

import Draggable from "react-draggable";
import { Resizable } from "react-resizable";
import "react-resizable/css/styles.css";

import { Worker } from '@react-pdf-viewer/core';
import { Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
//import ViewStatementPDF from "../ui/view-statement-pdf";
import PDFReviewer from './review-jump-page-pdf.tsx';

//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;







function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}



export default function ViewStatementDialog(props) {

  const [url, setUrl] = useState(null);
  //const [numPages, setNumPages] = useState(0);
  //const [pageNumber, setPageNumber] = useState(1);
  const [height, setHeight] = React.useState(750);
  const [width, setWidth] = React.useState(900);
  

  const parentOnSubmit = props.onSubmit ? props.onSubmit : () => { }
  const parentOnCancel = props.onCancel ? props.onCancel : () => { }
  
  

  const fileKey = props.fileKey

  
  const getFileUrl = async () => {

    try {
      const { credentials } = await fetchAuthSession();
      const S3_BUCKET = 'divisibly-statements';
      const region = amplifyConfig.aws_project_region
      //const ACCESS_KEY_ID = AWS.config.credentials.accessKeyId;
      //const SECRET_ACCESS_KEY = AWS.config.credentials.secretAccessKey;

      const client = new CognitoIdentityProviderClient({
        region: region,
        credentials: credentials
      });

      AWS.config.update(
        {
          region: region,
          credentials: credentials
        }
      );

      const s3 = new AWS.S3({
        apiVersion: "2006-03-01"
      });


      //const fileName = uuidv4() + '-' + file.name; // Generating unique file names

      //console.log(`file.type=${file.type}`)

      const params = {
        Bucket: S3_BUCKET,
        Key: fileKey,
      };

      const signedUrl = await s3.getSignedUrlPromise('getObject', params);
      setUrl(signedUrl);

    } catch (error) {
      console.error('Error reading file:');
      console.error(error)
    }
  };

  //if(!props.form){ throw new Error('you must pass a form in the "form" property')}


  //console.log(`allFilesUploaded=${allFilesUploaded}`)

  const handleCancel = () => {
    parentOnCancel()
  };


  if (fileKey != null) {
    getFileUrl()
  }

  if (fileKey == null || url == null) {
    return (
      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title ? props.title : "View Statement"}

        </DialogTitle>
        <DialogContent>
        </DialogContent>
      </Dialog>
    )
  }


  //const areas=ocr_boxes.map( (ocr_box) => getHighlightArea(ocr_box))


  //console.log(`ocr_boxes=${ocr_boxes}`)

  return (
    <React.Fragment>
      <Resizable
        height={height}
        width={width}
        onResize={(event, data) => {
          console.log('resizing....')
          setHeight(height + event.movementY);
          setWidth(width + event.movementX);
        }}
      >
        <Dialog open={props.open} onClose={handleCancel} maxWidth="xl" PaperComponent={PaperComponent} 
          aria-labelledby="draggable-dialog-title">


          <DialogTitle style={{ cursor: "move" }}>{props.title ? props.title : "View Statement"}
            <IconButton aria-label="close" onClick={handleCancel} sx={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <DialogContent style={{
            //height: '750px',
            //width: '900px',
            height: `${height}px`,
            width: `${width}px`,
            marginLeft: 'auto',
            marginRight: 'auto',
          }} >


          <PDFReviewer fileUrl={url} />


          </DialogContent>

        </Dialog>

      </Resizable>
    </React.Fragment>
  );
}