import { mutate } from "swr";
import { mutatePrimaryNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";


export default function mutateCaseAssignment(orgID, caseID, userIDs, action=MUTATE_UPDATE) {
    
    
    const update_mutation = 'match (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(c:Case{caseID: $caseID}) with o,c UNWIND $userIDs as uid match (o)<-[r:BELONGS_TO]-(u:User {userID: uid}) WITH datetime() AS dt,o,c,u MERGE (c)<-[r:BELONGS_TO]-(u) SET r.lastDate=dt return c,r,u'
    const delete_mutation = 'match (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(c:Case{caseID: $caseID}) with o,c MATCH (c)<-[r]-(u:User) DELETE r'
    const parms = { "orgID": orgID, "caseID": caseID, "userIDs": userIDs }


    

    let mutation = action===MUTATE_DELETE?delete_mutation:update_mutation
    
    console.log(`in mutateCaseAssignment: mutation=${mutation}`)
    console.log(`in mutateCaseAssignment: parms=${JSON.stringify(parms)}`)

    const result = mutate(['CASEASSIGN', caseID], mutatePrimaryNeo4j(mutation, parms))
    
    console.log(`in mutateCaseAssignment: result=${JSON.stringify(result)}`)
    return result
}