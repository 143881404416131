import React from "react";
import PortalFrame from '../ui/portal-frame';

//import BasicOrgList from "../ui/list-org-basic.js";
import OrgProfileForm from '../ui/form-org-profile.js';
import mutateOrg from "../../api/mutateorg.js";
import { listOrgQuery } from "../../api/mutateorg.js";
import EditList from "../ui/list-edit.js";
import OrgEditList from "../ui/list-edit-org.js";
import useAuthCurrentUser from "../../api/swrauthcurrentuser.js";
import PageSkeleton from "../ui/pageskeleton.js";

export default function ManageOrgPage() {

  const title="Manage Organizations"
  const currentUser=useAuthCurrentUser()

  if (currentUser.isLoading){
    return (
      <PortalFrame title={title}>
        <PageSkeleton/>
      </PortalFrame>
    )
  }
 
  const currentUserID=currentUser.data.sub

  return (
    <PortalFrame title={title}>
    <EditList listQuery={listOrgQuery}  editForm={OrgProfileForm} objectLabel="Organization" editList={OrgEditList} mutateHandler={mutateOrg} currentUserID={currentUserID}/>
    </PortalFrame>
  );
};