import { useContext } from 'react';
import { queryCaseNeo4j } from './pydbcall';
import useSWR from 'swr'
import { dateGetter } from '../components/ui/type-utilities';




function postProcessResult(records) {

  return records.map(record => ({
    flag: record.flag.name
    

  })
  )

}

async function getFlagsForCurrentCase(orgID, caseID) {
  let neoResult


  //const defaultLabel=includeDefault?"":":DefinedCategory"
  const qry = `MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(flag:Flag) OPTIONAL MATCH (flag)<-[]-(t:Transaction) RETURN flag, count(t) as usage_count`


  const parameters = { orgID: orgID, caseID: caseID }

  await queryCaseNeo4j(qry, parameters).then((result) => {
    //  console.log(`neo result=${JSON.stringify(result)}`)
    neoResult = postProcessResult(result.records)
    //console.log(`processed result=${JSON.stringify(neoResult)}`)

  })

  return neoResult
}

export function useFlagsForCurrentCase(orgID, caseID, shouldFetch) {

  const result = useSWR(shouldFetch ? ['FLAGS_FOR_CURRENT_CASE', orgID, caseID] : null, ([path, orgID, caseID]) => getFlagsForCurrentCase(orgID, caseID));
  return result
}
