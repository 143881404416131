//import { useContext } from 'react';
import { queryCaseNeo4j } from './pydbcall';
import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import useSWR from 'swr'
import useSWRMutation from 'swr/mutation'
import useSWRInfinite from 'swr/infinite'
import { dateGetter } from '../components/ui/type-utilities';
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";
import { post } from 'aws-amplify/api';
import { lazy_decompress } from './apiutils';


function postProcessResultV2(records) {
  //console.log("postProcessResultV2: records=", JSON.stringify(records))
  return records.map(transaction => (
    {
      ...transaction,
      txn_date: dateGetter(transaction.txn_date),
      original_txn_date: transaction.original_txn_date ? dateGetter(transaction.original_txn_date) : null,
    }
  ));
}
function postProcessResult(records) {
  return records.map(transaction => (
    {
      ...transaction.txn,
      txn_date: dateGetter(transaction.txn.txn_date),
      id: transaction.txn.pk,
      cat_type: transaction.cat_type,
      subcategory: transaction.subcat?.name ? transaction.subcat?.name : "UNKNOWN",
      category: transaction.cat?.name ? transaction.cat?.name : "UNKNOWN",
      flag_type: transaction.flag_type,
      flag: transaction.flag?.name,
      orgID: transaction.o.orgID,
      caseID: transaction.c.caseID,
      bank_name: transaction.ba.bank_name,
      account_number: transaction.ba.account_number,
      original_txn_date: transaction.origtxn ? dateGetter(transaction.origtxn.txn_date) : null,
      original_description: transaction.origtxn?.description,
      original_amount: transaction.origtxn?.amount,
      cat_basis: transaction.cat_basis,
      flag_basis: transaction.flag_basis,
      transfer_candidates: transaction.transfer_candidates,
      //transfer_candidates: transaction.transfer_candidates?postProcessResult(transaction.transfer_candidates):[],
      has_transfer_candidates: transaction.transfer_candidates?.length > 0,
      //days_diff: transaction.days_diff
    }
  ));


}
export async function getTotalTransactionCount(orgID, caseID) {
  const qry = `
    MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount)-[:HAS]->(bs:BankStatement)-[rbst:CONTAINS]->(txn:Transaction {})-[:ORIGINAL]->(origtxn:ScannedTransaction) \
    RETURN count(txn) as totalRecords
  `;

  const parameters = { orgID, caseID };

  const result = await queryCaseNeo4j(qry, parameters);
  console.log('Result:', result);
  console.log('Records:', result.records);

  if (result.records.length > 0) {
    const totalRecords = result.records[0].totalRecords;
    return totalRecords;
  } else {
    return 0;
  }
}



export async function getTransactionsForCurrentCase(orgID, caseID, skip = 0, limit = 4000) {
  const transfer_days_threshold = 5;

  const qry = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount)-[:HAS]->(bs:BankStatement)-[rbst:CONTAINS]->(txn:Transaction {})-[:ORIGINAL]->(origtxn:ScannedTransaction) \
  OPTIONAL MATCH (txn)-[rsc]->(sc:Subcategory) \
  OPTIONAL MATCH (sc)-[]->(cat:Category) \
  OPTIONAL MATCH (txn)-[rf]->(f:Flag) \
  CALL { \
    WITH txn \
    OPTIONAL MATCH (txn)-[rt:TRANSFER]->(txn2)<-[:CONTAINS]-(:BankStatement)<-[:HAS]-(ba2:BankAccount) return rt, ba2, txn2, 'OUT' as transfer_direction \
  UNION ALL \
    WITH txn \
    OPTIONAL MATCH (txn)<-[rt:TRANSFER]-(txn2)<-[:CONTAINS]-(:BankStatement)<-[:HAS]-(ba2:BankAccount) return rt, ba2, txn2, 'IN' as transfer_direction \
  } \
  WITH o, c, ba, bs, txn, origtxn, rsc, sc, rf, f, cat, ba2, txn2, rt, transfer_direction, \
  EXISTS { MATCH (txn2)-[rt2:TRANSFER]-(txn3:Transaction) WHERE txn3.pk<>txn.pk AND (rt2.disposition='PROPOSED' or rt2.disposition='CONFIRMED')} as txn2_other_match, \
  EXISTS { MATCH (txn2)-[rt2:TRANSFER]-(txn3:Transaction) WHERE txn3.pk<>txn.pk AND (rt2.type='PROPOSED' or rt2.type='POSSIBLE')} as txn2_other_possible \
  RETURN o, c, ba, txn, origtxn, type(rsc) AS cat_type, properties(rsc) AS cat_basis, sc AS subcat, cat AS cat, type(rf) AS flag_type, properties(rf) AS flag_basis, f AS flag, \
        COALESCE(COLLECT(DISTINCT CASE WHEN ba2 IS NOT NULL THEN { txn_pk:txn2.pk \
        , transfer_props: properties(rt), transfer_direction: transfer_direction, target_has_other_match: txn2_other_match, target_has_other_possible: txn2_other_possible \
  } END), []) AS transfer_candidates \
  ORDER BY txn.pk ASC \
  SKIP $skip \
  LIMIT $limit \
  "

  const parameters = { orgID, caseID, skip, limit };

  const result = await queryCaseNeo4j(qry, parameters);
  const neoResult = postProcessResult(result.records);

  return neoResult;
}

export async function getTransactionsForCurrentCaseV2(orgID, caseID, pageIndex=0, pageSize=3000){

  const reqbody={
    orgID: orgID,
    caseID: caseID,
    page: pageIndex,
    pageSize: pageSize,
  }

  const payload={
    apiName: 'casetxns',
    path: '/casetxns',
    options: {
      body: reqbody
    }
  }

  //console.log("POSTING TO API: ", payload)
  const restOperation = post(payload); 

  
  //const { body, headers } = await restOperation.response;
  const response = await restOperation.response;
  const raw_result = await lazy_decompress(response)
  const result = postProcessResultV2(raw_result)
  //console.log("RESULT: ", result)
  return result

  // const isCompressed = headers['Content-Encoding'] === 'gzip' || headers['X-Compressed'] === 'true';

  // //console.log("RESPONSE: ", body)
  // if(!isCompressed){
  //   //console.log(`requestID: ${requestID} response was not compressed`)

  //   let json = await body.json();
  //   //console.log(`response body: ${JSON.stringify(json)}`)
  
  //   json = JSON.parseWithDate(JSON.stringify(json));

  //   return json
  // }


}



export async function forceRefreshTransactionsForCurrentCase(orgID, caseID) {
  console.log(`forcing revalidate....`)
  mutate(['TRANSACTIONS_FOR_CURRENT_CASE', orgID, caseID]
    //, undefined,   // update cache data to `undefined`
    //{ revalidate: true } // do not revalidate
  )
}

export function useTransactionsForCurrentCase(orgID, caseID, shouldFetch = true) {
  const result = useSWR(
    shouldFetch ? ['TRANSACTIONS_FOR_CURRENT_CASE', orgID, caseID] : null,
    ([path, orgID, caseID]) => getTransactionsForCurrentCaseV2(orgID, caseID,0,0)
  );
  return result;
}

export function useInfiniteTransactionsForCurrentCase(orgID, caseID, shouldFetch = true) {
  const getKey = (pageIndex, previousPageData) => {
    if (!shouldFetch) return null; // don't fetch if shouldFetch is false
    if (previousPageData && !previousPageData.length) return null; // reached the end
    return ['TRANSACTIONS_FOR_CURRENT_CASE', orgID, caseID, pageIndex]
  }

  const result = useSWRInfinite(getKey, ([path, orgID, caseID, pageIndex]) => getTransactionsForCurrentCaseV2(orgID, caseID, pageIndex), { parallel: true, initialSize: 5 });
  return result;
}


export function useMutateTransactionForCurrentCase(orgID, caseID) {

  const result = useSWRMutation(['TRANSACTIONS_FOR_CURRENT_CASE', orgID, caseID], mutateTransactionForCurrentCase)
  return result
}
export async function mutateTransactionForCurrentCase(url, args) {
  const currentUserProfile = args?.arg?.userProfile
  const transaction = args?.arg?.transaction
  const changedFields = args?.arg?.changedFields

  /// sanity check
  if (!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser) {
    throw Error(`pass a valid current user profile to this call`)
  }

  const orgID = currentUserProfile.currentOrg.orgID
  const caseID = currentUserProfile.currentCase.caseID
  const email = currentUserProfile.currentUser.email

  /// more sanity checks

  if (transaction.orgID && transaction.orgID !== orgID) {
    throw Error(`transaction org is different from the current user org, aborting`)
  }
  if (transaction.caseID && transaction.caseID !== caseID) {
    throw Error(`transaction case is different from the current user case, aborting`)
  }


  function anyChangedFields(fields) {
    return fields.some(field => field in changedFields)
  }

  function isTransactionUpdated() {
    const transactionFields = ["_verified", "amount", "txn_date", "description"]
    return anyChangedFields(transactionFields)
  }

  function isSubcategoryUpdated() {
    const subcategoryFields = ["cat_type", "subcategory", "category"]
    return anyChangedFields(subcategoryFields)
  }

  function isCategoryTypeUpdated() {
    const fields = ["cat_type"]
    return anyChangedFields(fields)
  }

  function isFlagUpdated() {
    const flagFields = ["flag_type", "flag"]
    return anyChangedFields(flagFields)
  }

  function isFlagTypeUpdated() {
    const fields = ["flag_type"]
    return anyChangedFields(fields)
  }

  if (isTransactionUpdated()) {
    console.log('mutating transaction details...')
    const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$period_pk})-[:CONTAINS]->(t:Transaction {pk:$pk}) MERGE (t)-[r:AUDIT]->(th:TransactionHistory {_auditDate:dt}) set th=properties(t), th._auditDate=dt, th._auditUser=$currentUser, r._auditDate=dt, r._auditUser=$currentUser, t._updateDate=dt, t._updateUser=$currentUser, t._verified=$_verified, t.amount=$amount, t.txn_date=date(datetime($txn_date)), t.description=$description return t,r,th'
    const parms = {
      "orgID": orgID,
      "caseID": caseID,
      "currentUser": email,
      "bank_account_pk": transaction.bank_account_pk,
      "period_pk": transaction.period_pk,
      "pk": transaction.pk,
      "_verified": transaction._verified,
      "amount": transaction.amount,
      "txn_date": transaction.txn_date,
      "description": transaction.description
    }

    await mutateCaseNeo4j(update_mutation, parms)
  }

  if (isSubcategoryUpdated()) {
    console.log('mutating subcategory details...')
    //console.log(`transaction=${JSON.stringify(transaction)}`)

    let newTypeLabel = ":CONFIRMED"
    let oldTypeLabel = ":PROPOSED"

    if (isCategoryTypeUpdated() && transaction.cat_type === "PROPOSED") {
      // user wants to set category back to proposed
      newTypeLabel = ":PROPOSED"
      oldTypeLabel = ":CONFIRMED"

    }

    const update_mutation = `MATCH (o:Organization {orgID:$orgID})<-[rco:BELONGS_TO]-(c:Case {caseID:$caseID})-[rcba:USES]->(tc:Subcategory {name:$subcategory}) WITH c,tc MATCH (c)-[rcba:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[rbabs:HAS]->(bs:BankStatement {pk:$period_pk})-[rbst:CONTAINS]->(t:Transaction {pk:$pk})  WITH datetime() as dt, t, tc  MERGE (t)-[rtc${newTypeLabel}]->(tc)   ON CREATE SET  rtc._updateDate=dt, rtc._updateUser=$currentUser, rtc._createDate=dt, rtc._createUser=$currentUser ON MATCH SET rtc._updateDate=dt, rtc._updateUser=$currentUser WITH t,rtc OPTIONAL MATCH (t)-[rtc_exist${newTypeLabel}]->(tc2:Subcategory) WHERE tc2.name <> $subcategory DELETE rtc_exist WITH t,rtc OPTIONAL MATCH (t)-[rtp_exist${oldTypeLabel}]->(tc3:Subcategory) DELETE rtp_exist return rtc`
    const parms = {
      "orgID": orgID,
      "caseID": caseID,
      "currentUser": email,
      "bank_account_pk": transaction.bank_account_pk,
      "period_pk": transaction.period_pk,
      "pk": transaction.pk,
      "subcategory": transaction.subcategory

    }

    await mutateCaseNeo4j(update_mutation, parms)
  }

  if (isFlagUpdated()) {
    console.log('mutating flag details...')
    const flag_type_category_match = 'FLAG MATCH'

    let newTypeLabel = ":CONFIRMED"
    let oldTypeLabel = ":PROPOSED"

    if (isFlagTypeUpdated() && transaction.flag_type === "PROPOSED") {
      // user wants to set category back to proposed
      newTypeLabel = ":PROPOSED"
      oldTypeLabel = ":CONFIRMED"

    }
    const delete_mutation = `MATCH (o:Organization {orgID:$orgID})<-[rco:BELONGS_TO]-(c:Case {caseID:$caseID})-[rcba:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[rbabs:HAS]->(bs:BankStatement {pk:$period_pk})-[rbst:CONTAINS]->(t:Transaction {pk:$pk}) WITH c,t OPTIONAL MATCH (t)-[rtc2]->(f:Flag) DELETE rtc2 WITH datetime() as dt,t,c MATCH (c)-[:USES]->(df:DefaultFlag) MERGE (t)-[rtc:CONFIRMED]->(df) ON CREATE SET rtc.type=$flagType, rtc._updateDate=dt, rtc._updateUser=$currentUser, rtc._createDate=dt, rtc._createUser=$currentUser ON MATCH SET rtc.type=$flagType, rtc._updateDate=dt, rtc._updateUser=$currentUser  return t`
    const update_mutation = `MATCH (o:Organization {orgID:$orgID})<-[rco:BELONGS_TO]-(c:Case {caseID:$caseID}) MERGE (c)-[rcba:USES]->(f:Flag {name:$flag}) WITH c,f MATCH (c)-[rcba:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[rbabs:HAS]->(bs:BankStatement {pk:$period_pk})-[rbst:CONTAINS]->(t:Transaction {pk:$pk})  WITH datetime() as dt, t, f  MERGE (t)-[rtc${newTypeLabel}]->(f)   ON CREATE SET rtc.type=$flagType, rtc._updateDate=dt, rtc._updateUser=$currentUser, rtc._createDate=dt, rtc._createUser=$currentUser ON MATCH SET rtc.type=$flagType, rtc._updateDate=dt, rtc._updateUser=$currentUser WITH t,rtc OPTIONAL MATCH (t)-[rtc_exist${newTypeLabel}]->(f2:Flag) WHERE f2.name <> $flag DELETE rtc_exist WITH t,rtc OPTIONAL MATCH (t)-[rtp_exist${oldTypeLabel}]->(f3:Flag) DELETE rtp_exist return rtc`
    const parms = {
      "orgID": orgID,
      "caseID": caseID,
      "currentUser": email,
      "bank_account_pk": transaction.bank_account_pk,
      "period_pk": transaction.period_pk,
      "pk": transaction.pk,
      "flag": transaction.flag,
      "flagType": flag_type_category_match

    }
    console.log(`parms=${JSON.stringify(parms)}`)
    const mutation = transaction.flag ? update_mutation : delete_mutation
    await mutateCaseNeo4j(mutation, parms)

  }




  const result = null
  //const result = getTransactionsForCurrentCase(orgID, caseID)

  return result




}
