import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";


export default async function mutateBankAccount(currentUserProfile, data, action=MUTATE_UPDATE) {
    

    if (action != MUTATE_UPDATE){
        throw Error(`unsupported action: ${action}`)
    }

    
    if(!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser){
        throw Error(`pass a valid current user profile to this call`)
    }

    const orgID=currentUserProfile.currentOrg.orgID
    const caseID=currentUserProfile.currentCase.caseID
    const email=currentUserProfile.currentUser.email


    const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$pk}) MERGE (ba)-[r:AUDIT]->(baa:BankAccountHistory {_auditDate:dt}) set baa=properties(ba), baa._auditDate=dt, baa._auditUser=$currentUser, r._auditDate=dt, r._auditUser=$currentUser, ba.holder_state=$holder_state, ba.bank_name=$bank_name, ba.account_number=$account_number, ba._updateDate=dt, ba._updateUser=$currentUser, ba.holder_country=$holder_country, ba.holder_address_2=$holder_address_2, ba.holder_zip=$holder_zip, ba.holder_address_1=$holder_address_1, ba.account_holder=$account_holder, ba._verified=$_verified, ba.account_category=$account_category, ba.name=$name, ba.holder_city=$holder_city, ba.account_type=$account_type return ba,r,baa'
    const parms = { 
        "orgID": orgID,
        "caseID": caseID, 
        "currentUser": email,
        "pk": data.pk, 
        "name": data.name, 
        "bank_name": data.bank_name,
        "account_type": data.account_type,
        "account_holder": data.account_holder,
        "account_number": data.account_number,
        "holder_zip": data.holder_zip,
        "holder_country": data.holder_country,
        "holder_state": data.holder_state,
        "holder_city": data.holder_city,
        "holder_address_1": data.holder_address_1,
        "holder_address_2": data.holder_address_2,
        "account_category": data.account_category,
        "_verified": data._verified
    }

   

    
    let mutation = update_mutation
    
    const result = mutate(['BANKACCOUNT', data.pk], mutateCaseNeo4j(mutation, parms))
    
    //console.log(`in mutateCase: result=${JSON.stringify(result)}`)
    return result
}