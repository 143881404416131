import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from './components/pages/page-dashboard';
import AnalysisPage from "./components/pages/page-analysis";
import ManageOrgPage from "./components/pages/page-manage-org";
import ManageCasePage from "./components/pages/page-manage-case";
import ManageUserPage from "./components/pages/page-manage-user";
import EmergencyDump from "./components/pages/page-emergency-dump";
import ManageCaseAssignmentPage from "./components/pages/page-manage-case-assignment";
import ManageUploadedFiles from "./components/pages/page-manage-uploaded-documents";
import ReviewStatementTransactions from "./components/pages/page-review-statement-transactions";
import ReviewStatementCategorization from "./components/pages/page-review-categorization";
import ReviewBankAccounts from "./components/pages/page-review-bank-accounts";
import ReviewStatements from "./components/pages/page-review-statements";
import BulkLoadCategories from "./components/pages/page-category-bulk-load";
import ViewCategories from "./components/pages/page-view-categories";
import ManageCategoryRules from "./components/pages/page-manage-category-rules";
import Test4 from "./components/pages/test-page4";
import Test3 from "./components/pages/test-page3";
import TestPage from "./components/pages/test-page";
//import DRFAReportPage from "./components/pages/old/page-report-drfa";
import DRFAReportEditPage from "./components/pages/page-report-drfa-grid";
import StatementQualityReportPage from "./components/pages/page-report-statement-quality";
import ManageFlagRules from "./components/pages/page-manage-flag-rules";
import ManageReportListPage from "./components/pages/page-manage-report-list";
import Test2 from "./components/pages/test-page2";
import SampleDRFAReportPage from "./components/pages/page-report-drfa-sample";
import UnmatchedTransfersReportPage from "./components/pages/page-report-unmatched-transfers";
import TotalByYearReportPage from "./components/pages/page-report-total-by-year";
import FlaggedTransactionsReportPage from "./components/pages/page-report-flagged-transactions";
import CaseActionsPage from "./components/pages/page-case-actions";

function AppRoutes() {
    return (

        <BrowserRouter basename="/">

            <Switch>
                <Route exact path="/">
                    <Dashboard />
                </Route>
                <Route path="/analysis/:analysis_id">
                    <AnalysisPage />
                </Route>
                <Route path="/manage_org">
                    <ManageOrgPage />
                </Route>
                <Route path="/manage_case">
                    <ManageCasePage />
                </Route>
                <Route path="/manage_user">
                    <ManageUserPage />
                </Route>
                <Route path="/manage_case_assignment">
                    <ManageCaseAssignmentPage />
                </Route>
                <Route path="/manage_uploaded_files">
                    <ManageUploadedFiles />
                </Route>
                <Route path="/review_transactions">
                    <ReviewStatementTransactions />
                </Route>
                <Route path="/review_bank_accounts">
                    <ReviewBankAccounts />
                </Route>
                <Route path="/review_bank_statements">
                    <ReviewStatements />
                </Route>
                <Route path="/review_statement_quality">
                    <StatementQualityReportPage />
                </Route>
                <Route path="/bulk_load_categories">
                    <BulkLoadCategories />
                </Route>
                <Route path="/view_categories">
                    <ViewCategories />
                </Route>
                <Route path="/manage_category_rules">
                    <ManageCategoryRules />
                </Route>
                <Route path="/manage_flag_rules">
                    <ManageFlagRules />
                </Route>
                <Route path="/review_transaction_categorization">
                    <ReviewStatementCategorization />
                </Route>

                {/*
                <Route path="/drfa_report">
                    <DRFAReportPage />
                </Route>
                <Route path="/drfa_report_edit">
                    <DRFAReportEditPage />
                </Route>*/}

                <Route path="/manage_report_list">
                    <ManageReportListPage />
                </Route>

                <Route path="/report/DRFA_REPORT/:reportID">
                    <DRFAReportEditPage />
                </Route>

                <Route path="/report/SAMPLE_DRFA_REPORT/:reportID">
                    <SampleDRFAReportPage />
                </Route>

                <Route path="/report/UNMATCHED_TRANSFERS/:reportID">
                    <UnmatchedTransfersReportPage />
                </Route>

                <Route path="/report/SPEND_BY_YEAR/:reportID">
                    <TotalByYearReportPage />
                </Route>

                <Route path="/report/FLAGGED_TRANSACTIONS/:reportID">
                    <FlaggedTransactionsReportPage />
                </Route>

                <Route path="/emergency_dump">
                    <EmergencyDump />
                </Route>

                <Route path="/case_actions">
                    <CaseActionsPage />
                </Route>

                <Route path="/test4">
                    <Test4 />
                </Route>

                <Route path="/test3">
                    <Test3 />
                </Route>

                <Route path="/test2">
                    <Test2 />
                </Route>

                <Route path="/test1">
                    <TestPage />
                </Route>

            </Switch>
        </BrowserRouter>
    );
}

export default AppRoutes;