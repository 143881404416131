import * as React from 'react';
import { useContext, useMemo, useState, useEffect, Fragment } from 'react';
import UserProfileContext from './userprofilecontext.js';
import usePDFForTransaction from './pdf-ref-hook.js';
import PDFBottomPanel from './pdf-bottom-panel.js';
import TransactionPreviewGrid from './grid-review_transaction_details.js';




export default function TransferTransactionPDFBottomPanel(props) {

    //console.log(`rendering TransferTransactionPDFBottomPanel`)

    const selectedTransaction = props.transaction ? props.transaction : []

    //console.log(`transaction=${JSON.stringify(selectedTransaction)}`)
    const parentProcessRowUpdate = props.processRowUpdate ? props.processRowUpdate : () => { }

    const userProfileContext = useContext(UserProfileContext);
    const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg.orgID
    const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase.caseID

    const { currentTransaction, setCurrentTransaction: setTransaction, pdfUrl: transactionPdfUrl } = usePDFForTransaction(orgID, caseID)

    useEffect(
        () => setTransaction(selectedTransaction)
        , [selectedTransaction]
    )

    let selectedTransactions = []
    selectedTransactions.push(currentTransaction)


    const processRowUpdate = async (newRow, oldRow) => {

        const updatedRow = await parentProcessRowUpdate(newRow, oldRow)

        setTransaction(updatedRow)   // this is needed to keep the UI in sync until data refreshes from parent
        return updatedRow
    }




    return (
        <Fragment>
            {transactionPdfUrl &&
                <PDFBottomPanel topChild={<TransactionPreviewGrid transactions={selectedTransactions} processRowUpdate={processRowUpdate} />} highlightedTxn={currentTransaction} fileUrl={transactionPdfUrl} />
            }
        </Fragment>
    )
}

