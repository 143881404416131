import * as React from 'react';
import { useContext, useState, useEffect, Fragment } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Stepper, Step, StepLabel } from '@mui/material';
import Alert from '@mui/material/Alert';
import { Typography } from '@mui/material';
import * as XLSX from 'xlsx';
import { useDropzone } from 'react-dropzone';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FlagRuleDataPreview from './flag-rule-data-preview-panel.js';
import { mutateFlagRuleList } from '../../api/mutateflagrule.js';
import ExportToExcel from './export-to-excel.js';

import SnackbarContext from './snackbar-context.js';
import UserProfileContext from './userprofilecontext.js';
import { removeNewlines } from './type-utilities.js';

export default function FlagRuleBulkLoadDialog(props) {

  const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });

  const parentOnSubmit = props.onSubmit ? props.onSubmit : (data) => { }
  const parentOnCancel = props.onCancel ? props.onCancel : () => { }
  const caseFlagRules = props.caseFlagRules ? props.caseFlagRules : []
  const caseFlags = props.caseFlags ? props.caseFlags : []
  //const currentUserProfile = props.currentUserProfile
  

  const step_index_select_flag_file = 0
  const step_index_select_worksheet = 1
  const step_index_select_phrase_column = 2
  const step_index_select_flag_column = 3
  const step_index_data_quality_check = 4
  const step_index_change_review = 5


  const steps = ['Select File', 'Select Worksheet', 'Select Phrase Column', 'Select Flag Column', 'Data Quality Check', 'Review Changes'];


  //const alert_severity_success = 'success'
  const alert_severity_info = 'info'
  const alert_severity_warning = 'warning'
  //const alert_severity_error = 'error'


  const userProfileContext = useContext(UserProfileContext);

  const [activeStep, setActiveStep] = useState(0);
  const [skippedStep, setSkippedStep] = useState(new Set());

  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [snackbarAlertMessage, setSnackbarAlertMessage] = useState('Wow Something Happened')
  // const [snackbarAlertSeverity, setSnackbarAlertSeverity] = useState(alert_severity_success)
  // const [snackbarAction, setSnackbarAction] = useState()

  const [file, setFile] = useState()
  const [sheetNames, setSheetNames] = useState([])
  const [sheetName, setSheetName] = useState('')
  const [columnNames, setColumnNames] = useState([])
  const [columnNames2, setColumnNames2] = useState([])
  const [columnNames3, setColumnNames3] = useState([])
  //const [columnNames4, setColumnNames4] = useState([])

  const [rawData, setRawData] = useState();

  const [phraseColumn, setPhraseColumn] = useState(null)
  //const [subcategoryColumn, setSubcategoryColumn] = useState(null)
  const [flagColumn, setFlagColumn] = useState(null)
  const [mappedFlagRuleData, setmappedFlagRuleData] = useState([]);

  const [passData, setPassData] = useState([]);
  const [failData, setFailData] = useState([]);

  //const [caseCategoryRules, setCaseCategoryRules]=useState([])


  const [updates, setUpdates] = useState([])
  // const [categoryColumn, setCategoryColumn] = useState('')
  // const [drfaColumn, setDrfaColumn] = useState('')
  // const [incomeExpenseColumn, setIncomeExpenseColumn] = useState('')

  const snackbarContext=useContext(SnackbarContext);







  const handleBulkLoadCancel = () => {
    setPhraseColumn(null)
    //setSubcategoryColumn(null)
    setFlagColumn(null)
    handleReset()
    //setOpenSnackbar(false)

    parentOnCancel()
  };

  




  

  useEffect(() => {


    const createDict = (records) => {
      //console.log(`createDict: records=${JSON.stringify(records)}`)
      const dictionary = records.reduce((acc, item) => {
        //console.log(`item=${JSON.stringify(item)}`)
        acc[item.phrase.toLowerCase()] = item
        return acc
      }, {}

      )
      return dictionary
    }

    //let adds = []
    let updates = []
    //let deletes = []

    const deleteDict = createDict(passData)
    const caseFlagRuleDict = createDict(caseFlagRules)


    passData.map(record => {
      // need to process deletes
      const match = caseFlagRuleDict[record.phrase.toLowerCase()]
      if (match) {
        //console.log(`Matched Input Data: ${JSON.stringify(record)}`)
        //console.log(`Matched DB Data: ${JSON.stringify(match)}`)
        record.phrase=match.phrase // force to use existing phrase in case of different string case (upper/lower)
        const exact_match = ((record.flag ? record.flag : '') == (match.flag ? match.flag : ''))
        const updateRecord = { ...record, proposed_count: match.proposed_count, confirmed_count: match.confirmed_count, original_flag: match.flag }
        updates.push({ ...updateRecord, action: exact_match ? 'NO CHANGE' : 'UPDATE' })
      } else {
        updates.push({ ...record, action: 'ADD' })
      }
    })

    // skip deletes

    // caseCategoryRules.map( record => {
    //     const match=deleteDict[record.phrase]
    //     if(!match){
    //         updates.push({...record, action:'DELETE'})
    //     }

    // })

    //setAdds(adds)
    setUpdates(updates)
    //setDeletes(deletes)

  }, [caseFlagRules, passData]
  )





  /////////////////////////////////////////
  /// handling stepper
  const isStepOptional = (step) => {
    //return step === 1;
    return false
  };

  const isStepSkipped = (step) => {
    return skippedStep.has(step);
  };

  const handleNext = () => {
    let newSkipped = skippedStep;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkippedStep(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkippedStep((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  /////////////////////////////////////

  


  /////////////////////////////////////////
  /// handling file drop zone

  function getExcelSheetNames(file) {
    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      const sheetNames = workbook.SheetNames;
      setSheetNames(sheetNames);
      setSheetName(sheetNames[0])
    };

    reader.readAsArrayBuffer(file);
  }



  const loadFile = () => {

    // function stripHtmlTags(str) {
    //     const tempDiv = document.createElement('div');
    //     tempDiv.innerHTML = str;
    //     return tempDiv.textContent || tempDiv.innerText || '';
    // }


    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });

      //const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      if (!worksheet) {
        // setSnackbarAlertMessage(`Sheet with name "${sheetName}" not found.`)
        // setSnackbarAlertSeverity(alert_severity_error)
        // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError(`Sheet with name "${sheetName}" not found.`)

        return;
      }


      let json = XLSX.utils.sheet_to_json(worksheet);
      // json = json.map(row => {
      //     return Object.fromEntries(
      //         Object.entries(row).map(([key, value]) => [key, typeof value === 'string' ? stripHtmlTags(value) : value])
      //     );
      // });

      // add a unique ID to each row 
      json = json.map((row,index) => ({...row, _id:index + 2 }) )
      //console.log(`raw data: ${JSON.stringify(json)}`)
      
      setRawData(json);

      // Get the range of the worksheet
      const range = XLSX.utils.decode_range(worksheet['!ref']);

      // Get the first row (header row)
      const headers = [];
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
        const cell = worksheet[cellAddress];
        const header = cell ? XLSX.utils.format_cell(cell) : `UNKNOWN ${C}`;
        headers.push(header);
      }

      setColumnNames(headers)
      if (headers.length < 3) {
        // setSnackbarAlertMessage(`Sheet with name "${sheetName}" has less than 3 columns.`)
        // setSnackbarAlertSeverity(alert_severity_error)
        // handleSnackbarOpen(true)
        snackbarContext.openSnackbarError(`Sheet with name "${sheetName}" has less than 3 columns.`)
        return;
      }

      setPhraseColumn(null)
      setFlagColumn(null)

      
    };

    reader.readAsArrayBuffer(file);
  };


  const onDropRejected = (fileRejections) => {

    // setSnackbarAlertMessage("Please select one Excel file")
    // setSnackbarAlertSeverity(alert_severity_error)
    // handleSnackbarOpen(true)
    snackbarContext.openSnackbarError("Please select one Excel file")

  }

  const onDropAccepted = (files) => {

    const file = files[0]
    const fileName = file.name

    // setSnackbarAlertMessage(`found file ${fileName}`)
    // setSnackbarAlertSeverity(alert_severity_success)
    // handleSnackbarOpen(true)
    snackbarContext.openSnackbarSuccess(`found file ${fileName}`)
    setFile(file)
    getExcelSheetNames(file)

    // advance the step
    handleNext()

  }

  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
    },
    onDropRejected: onDropRejected,
    onDropAccepted: onDropAccepted
  });


  /////////////////////////////////////////
  /// inside Excel file
  const handleSelectSheet = () => {
    if (!sheetName) {
      // setSnackbarAlertMessage("Please select the data worksheet")
      // setSnackbarAlertSeverity(alert_severity_error)
      // handleSnackbarOpen(true)
    snackbarContext.openSnackbarError("Please select the data worksheet")  
    }
    else {
      loadFile()
      handleNext()
    }
  }

  const handleSelectPhraseColumn = () => {
    if (!phraseColumn || !columnNames.includes(phraseColumn)) {
      // setSnackbarAlertMessage("Please select a valid phrase column")
      // setSnackbarAlertSeverity(alert_severity_error)
      // handleSnackbarOpen(true)
    snackbarContext.openSnackbarError("Please select a valid phrase column")  
    }
    else {
      // remove the selected column from the list
      const remainingColumnNames = columnNames.filter(column => column !== phraseColumn);
      setColumnNames2(remainingColumnNames)
      handleNext()
    }
  }

  // const handleSelectSubcategoryColumn = () => {
  //   if (!subcategoryColumn || !columnNames2.includes(subcategoryColumn)) {
  //     // setSnackbarAlertMessage("Please select a valid subcategory column")
  //     // setSnackbarAlertSeverity(alert_severity_error)
  //     // handleSnackbarOpen(true)
  //   snackbarContext.openSnackbarError("Please select a valid subcategory column")  
  //   }
  //   else {
  //     // remove the selected column from the list
  //     const remainingColumnNames = columnNames2.filter(column => column !== subcategoryColumn);
  //     setColumnNames3(remainingColumnNames)
  //     handleNext()
  //   }
  // }
  const handleSelectFlagColumn = () => {
    if (!flagColumn || !columnNames2.includes(flagColumn)) {
      // setSnackbarAlertMessage("Please select a valid Flag column")
      // setSnackbarAlertSeverity(alert_severity_error)
      // handleSnackbarOpen(true)
    snackbarContext.openSnackbarError("Please select a valid Flag column")  
    }
    else {

      // const remainingColumnNames = columnNames3.filter(column => column !== flagColumn);
      // setColumnNames4(remainingColumnNames)

      mapFlagRuleData()
      handleNext()
    }
  }

  const mapFlagRuleData = () => {
    const mappedData = rawData.map(record => ({ _id:record._id, phrase: removeNewlines(record[phraseColumn])?.trim(), flag: removeNewlines(record[flagColumn])?.trim() }))
    setmappedFlagRuleData(mappedData)
    checkDataQuality(mappedData)
  }

  const checkDataQuality = (mappedData) => {
    const passData = []
    const failData = []

    //console.log(`checking data quality....`)

    //create a dictionary for known flags, to have a fast way to check if a flag is in the valid list
    // const categoryDict = {};
    // caseFlags.forEach(item => {
    //   categoryDict[item.subcategory] = item;
    // });

    
    const dqRules = (record) => {
      const formatPassResult = () => {
        return { pass: true }
      }
      const formatFailResult = (message) => {
        return { pass: false, reason: message }
      }

      // function isValidSubcategory(record,fieldName)  {
      //   // field must be present and the value of the field must be in the category dict
      //   return fieldName in record && categoryDict[record[fieldName]]
      // }

      function isFieldPresentAndHasValue(record, fieldName) {
        //console.log(`inside isFieldPresentAndHasValue(${JSON.stringify(record)}, ${fieldName})`)
        return fieldName in record && record[fieldName] !== null && record[fieldName] !== undefined && record[fieldName] !== '';
        //console.log(`result=${result}`)

      }

      function checkRequiredValidValues(record, fieldName, validValues) {


        // Check if the field exists and has a value
        if (fieldName in record && record[fieldName] !== null && record[fieldName] !== undefined && record[fieldName] !== '') {
          // Convert the field value to lowercase and check if it is in the valid values array
          return validValues.includes(record[fieldName].toLowerCase());
        }
        return false

      }

      function checkDuplicatePhrases(record){
        //console.log(`checking record for duplicate phrase: ${JSON.stringify(record)}`)
        const duplicateRow = mappedData.find(
          (row) => 
            row._id != record._id && row.phrase &&
            !row.phrase.localeCompare(record.phrase, undefined, { sensitivity: 'accent' }) // case insensitive string compare 
        )
        //console.log(`find result: ${duplicateRow?JSON.stringify(duplicateRow):"not found"}`)
        return(!duplicateRow)

      }



      // function checkDrfaCategoryRule(record) {
      //   // check if the income/expense field is "Expense" or "E"
      //   if (record.incomeExpense.charAt(0).toLowerCase() == 'e') {
      //     // expenses need to have a DRFA category (at least in state of Georgia)
      //     if (!isFieldPresentAndHasValue(record, "drfaCategory")) {
      //       return false
      //     }

      //   }

      //   return true

      // }

      //console.log(`Evaluating Record: ${JSON.stringify(record)}`)

      if (!isFieldPresentAndHasValue(record, "phrase")) {
        return formatFailResult('missing phrase')
      }

      if (!isFieldPresentAndHasValue(record, "flag")) {
        return formatFailResult('missing flag')
      }

      //console.log(`Need to add check for subcategory in subcategory list`)
      // if (!isValidSubcategory(record, "subcategory")) {
      //   return formatFailResult(`subcategory: "${record["subcategory"]}" not found in the case subcategories`)
      // }

      if(!checkDuplicatePhrases(record)) {
        return formatFailResult(`phrase: "${record["phrase"]}" occurs more than once in the file`)
      }

      
      // true is good data, false is bad data
      return formatPassResult()
    }

    mappedData.forEach(record => {
      const result = dqRules(record)
      if (result.pass) {
        passData.push(record);
      } else {
        failData.push({ ...record, _dqError: result.reason });
      }
    });

    
    setPassData(passData)
    setFailData(failData)
    if (failData.length > 0) {
      // setSnackbarAlertMessage(`We found ${failData.length} records with data quality issues`)
      // setSnackbarAlertSeverity(alert_severity_warning)
      // handleSnackbarOpen(true)
    snackbarContext.openSnackbarWarning(`We found ${failData.length} records with data quality issues`)  
    }
    else {
      // setSnackbarAlertMessage(`Good News! Your data set passed our quality checks!`)
      // setSnackbarAlertSeverity(alert_severity_success)
      // handleSnackbarOpen(true)
    snackbarContext.openSnackbarSuccess(`Good News! Your data set passed our quality checks!`)  
      handleNext()
    }
  }


  const getFlagRuleGridColumns = (fieldList) => {

    const fieldTitles = {
      //subcategory: 'Subcategory',
      phrase: 'Phrase',
      flag: 'Flag',
      _dqError: 'Validation Error',
      action: 'Action',
      proposed_count: 'Proposed Count',
      confirmed_count: 'Confirmed Count',
      //original_subcategory: 'Existing Subcategory',
      original_flag: 'Existing Flag',
      _id: 'Excel Line Number'

    }

    const columns = fieldList.map(field => ({ field: field, headerName: fieldTitles[field], width: 250 }))

    return columns
  }


  const handleMutate = async () => {
    
    
    mutateFlagRuleList(userProfileContext.userProfile, updates).then(async (result) => {
      
      parentOnSubmit(updates)
    })

    handleNext()
  }

  const orgName = userProfileContext.userProfile?.currentOrg.orgName
  const caseName = userProfileContext.userProfile?.currentCase.caseName


  return (
    <React.Fragment>

      <Dialog fullWidth={true} maxWidth={"xxl"} open={props.open} onClose={handleBulkLoadCancel}>
        <DialogTitle>{props.title ? props.title : "Upload Flag Rules"}</DialogTitle>
        <DialogContent>
          

          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography variant="caption">Optional</Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {caseFlags.length == 0 &&
            <Alert severity={alert_severity_warning} variant='filled' sx={{ width: '100%' }}>There are no categories available for this case.  Upload will not be able to complete.  Please add case categories first.</Alert>
          }
          {caseFlags.length > 0 &&
            <Fragment>
              {activeStep === steps.length &&
                <Fragment>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button onClick={handleBulkLoadCancel}>Close</Button>
                  </Box>
                </Fragment>
              }
              {activeStep == step_index_select_flag_file &&
                <Fragment>
                  <Box component="section" sx={{ p: 2, border: '1px dashed grey' }}>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>Drop your Flag Rule Excel file here, or click to select a file</p>
                      <em>(Only *.xlsx and *.xls images will be accepted)</em>
                    </div>
                  </Box>



                </Fragment>
              }
              {activeStep == step_index_select_worksheet &&
                <Fragment>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Typography>File Name: {file.name}</Typography>
                  {sheetNames.length > 1 &&
                    <Fragment>
                      <Typography>Select the data worksheet:</Typography>
                      <Autocomplete
                        required
                        value={sheetName}
                        onChange={(event, newValue) => {
                          setSheetName(newValue);
                        }}
                        //inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        id="worksheet-selection"
                        options={sheetNames}
                        sx={{ width: 400 }}
                        renderInput={(params) => <TextField {...params} label="Worksheet" />}
                      />
                    </Fragment>
                  }
                  {sheetNames.length == 1 &&
                    <Fragment>
                      <Typography>Only one sheet found, we will use this one: {sheetName}</Typography>
                    </Fragment>
                  }
                  <Button onClick={handleSelectSheet}>
                    Next
                  </Button>
                </Fragment>
              }
              {activeStep == step_index_select_phrase_column &&
                <Fragment>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Typography>Select the Phrase column:</Typography>
                  <Autocomplete
                    required
                    value={phraseColumn}
                    onChange={(event, newValue) => {
                      setPhraseColumn(newValue);
                    }}
                    //inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //     setInputValue(newInputValue);
                    // }}
                    id="worksheet-selection"
                    options={columnNames}
                    sx={{ width: 400 }}
                    renderInput={(params) => <TextField {...params} label="Phrase" />}
                  />
                  <Button onClick={handleSelectPhraseColumn}>
                    Next
                  </Button>
                </Fragment>

              }
              {/*activeStep == step_index_select_subcategory_column &&
                <Fragment>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Typography>Select the Subcategory column:</Typography>
                  <Autocomplete
                    required
                    value={subcategoryColumn}
                    onChange={(event, newValue) => {
                      setSubcategoryColumn(newValue);
                    }}
                    //inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //     setInputValue(newInputValue);
                    // }}
                    id="worksheet-selection"
                    options={columnNames2}
                    sx={{ width: 400 }}
                    renderInput={(params) => <TextField {...params} label="Subcategory" />}
                  />
                  <Button onClick={handleSelectSubcategoryColumn}>
                    Next
                  </Button>
                </Fragment>
              */
              }
              {activeStep == step_index_select_flag_column &&
                <Fragment>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Typography>Select the Flag column:</Typography>
                  <Autocomplete
                    required
                    value={flagColumn}
                    onChange={(event, newValue) => {
                      setFlagColumn(newValue);
                    }}
                    //inputValue={inputValue}
                    // onInputChange={(event, newInputValue) => {
                    //     setInputValue(newInputValue);
                    // }}
                    id="worksheet-selection"
                    options={columnNames2}
                    sx={{ width: 400 }}
                    renderInput={(params) => <TextField {...params} label="Flag" />}
                  />
                  <Button onClick={handleSelectFlagColumn}>
                    Next
                  </Button>
                </Fragment>

              }
              {   // display data preview grid when selecting columns for use
                (
                  //activeStep == step_index_select_subcategory_column ||
                  activeStep == step_index_select_phrase_column ||
                  activeStep == step_index_select_flag_column) &&

                <Fragment>

                  <FlagRuleDataPreview data={rawData} columnNames={columnNames} phraseColumn={phraseColumn} flagColumn={flagColumn} />


                </Fragment>
              }
              {
                activeStep == step_index_data_quality_check &&
                <Fragment>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Button onClick={handleNext}>
                    Next
                  </Button>
                  {failData.length > 0 &&
                    <Fragment>
                      <Alert severity={alert_severity_warning}>If you proceed, the data below will not be loaded, other records will be loaded (we will review those if you proceed).</Alert>
                      <ExportToExcel data={failData} fileName={orgName + " - " + caseName+ " Flag Rule Data Issues"} sheetName={"Flag Rule Data Issues"}/>


                      <FlagRuleDataPreview data={failData} columns={getFlagRuleGridColumns(Object.keys(failData[0]))} />
                    </Fragment>
                  }

                </Fragment>
              }
              {
                activeStep == step_index_change_review &&
                <Fragment>
                  <Button
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                  >
                    Back
                  </Button>
                  <Button onClick={handleMutate}>
                    Next
                  </Button>
                  {true &&
                    <Fragment>

                      <Alert severity={alert_severity_info}>Here are the changes that will be applied</Alert>
                      <FlagRuleDataPreview data={updates} columns={getFlagRuleGridColumns(['action', 'proposed_count', 'confirmed_count', 'phrase', 'original_flag', 'flag'])} />




                    </Fragment>
                  }

                </Fragment>
              }
            </Fragment>
          }


        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}