import { useState, useEffect } from 'react';
import PortalFrame from '../ui/portal-frame.js';
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";
import CategoryDataPreview from '../ui/category-data-preview-panel.js';
import { queryCaseNeo4j } from '../../api/pydbcall.js';
import ExportToExcel from '../ui/export-to-excel.js';
import { Alert } from '@mui/material';

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';

export default function ViewCategories(props) {
    const [caseCategories, setCaseCategories] = useState([])
    const [categoryLoading, setCategoryLoading] = useState(true)
    const title = props.title ? props.title : "View Case Categories"

    //Step 2 added new way
    const userProfileContext = useContext(UserProfileContext);

    //const userProfile=useCurrentUserProfile(); // comment out old way

    const userProfile = userProfileContext.userProfile  // add new way

    useEffect(() => {

        const formatCategoryResult = (records) => {
            return records.map(record => ({
                "Subcategory": record.subcat.name,
                "Category": record.cat.name,
                //"DRFA ID": record.drfa?record.drfa.name:null,
                "DRFA Category": record.drfa?.category,
                "DRFA Subcategory": record.drfa?.subcategory,
                'Income/Expense/Transfer': record.subcat.type,
                'Transactions Affected': record.usage_count
            })

            )
        }

        const getCaseCategories = async () => {
            setCategoryLoading(true)
            const orgID = userProfileContext.userProfile.currentOrg.orgID
            const caseID = userProfileContext.userProfile.currentCase.caseID
            //console.log(`getting case categories for orgID=${orgID} caseID=${caseID}`)
            const categoryQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:USES]->(subcat:Subcategory:DefinedCategory)-[:WITHIN]->(cat:Category) OPTIONAL MATCH (subcat)-[:MAPS_TO]->(drfa:DRFACategory) OPTIONAL MATCH (subcat)<-[]-(t:Transaction) RETURN subcat, cat, drfa, count(t) as usage_count"
            const params = {
                orgID: orgID,
                caseID: caseID
            }
            const caseCategoryResult = await queryCaseNeo4j(categoryQuery, params)
            //console.log(`Case Categories: ${JSON.stringify(caseCategoryResult)}`)
            setCaseCategories(formatCategoryResult(caseCategoryResult.records))
            setCategoryLoading(false)


        }

        //if (!userProfile.isLoading) {
        if (!userProfileContext.isLoading && userProfileContext.userProfile.currentOrg && userProfileContext.userProfile.currentCase) {
            getCaseCategories()
        }

    }, [userProfileContext.isLoading])

    //if (userProfile.isLoading || categoryLoading) {
    if (userProfileContext.isLoading || categoryLoading) {
        return (
            <PortalFrame title={title}>
                <PageSkeleton />
            </PortalFrame>
        )
    }

    if (!userProfile.currentOrg?.orgID || !userProfile.currentCase?.caseID) {
        return (
            <PortalFrame title={title}>
                You are not assigned to any cases for this organization
            </PortalFrame>
        )
    }


    //const orgID = userProfile.data.currentOrg.orgID
    //const orgName = userProfile.data.currentOrg.orgName
    //const caseID = userProfile.data.currentCase.caseID
    //const caseName = userProfile.data.currentCase.caseName

    const orgID = userProfile.currentOrg.orgID
    const orgName = userProfile.currentOrg.orgName
    const caseID = userProfile.currentCase.caseID
    const caseName = userProfile.currentCase.caseName


    //   const getCategoryGridColumns = (fieldList) => {
    //     //'action','usage_count','original_category','original_drfaCategory','original_incomeExpense'
    //             const fieldTitles = { 
    //                 subcategory: 'Subcategory', 
    //             category: 'Category', 
    //             drfaCategory: 'DRFA Category',
    //              _dqError: 'Validation Error', 
    //              incomeExpense: 'Income/Expense/Transfer',
    //              action: 'Action',
    //              usage_count: 'Transactions Affected',
    //              original_category: 'Existing Category',
    //              original_drfaCategory: 'Existing DRFA Category',
    //              original_incomeExpense: 'Existing Income/Expense/Transfer'
    //              }

    //             const columns = fieldList.map(field => ({ field: field, headerName: fieldTitles[field], width: 250 }))

    //             return columns
    //         }
    //         //columns={getCategoryGridColumns(['usage_count','subcategory','category','drfaCategory','incomeExpense'])}

    return (
        <PortalFrame title={title} orgName={orgName} caseName={caseName} >
            <ExportToExcel data={caseCategories} fileName={orgName + " - " + caseName + " Categories"} sheetName={"Case Categories"} />
            {caseCategories.length > 0 &&
                <CategoryDataPreview data={caseCategories} columnNames={Object.keys(caseCategories[0])} />
            }{caseCategories.length == 0 &&
                <Alert severity="info">No Data Found</Alert>
            }

        </PortalFrame>
    );
}