import React, {useState, useEffect} from "react";
import PortalFrame from '../ui/portal-frame';
import { getCurrentUser, fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';
import { Typography } from "@mui/material";
import FreeSoloCreateOptionAutoComplete from "../ui/autocomplete-free-solo-create";

//import { useCurrentUserProfile, getCurrentUserProfile } from "../../api/swrcurrentuserprofile";

export default function Test4() {

    const [result,setResult] = useState()
    const [user,setUser]=useState()
    const [credentials,setCredentials]=useState()


    

    useEffect( () => {
        const getProfile = async () => {
            await fetchUserAttributes().then((attrs)=>setResult(attrs)); 
            await getCurrentUser().then((user)=>setUser(user)); 
            await fetchAuthSession().then((credentials)=>setCredentials(credentials));
        }

        getProfile()
        
    },[]

    )

    // useEffect ( () => {
        
    //     cognitoUser.getSession(function(err, session) {
    //         if (err) {
    //             alert(err.message || JSON.stringify(err));
    //             return;
    //         }
    //         console.log('session validity: ' + session.isValid());
          
    //         cognitoUser.getUserAttributes(function(err, attributes) {
    //           if (err) {
    //               // Handle error
    //               console.log(err);
    //           } else {
    //               console.log(attributes);
    //               // Attributes is an array of objects describing the user's attributes
    //               attributes.forEach(attribute => {
    //                   console.log('Attribute ' + attribute.getName() + ' has value ' + attribute.getValue());
    //               });
    //           }
    //         });
    //       });
          
    // })
    
    
    //const userProfile=useCurrentUserProfile();
    return (
        <PortalFrame title={"Big Tittie Goth Girls"}>
            <Typography>{JSON.stringify(result)}</Typography>
            <Typography>{JSON.stringify(user)}</Typography>
            <Typography>{JSON.stringify(credentials)}</Typography>



                
        </PortalFrame>
    )
}

