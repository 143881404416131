import React from 'react';
import PortalFrame from '../ui/portal-frame';
import { Typography } from '@mui/material';
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';
import EditableDRFAReport from '../ui/report-drfa-editable.js';
import { useDRFAReportForCurrentCase } from '../../api/swrdrfareportforcurrentcase.js';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min.js';
import ReportContext from '../ui/user-report-context.js';

export default function DRFAReportPage(props) {
    //Step 2 added new way
    const userProfileContext = useContext(UserProfileContext);

    //const userProfile = useCurrentUserProfile(); // comment out old way

    const userProfile = userProfileContext.userProfile  // add new way
    const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg.orgID
    const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase.caseID
    const drfaQueryResult = useDRFAReportForCurrentCase(orgID, caseID, !userProfileContext.isLoading)

    const title = "DRFA Editable Report"
    const reportID = useParams().reportID
    const reportContext = useContext(ReportContext);

    console.log(`in DRFAReportPage, reportID=${reportID}`)

    const report=reportContext.getReportByID(reportID)

    //console.log(`in DRFAReportPage, reportID=${reportID}, report=${JSON.stringify(report)}`)
    

    //if (userProfile.isLoading) {
    if (reportContext.isLoading || userProfileContext.isLoading || drfaQueryResult.isLoading) {


        return (
            <PortalFrame title={title}>
                <PageSkeleton />
            </PortalFrame>
        )
    }


    //const orgID = userProfile.data.currentOrg.orgID
    //const caseID = userProfile.data.currentCase.caseID
    //console.log(`in dashboard, user Profile=${JSON.stringify(userProfile)}`)
    //const orgID = userProfile.currentOrg.orgID
    //const caseID = userProfile.currentCase.caseID

    //const debug=false

    return (
        <PortalFrame title={title}>

        <EditableDRFAReport reportData={report.reportData}/>
        
        </PortalFrame>
    );
}