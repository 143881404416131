import * as React from 'react';
import Box from '@mui/material/Box';
import { useGridApiContext } from '@mui/x-data-grid';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { TextField } from '@mui/material';
import FreeSoloCreateOptionAutoComplete from './autocomplete-free-solo-create';

export default function FreeSoloCreateOptionAutoCompleteEditInputCell(props) {
    const { id, value, field, hasFocus, options, valueField, ...otherProps } = props;
    const selectedOption = value ? options.find(option => option[valueField] === value) : null;
    const apiRef = useGridApiContext();
    const ref = React.useRef(null);

    const handleChange = (event, newValue) => {
        apiRef.current.setEditCellValue({ id, field, value: newValue ? newValue[valueField] : null });
    };

    useEnhancedEffect(() => {
        if (hasFocus && ref.current) {
            ref.current.focus();
        }
    }, [hasFocus]);

    const sortOptions = (a, b) => -b[valueField].localeCompare(a[valueField]);

    const isOptionEqualToValue = (option, value) => option[valueField] === value[valueField];

    return (
        <Box sx={{ width: '100vw', alignItems: 'left' }}>
            <FreeSoloCreateOptionAutoComplete
                {...otherProps}
                required
                autoSelect
                valueField={valueField}
                name={field}
                value={selectedOption}
                onChange={handleChange}
                options={options.sort((a, b) => sortOptions(a, b))}
                inputRef={ref} // Pass ref to child component
                renderInput={(params) => <TextField {...params} required />}
                isOptionEqualToValue={isOptionEqualToValue}
            />
        </Box>
    );
}
