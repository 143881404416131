import React, { useEffect, useState } from "react";
import PortalFrame from '../ui/portal-frame';
// import { useTransactionsForCurrentCase, getTransactionsForCurrentCaseV2, useInfiniteTransactionsForCurrentCase } from "../../api/swrtransactionsforcurrentcase.js";
// import UserProfileContext from '../ui/userprofilecontext.js';
// import { Button } from "@mui/material";
//import useUserContext from "../../api/swrusercontext";
import useFindAccounts from "../../api/swrfindaccts";

export default function TestPage(props) {

    // const userProfileContext = React.useContext(UserProfileContext);

    // const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg.orgID
    // const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase.caseID
    // const shouldFetch = orgID && caseID
    // const [data, setData] = useState(null);
    // const result = useTransactionsForCurrentCase(orgID, caseID, shouldFetch);
    // //const result = useInfiniteTransactionsForCurrentCase(orgID, caseID, shouldFetch);

    

    // let totalCount=null
    // if(result.data){
    //     //result.data.forEach((d)=>{totalCount+=d.length})
    //     totalCount=result.data.length
    // }

    //const userContext = useUserContext();
    const findAccounts = useFindAccounts();
    
    //const caseSecret = useCaseSecret();


    return (
        <PortalFrame title={"Testing"}>
            {JSON.stringify(findAccounts)}
            {/* {JSON.stringify(caseSecret)} */}
            {/* {!result.isLoading && result.data && <div>{totalCount}</div>
                
            }
            {!result.isLoading && result.error && <div>{JSON.stringify(result.error)}</div>
                
            } */}
        </PortalFrame>
    );
}

