import pako from 'pako';  // For decompression if needed

export async function lazy_decompress(response){
    const { body, headers } = response
    const isCompressed = headers['Content-Encoding'] === 'gzip' || headers['X-Compressed'] === 'true';
    if(!isCompressed){
        let json = await body.json();
        
        json = JSON.parseWithDate(JSON.stringify(json));
    
        return json
    }
    else{
        //console.log(`requestID: ${requestID} response was compressed`)
        const base64CompressedData = body;
        const compressedData = atob(base64CompressedData);

        // Convert to Uint8Array for decompression
        const compressedArray = Uint8Array.from(compressedData, c => c.charCodeAt(0));

        // Decompress using pako
        const decompressedData = pako.inflate(compressedArray, { to: 'string' });

        // Parse the JSON from the decompressed data
        const jsonResponse = JSON.parseWithDate(decompressedData);
        return jsonResponse;
    }    
}