import { CognitoIdentityProviderClient, AdminDeleteUserCommand  } from "@aws-sdk/client-cognito-identity-provider"; // ES Modules import

import amplifyConfig from '../amplifyconfiguration.json'
import { CognitoIdentity } from '@aws-sdk/client-cognito-identity';
import { fetchAuthSession } from 'aws-amplify/auth';



export default async function authDeleteUser(data) {

    

    
    
    const region = amplifyConfig.aws_project_region

    const { credentials } = await fetchAuthSession();
    // console.log(`CREDENTIALS: ${JSON.stringify(credentials)}`)


    
    const client = new CognitoIdentityProviderClient({
        region: region,
        credentials: credentials
    });

    const input = { // AdminDeleteUserRequest
        UserPoolId: amplifyConfig.aws_user_pools_id, // required
        Username: data.userID, // required
        
        
    };
    const command = new AdminDeleteUserCommand(input);
    const response = await client.send(command);
    
    
    return response;
    

}