import { post, get, put, del } from 'aws-amplify/api';



export async function applyRulestoCase(orgID,caseID,transactions=null) {
    
        //transactions is an optional array of transaction objects 

        const reqbody = {
          
          transactions: transactions,
          category_prediction_model: "PLAID-ENRICH",
          orgID: orgID,
          caseID: caseID,
          category_model_parameters: {
            orgID: orgID,
            caseID: caseID,
            description_field: "description",
            amount_field: "amount",
            id_field: "pk",
            PLAID_ENV: "PROD"
          },
          flag_prediction_model: "TF-IDF",
          flag_model_parameters: {
            orgID: orgID,
            caseID: caseID,
            description_field: "description",
            "prediction_threshold": 0.043333
          }

          //category_prediction_threshold: 0.001,
          //flag_prediction_threshold: 0.06
          
        }

        const payload={
          apiName: 'applyrules',
          path: '/applyrules',
          options: {
            body: reqbody
          }
        }

        console.log('GOT HERE1 applyRulestoCase')
        console.log(`payload=${JSON.stringify(payload)}`)
        
    
    
        const restOperation = post(payload); 

        
        const { body } = await restOperation.response;
        let json = await body.json();
    
        console.log('GOT HERE2 applyRulestoCase')
        console.log(`json=${JSON.stringify(json)}`)
        
        json = JSON.parseWithDate(JSON.stringify(json));
    
        return json
    
    

}


export async function applyRulestoTransaction(orgID,caseID,transaction) {
  //transactions is a transaction object 
  return applyRulestoCase(orgID,caseID, [transaction])
}

