import React, { useMemo, useRef } from 'react';
import ReactToPrint from 'react-to-print';
import { currencyFormatter } from './type-utilities';
import {
  Container,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Box,
} from '@mui/material';

const TotalByYearDetailReport = ({ reportData = [] }) => {
  // Define the reportRef at the top level
  const reportRef = useRef();

  // Predefined set of colors
  const predefinedColors = [
    '#e0f7fa',
    '#f1f8e9',
    '#fff3e0',
    '#e1bee7',
    '#fce4ec',
  ];

  // Build the year-to-color mapping using useMemo
  const { yearToColorMap, processedData } = useMemo(() => {
    const mapping = {};
    let colorIndex = 0;

    // Assign colors to each unique year
    reportData.forEach((row) => {
      const year = row.Year ? row.Year.toString() : '';

      if (year && !mapping[year]) {
        mapping[year] = predefinedColors[colorIndex % predefinedColors.length];
        colorIndex++;
      }
    });

    return {
      yearToColorMap: mapping,
      processedData: reportData,
    };
  }, [reportData]);

  // Ensure hooks are called before any early returns
  if (processedData.length === 0) {
    return (
      <Container sx={{ flexGrow: 1, padding: 2 }}>
        <Typography variant="h6">No data available.</Typography>
      </Container>
    );
  }

  return (
    <Container sx={{ flexGrow: 1, padding: 2 }}>
      {/* Place the Print Button */}
      <Box mt={2}>
        <ReactToPrint
          trigger={() => (
            <Button variant="contained" color="primary">
              Print Report
            </Button>
          )}
          content={() => reportRef.current}
        />
      </Box>

      {/* Wrap the content you want to print with ref={reportRef} */}
      <div ref={reportRef}>
        {/* Report Title */}
        <Paper sx={{ padding: 2, textAlign: 'center', marginBottom: 2 }}>
          <Typography variant="h4" component="h1">
            Spend By Year Detail Report
          </Typography>
        </Paper>

        {/* Details Table */}
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Year</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Category</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Sub Category</TableCell>
                <TableCell style={{ fontWeight: 'bold' }} align="right">
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {processedData.map((row, index) => {
                const year = row.Year ? row.Year.toString() : '';
                const rowColor = yearToColorMap[year] || '#ffffff';

                return (
                  <TableRow key={index} style={{ backgroundColor: rowColor }}>
                    <TableCell>{year}</TableCell>
                    <TableCell>{row.Category}</TableCell>
                    <TableCell>{row.SubCategory}</TableCell>
                    <TableCell align="right">
                      {currencyFormatter(row.Total)}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </Container>
  );
};

export default TotalByYearDetailReport;
