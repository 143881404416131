import { Fragment, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Button, Paper } from "@mui/material";
import DialogTitle from '@mui/material/DialogTitle';
//import { useCasesForCurrentUser, getCasesForCurrentUser } from "../../api/swrcasesforcurrentuser.js";
//import { useOrgsForCurrentUser, getOrgsForCurrentUser } from '../../api/swrorgsforcurrentuser.js';
//import { useCurrentUserProfile } from '../../api/swrcurrentuserprofile.js';
import SelectCurrentOrgDialog from './dialog-select-current-org.js';
import SelectCurrentCaseDialog from './dialog-select-current-case.js';
import {Skeleton, Box, Divider, Stack, Tooltip} from '@mui/material';

//import { AutocompleteElement } from 'react-hook-form-mui';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { ArrowRight } from '@mui/icons-material';

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';


export default function MyUserProfileDialog(props) {

  const title = "Manage My Profile"
  //const parentOnSubmit = props.onSubmit ? props.onSubmit : () => { }
  const parentOnCancel = props.onCancel ? props.onCancel : () => { }

  const [openOrgDialog, setOpenOrgDialog] = useState(false)
  const [openCaseDialog, setOpenCaseDialog] = useState(false)
  //const [currentOrgID,setCurrentOrgID]=useState()
  //const [currentCaseID,setCurrentCaseID]=useState()
  //const [userProfile,setUserProfile]=useState()

  //Step 2 added new way
  const {userProfile, isLoading}=useContext(UserProfileContext);

  
  
//   useEffect( () => {
//     setUserProfile(userProfile)
//   }, isLoading,userProfile
// )
  
  


  const handleCloseOrgDialog = () => {
    setOpenOrgDialog(false)
  }

  const handleCloseCaseDialog = () => {
    setOpenCaseDialog(false)
  }

  const handleCancel = () => {
    parentOnCancel()
  };




  function handleEditOrgClick() {
    //setCurrentOrgID(userProfile.currentOrg.orgID)
    setOpenOrgDialog(true)
  }

  function handleEditCaseClick() {
    //setCurrentCaseID(userProfile.currentCase.caseID)
    setOpenCaseDialog(true)

  }

  function handleSaveCase(data) {
    //setCurrentCaseID(data.current_case.id)
    setOpenCaseDialog(false)
  }

  function handleSaveOrg(data) {
    //setCurrentOrgID(data.current_org.id)
    setOpenOrgDialog(false)
  }


  

  //console.log(`user profile = ${JSON.stringify(userProfile)}`)

  //const userProfile = useCurrentUserProfile(); // comment out old way

  

  //if (userProfile.isLoading) {
  if (isLoading) {

    return (
      <Fragment>

        <Dialog fullScreen open={props.open} onClose={handleCancel}>
          <DialogTitle>{title}
            <IconButton aria-label="close" onClick={handleCancel} sx={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
          <Box sx={{ p: 2, display: 'flex', flexDirection: 'row'}}>
          <Skeleton variant="rectangular" sx={{minWidth: 500, minHeight: 300}} />
          </Box>
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }



  //console.log(`before render: ${JSON.stringify(userProfile)}`)
  return (
    <Fragment>
      {userProfile&&userProfile.currentOrg &&
        <SelectCurrentOrgDialog onSubmit={handleSaveOrg}  open={openOrgDialog} onCancel={handleCloseOrgDialog} />
      }
      {userProfile&&userProfile.currentCase &&
        <SelectCurrentCaseDialog onSubmit={handleSaveCase}  open={openCaseDialog} onCancel={handleCloseCaseDialog} />
      }
      <Dialog fullScreen open={props.open} onClose={handleCancel} >
        <DialogTitle>{title}
          <IconButton aria-label="close" onClick={handleCancel} sx={{ position: 'absolute', right: 8, top: 8 }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
        
        <Box sx={{ p: 2, display: 'flex', flexDirection: 'row'}}>
          <List>
            {userProfile&&userProfile.currentOrg &&
              <Fragment>
              <ListItem key={userProfile.currentOrg.orgID}>
              
                <ListItemText primary={'Current Organization: ' } secondary={userProfile.currentOrg.orgName}/>
                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditOrgClick()}>
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider/>
              </Fragment>
            }
            {userProfile&&userProfile.currentCase &&
            <Fragment>
              <ListItem key={userProfile.currentCase.caseID}>
                <ListItemText primary={'Current Case: '} secondary={userProfile.currentCase.caseName} />

                <ListItemSecondaryAction>
                  <IconButton edge="end" aria-label="edit" onClick={() => handleEditCaseClick()}>
                    <EditIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
              <Divider/>
              </Fragment>
            }


          </List>
          </Box>

          <Button
              style={{
                //padding: "20px",
                margin: "10px 0px",
                maxWidth: "75px",
              }}
              onClick={handleCancel} variant={"contained"}>
              Done
            </Button>
        
        </DialogContent>
      </Dialog>
    </Fragment>
  );







}