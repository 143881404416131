import { mutate } from "swr";
import { mutatePrimaryNeo4j, queryPrimaryNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";
import authFindUser from "./authfinduser";
import authAddUser from "./authadduser";
import authDeleteUser from "./authdeleteuser";


// the UI is expecting a result object called "record" for this query, do not change!
export const listUserQuery = "MATCH (record:User)-[r:BELONGS_TO]->(org:Organization {orgID:$orgID}) RETURN record ORDER by record.userID";

async function deleteUser(orgID, data) {
    //const delete_mutation = 'MATCH (user:User {userID:$userID})-[r]->(o:Organization {orgID:$orgID}) DELETE r DELETE user'
    // console.log('deleteUser starting...')
    let result = {}
    const delete_user_org_relation_mutation = 'MATCH (user:User {userID:$userID})-[r]->(o:Organization {orgID:$orgID}) DELETE r'
    const parms = { "orgID": orgID, "userID": data.userID }

    
    await mutate(['USER', data.userID], mutatePrimaryNeo4j(delete_user_org_relation_mutation, parms)).then(async (delete_relation_result) => {
        // console.log(`after first mutate, that result=${JSON.stringify(delete_relation_result)}`)

        result["delete_relation_result"] = delete_relation_result
        const user_query = 'MATCH (user:User {userID:$userID})-[r]->(o) RETURN count(r) as count'
        await queryPrimaryNeo4j(user_query, parms).then(async (search_remaining_result) => {

            // console.log(`residual query, that result=${JSON.stringify(search_remaining_result)}`)
            result["search_remaining_result"] = search_remaining_result

            if (search_remaining_result["records"][0].count === 0) {
                // console.log(`in condition where count === 0`)
                // the user has no more associations, ok to delete it completely
                // delete from cognito
                await authDeleteUser(data).then(async (delete_auth_user_result) => {

                    // console.log(`after authDeleteUser, that result=${JSON.stringify(delete_auth_user_result)}`)

                    result["delete_auth_user_result"] = delete_auth_user_result
                    // delete from neo4j
                    const delete_user_mutation = 'MATCH (user:User {userID:$userID}) DELETE user'
                    await mutate(['USER', data.userID], mutatePrimaryNeo4j(delete_user_mutation, parms)).then((delete_neo4j_user_result) => {
                        // console.log(`after neo4j delete_user_mutation, that result=${JSON.stringify(delete_neo4j_user_result)}`)

                        result["delete_neo4j_user_result"] = delete_neo4j_user_result
                    })
                })
            }
        })
    })

    // console.log(`returning from deleteUser, result=${JSON.stringify(result)}`)
    return result
}

export default async function mutateUser(orgID, orgName, currentUserID, data, action = MUTATE_UPDATE, search_before_create = true) {

    if (action === MUTATE_DELETE) {
        return deleteUser(orgID, data)
    }

    else if (!data.userID && (action === MUTATE_UPDATE || action === MUTATE_CREATE)) {
        await authFindUser(data.email).then(async (result) => {
            //console.log(`authFindUser result=${JSON.stringify(result)}`)
            // console.log(`authFindUser result.$metadata=${JSON.stringify(result.$metadata)}`)
            // console.log(`authFindUser result.$metadata.httpStatusCode=${JSON.stringify(result.$metadata.httpStatusCode)}`)
            if (result.$metadata.httpStatusCode !== 200) { throw new Error(`received status code ${result.$metadata.httpStatusCode} attempting call authFindUser`) }
            if (result.Users.length === 0) {

                const addUserData = {
                    userEmail: data.email
                }

                await authAddUser(addUserData).then((createResult) => {
                    // console.log(`authAddUser result:${JSON.stringify(createResult)}`)
                    if (createResult.$metadata.httpStatusCode !== 200) { throw new Error(`received status code ${createResult.$metadata.httpStatusCode} attempting call authAddUser`) }
                    if (!createResult.User) { throw new Error(`no user returned from authAddUser: ${JSON.stringify(createResult)}`) }

                    data.userID = createResult.User.Username
                    // console.log(`got here 1:${data.userID}`)
                }
                )
                // no user found
                // try to create a user entry        
            }
            else {
                // user found
                const foundUser = result.Users[0]
                data.userID = foundUser.Username
                // console.log(`got here 1.5:${data.userID}`)
            }

        }
        )
        // console.log(`got here 2:${data.userID}`)
        //if (!data.userID) { throw new Error(`no user assigned after lookup/create attempt`) }

        //// REPLACE THIS WITH CREATE Cognito User!
        //data.userID = crypto.randomUUID()
    }



    // console.log(`got here 3:${JSON.stringify(data)}`)
    const update_mutation = 'WITH datetime() AS dt MATCH (o:Organization {orgID:$orgID}) MERGE (user:User {userID:$userID}) set user={userID:$userID, email:$email, firstName:$firstName, lastName:$lastName, phone:$phone}  MERGE (user)-[r:BELONGS_TO]->(o) SET r.lastDate=dt RETURN user'
    //const update_mutation_no_org = 'MERGE (user:User {userID:$userID}) set user={userID:$userID, email:$email, firstName:$firstName, lastName:$lastName, phone:$phone} RETURN user'

    //const delete_mutation_no_org = 'MATCH (user:User {userID:$userID}) DELETE user'

    const parms = { "orgID": orgID, "userID": data.userID, "email": data.email, "firstName": data.firstName, "lastName": data.lastName, "phone": data.phone }


    if (action === MUTATE_UPDATE && search_before_create) {
        //console.log('IN MUTATE SEARCH BEFORE CREATE AREA')

        /// maybe add a case for no orgID?
        const sb4create = 'MATCH (user:User {email:$email})-[r:BELONGS_TO]->(org:Organization {orgID:$orgID}) WHERE user.userID <> $userID RETURN user'
        const sb4result = mutate(['USER', data.userID], mutatePrimaryNeo4j(update_mutation, parms, sb4create))
        //console.log(JSON.stringify(sb4result))
        return sb4result
    }

    //console.log(`in mutateUser: mutation=${mutation}, parms=${parms}`)
    //const result = useSWR(qry, postNeo4j);
    
    //let mutation = action === MUTATE_DELETE ? delete_mutation : update_mutation
    let mutation = update_mutation

    //console.log(`got here 4:${JSON.stringify(data)}`)
    const result = mutate(['USER', data.userID], mutatePrimaryNeo4j(mutation, parms))

    //console.log(`in mutateUser: result=${JSON.stringify(result)}`)
    return result
}