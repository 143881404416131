import { useState, useEffect } from 'react';

import AWS from 'aws-sdk';
import amplifyConfig from '../../amplifyconfiguration.json'
import { fetchAuthSession } from 'aws-amplify/auth';
import { CognitoIdentityProviderClient } from "@aws-sdk/client-cognito-identity-provider";
import useSWR from 'swr';
import { queryCaseNeo4j } from '../../api/pydbcall.js';




const usePDFForTransaction = (orgID, caseID) => {
    const [currentTransaction, setCurrentTransaction] = useState(null)
    //const [bank_account_pk, setBankAccountPK] = useState(null)
    //const [bank_statement_pk, setBankStatementPK] = useState(null)
    //const [fileS3Key, setFileS3Key] = useState()
    //const [pdfUrl, setPdfUrl] = useState(null)
    

    

    // useEffect(() => {
    //     setBankAccountPK(currentTransaction?.bank_account_pk)
    //     setBankStatementPK(currentTransaction?.period_pk)
        
    // }, [currentTransaction]

    // )

    const bank_account_pk = currentTransaction?.bank_account_pk
    const bank_statement_pk = currentTransaction?.period_pk
    const parms = {
        orgID: orgID,
        caseID: caseID,
        bank_account_pk: bank_account_pk,
        bank_statement_pk: bank_statement_pk
    }
    const shouldFetchPdfS3Key=(bank_account_pk != null && bank_statement_pk != null)

    const pdfS3KeyQuery = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$bank_statement_pk})<-[CONTAINS]-(sd:ScannedDoc)-[:PROCESSED]->(cf:CaseFile) return cf.path as s3key"

    const {data, isLoading, error} = useSWR(shouldFetchPdfS3Key?[pdfS3KeyQuery,parms]:null, ([pdfS3KeyQuery,parms])=>queryCaseNeo4j(pdfS3KeyQuery,parms))
    const fileS3Key = data?.records[0]?.s3key

    const {data: pdfUrl, error: pdfUrlError} = useSWR(fileS3Key?['S3KEY',fileS3Key]:null, ([tag,fileS3Key])=>getPDFUrl(fileS3Key))

    
    const getPDFUrl = async (fileS3Key) => {


            console.log(`getting PDF URL for S3 Key:${fileS3Key}`)
        


            const { credentials } = await fetchAuthSession();
            const S3_BUCKET = 'divisibly-statements';
            const region = amplifyConfig.aws_project_region

            const client = new CognitoIdentityProviderClient({
                region: region,
                credentials: credentials
            });

            AWS.config.update(
                {
                    region: region,
                    credentials: credentials
                }
            );

            const s3 = new AWS.S3({
                apiVersion: "2006-03-01"
            });



            const params = {
                Bucket: S3_BUCKET,
                Key: fileS3Key,
            };

            const signedUrl = await s3.getSignedUrlPromise('getObject', params);
            return signedUrl
            

    }
    



    return {currentTransaction, setCurrentTransaction, pdfUrl}

}

export default usePDFForTransaction