import * as React from 'react';
import { useContext, useMemo, useState, useEffect, Fragment } from 'react';
import UserProfileContext from './userprofilecontext.js';
import usePDFForTransaction from './pdf-ref-hook.js';
import PDFBottomPanel from './pdf-bottom-panel';
import TransferCandidatesPreviewGrid from './grid-review_transfer_candidates';



export default function TransferCandidatesPDFBottomPanel(props) {

    //console.log(`rendering TransferCandidatesPDFBottomPanel`)

    const transferCandidates = props.transferCandidates ? props.transferCandidates : []
    const processRowUpdate = props.processRowUpdate ? props.processRowUpdate : () => { }

    const userProfileContext = useContext(UserProfileContext);
    const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg.orgID
    const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase.caseID

    const { currentTransaction: currentCandidate, setCurrentTransaction: setCandidate, pdfUrl: candidatePdfUrl } = usePDFForTransaction(orgID, caseID)

    useEffect(() => {

        const candidate = transferCandidates?.length > 0 ? transferCandidates[0] : null
        setCandidate(candidate)

    }, [transferCandidates]

    )

    const handleCandidateRowClick = (row) => {
        setCandidate(row)
    }



    return (
        <Fragment>
            {candidatePdfUrl &&
                <PDFBottomPanel topChild={<TransferCandidatesPreviewGrid transactions={transferCandidates} handleRowClick={handleCandidateRowClick} processRowUpdate={processRowUpdate} />} highlightedTxn={currentCandidate} fileUrl={candidatePdfUrl} />
            }
        </Fragment>
    )
}

