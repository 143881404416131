import * as React from 'react';
import Box from '@mui/material/Box';


import { useGridApiContext } from '@mui/x-data-grid';
import { unstable_useEnhancedEffect as useEnhancedEffect } from '@mui/utils';
import { Autocomplete } from '@mui/material';
import { TextField } from '@mui/material';




export default function SubcategoryEditInputCell(props) {
    const { id, value, field, hasFocus, subcategoryOptions } = props;
    //console.log(`at top of edit input cell, value=${JSON.stringify(value)}`)
    const selectedOption=value?subcategoryOptions.find(option=>option.subcategory==value):null
    //console.log(`at top of edit input cell, selectedOption=${JSON.stringify(selectedOption)}`)

    const apiRef = useGridApiContext();
    const ref = React.useRef(null);
  
    const handleChange = (event, newValue) => {
      //console.log(`changing value to ${JSON.stringify(newValue)}, newValue.subcategory=${newValue.subcategory}`)
      if(newValue){
        apiRef.current.setEditCellValue({ id, field, value: newValue.subcategory });
        }
    };
  
    useEnhancedEffect(() => {
      if (hasFocus && ref.current) {
        const input = ref.current.querySelector('input');
        input?.focus();
      }
    }, [hasFocus]);

    const sortCategorySubcategory = (a,b) => {
      const categoryCompare = -b.category.localeCompare(a.category)
      if (categoryCompare != 0) {
        return categoryCompare
      }
      return -b.subcategory.localeCompare(a.subcategory)
    }

    const isOptionEqualToValue = (option, value) =>{
      //console.log(`in isOptionEqualToValue option=${JSON.stringify(option)}, value=${value}`)
      return option.subcategory == value.subcategory
    }

    const getOptionLabel= (option) => {
      //console.log(`in getOptionLabel, option=${JSON.stringify(option)}`)
      return option.subcategory
    }
  
    return (
      <Box sx={{ width: '100vw', alignItems: 'left' }}>
        <Autocomplete
          required
          autoSelect
          ref={ref}
          name={field}
          value={selectedOption}
          onChange={handleChange}
          options={subcategoryOptions.sort((a, b) => sortCategorySubcategory(a,b))}
          //getOptionLabel={(option) => option.subcategory}
          getOptionLabel={getOptionLabel}
          groupBy={(option) => option.category}
          renderInput={(params) => <TextField {...params} required />}
          isOptionEqualToValue={isOptionEqualToValue}
        />
      </Box>
    );
  }