import * as React from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function RestoreValueActionItem({ restoreValue, currentValue, originalValue, currentDisplayValue, originalDisplayValue, ...props }) {
    const [open, setOpen] = React.useState(false);


    return (
      <React.Fragment>
        <GridActionsCellItem {...props} onClick={() => setOpen(true)} />
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Restore Original Value?</DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: "success.dark" }}>

              Current Value: {currentDisplayValue}

            </DialogContentText>
            <DialogContentText sx={{ color: "warning.dark" }}>

              Original Value: {originalDisplayValue}

            </DialogContentText>

            <DialogContentText sx={{ color: "warning.dark" }}>

              This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen(false)}>Cancel</Button>
            <Button
              onClick={() => {
                setOpen(false);
                restoreValue();
              }}
              color="warning"
              autoFocus
            >
              Restore
            </Button>
          </DialogActions>
        </Dialog>
      </React.Fragment>
    );
  }
