import React from "react";
import { Box, Typography } from '@mui/material';
import Title from './title';

import { DataGrid } from "@mui/x-data-grid";
import { blue } from "@mui/material/colors";

//import {postNeo4j} from '../../api/pydbcall';



function preventDefault(event) {
  event.preventDefault();
}
function enableDefault(event) {
  return;
}

export default function CategoryDataPreview(props) {
    const data=props.data?props.data:[]
    const columnNames=props.columnNames?props.columnNames:[]
    const subcategoryColumn=props.subcategoryColumn?props.subcategoryColumn:'1'
    const categoryColumn=props.categoryColumn?props.categoryColumn:'2'
    const drfaCategoryColumn=props.drfaCategoryColumn?props.drfaCategoryColumn:'3'
    const drfaSubcategoryColumn=props.drfaSubcategoryColumn?props.drfaSubcategoryColumn:'4'
    const incomeExpenseColumn=props.incomeExpenseColumn?props.incomeExpenseColumn:'5'

    let rows=[];
    let columns=[];
    rows=data.map(
      (row,index) => ({'id':index, ...row})
    );
   
    
    columns=props.columns?props.columns:columnNames.map(
      (col) => ({field: col, headerName: col, width: 200})
    )
  

    const columnGroupingModel = [
      {
        groupId: 'Subcategory',
        description: '',
        headerClassName: 'mytheme--subcategory-group',
        children: [{ field: subcategoryColumn }],
      },
      {
        groupId: 'Category',
        description: '',
        headerClassName: 'mytheme--category-group',
        children: [{ field: categoryColumn }],
      },
      {
        groupId: 'DRFA Category',
        description: '',
        headerClassName: 'mytheme--drfacategory-group',
        children: [{ field: drfaCategoryColumn }],
      },
      {
        groupId: 'DRFA Subcategory',
        description: '',
        headerClassName: 'mytheme--drfasubcategory-group',
        children: [{ field: drfaSubcategoryColumn }],
      },
      {
        groupId: 'Income/Expense/Transfer Indicator',
        description: '',
        headerClassName: 'mytheme--incomeexpense-group',
        children: [{ field: incomeExpenseColumn }],
      },
    ];
   
  
  return (
    <React.Fragment>
      <Title>{props.preview_title}</Title>
      
      
      <Box
                sx={{
                  // flex: 1,
                  //       //display: 'flex',
                  //       display: 'inline-block',
                  //       //width: '50vw',
                  //       width: '100%',
                  //       //height: '75vh',
                  //       alignItems: 'center',
                  //       justifyContent: 'center',
                    
                  //   maxHeight: '75vh',
                  
                    display: 'flex',
                    flexDirection: 'column',
                    height: '70vh',  // Ensure height is set
                    width: '100%',
                  
                }}
            >
      <DataGrid sx={{
                    // flexGrow: 1,
                    // '& .MuiDataGrid-virtualScroller': {
                    //   overflow: 'auto !important',  // Ensure the virtual scroller can overflow
                    // },
                    //maxHeight: '75vh',
                    '& .mytheme--subcategory-group': {
                      backgroundColor: blue[200],
                    },
                    '& .mytheme--category-group': {
                      backgroundColor: blue[400],
                    },
                    '& .mytheme--drfacategory-group': {
                      backgroundColor: blue[500],
                      color: blue[50],
                    },
                    '& .mytheme--drfasubcategory-group': {
                      backgroundColor: blue[600],
                      color: blue[50],
                    },
                    '& .mytheme--incomeexpense-group': {
                      backgroundColor: blue[800],
                      color: blue[50],
                    },
                    
                }} rows={rows} columns={columns} columnGroupingModel={columnGroupingModel} 
                //autoHeight={false}  // Ensure autoHeight is false
                //disableExtendRowFullWidth
                />
      </Box>
      
    </React.Fragment>
  );
}