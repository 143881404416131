import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

export default function EditFormDialog(props) {
  
  const parentOnSubmit = props.onSubmit?props.onSubmit:() => {}
  const parentOnCancel = props.onCancel?props.onCancel:() => {}

  if(!props.form){ throw new Error('you must pass a form in the "form" property')}
  


  const handleCancel = () => {
    parentOnCancel()
  };

  const handleSubmit = (data) => {
    parentOnSubmit(data)
  };

  return (
    <React.Fragment>
    
    <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title?props.title:"Edit Data"}</DialogTitle>
        <DialogContent>
          <props.form data={props.data} onCancel={handleCancel} onSubmit={handleSubmit} />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}