import * as React from 'react';
import { useEffect, useState } from 'react';
import { Viewer } from '@react-pdf-viewer/core';
import { pdfjs } from 'react-pdf';

import '@react-pdf-viewer/core/lib/styles/index.css';

import JumpToPagePlugin from './pdf-jump-page-plugin.tsx';
import { Worker, SpecialZoomLevel, LayerRenderStatus, Plugin, PluginOnCanvasLayerRender } from '@react-pdf-viewer/core';
import { highlightPlugin, Trigger } from '@react-pdf-viewer/highlight';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';


////////////////////////////////////
const BBOX_IDX_LEFT = 0
const BBOX_IDX_TOP = 1
const BBOX_IDX_RIGHT = 2
const BBOX_IDX_BOTTOM = 3

/// test data below
// const test_ocr_boxes = [
//   {
//     page_idx: 1,
//     bbox: [
//       153,
//       467,
//       1645,
//       487
//     ],
//   },
//   {
//     page_idx: 1,
//     bbox: [
//       156,
//       1813,
//       1639,
//       1830
//     ]
//   }
//]


function normalizeHighlightArea(ocr_area, pdfWidth, pdfHeight) {
  // normalizes the bounding box from pixels to percent to allow for scaling

  // working from: https://react-pdf-viewer.dev/examples/render-the-highlight-areas/

  const pageIndex = ocr_area.page_idx
  let result = {
    pageIndex: pageIndex,
    height: 0,
    width: 0,
    left: 0,
    top: 0,
  }
  if (ocr_area.bbox) {

    //console.log(`entering computation, bbox=${JSON.stringify(ocr_area)}`)
    const bbox_top_px = ocr_area.bbox[BBOX_IDX_TOP]
    const bbox_bottom_px = ocr_area.bbox[BBOX_IDX_BOTTOM]
    const bbox_left_px = ocr_area.bbox[BBOX_IDX_LEFT]
    const bbox_right_px = ocr_area.bbox[BBOX_IDX_RIGHT]


    // assumptions on page size, need to determine if there is a way to identify this per each PDF page
    // via PDF library, ref:  https://acrobatusers.com/forum/javascript/retrieve-pdf-page-size-through-javascripting/#:~:text=Use%20the%20doc%20method%20%22getPageBox,1%2F72%20of%20an%20inch.
    //     Use the doc method "getPageBox()" method for the "media" box to get the page size in points. 1 point is 1/72 of an inch.

    // The JavaScript API Reference example:

    // var aRect = this.getPageBox("Media");
    // var width = aRect[2] - aRect[0];
    // var height = aRect[1] - aRect[3];
    // console.println("Page 1 has a width of " + width + " and a height of " +
    // height);

    // // now just add the conversion from points to inches
    // console.println("Page 1 has a width of " + (width * (1/72) )+ " inches and a height of " +
    // (height * (1/72) ) + " inches");

    //Just an FYI, when using getPageBox, you can specify "nPage" as your page number. I imagine you'll be working with multi-page documents. The solution above (copy and pasted from the API reference verbatim (sans points/inches conversion) will only work with page 0 (first page in the document). Also, "Media" isn't a documented working value, unless it's omitted in the documentation.

    //// MORE REFERENCE AT:
    /////https://opensource.adobe.com/dc-acrobat-sdk-docs/library/jsapiref/doc.html#getpagebox

    const dpi_choices = [300, 72] // keep in descending order

    for (let i = 0; i < dpi_choices.length; i++) {
      const scan_pixel_per_inch = dpi_choices[i]

      const page_height_inches = pdfHeight //11.0
      const page_width_inches = pdfWidth //8.5

      if (pdfWidth === 0) break
      

      //console.log(`Inside scaling routine, PDF Height = ${page_height_inches}`)
      //console.log(`Inside scaling routine, PDF Width = ${page_width_inches}`)

      const page_height_px = page_height_inches * scan_pixel_per_inch
      const page_width_px = page_width_inches * scan_pixel_per_inch

      const bbox_top_pct = bbox_top_px / page_height_px * 100
      const bbox_height_pct = (bbox_bottom_px - bbox_top_px) / page_height_px * 100
      const bbox_left_pct = bbox_left_px / page_width_px * 100
      const bbox_width_pct = (bbox_right_px - bbox_left_px) / page_width_px * 100

      result = {
        pageIndex: pageIndex,
        height: bbox_height_pct,
        width: bbox_width_pct,
        left: bbox_left_pct,
        top: bbox_top_pct,
      }
      

      if(bbox_width_pct > 100){
        console.log(`WARNING - Bounding Box Computation Exceeds 100% at ${scan_pixel_per_inch} DPI, review the DPI list and/or this algorithm`)
        break
      }

      if(bbox_width_pct > 50){
        console.log(`guessing at DPI ${scan_pixel_per_inch} width=${bbox_width_pct}`)
        break
      }


    }

    // const scan_pixel_per_inch = 300 /// 72 for lending bank statement example

    // const page_height_inches = pdfHeight //11.0
    // const page_width_inches = pdfWidth //8.5

    // console.log(`Inside scaling routine, PDF Height = ${page_height_inches}`)
    // console.log(`Inside scaling routine, PDF Width = ${page_width_inches}`)

    // const page_height_px = page_height_inches * scan_pixel_per_inch
    // const page_width_px = page_width_inches * scan_pixel_per_inch

    // const bbox_top_pct = bbox_top_px / page_height_px * 100
    // const bbox_height_pct = (bbox_bottom_px - bbox_top_px) / page_height_px * 100
    // const bbox_left_pct = bbox_left_px / page_width_px * 100
    // const bbox_width_pct = (bbox_right_px - bbox_left_px) / page_width_px * 100

    // console.log(`bbox width pct=${bbox_width_pct}`)

    // result = {
    //   pageIndex: pageIndex,
    //   height: bbox_height_pct,
    //   width: bbox_width_pct,
    //   left: bbox_left_pct,
    //   top: bbox_top_pct,
    // }

  }

  //console.log(`result=${JSON.stringify(result)}`)

  return result

}

////////////////////////////////////

interface PDFReviewTransactionProps {
  highlightedTxn;
  fileUrl: string;
  height;
}

interface AreaType {
  pageIndex: any;
  height: number;
  width: number;
  left: number;
  top: number;
}

const PDFReviewer: React.FC<PDFReviewTransactionProps> = ({ highlightedTxn, fileUrl, height }) => {


  const [jumpToPagePluginInstance, setJumpToPagePluginInstance] = useState(JumpToPagePlugin())
  const [areas, setAreas] = useState<AreaType[]>([])
  const [pdfWidth, setPdfWidth] = useState<number>()
  const [pdfHeight, setPdfHeight] = useState<number>()

  //const jumpToPagePluginInstance = JumpToPagePlugin();

  height = height ? height : '75vh'


  useEffect(() => {
    if (highlightedTxn && highlightedTxn.page_idx) {
      const { jumpToPage } = jumpToPagePluginInstance;
      //console.log(`in useEffect, jumping to page ${highlightedTxn.page_idx}`)
      //console.log(jumpToPage)
      jumpToPage(highlightedTxn.page_idx)
    }
    const new_areas = highlightedTxn ? [normalizeHighlightArea(highlightedTxn, pdfWidth, pdfHeight)] : []
    setAreas(new_areas)

  }, [pdfHeight, pdfWidth, highlightedTxn, jumpToPagePluginInstance])



  //let areas = highlightedTxn ? [normalizeHighlightArea(highlightedTxn)] : []


  const renderHighlights = (props) => (
    <div>
      {areas
        .filter((area) => area.pageIndex === props.pageIndex)
        .map((area, idx) => (
          <div
            key={idx}
            className="highlight-area"
            style={Object.assign(
              {},
              {
                background: 'yellow',
                opacity: 0.4,
              },
              props.getCssProperties(area, props.rotation)
            )}
          />
        ))}
    </div>
  );

  const highlightPluginInstance = highlightPlugin({
    renderHighlights,
    trigger: Trigger.None,
  });

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const workerUrl = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

  const message = 'customer@email.com';

  const customCanvasPlugin = (): Plugin => {
    const onCanvasLayerRender = (e: PluginOnCanvasLayerRender) => {
      // Return if the canvas isn't rendered completely
      if (e.status !== LayerRenderStatus.DidRender) {
        return;
      }

      // `e.ele` is the canvas element
      const canvas = e.ele;

      const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      // console.log(`canvas height=${canvas.height}`)
      // console.log(`canvas width=${canvas.width}`)
      // console.log(`scale=${e.scale}`)

      const pdf_dpi = 72 // standard 72 dpi resolution for PDF

      const original_width = canvas.width / e.scale / pdf_dpi
      setPdfWidth(original_width)

      const original_height = canvas.height / e.scale / pdf_dpi
      setPdfHeight(original_height)

      //console.log(`original width=${original_width} inches`)
      //console.log(`original_height=${original_height} inches`)

      // const fonts = ctx.font.split(' ');
      // const fontSize = parseInt(fonts[0], 10);

      // ctx.textAlign = 'center';
      // ctx.font = `${fontSize * e.scale * 4}px ${fonts[1]}`;
      // ctx.scale(e.scale,e.scale)

      // ctx.fillStyle = '#CCC';
      

      //ctx.fillText(message, centerX, 100);
    };

    return {
      onCanvasLayerRender,
    };
  };

  const customCanvasPluginInstance = customCanvasPlugin();

  return (
    <>
      <div
        style={{
          border: '1px solid rgba(0, 0, 0, .3)',
          height: height,

        }}
      >
        <Worker workerUrl={workerUrl}>
          <Viewer fileUrl={fileUrl} defaultScale={SpecialZoomLevel.PageFit} plugins={[defaultLayoutPluginInstance, highlightPluginInstance, jumpToPagePluginInstance, customCanvasPluginInstance]} />
        </Worker>
      </div>
    </>
  );
};

export default PDFReviewer;