import authListUsers from "./authlistusers"

export default function authFindUser(email){
    const listUsersInput = {
        AttributesToGet: [
          "email",
          "sub"
        ],
        Filter: `"email"^="${email}"`,
        
      }
      const userList=authListUsers(listUsersInput)

      return userList
}