import { post } from 'aws-amplify/api';
import { ta } from 'date-fns/locale';
import pako from 'pako';  // For decompression if needed

//require('./json.date-extensions')
    //JSON.UseDateParser();    

export async function queryPrimaryNeo4j(qry,parameters={}) {
    return _queryNeo4j(qry,parameters,"PRIMARY")
}

export async function queryCaseNeo4j(qry,parameters={}) {
    return _queryNeo4j(qry,parameters,"CASE")
}

export async function _queryNeo4j(qry,parameters={},target_db="PRIMARY") {
    
        //console.log(`calling queryNeo4j: qry=${qry} parameters=${JSON.stringify(parameters)}`)
        //console.trace()

        const requestID=crypto.randomUUID();
        //console.log(`requestID: ${requestID} JSON.stringify(parameters): ${JSON.stringify(parameters)}`)


        const payload={
          apiName: 'pyneo4jproxy',
          path: '/neo4j/proxy',
          options: {
            body: {
              compress: true,
              requestID: requestID,
              query: qry,
              parameters: parameters,
              target: target_db
            }
          }
        }

    
    
        const restOperation = post(payload); 

        
        const { body, headers } = await restOperation.response;
        //console.log(`response headers: ${JSON.stringify(headers)}`)
        

        const isCompressed = headers['Content-Encoding'] === 'gzip' || headers['X-Compressed'] === 'true';
        //const traceID=headers['X-Amzn-Trace-Id'];   //didn't work
        //console.log(`traceID: ${traceID}`)

        if(!isCompressed){
          //console.log(`requestID: ${requestID} response was not compressed`)

          let json = await body.json();
          //console.log(`response body: ${JSON.stringify(json)}`)
        
          json = JSON.parseWithDate(JSON.stringify(json));
    
          return json
        }
        else{
          //console.log(`requestID: ${requestID} response was compressed`)
          const base64CompressedData = body;
            const compressedData = atob(base64CompressedData);

            // Convert to Uint8Array for decompression
            const compressedArray = Uint8Array.from(compressedData, c => c.charCodeAt(0));

            // Decompress using pako
            const decompressedData = pako.inflate(compressedArray, { to: 'string' });

            // Parse the JSON from the decompressed data
            const jsonResponse = JSON.parseWithDate(decompressedData);
            return jsonResponse;
        }
    
    

}

export async function mutatePrimaryNeo4j(mutation,parameters={},sb4create=null) {
  return _mutateNeo4j(mutation,parameters,sb4create,"PRIMARY")
}
export async function mutateCaseNeo4j(mutation,parameters={},sb4create=null) {
  return _mutateNeo4j(mutation,parameters,sb4create,"CASE")
}

async function _mutateNeo4j(mutation,parameters={},sb4create=null,target_db="PRIMARY") {
    
  //console.log(`ENTERING NEO4J: ${JSON.stringify(sb4create)}`)
  const payload={
    apiName: 'pyneo4jproxy',
    path: '/neo4j/proxy',
    options: {
      body: {
        mutation: mutation,
        parameters: parameters,
        search_before_create: sb4create,
        target: target_db
      }
    }
  }


  //console.log(`posting`)
  //console.log(payload)

  const restOperation = post(payload); 

  
  const { body } = await restOperation.response;
  let json = await body.json();

  
  json = JSON.parseWithDate(JSON.stringify(json));
  //console.log(`RETURN FROM NEO4J: ${JSON.stringify(json)}`)

  return json



}