import Paper from '@mui/material/Paper';
import React from 'react';


function DashboardPaper(props) {
    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
            {props.children}
        </Paper>
    );
}

export default DashboardPaper;
