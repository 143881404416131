import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import {
  GridRowModes,
  DataGrid,
  GridToolbarContainer,
  GridActionsCellItem,
  GridRowEditStopReasons,
} from '@mui/x-data-grid';
import { dateGetter,currencyFormatter } from './type-utilities';
import { renderSwitch, renderSwitchEditInputCell } from './grid-input-switch-component';
import ReportContext from '../ui/user-report-context.js';
import { useContext } from 'react';
//import mutateBankTransaction from '../../api/mutatebanktransaction';



export default function ReportCrudGrid(props) {

  const reportContext = useContext(ReportContext);
  const report = reportContext.getReportByID(props.reportID)
  const parentHandleRowClick = props.handleRowClick ? props.handleRowClick : (txn) => { }
  const gridColumns=props.columns?props.columns:[]
  const rows=report.isLoading?[]:report.reportData

  //const rows=props.rows?props.rows:[]
  //const setRows=props.setRows?props.setRows:()=>{}

  //console.log(`rows: ${JSON.stringify(rows)}`)

  //const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const setRows = (newRows) => {
    reportContext.updateReportData(props.reportID,newRows)
  }

  // React.useEffect(() => {

  //   //console.log(`computing the transaction map....`)
  //   const transactions = props.transactions.map(transaction => ({ ...transaction, id: transaction.pk }));
  //   setRows(transactions)
  //   //console.log(`computed the transaction map....`)


  // }, [props.transactions]

  // )

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleDeleteClick = (id) => () => {
    console.log(`delete ${id} clicked`)
    //setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      //reportContext.updateReportData(props.reportID,rows.filter((row) => row.id !== id))
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow) => {
    const updatedRow = { ...newRow, isNew: false };
    //const result=mutateBankTransaction(props.currentUserProfile,updatedRow)
    //reportContext.updateReportData(props.reportID,rows.map((row) => (row.id === newRow.id ? updatedRow : row)))
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    //console.log(`row "${JSON.stringify(params.row)}" clicked`);
    parentHandleRowClick(params.row)
  };


  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />  
        ];
      },
    },
    ...gridColumns


    
  ];

  return (
    <Box
      sx={{
        height: '75vh',
        //width: '100%',
        '& .actions': {
          color: 'text.secondary',
        },
        '& .textPrimary': {
          color: 'text.primary',
        },
      }}
    >
      <DataGrid
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        onRowClick={handleRowClick}
        slots={{
          //toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
      />
    </Box>
  );
}