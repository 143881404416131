import React, { useState, useEffect, useContext } from 'react';
import * as XLSX from 'xlsx';

import UserProfileContext from '../ui/userprofilecontext.js';
import PortalFrame from '../ui/portal-frame.js';
import PageSkeleton from "../ui/pageskeleton.js";
import {getTotalTransactionCount, getTransactionsForCurrentCase, getAccountsForCurrentCase} from '../../api/swrtransactionsforcurrentcaseemergencydump';
import SnackbarContext from '../ui/snackbar-context.js';
import { dateGetter } from '../ui/type-utilities';



  export default function EmergencyDump(props) {
    const snackbarContext = useContext(SnackbarContext);
    const userProfileContext = useContext(UserProfileContext);
    const orgID = userProfileContext.isLoading
      ? null
      : userProfileContext.userProfile.currentOrg?.orgID;
    const caseID = userProfileContext.isLoading
      ? null
      : userProfileContext.userProfile.currentCase?.caseID;

    const [transactionsData, setTransactionsData] = useState([]);
    const [accountsData, setAccountsData] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(undefined);

    const limit = 4000;

    useEffect(() => {
      if (orgID && caseID) {
        fetchData();
      }
    }, [orgID, caseID]);

    const fetchData = async () => {
      setIsFetching(true);
      try {
        const total = await getTotalTransactionCount(orgID, caseID);
        setTotalRecords(total);

        let allTransactions = [];
        let skip = 0;

        while (skip < total) {
          const transactions = await getTransactionsForCurrentCase(
            orgID,
            caseID,
            skip,
            limit
          );
          console.log(transactions)
          allTransactions = [...allTransactions, ...transactions];
          skip += limit;
        }
        console.log('HERE')

        setTransactionsData(allTransactions);

        // Fetch accounts data
        const accounts = await getAccountsForCurrentCase(orgID, caseID);
        setAccountsData(accounts);
      } catch (error) {
        console.error('Error fetching data:', error);
        snackbarContext.openSnackbarError(`Error fetching data: ${error.message}`);
      } finally {
        setIsFetching(false);
      }
    };

    const downloadExcel = () => {
      setIsDownloading(true);

      // Process transactionsData
      const processedTransactionsData = transactionsData.map(transaction => ({
        ...transaction,
        'Transaction Update Date': dateGetter(transaction['Transaction Update Date']),
      }));

      const wsTransactions = XLSX.utils.json_to_sheet(processedTransactionsData);
      const wsAccounts = XLSX.utils.json_to_sheet(accountsData);

      // Adjust column widths based on data length
      const adjustColumnWidth = (sheet, data) => {
        if (data.length === 0) return;

        const colWidths = Object.keys(data[0]).map(key => ({
          wch: Math.max(
            ...data.map(row => (row[key] ? row[key].toString().length : 0)),
            key.length
          ),
        }));

        sheet['!cols'] = colWidths;
      };

      // Adjust widths for the Transactions sheet
      adjustColumnWidth(wsTransactions, processedTransactionsData);

      // Adjust widths for the Account Details sheet
      adjustColumnWidth(wsAccounts, accountsData);

      // Create a new workbook and append the worksheets
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, wsTransactions, 'Transactions');
      XLSX.utils.book_append_sheet(wb, wsAccounts, 'Account Details');

      try {
        XLSX.writeFile(wb, `EmergencyCaseDump${caseName}.xlsx`);
      } catch (error) {
        console.error('Error generating Excel file:', error);
        snackbarContext.openSnackbarError(`Error generating Excel file: ${error.message}`);
      } finally {
        setIsDownloading(false);
      }
    };


    const title = 'Emergency Data Export';

    const buttonStyle = {
      display: 'block',
      margin: '20px auto',
      padding: '20px 40px',
      fontSize: '24px',
      backgroundColor: isFetching || isDownloading ? 'grey' : 'red',
      color: 'white',
      border: 'none',
      borderRadius: '10px',
      cursor: isFetching || isDownloading ? 'not-allowed' : 'pointer',
      textAlign: 'center',
    };

    const orgName = userProfileContext.userProfile?.currentOrg?.orgName || '';
    const caseName = userProfileContext.userProfile?.currentCase?.caseName || '';

    if (userProfileContext.isLoading || isFetching || totalRecords === undefined) {
      return (
        <PortalFrame title={title} orgName={orgName} caseName={caseName}>
          <PageSkeleton />
          <p>
            Loading {transactionsData ? transactionsData.length : 0}/{totalRecords}{' '}
            transactions...
          </p>
        </PortalFrame>
      );
    }

    if (!userProfileContext.userProfile || !userProfileContext.userProfile.currentCase) {
      return (
        <PortalFrame title={title}>
          <p>You are not assigned to any cases</p>
        </PortalFrame>
      );
    }

    return (
      <PortalFrame title={title} orgName={orgName} caseName={caseName}>
        <button
          style={buttonStyle}
          onClick={downloadExcel}
          disabled={isFetching || isDownloading}
        >
          {isFetching
            ? 'Loading Data...'
            : isDownloading
            ? 'Downloading...'
            : 'Emergency Download'}
        </button>
      </PortalFrame>
    );
  }
