import React, { useState } from 'react';
import {
  Toolbar,
  Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import {usePrimaryQuery} from '../../api/swrdbcall.js';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { MUTATE_UPDATE, MUTATE_DELETE } from '../../api/mutate-actions.js';
import { mutate } from "swr";
import ListPaper from './paper-list.js';
import EditFormDialog from './dialog-edit-form.js';
import SearchBeforeCreateDialog from "./dialog-search-before-create.js";
import ConfirmBeforeDeleteDialog from './dialog-confirm-before-delete.js';

const EditList = (props) => {
 

  const [selectedItem, setselectedItem] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editData, setEditData] = useState({})
  const [existingMatches, setExistingMatches] = useState()
  const [openExistingDialog, setOpenExistingDialog] = useState(false)
  const [openDeleteConfirmDialog, setOpenDeleteConfirmDialog] = useState(false)

  const emptyItem = {}
  const mutateHandler = props.mutateHandler
  const editFormObjectLabel=props.objectLabel?props.objectLabel:"Data"

  
  const listQuery = props.listQuery
  const listParams = props.orgID?{ "orgID": props.orgID }:{}
  const queryResult = usePrimaryQuery(listQuery,listParams);

  if (queryResult.isLoading || !queryResult.data) {
    return (
      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <Skeleton variant="rectangular" sx={{minHeight: 300}} />
      </Box>
    )
  }

  const handleCreateClick = () => {
    //create is just an edit on a blank (empty) item
    handleEditClick(emptyItem)
  }

  const handleEditClick = (data) => {
    // set the selected item
    setselectedItem(data);
    // set the data to edit
    setEditData(data)
    // open the edit item dialog window
    setOpenEditDialog(true);
  };

  const handleDelete = (data) => {
    // set the selected item
    setselectedItem(data);
    // open the delete confirmation dialog
    setOpenDeleteConfirmDialog(true);
  }

  const forceListReload = () => {
    // the following command forces the cache to invalidate and triggers a list query reload    
    mutate(
      key => String(key).startsWith(listQuery), // which cache keys are updated
      undefined,   // update cache data to `undefined`
      { revalidate: true } // do not revalidate
    );
  }


  const handleSaveWithSearch = (data, search_before_create = true) => {
    // search before saving, and if no existing data or user asks to proceed, then save the data
    try {

      // call handler to update the data 
      mutateHandler(props.orgID, props.orgName, props.currentUserID, data, MUTATE_UPDATE, search_before_create).then((result) => {
        // console.log(`in list-edit.js line 81, result=${JSON.stringify(result)}`)
        if (result.search_before_create) {
          // save the edited data into state variable, in case user decides to proceed we will recover it from there
          setEditData(data)
          // set the existing matches into state variable for dialog to display it
          setExistingMatches(result.search_before_create)
          // open the dialog window to prompt about the existing data
          setOpenExistingDialog(true)
        }
        else {
          // force reload of list
          forceListReload()
          // close the edit dialog
          setOpenEditDialog(false);
        }


      })


    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleSave = (data) => {
    // search before saving, and if no existing data or user asks to proceed, then save the data
    handleSaveWithSearch(data)
  }


  const onProceedExisting = () => {
    // user has been shown that there are similar records that exist, but still wants to proceed with saving

    //clear the existing data list
    setExistingMatches()
    //save the data that was cached in the editData state variable, with no check for existing data
    handleSaveWithSearch(editData, false)
    //close the existing data dialog
    setOpenExistingDialog(false)
  }

  const onCancelExisting = () => {
    // user has been shown that there are similar records that exist, and cancelled out of the save

    //clear the existing data list
    setExistingMatches()
    //close the existing data dialog
    setOpenExistingDialog(false)
  }

  const onProceedDelete = () => {
    // user has been asked to confirm the delete, and still wants to proceed with delete

    // call handler to delete the data 
    mutateHandler(props.orgID,props.orgName, props.currentUserID, selectedItem, MUTATE_DELETE).then((response) => {
      //after save, reload the list
      forceListReload()
    }

    )
    //close the delete confirm dialog
    setOpenDeleteConfirmDialog(false)
  }

  const onCancelDelete = () => {
    // user has been asked to confirm the delete, and cancelled out of the delete

    //close the delete confirm dialog
    setOpenDeleteConfirmDialog(false)
  }

  return (
    <div>
      <Box sx={{ flexGrow: 1, maxWidth: 752 }}>
        <ListPaper>
          <Toolbar>
            <Button color="primary" startIcon={<AddIcon />} onClick={handleCreateClick}>
              Add record
            </Button>
          </Toolbar>
          <props.editList showEdit={true} showDelete={true} data={queryResult.data.records.map( ({record})  => record )} handleEditClick={handleEditClick} handleDelete={handleDelete} />
        </ListPaper>
      </Box>
      <EditFormDialog title={"Edit "+editFormObjectLabel} form={props.editForm} open={openEditDialog} data={editData} onCancel={() => setOpenEditDialog(false)} onSubmit={handleSave} />
      <SearchBeforeCreateDialog open={openExistingDialog} list_element={props.editList} existing_data={existingMatches} onProceed={onProceedExisting} onCancel={onCancelExisting} />
      <ConfirmBeforeDeleteDialog open={openDeleteConfirmDialog} list_element={props.editList} data={selectedItem} onProceed={onProceedDelete} onCancel={onCancelDelete} />
    </div>
  );
};

export default EditList;
