import * as React from 'react';
import List from '@mui/material/List';
import { Collapse } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
//import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
//import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import RequestPageIcon from '@mui/icons-material/RequestPage';
import GradingIcon from '@mui/icons-material/Grading';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';
import GavelIcon from '@mui/icons-material/Gavel';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import UploadIcon from '@mui/icons-material/Upload';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import FlagIcon from '@mui/icons-material/Flag';


export default function DrawerItemList(props) {

  const [openAnalysis, setOpenAnalysis] = React.useState(false);
  const [openAdmin, setOpenAdmin] = React.useState(false);


  const handleAnalysisClick = () => {
    setOpenAnalysis(!openAnalysis);
  };



  const handleAdminClick = () => {
    setOpenAdmin(!openAdmin);
  };

  return (
    <List component="nav">
      <ListItemButton component="a" href="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItemButton>

      <ListItemButton component="a" href="/manage_report_list">
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Manage Reports" />
      </ListItemButton>

      <ListItemButton onClick={handleAnalysisClick}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Analysis" />
        {openAnalysis ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={openAnalysis} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton sx={{ pl: 4 }} component="a" href="/analysis/COUNT_BY_CATEGORY">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis 1" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component="a" href="/analysis/INCOME_BY_CATEGORY">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis 2" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component="a" href="/analysis/EXPENSE_BY_CATEGORY">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis 3" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component="a" href="/analysis/FLAGGED_EXPENSE_BY_CATEGORY">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis 4" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component="a" href="/analysis/TOTAL_TRANSFERS_IN">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis 5" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component="a" href="/analysis/EXPENSE_BY_SUBCATEGORY">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis 6" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component="a" href="/analysis/FLAGGED_TRANSFERS_OUT">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis 7" />
          </ListItemButton>
          <ListItemButton sx={{ pl: 4 }} component="a" href="/analysis/TIME_SERIES">
            <ListItemIcon>
              <BarChartIcon />
            </ListItemIcon>
            <ListItemText primary="Analysis 8" />
          </ListItemButton>
        </List>
      </Collapse>



      <ListItemButton onClick={handleAdminClick}>
        <ListItemIcon>
          <AdminPanelSettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
        {openAdmin ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>

      <Collapse in={openAdmin} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/manage_org">
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Organizations" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/manage_user">
            <ListItemIcon>
              <PeopleIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Team" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/manage_case">
            <ListItemIcon>
              <GavelIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Cases" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/manage_case_assignment">
            <ListItemIcon>
              <AssignmentIndIcon />
            </ListItemIcon>
            <ListItemText primary="Manage Assignments" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/view_categories">
            <ListItemIcon>
              <WorkspacesIcon />
            </ListItemIcon>
            <ListItemText primary="View Categories" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/bulk_load_categories">
            <ListItemIcon>
              <UploadIcon />
            </ListItemIcon>
            <ListItemText primary="Load Categories" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/manage_category_rules">
            <ListItemIcon>
              <BookmarksIcon />
            </ListItemIcon>
            <ListItemText primary="Category Rules" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/manage_flag_rules">
            <ListItemIcon>
              <FlagIcon />
            </ListItemIcon>
            <ListItemText primary="Flag Rules" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/emergency_dump">
            <ListItemIcon>
              <CloudDownloadIcon />
            </ListItemIcon>
            <ListItemText primary="Emergency Case Dump" />
          </ListItemButton>

          <ListItemButton sx={{ pl: 4 }} component="a" href="/case_actions">
            <ListItemIcon>
              <ThumbUpAltIcon />
            </ListItemIcon>
            <ListItemText primary="Case Actions" />
          </ListItemButton>

        </List>
      </Collapse>

      <ListItemButton component="a" href="/manage_uploaded_files">
        <ListItemIcon>
          <UploadFileIcon />
        </ListItemIcon>
        <ListItemText primary="Manage Files" />
      </ListItemButton>


      <ListItemButton component="a" href="/review_bank_accounts">
        <ListItemIcon>
          <AccountBalanceIcon />
        </ListItemIcon>
        <ListItemText primary="Review Accounts" />
      </ListItemButton>

      <ListItemButton component="a" href="/review_bank_statements">
        <ListItemIcon>
          <RequestPageIcon />
        </ListItemIcon>
        <ListItemText primary="Review Statements" />
      </ListItemButton>

      <ListItemButton component="a" href="/review_statement_quality">
        <ListItemIcon>
        <GradingIcon />
        </ListItemIcon>
        <ListItemText primary="Review Statement Quality" />
      </ListItemButton>

      <ListItemButton component="a" href="/review_transactions">
        <ListItemIcon>
          <GradingIcon />
        </ListItemIcon>
        <ListItemText primary="Review Transactions" />
      </ListItemButton>

      <ListItemButton component="a" href="/review_transaction_categorization">
        <ListItemIcon>
          <AccountTreeIcon />
        </ListItemIcon>
        <ListItemText primary="Review by Category" />
      </ListItemButton>

    </List>

  );

}