import React from 'react';
//import Grid from '@mui/material/Grid';
import ChartPaper from '../ui/chart-paper';
import CompositionChart from '../ui/composition-chart';
import SimpleBarChart from './simple-bar-chart';
import SimpleLineChart from './simple-line-chart';




export default function SingleChartContent(props) {
    let chartcfg=props.chart;
    let Chart= 
        chartcfg.chart_type==="BAR" ? SimpleBarChart
        : chartcfg.chart_type==="LINE" ? SimpleLineChart
        : CompositionChart;
    
    return (
    
        
                        
                           
                            <ChartPaper orgID={props.orgID} caseID={props.caseID} {...chartcfg}>
                                <Chart orgID={props.orgID} caseID={props.caseID} {...chartcfg}/>
                            </ChartPaper>
                            
                            
                            
                        
                        
                        
        
    
    );
}