import * as React from 'react';
import { useContext, useMemo, useState, useEffect, Fragment } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import SnackbarContext from './snackbar-context.js';
import UserProfileContext from './userprofilecontext.js';
//import { removeNewlines } from './type-utilities.js';
//import DataPreviewGrid from './grid-data-preview.js';
import TransactionPreviewGrid from './grid-review_transaction_details.js';
import TransferCandidatesPreviewGrid from './grid-review_transfer_candidates.js';
import SideBySidePanel from './side-by-side-panel.js';
//import usePDFForTransaction from './pdf-ref-hook.js';
//import { Typography } from '@mui/material';
//import PDFBottomPanel from './pdf-bottom-panel.js';
import TransferCandidatesPDFBottomPanel from './pdf-bottom-panel-transfer-candidates.js';
import TransferTransactionPDFBottomPanel from './pdf-bottom-panel-transfer-transaction.js';
import ConfirmBeforeDeleteDialog from './dialog-confirm-before-delete.js';
import { findChangedFields } from './update-utils.js';
import mutateTransfer from '../../api/mutatetransfer.js';
import { IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import TransactionReviewContext from './transaction-review-context.js';
import Button from '@mui/material/Button';

export default function TransferReviewDialog(props) {

  //console.log(`rendering TransferReviewDialog`)

  const parentOnSubmit = props.onSubmit ? props.onSubmit : (data) => { }
  const parentOnCancel = props.onCancel ? props.onCancel : () => { }
  //const selectedTransaction = props.transaction ? props.transaction : null
  const transactions = props.transactions ? props.transactions : []
  //const mutateTransaction = props.mutateTransaction ? props.mutateTransaction : (txn) => {console.log(`(fake default) mutating transaction...`) }
  const parentProcessRowUpdate = props.processRowUpdate ? props.processRowUpdate : () => {}
  const parentProcessTransferUpdate = props.processTransferUpdate ? props.processTransferUpdate : () => {}
  const transactionReviewContext=useContext(TransactionReviewContext)
  const selectedTransaction=transactionReviewContext.transaction
  
  const transferCandidates = useMemo(()=>getTransferCandidates(selectedTransaction?.transfer_candidates ? selectedTransaction.transfer_candidates : []), [selectedTransaction,transactions])
  

  //const currentUserProfile = props.currentUserProfile

  // render row data from the transfer candidate IDs
  function getTransferCandidates(candidateIDs){
    //console.log(`getting transfer candidates, input =${JSON.stringify(candidateIDs)}`)
    const idsSet = new Set(candidateIDs.map(record=>record.txn_pk)); 
    const filteredRecords = transactions.filter(record => idsSet.has(record.pk));
    return filteredRecords.map((record)=>{ 
        const {txn_pk, transfer_props, ...rest}=candidateIDs.find(c=>c.txn_pk===record.pk)
        return {...record, transfer_disposition: transfer_props.disposition, transfer_days_diff: transfer_props.days_diff, transfer_type: transfer_props.type, transfer_amount_match: transfer_props.amount_match, transfer_descr_match: transfer_props.descr_match, ...rest } 
    })
    
  }

  const userProfileContext = useContext(UserProfileContext);
  const orgID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentOrg.orgID
  const caseID = userProfileContext.isLoading ? null : userProfileContext.userProfile.currentCase.caseID

  const snackbarContext = useContext(SnackbarContext);


  const processLeftRowUpdate = async (newRow, oldRow) => {
    const updatedRow = await parentProcessRowUpdate(newRow, oldRow)
    
    
    return updatedRow
  }

  const processRightRowUpdate = async (newRow, oldRow) => {
    const changedFields=findChangedFields(newRow,oldRow)
    let updatedRow=newRow
    if (Object.keys(changedFields).length > 0) {
      updatedRow = await parentProcessRowUpdate(newRow, oldRow)
      if(changedFields.transfer_disposition){
        mutateTransfer(userProfileContext.userProfile,selectedTransaction,updatedRow,updatedRow.transfer_disposition)
        parentProcessTransferUpdate(selectedTransaction,updatedRow,updatedRow.transfer_disposition)
      }
    }
    return updatedRow
  }


  const handleCancel = () => {
    transactionReviewContext.clearTransactionStack()
  
    parentOnCancel()
  };

  
  const handleClickPreviousTransaction = () => {
    const priorTransaction = transactionReviewContext.goPreviousTransaction()
    //console.log(`prior transaction: ${JSON.stringify(priorTransaction)}`)
  }



  return (
    <React.Fragment>

      <Dialog fullWidth={true} maxWidth={"xxl"} open={props.open} onClose={handleCancel}>
        <DialogTitle>{props.title ? props.title : "Review Transfer Candidates"}
        <IconButton aria-label="close" onClick={handleCancel} sx={{ position: 'absolute', right: 8, top: 8 }}>
              <CloseIcon />
            </IconButton>
            
        
        </DialogTitle>
        
          <DialogContent>
          {transactionReviewContext.hasPreviousTransaction() &&
        <Button onClick={handleClickPreviousTransaction} color="primary" sx={{ mt: 1, mb: 1 }}>Previous Transaction</Button>
        }
          <SideBySidePanel 
          //leftChild={<PDFBottomPanel topChild={<TransactionPreviewGrid transactions={selectedTransactions} processRowUpdate={processLeftRowUpdate} />} highlightedTxn={currentTransaction} fileUrl={transactionPdfUrl}/>} 
          leftChild={<TransferTransactionPDFBottomPanel transaction={selectedTransaction} processRowUpdate={processLeftRowUpdate}/>}
          //rightChild={<PDFBottomPanel topChild={<TransferCandidatesPreviewGrid transactions={transferCandidates} handleRowClick={handleCandidateRowClick} processRowUpdate={processRightRowUpdate} />} highlightedTxn={currentCandidate} fileUrl={candidatePdfUrl}/>} 
          rightChild={<TransferCandidatesPDFBottomPanel transferCandidates={transferCandidates} processRowUpdate={processRightRowUpdate} />}
          />
          


        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}