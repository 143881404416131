
import { TextField, Box, Button } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { createContext, useContext, useState, useEffect } from 'react';

// Create a context with default values
const DateRangeContext = createContext({
  dateRange: [null, null],
  setDateRange: () => {},
  minDate: null,
  maxDate: null,
  handleResetDateRange: () => {},
});

// Create a provider component
export const DateRangeProvider = ({ children, data, date_field }) => {
  const [dateRange, setDateRange] = useState([null, null]);
  const [minDate, setMinDate] = useState(null);
  const [maxDate, setMaxDate] = useState(null);

  useEffect(() => {
    const dates = data.map(item => new Date(item[date_field]));
    const minDate = new Date(Math.min(...dates));
    const maxDate = new Date(Math.max(...dates));
    setDateRange([minDate, maxDate]);
    setMinDate(minDate);
    setMaxDate(maxDate);
  }, [data]);

  const handleResetDateRange = () => {
    setDateRange([minDate, maxDate]);
  };

  return (
    <DateRangeContext.Provider value={{ dateRange, setDateRange, minDate, maxDate, handleResetDateRange }}>
      {children}
    </DateRangeContext.Provider>
  );
};

// Create a custom hook to use the DateRangeContext
export const useDateRange = () => useContext(DateRangeContext);



const DateRangePicker = () => {
    const { dateRange, setDateRange, minDate, maxDate, handleResetDateRange } = useDateRange();

    const handleStartDateChange = (date) => {
      if (!date) return; // Handle null case when clearing the date picker
      if ((!dateRange[1] || date <= dateRange[1]) && (!maxDate || date >= minDate)) {
        setDateRange([date, dateRange[1]]);
      } else if (date > dateRange[1]) {
        setDateRange([dateRange[1], dateRange[1]]); // Reset start date to end date if invalid
      } else {
        setDateRange([minDate, dateRange[1]]); // Reset start date to min date if invalid
      }
    };
  
    const handleEndDateChange = (date) => {
      if (!date) return; // Handle null case when clearing the date picker
      if ((!dateRange[0] || date >= dateRange[0]) && (!maxDate || date <= maxDate)) {
        setDateRange([dateRange[0], date]);
      } else if (date < dateRange[0]) {
        setDateRange([dateRange[0], dateRange[0]]); // Reset end date to start date if invalid
      } else {
        setDateRange([dateRange[0], maxDate]); // Reset end date to max date if invalid
      }
    };
  
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, padding: 0 }}>
          <DatePicker
            label="Start Date"
            value={dateRange[0]}
            onChange={handleStartDateChange}
            slotProps={{ textField: { variant: 'outlined' } }}
            minDate={minDate} // Set min date prop to enforce min date
            maxDate={dateRange[1]} // Set max date prop to enforce max date
          />
          <DatePicker
            label="End Date"
            value={dateRange[1]}
            onChange={handleEndDateChange}
            slotProps={{ textField: { variant: 'outlined' } }}
            minDate={dateRange[0]} // Set min date prop to enforce min date
            maxDate={maxDate} // Set max date prop to enforce max date
          />
          <Button variant="outlined" onClick={handleResetDateRange}>Reset</Button>
        </Box>
      </LocalizationProvider>
    );
  };
  
  export default DateRangePicker;
