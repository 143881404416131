//import { useContext } from 'react';
import { queryCaseNeo4j } from './pydbcall';
import { mutate } from "swr";
import useSWR from 'swr'
//import useSWRMutation from 'swr/mutation'
//import { dateGetter } from '../components/ui/type-utilities';






export async function getDocumentsForCurrentCase(orgID, caseID) {
  

  const qry = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})<-[:BELONGS_TO]-(f:CaseFile) OPTIONAL MATCH (f)<-[:PROCESSED]-(sd:ScannedDoc) return f.path as `File Path`, f.scanned_path as scanned_path, f.logicalFile as `Uploaded File`, f.created as `Uploaded Date`,sd.status as `Processing Status`, sd.uploaded_doc_type as `Document Type`, sd.sub_doc_type as  `Specific Document Type`, sd.pages as `Page Count`, sd._updateDate as `Processed Date` order by `Uploaded Date`"

  const parameters = { orgID, caseID};

  const result = await queryCaseNeo4j(qry, parameters);
  

  return result;
}



export async function forceRefreshDocumentsForCurrentCase(orgID, caseID) {
  console.log(`forcing revalidate....`)
  mutate(['DOCUMENTS_FOR_CURRENT_CASE', orgID, caseID])
}

export function useDocumentsForCurrentCase(orgID, caseID, shouldFetch) {
  const result = useSWR(
    shouldFetch ? ['DOCUMENTS_FOR_CURRENT_CASE', orgID, caseID] : null,
    ([path, orgID, caseID]) => getDocumentsForCurrentCase(orgID, caseID)
  );
  return result;
}

