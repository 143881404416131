import React, { Fragment, useState, useEffect } from 'react';
//import { TextField } from '@mui/material';
import { currencyFormatter } from './type-utilities';
import { 
  Container, 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow ,
  Card,
  CardContent,
  CardHeader
} from '@mui/material';
//import { useForm, FormProvider } from 'react-hook-form';
//import CurrencyTextField from './rh_form_elements/rh-currency-field-element';

const personalInfo = {
  name: 'Bob Smith',
  address: '123 Main St',
  phone: '212-555-1212',
  email: 'bob.smith@foo.bar'
};

// const initialData = {
//   personalInfo: {
//     name: '',
//     address: '',
//     phone: '',
//     email: ''
//   },
//   categoryData: {
    
//   }
// };

const DRFAReport = (props) => {

  

  //console.log('rendering EditableDRFAReport');

  const categoryData = props.data ? props.data : [];
  const totals=calculateTotals(categoryData)
  //console.log(`categoryData ${JSON.stringify(categoryData)}`)
  //console.log(`totals ${JSON.stringify(totals)}`)
  const [data, setData] = useState({ personalInfo: personalInfo, categoryData: categoryData});


  function calculateTotals(data) {
    const result = {};

    data.forEach(record => {
      const mainCategory = record.drfa_category;
      const subCategory = record.drfa_subcategory;
      const amount = record.amount;

      if (!result[mainCategory]) {
        result[mainCategory] = { total: 0 };
      }

      result[mainCategory].total += amount;

      if (subCategory) {
        if (!result[mainCategory][subCategory]) {
          result[mainCategory][subCategory] = 0;
        }
        result[mainCategory][subCategory] += amount;
      }
    });
    //console.log('calculateTotals', result);
    return result;
  }

  

  

  
  if (!data) return null;
  
  //console.log(`categoryData ${JSON.stringify(data.categoryData)}`)

  return (
    <Container sx={{ flexGrow: 1, padding: 2 }}>
      <Paper sx={{ padding: 2, textAlign: 'center', color: 'text.secondary', marginBottom: 2 }}>
        <Typography variant="h4" component="h1">
          Domestic Relations Financial Affidavit
        </Typography>
      </Paper>
      
        
      <Card sx={{ padding: 2, marginBottom: 2 }}>
            <CardHeader title="Personal Information" />

            <CardContent>
            
          <Typography variant="body2" >Name: {personalInfo.name}</Typography>
          <Typography variant="body2" >Address: {personalInfo.address}</Typography>
          <Typography variant="body2" >Phone: {personalInfo.phone}</Typography>
          <Typography variant="body2" >Email: {personalInfo.email}</Typography>
          

            </CardContent>
          </Card>
          
          
        

        
        
        <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow key={'header'}>
            <TableCell>Category / Subcategory</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(totals).map((category,idx) => totals[category].total !== 0 && (
            <Fragment key={`categorydummy-${idx}`}>
              {/* Main category row */}
              <TableRow key={`category-${idx}`}>
                <TableCell style={{ fontWeight: 'bold' }}>{category}</TableCell>
                <TableCell align="right" style={{ fontWeight: 'bold' }}>
                  {currencyFormatter(totals[category].total)}
                </TableCell>
              </TableRow>

              {/* Subcategories */}
              {Object.keys(totals[category]).map(
                (sub, index) =>
                  sub !== 'total' && totals[category][sub] !== 0 && (
                    <TableRow key={`sub-${index}`}>
                      <TableCell style={{ paddingLeft: '4em' }}>{sub}</TableCell>
                      <TableCell align="right">{currencyFormatter(totals[category][sub])}</TableCell>
                    </TableRow>
                  )
              )}
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
        
        
    </Container>
  );
};

export default DRFAReport;
