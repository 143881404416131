import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

export default function SearchBeforeCreateDialog(props) {

    const parentOnProceed = props.onProceed ? props.onProceed : () => { }
    const parentOnCancel = props.onCancel ? props.onCancel : () => { }
    let existingData = props.existing_data
    existingData = existingData ? JSON.parse(existingData).records.map((record) => record.org) : []



    //console.log(`in search dialog, existingData=${JSON.stringify(existingData)}`)



    const handleCancel = () => {
        parentOnCancel()
    };

    const handleProceed = () => {
        parentOnProceed()
    };

    return (
        <React.Fragment>

            <Dialog
                open={props.open}
                onClose={handleCancel}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {props.title ? props.title : "Existing data found matching your request"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        We found existing data that may match what you are trying to save, please confirm if you still want to proceed with saving this form.

                        
                            
                        

                    </DialogContentText>
                    <props.list_element data={existingData}></props.list_element>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleProceed}>Proceed</Button>
                    <Button onClick={handleCancel} autoFocus>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}