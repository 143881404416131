import React, { Fragment, useContext } from 'react';
import { 
  Container, 
  Paper, 
  Typography, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow ,
  Card,
  CardContent,
  CardHeader,
  Skeleton
} from '@mui/material';
import { currencyFormatter } from './type-utilities';

import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from './pageskeleton.js';
import { orange } from '@mui/material/colors';



const StatementQualityReport = (props) => {
  const missingStatements = props.missingStatements;
  const userProfileContext=useContext(UserProfileContext);

  if (!missingStatements || userProfileContext.isLoading) {
    return (<Container sx={{ flexGrow: 1, padding: 2 }}>
      <Paper sx={{ padding: 2, textAlign: 'center', color: 'text.secondary', marginBottom: 2 }}>
        <Typography variant="h4" component="h1">
          Statement Quality Report
        </Typography>
        <PageSkeleton />
      </Paper>
      </Container>);};
  
  return (
    <Container sx={{ flexGrow: 1, padding: 2 }}>
      
      <Paper sx={{ padding: 2, textAlign: 'center', color: 'text.secondary', marginBottom: 2 }}>
        <Typography variant="h4" component="h1">
          Statement Quality Report
        </Typography>
      </Paper>

      {/* Personal Information Card */}
      <Card sx={{ padding: 2, marginBottom: 2 }}>
        <CardHeader title={userProfileContext.userProfile.currentCase.caseName} />
        
      </Card>

      {/* Missing Statements Table */}
      {Object.keys(missingStatements.result).map((account_pk, idx) => (
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table>
          <TableHead>

          {/* Account Header Row */}
          <TableRow key={`account-header-${idx}`}>
              <TableCell align="left" sx={{ fontWeight: 'bold', backgroundColor: '#e0e0e0' }}>
                Account:
              </TableCell>
            

            
            
                  <TableCell align="left"  colSpan={4} sx={{ backgroundColor: '#e0e0e0', fontWeight: 'bold' }}>
                    {missingStatements.result[account_pk].bank_account.name}
                  </TableCell>
                </TableRow>

            <TableRow>
              <TableCell style={{ paddingLeft: '4em' }}>Statement Status</TableCell>
              <TableCell align="right" >Start Date</TableCell>
              <TableCell align="right" >End Date</TableCell>
              <TableCell align="right" >Starting Balance</TableCell>
              <TableCell align="right" >Ending Balance</TableCell>
              <TableCell align="right" >Expected Transaction Total</TableCell>
              <TableCell align="right" >Actual Transaction Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
              <Fragment key={`account-${idx}`}>
                
                {/* Statement Rows */}
                {missingStatements.result[account_pk].combined_statements.map((statement, index) => {
                  let matching_amounts=true;
                  if(index > 0 && !statement.missing && !missingStatements.result[account_pk].combined_statements[index-1].missing){
                    matching_amounts=missingStatements.result[account_pk].combined_statements[index-1].end_balance===statement.begin_balance;
                  }
                  return (
                  <TableRow key={`statement-${index}`}>
                    {statement.missing ? (
                      <Fragment>
                        <TableCell style={{ paddingLeft: '4em', color: 'red' }}>
                          MISSING
                        </TableCell>
                        <TableCell align="right" style={{ color: 'red' }}>{statement.expected_begin_date}</TableCell>
                        <TableCell align="right" style={{ color: 'red' }}>{statement.expected_end_date}</TableCell>
                        <TableCell align="right" style={{ color: 'red' }}>-</TableCell>
                        <TableCell align="right" style={{ color: 'red' }}>-</TableCell>
                        <TableCell align="right" style={{ color: 'red' }}>-</TableCell>
                        <TableCell align="right" style={{ color: 'red' }}>-</TableCell>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <TableCell style={{ paddingLeft: '4em' , color: statement.status === 'OVERLAP' ? orange[900] : undefined}}>
                          {/*statement.verified?'VERIFIED':'SCANNED'*/}
                          {statement.status}
                        </TableCell>
                        <TableCell align="right" >{statement.begin_date}</TableCell>
                        <TableCell align="right" >{statement.end_date}</TableCell>
                        <TableCell align="right" style={{ color: matching_amounts?'green':'red'}}>{currencyFormatter(statement.begin_balance)}</TableCell>
                        <TableCell align="right" >{currencyFormatter(statement.end_balance)}</TableCell>
                        <TableCell align="right" style={{ color: statement.balance_match_to_transaction?'green':'red'}} >{currencyFormatter(statement.expected_transaction_total)}</TableCell>
                        <TableCell align="right" style={{ color: statement.balance_match_to_transaction?'green':'red'}} >{currencyFormatter(statement.transaction_total)}</TableCell>
                      </Fragment>
                    )}
                  </TableRow>
                )})}
              </Fragment>
            
          </TableBody>
        </Table>
      </TableContainer>
      ))}
    </Container>
    
  );
};

export default StatementQualityReport;
