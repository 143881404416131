import React from 'react';
import PortalFrame from '../ui/portal-frame';
import { useParams } from 'react-router-dom';
import pages from '../../cfg/analysis-pages.json';
import SingleChartContent from '../ui/single-chart-content';
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';


export default function AnalysisPage(props) {
    let { analysis_id } = useParams();
    let pageprops = pages[analysis_id];

    let ChartContent = 
        pageprops.page_type === "SINGLE_CHART" ? SingleChartContent 
        : SingleChartContent;

    const title="Analysis - " + pageprops.title
    
    
    //Step 2 added new way
    const userProfileContext=useContext(UserProfileContext);

    //const userProfile = useCurrentUserProfile();   //comment out old way
    const userProfile = userProfileContext.userProfile  // add new way

    // if(userProfile.error){
    //     console.log(`user profile error: ${userProfile.error}`)
    // }

    //if (userProfile.isLoading || !userProfile.data) {
    if (userProfileContext.isLoading || !userProfile) {
    
        return (
            <PortalFrame title={title}>
                <PageSkeleton/>

            </PortalFrame>
        )
    }

    

    // const orgID = userProfile.data.currentOrg.orgID
    // const caseID = userProfile.data.currentCase.caseID
    const orgID = userProfile.currentOrg.orgID
    const caseID = userProfile.currentCase.caseID

    return (
        <PortalFrame title={title}>

            <ChartContent orgID={orgID} caseID={caseID} {...pageprops} />

        </PortalFrame>
    );
}