import React from "react";
import { Box, Typography } from '@mui/material';
import Title from './title';

import { DataGrid } from "@mui/x-data-grid";
import { blue, deepPurple } from "@mui/material/colors";
//import {postNeo4j} from '../../api/pydbcall';



function preventDefault(event) {
  event.preventDefault();
}
function enableDefault(event) {
  return;
}

export default function CategoryRuleDataPreview(props) {
  const data = props.data ? props.data : []
  const columnNames = props.columnNames ? props.columnNames : []

  const phraseColumn = props.phraseColumn ? props.phraseColumn : '1'
  const subcategoryColumn = props.subcategoryColumn ? props.subcategoryColumn : '2'
  const flagColumn = props.flagColumn ? props.flagColumn : '3'

  let rows = [];
  let columns = [];
  rows = data.map(
    (row, index) => ({ 'id': index, ...row })
  );


  columns = props.columns ? props.columns : columnNames.map(
    (col) => ({ field: col, headerName: col, width: 200 })
  )


  const columnGroupingModel = [
    {
      groupId: 'Subcategory',
      description: '',
      headerClassName: 'mytheme--subcategory-group',
      children: [{ field: subcategoryColumn }],
    },
    {
      groupId: 'Phrase',
      description: '',
      headerClassName: 'mytheme--phrase-group',
      children: [{ field: phraseColumn }],
    },
    {
      groupId: 'Flag',
      description: '',
      headerClassName: 'mytheme--flag-group',
      children: [{ field: flagColumn }],
    },

  ];


  return (
    <React.Fragment>
      <Title>{props.preview_title}</Title>


      <Box
        sx={{
          // flex: 1,
          //       //display: 'flex',
          //       display: 'inline-block',
          //       //width: '50vw',
          //       width: '100%',
          //       //height: '75vh',
          //       alignItems: 'center',
          //       justifyContent: 'center',

          //   maxHeight: '75vh',

          display: 'flex',
          flexDirection: 'column',
          height: '70vh',  // Ensure height is set
          width: '100%',

        }}
      >
        <DataGrid sx={{
          // flexGrow: 1,
          // '& .MuiDataGrid-virtualScroller': {
          //   overflow: 'auto !important',  // Ensure the virtual scroller can overflow
          // },
          //maxHeight: '75vh',

          '& .mytheme--phrase-group': {
            backgroundColor: blue[400],
          },
          '& .mytheme--subcategory-group': {
            backgroundColor: deepPurple[400],
            color: deepPurple[50],
          },
          '& .mytheme--flag-group': {
            backgroundColor: deepPurple[600],
            color: deepPurple[50],
          },
        }} rows={rows} columns={columns} columnGroupingModel={columnGroupingModel}
        //autoHeight={false}  // Ensure autoHeight is false
        //disableExtendRowFullWidth
        />
      </Box>

    </React.Fragment>
  );
}