import React from "react";
import PortalFrame from '../ui/portal-frame.js';

//import BasicOrgList from "../ui/list-org-basic.js";
import CaseProfileForm from '../ui/form-case-profile.js';
import mutateCase from "../../api/mutatecase.js";
import { listCaseQuery } from "../../api/mutatecase.js";
import EditList from "../ui/list-edit.js";
import CaseEditList from "../ui/list-edit-case.js";
//import useCurrentCaseForCurrentUser from "../../api/swrcasesforcurrentuser.js";
//import {useCurrentOrgForCurrentUser} from "../../api/swrorgsforcurrentuser.js";
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from "../ui/pageskeleton.js";


export default function ManageCasePage() {
  const title="Manage Cases"
  //const userOrg=useCurrentOrgForCurrentUser()

//Step 2 added new way
const userProfileContext=useContext(UserProfileContext);

//const userProfile=useCurrentUserProfile(); // comment out old way

const userProfile = userProfileContext.userProfile  // add new way

  //if (userProfile.isLoading) {
if (userProfileContext.isLoading) {
    return (
      <PortalFrame title={title}>
        <PageSkeleton/>
      </PortalFrame>
    )
  }

  //if (!userProfile.data.currentOrg){
    if (!userProfile.currentOrg){
    return (
      <PortalFrame title={title}>
        You are not assigned to any organizations
      </PortalFrame>
    )
  }
  
  //const orgID=userProfile.data.currentOrg.orgID
  //const orgName=userProfile.data.currentOrg.orgName
  //const currentUserID=userProfile.data.currentUser.userID
  //console.log(`got HERE in CASE orgID=${orgID} orgName=${orgName}`)

  const orgID=userProfile.currentOrg.orgID
  const orgName=userProfile.currentOrg.orgName
  const currentUserID=userProfile.currentUser.userID
  console.log(`got HERE in CASE orgID=${orgID} orgName=${orgName}`)

  
  return (
    <PortalFrame title={title} orgName={orgName} >
    <EditList listQuery={listCaseQuery}  editForm={CaseProfileForm} objectLabel="Case" editList={CaseEditList} mutateHandler={mutateCase} orgID={orgID} orgName={orgName} currentUserID={currentUserID} />
    </PortalFrame>
  );
};