//import { useContext } from 'react';
import { queryCaseNeo4j } from './pydbcall';







export async function getTotalTransactionCount(orgID, caseID) {
  const qry = `
    MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount)-[:HAS]->(bs:BankStatement)-[rbst:CONTAINS]->(txn:Transaction {})-[:ORIGINAL]->(origtxn:ScannedTransaction) \
    RETURN count(txn) as totalRecords
  `;

  const parameters = { orgID, caseID };

  const result = await queryCaseNeo4j(qry, parameters);
  console.log('Result:', result);
  console.log('Records:', result.records);

  if (result.records.length > 0) {
    const totalRecords = result.records[0].totalRecords;
    return totalRecords;
  } else {
    return 0;
  }
}

export async function getTransactionsForCurrentCase(orgID, caseID, skip = 0, limit = 4000) {
  const transfer_days_threshold = 5;

  const qry = "MATCH (o:Organization {orgID:$orgID})<-[rco:BELONGS_TO]-(c:Case {caseID:$caseID})-[rcba:HAS]->(ba:BankAccount)-[rbabs:HAS]->(bs:BankStatement)-[rbst:CONTAINS]->(t:Transaction)-[rtsc]->(tsc:Subcategory)-[:WITHIN]->(tc:Category) \
               OPTIONAL MATCH (t)-[cfm:CONFIRMED]->(f:Flag) \
               WITH o, rco, c, rcba, ba, rbabs, bs, rbst, f, cfm, t, tc, tsc, rtsc \
               RETURN t.txn_date as `Transaction Date`, t._updateDate as `Transaction Update Date`, trim(t.description) as Description, tc.name AS Category, tsc.name as `Sub Category`, rtsc.merchant_name as `Merchant Name`, rtsc.similarity as `Confidence Score`, rtsc.plaid_category as `Plaid Personal Finance Category`, ba.account_number as `Account Number`, ba.bank_name as `Bank Name`, t.amount as `Transaction Amount`, f.name as Flag ORDER BY \
               `Transaction Date` desc \
               SKIP $skip \
               LIMIT $limit \
              "

  const parameters = { orgID, caseID, skip, limit };
  const result = await queryCaseNeo4j(qry, parameters);

  return result.records;
}


export async function getAccountsForCurrentCase(orgID, caseID) {

  const qry = "MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount) return ba.bank_name as `Bank Name`, ba.account_holder as `Account Holder`, ba.account_number as `Account Number`, ba.name as `Account Name`"
  const parameters = { orgID, caseID};
  const result = await queryCaseNeo4j(qry, parameters);

  return result.records;
}


