import {useMemo} from 'react';
import { createStore, Plugin, PluginFunctions } from '@react-pdf-viewer/core';

interface StoreProps {
    jumpToPage?(pageIndex: number): void;
}

interface iJumpToPagePlugin extends Plugin {
    jumpToPage(pageIndex: number): void;
}

const JumpToPagePlugin = (): iJumpToPagePlugin => {
    const store = useMemo(() => createStore<StoreProps>(), []);

    return {
        install: (pluginFunctions: PluginFunctions) => {
            //console.log('installing plugin jumpToPagePlugin')
            store.update('jumpToPage', pluginFunctions.jumpToPage);
        },
        jumpToPage: (pageIndex: number) => {
            const fn = store.get('jumpToPage');
            //console.log('jumping to page with fn')
            //console.log(fn)
            if (fn) {
                fn(pageIndex);
            }
        },
    };
};

export default JumpToPagePlugin;