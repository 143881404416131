import { useState, Fragment, useEffect } from 'react';
import { Button, Paper, Skeleton, Box } from "@mui/material";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
//import { useCasesForCurrentUser } from "../../api/swrcasesforcurrentuser.js";
import { useCasesForCurrentUserbyOrg } from '../../api/swrcasesforcurrentuser.js';
//import { useOrgsForCurrentUser, getOrgsForCurrentUser } from '../../api/swrorgsforcurrentuser.js';

import { fetchUserAttributes } from 'aws-amplify/auth';
import { AutocompleteElement } from 'react-hook-form-mui';
import { useForm } from 'react-hook-form-mui';
import { FormContainer } from 'react-hook-form-mui';
//import {mutateTouchUserOrgAssignment} from '../../api/mutatetouchorgassignment.js';
import mutateTouchCaseAssignment from '../../api/mutatetouchcaseassignment.js';
import UserProfileContext from './userprofilecontext.js';
import { useContext } from 'react';


function getCaseValue(caseID, caseName) {
  return { id: caseID, label: caseName }
}

function getCaseValues(data, current_case) {

  const obj = "case"
  const id_field = "caseID"
  const label_field = "caseName"


  let filtered = data.filter((record) => record[obj][id_field] == current_case)
  let selected = filtered[0]

  let values = data.map((record) => {
    //return { id: record[obj][id_field], label: record[obj][label_field] }
    return getCaseValue(record[obj][id_field], record[obj][label_field])
  }
  )

  return { selected: selected, values: values }
}

export default function SelectCurrentCaseDialog(props) {

  //const currentOrgID = props.currentOrgID
  //const currentCaseID = props.currentCaseID
  //const currentCaseName = props.currentCaseName
  //const currentUserID = props.currentUserID
  const parentOnSubmit = props.onSubmit ? props.onSubmit : () => { console.log('SHOULD NOT BE HERE') }
  const parentOnCancel = props.onCancel ? props.onCancel : () => { }
  const title = props.title ? props.title : "Select Current Case"


  const userProfileContext = useContext(UserProfileContext);
  //console.log(`user profile context obtained`)
  const currentOrgID = userProfileContext.userProfile.currentOrg.orgID
  //console.log(`starting select current case, ${JSON.stringify(currentOrgID)}`)
  //const userCases = useCasesForCurrentUser();
  const userCases = useCasesForCurrentUserbyOrg(currentOrgID, props.open)

  const currentCaseID = userProfileContext.userProfile.currentCase.caseID
  const currentCaseName = userProfileContext.userProfile.currentCase.caseName


  const _defaultValues = { "current_case": getCaseValue(currentCaseID, currentCaseName) }

  const [defaultValues, setDefaultValues] = useState(_defaultValues)
  const [values, setValues] = useState(_defaultValues)
  //const [caseOptions, setCaseOptions] = useState([])
  //const [selectedCase, setSelectedCase] = useState()
  //const [userID, setUserID] = useState()


  // useEffect(() => {

  //   // const getUserID = () => {
  //   //   //const attrs = await fetchUserAttributes()

  //   //   //setUserID(attrs.sub)
  //   //   setUserID(currentUserID)
  //   // }

  //   // getUserID()

  //   if (UserProfileContext.userProfile.currentCase.caseID) {
  //     //console.log(`currentCaseName=${currentCaseName}`)
  //     const _defaultValues = { "current_case": getCaseValue(UserProfileContext.userProfile.currentCase.caseID, UserProfileContext.userProfile.currentCase.caseName) }
  //     //console.log(`defaultValues: ${JSON.stringify(_defaultValues)}`)
  //     setValues(_defaultValues)
  //     setDefaultValues(_defaultValues)
  //   }


  // }, [UserProfileContext.userProfile.currentCase.caseID, UserProfileContext.userProfile.currentCase.caseName])


  const formContext = useForm({
    defaultValues: defaultValues, values
  });

  const { handleSubmit, reset, control, setValue, formState: { errors } } = formContext;



  const handleCancel = () => {
    parentOnCancel()
  };

  const handleAfterMutate = (mutateResult) => {

    //console.log(`touch case mutate result=${JSON.stringify(mutateResult)}`)
    const newCase = mutateResult.records[0].c
    //console.log(`touch case new case result=${JSON.stringify(newCase)}`)

    const newUserProfile = userProfileContext.userProfile
    newUserProfile.currentCase = newCase
    //userProfileContext.setUserProfile(newUserProfile)
    //console.log(`new user context: ${JSON.stringify(userProfileContext.userProfile)}`)
  }

  const onSubmit = (data) => {
    //console.log(`TEST HERE isLoading=${userProfileContext.isLoading}`)
    //console.log(`TEST HERE ${JSON.stringify(userProfileContext)}`)
    //console.log(`TEST HERE ${JSON.stringify(userProfileContext.userProfile)}`)

    //console.log(`TEST HERE ${JSON.stringify(userProfileContext.userProfile.currentOrg.orgID)}`)

    const newCaseID = data.current_case.id
    setValues(data)
    const orgID = userProfileContext.userProfile.currentOrg.orgID
    const userID = userProfileContext.userProfile.currentUser.userID
    //userProfileContext.invalidate()
    mutateTouchCaseAssignment(orgID, newCaseID, userID).then((result) => handleAfterMutate(result))
    parentOnSubmit(data)
  };


  //console.log(`userCases=${JSON.stringify(userCases)}`)
  if (userCases.isLoading || userCases.error || !userCases.data || userCases.data.records.length == 0) {
    return (
      <Fragment>

        <Dialog open={props.open} onClose={handleCancel}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>

            <Skeleton variant="rectangular" sx={{ minWidth: 500, minHeight: 300 }} />

          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }


  let options = []

  if (userCases.data.records.length > 0) {


    let cases = getCaseValues(userCases.data.records, userProfileContext.userProfile.currentCase.caseID)

    //console.log(`orgs=${JSON.stringify(orgs)}`)
    options = cases.values

  }




  return (
    <Fragment>

      <Dialog open={props.open} onClose={handleCancel}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent sx={{ minWidth: 500, minHeight: 300 }}>
          <FormContainer values={values} defaultValues={defaultValues} formContext={formContext} handleSubmit={handleSubmit}>
            <Box sx={{ pt: 1 }}>
              <AutocompleteElement name="current_case" options={options} setValue={setValue} label="Current Case" required />
            </Box>

            <Button
              style={{
                //padding: "20px",
                margin: "10px 0px",
                maxWidth: "75px",
              }}
              onClick={handleSubmit(onSubmit)} variant={"contained"}>
              Save
            </Button>


          </FormContainer>
        </DialogContent>
      </Dialog>
    </Fragment>
  );


}