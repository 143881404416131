import React, { useState } from 'react';
import PortalFrame from '../ui/portal-frame.js';
import StatementsPanel from "../ui/panel-statements.js";
import DocumentPreview from '../ui/document-preview-panel.js';
import UploadStatementsDialog from "../ui/dialog-upload-statements.js"

import { Button } from "@mui/material";
//import {useCurrentCaseForCurrentUser} from "../../api/swrcasesforcurrentuser.js";
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from '../ui/pageskeleton.js';

//import StatementEditList from '../ui/old/list-edit-statement.js';
import {Stack} from "@mui/material";

export default function ManageUploadedFiles() {

  //Step 2 added new way
  const userProfileContext=useContext(UserProfileContext);

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadCount,setUploadCount] = useState(0)
  
  //const userProfile=useCurrentUserProfile(); // comment out old way

  const userProfile = userProfileContext.userProfile  // add new way

  const title = "Manage Files"

  const handleUploadClick = (data) => {
    // open the upload dialog window
    setOpenUploadDialog(true);
  };

  

  // const handleEditClick = (data) => {
  //   console.log('edit clicked')
  // };

  // const handleDelete = (data) => {
  //   console.log('delete clicked')
  // }


  // const onUpload = () => {
  //   setUploadCount(uploadCount + 1)
  // }


  const onCancelUpload = () => {
    setOpenUploadDialog(false);
  };

  if (userProfileContext.isLoading) {


    return (
      <PortalFrame title={title}>
        <PageSkeleton/>
      </PortalFrame>
    )
  }

  //if (!userProfile.data.currentCase){
  if (!userProfileContext.userProfile.currentCase) {
    return (
      <PortalFrame title={title}>
        You are not assigned to any cases
      </PortalFrame>
    )
  }

  //const orgID = userProfile.data.currentOrg.orgID
  //const orgName = userProfile.data.currentOrg.orgName
  //const caseID = userProfile.data.currentCase.caseID
  //const caseName = userProfile.data.currentCase.caseName

  const orgID = userProfile.currentOrg.orgID
  const orgName = userProfile.currentOrg.orgName
  const caseID = userProfile.currentCase.caseID
  const caseName = userProfile.currentCase.caseName


  const parms = {
    orgID: orgID,
    caseID: caseID
  }

  


  return (
    <PortalFrame title={title} orgName={orgName} caseName={caseName}>
      <StatementsPanel>
      <Stack spacing={2}>
        <DocumentPreview parms={parms} handleUploadClick={handleUploadClick} uploadCount={uploadCount}/>
        {/*<StatementEditList orgID={orgID} caseID={caseID} handleEditClick={handleEditClick} handleDelete={handleDelete}/>
        <Button variant="contained" onClick={handleUploadClick}>Upload Statements</Button>*/}
      </Stack>
      </StatementsPanel>
      <UploadStatementsDialog orgID={orgID} caseID={caseID} open={openUploadDialog}  onCancel={onCancelUpload}   />
    </PortalFrame>
  );
};