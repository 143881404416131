export const findChangedFields = (newRow, oldRow) => {
    const changedFields = {};

    //console.log(`oldRow=${JSON.stringify(oldRow)}`)
    //console.log(`newRow=${JSON.stringify(newRow)}`)
    for (const field in newRow) {
      if (newRow[field] !== oldRow[field]) {
        //console.log(`field ${field} is different`)
        changedFields[field] = { oldValue: oldRow[field], newValue: newRow[field] };
      }
    }
    return changedFields
  }