import React, { createContext, useContext, useState, useEffect, Fragment } from 'react';

const TransactionReviewContext = createContext();

export const TransactionReviewProvider = ({ children }) => {


    const [transaction, setTransaction] = useState()
    const [transactionStack, setTransactionStack] = useState([])

    const hasPreviousTransaction = () => transactionStack.length > 0;

    const goPreviousTransaction = () => {
        if (! hasPreviousTransaction() ) return;
        const newStack = [...transactionStack]
        const priorTransaction = newStack.pop()   // recover old value
        setTransactionStack(newStack)
        setTransaction(priorTransaction) // set old value
        return priorTransaction
        
    }

    const goNextTransaction = (newTransaction) => {
        const newStack = [...transactionStack]
        newStack.push(transaction) // save old value
        setTransactionStack(newStack)
        setTransaction(newTransaction) //set new value
        return newTransaction
    }

    const clearTransactionStack = () => {
        setTransactionStack([])
    }

    return (
        <TransactionReviewContext.Provider value={{transaction, setTransaction, hasPreviousTransaction, goPreviousTransaction, goNextTransaction, clearTransactionStack}}>
          {children}
        </TransactionReviewContext.Provider>
      );

}

export default TransactionReviewContext;