import React from "react";
import { useContext, Fragment } from "react";
import { Box, Button, Typography } from '@mui/material';
import Title from './title';
import IconButton from '@mui/material/IconButton';

import { GridRowModes,GridActionsCellItem,
  GridRowEditStopReasons,DataGrid } from "@mui/x-data-grid";
import { dateGetter, currencyFormatter, bankAccountFormatter } from './type-utilities';
import RestoreValueActionItem from "./grid-restore-value-action";
import RestoreIcon from '@mui/icons-material/Restore';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PageviewIcon from '@mui/icons-material/Pageview';

import clsx from 'clsx';
import { deepPurple, blue, grey } from "@mui/material/colors";
import { TransferDispositionEditor, TransferDispositionRenderer } from "./transfer-disposition-toggle";
import TransactionReviewContext from './transaction-review-context.js';




function preventDefault(event) {
  event.preventDefault();
}
function enableDefault(event) {
  return;
}

export default function TransferCandidatesPreviewGrid(props) {

  const parentHandleRowClick = props.handleRowClick ? props.handleRowClick : () => { }
  //const mutateTransaction = props.mutateTransaction ? props.mutateTransaction : (txn) => {console.log(`(fake default) mutating transaction...`) }
  const parentProcessRowUpdate = props.processRowUpdate ? props.processRowUpdate : () => {}
  
  const [rows, setRows] = React.useState([]);
  const [rowModesModel, setRowModesModel] = React.useState({});

  const transactionReviewContext=useContext(TransactionReviewContext)
  
  React.useEffect(() => {

    setRows(props.transactions)


  }, [props.transactions]

  )
  //let rows=[];

  // rows=data.map(
  //   (row,index) => ({'id':index, ...row})
  // );

  const handleRowClick = (
    params, // GridRowParams
    event, // MuiEvent<React.MouseEvent<HTMLElement>>
    details, // GridCallbackDetails
  ) => {
    //console.log(`row "${JSON.stringify(params.row)}" clicked`);
    parentHandleRowClick(params.row)
  };

  const handleEditClick = (id) => () => {
    
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {
    //console.log(`id=${id}`)
    //console.log(`typeof id = ${typeof id}`)
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const findChangedFields = (newRow, oldRow) => {
    const changedFields = {};

    //console.log(`oldRow=${JSON.stringify(oldRow)}`)
    //console.log(`newRow=${JSON.stringify(newRow)}`)
    for (const field in newRow) {
      if (newRow[field] !== oldRow[field]) {
        //console.log(`field ${field} is different`)
        changedFields[field] = { oldValue: oldRow[field], newValue: newRow[field] };
      }
    }
    return changedFields
  }

  const preProcessUpdate = (newRow, oldRow, changedFields) => {
    /// check if subcategory changed, then confirm the subcategory assignment
    // if (changedFields.subcategory) {
    //   newRow.cat_type = 'CONFIRMED'
    // }
    // if (changedFields.flag) {
    //   //if (newRow.flag){newRow.flag_type = 'CONFIRMED'}
    //   //else {newRow.flag_type = null}
    //   newRow.flag_type = 'CONFIRMED'

    // }
    return newRow
  }

  // const processRowUpdate = async (newRow, oldRow) => {

  //   const changedFields = findChangedFields(newRow, oldRow)
  //   //console.log(`changed fields: ${JSON.stringify(changedFields)}`)



  //   const updatedRow = preProcessUpdate({ ...newRow, isNew: false }, oldRow, changedFields);
  //   //console.log(`updatedRow: ${JSON.stringify(updatedRow)}`)

  //   const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row))

  //   setRows(newRows);



  //   if (Object.keys(changedFields).length > 0) {
  //     //console.log(`calling mutate...`)
  //     await mutateTransaction(updatedRow, newRows, changedFields)
  //     // if (changedFields.subcategory
  //     //   //|| (changedFields.cat_type && updatedRow.cat_type=='CONFIRMED')
  //     // ) {
  //     //   parentHandleCategoryRuleCreate(updatedRow)

  //     // }
  //     // if (changedFields.flag
  //     //   //|| (changedFields.cat_type && updatedRow.cat_type=='CONFIRMED')
  //     // ) {
  //     //   parentHandleFlagRuleCreate(updatedRow)

  //     // }

  //   }


  //   return updatedRow;
  // };

  function restoreValue(id, currentField, originalField) {
    const oldRow = rows.find((row) => (row.id === id))
    const newRow = { ...oldRow, [currentField]: oldRow[originalField] }
    processRowUpdate(newRow, oldRow)
  }

  function restoreAmount(id) {
    const oldRow = rows.find((row) => (row.id === id))
    const newRow = { ...oldRow, amount: oldRow.original_amount * oldRow._applied_expense_factor }
    processRowUpdate(newRow, oldRow)
  }

  const processRowUpdate = async (newRow, oldRow) => {
    const updatedRow = await parentProcessRowUpdate(newRow, oldRow)
    //console.log(`new row is ${JSON.stringify(updatedRow)}`)
    
    const newRows = rows.map((row) => (row.id === newRow.id ? updatedRow : row))
    
    setRows(newRows);
    return updatedRow
  }

  const handleRowModesModelChange = (newRowModesModel) => {
    
    setRowModesModel(newRowModesModel);
    
  };

  const handleRowEditStop = (params, event) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleClickOtherPossible = (id) => {
    const row = rows.find((row) => row.id === id);
    //console.log(`row ${JSON.stringify(row)}`)
    transactionReviewContext.goNextTransaction(row)
  }



  const columns = [
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />
        ];
      },
    },
    {
      field: 'transfer_disposition',
      headerName: 'Disposition',
      width: 175,
      align: 'right',
      headerAlign: 'center',
      editable: false,
      renderCell: (params) => (
      
        <TransferDispositionEditor
          id={params.id}
          value={params.value}
          type={params.row.transfer_type}
          field={params.field}
          row={params.row}
          target_has_other_match={params.row.target_has_other_match}
          processRowUpdate={processRowUpdate}
      
        />
      ),
      
    },
    {
      field: 'pk',
      headerName: 'PK',
      width: 100,
      align: 'left',
      editable: false,
    },
    {
      field: 'bank_name',
      headerName: 'Bank',
      width: 150,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'account_number',
      headerName: 'Account',
      width: 75,
      valueGetter: bankAccountFormatter,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    
    {
      field: 'transfer_type',
      headerName: 'Transfer Type',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'target_has_other_possible',
      headerName: 'Others?',
      type: 'boolean',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
      renderCell: (params) => (
        <Fragment>
          {params.value &&
        <IconButton
          onClick={() => handleClickOtherPossible(params.row.id)}
          color={params.row.target_has_other_match?"warning":params.row.transfer_disposition=='CONFIRMED'?"default":"primary"}
        >
          <PageviewIcon />
        </IconButton>
      }
        </Fragment>
      ),
    },
    {
      field: 'transfer_descr_match',
      headerName: 'Descr Match?',
      type: 'boolean',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'transfer_amount_match',
      headerName: 'Amount Match?',
      type: 'boolean',
      width: 75,
      align: 'left',
      headerAlign: 'left',
      editable: false,
    },
    {
      field: 'txn_date',
      headerName: 'Transaction Date',
      type: 'date',
      valueGetter: dateGetter,
      width: 180,
      align: 'left',
      headerAlign: 'left',
      editable: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        if (params.value - params.row.original_txn_date === 0) {
          return '';
        }
        return clsx('transaction', 'changed');


      }
    },
    {
      field: 'txn_date_actions',
      type: 'actions',
      width: 10,
      getActions: ({ id, row }) => {
        if (row.original_txn_date - row.txn_date === 0) {
          return []
        }
        return [
          <RestoreValueActionItem
            showInMenu
            icon={<RestoreIcon />}
            label="Restore Original Date"
            sx={{
              color: 'primary.main',
            }}
            restoreValue={() => { restoreValue(id, "txn_date", "original_txn_date") }}
            originalDisplayValue={row.original_txn_date.toLocaleString(undefined, { dateStyle: "short" })}
            currentDisplayValue={row.txn_date.toLocaleString(undefined, { dateStyle: "short" })}
            closeMenuOnClick={false}
          />,
        ]
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 400,
      align: 'left',
      editable: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        if (params.value === params.row.original_description) {
          return '';
        }
        return clsx('transaction', 'changed');


      },
    },
    {
      field: 'description_actions',
      type: 'actions',
      width: 30,
      getActions: ({ id, row }) => {
        if (row.original_description === row.description) {
          return []
        }
        return [
          <RestoreValueActionItem
            showInMenu
            icon={<RestoreIcon />}
            label="Restore Original Description"
            sx={{
              color: 'primary.main',
            }}
            restoreValue={() => { restoreValue(id, "description", "original_description") }}
            originalDisplayValue={row.original_description}
            currentDisplayValue={row.description}
            closeMenuOnClick={false}
          />,
        ]
      }
      ,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      valueFormatter: currencyFormatter,
      width: 150,
      align: 'right',
      headerAlign: 'right',
      editable: true,
      cellClassName: (params) => {
        if (params.value == null) {
          return '';
        }
        if (params.value === params.row.original_amount * params.row._applied_expense_factor) {
          return '';
        }
        return clsx('transaction', 'changed');


      }
    },
    {
      field: 'amount_actions',
      type: 'actions',
      width: 30,
      getActions: ({ id, row }) => {
        if (row.amount === row.original_amount * row._applied_expense_factor) {
          return []
        }
        return [
          <RestoreValueActionItem
            showInMenu
            icon={<RestoreIcon />}
            label="Restore Original Amount"
            sx={{
              color: 'primary.main',
            }}
            restoreValue={() => { restoreAmount(id) }}
            originalDisplayValue={currencyFormatter(row.original_amount * row._applied_expense_factor)}
            currentDisplayValue={currencyFormatter(row.amount)}
            closeMenuOnClick={false}
          />,
        ]
      },
    },


  ];




  return (
    <React.Fragment>
      <Title>{props.preview_title}</Title>


      <Box
        sx={{

          display: 'flex',
          flexDirection: 'column',

          width: '100%',

          '& .transaction.changed': {
          //backgroundColor: grey[200],
          color: "warning.dark",
          //fontWeight: '600',
        },
        '& .category.proposed': {
          backgroundColor: grey[200],
          //color: '#1a3e72',
          //fontWeight: '600',
        },
        '& .category.confirmed': {
          backgroundColor: deepPurple[100],
          //color: '#1a3e72',
          fontWeight: '600',
        },
        '& .flag.proposed': {
          backgroundColor: grey[200],
          //color: '#1a3e72',
          //fontWeight: '600',
        },
        '& .flag.confirmed': {
          backgroundColor: deepPurple[300],
          color: deepPurple[50],
          fontWeight: '600',
        },
        '& .mytheme--subcategory-group': {
          backgroundColor: deepPurple[400],
          color: deepPurple[50],
        },
        '& .mytheme--flag-group': {
          backgroundColor: deepPurple[600],
          color: deepPurple[50],
        },
        '& .mytheme--transaction-group': {
          backgroundColor: blue[600],
          color: blue[50],
        },

        }}
      >
        
        <DataGrid sx={{


        }}
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          onRowClick={handleRowClick}
          processRowUpdate={processRowUpdate}

        />
      </Box>

    </React.Fragment>
  );
}