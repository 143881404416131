import React from "react";
import PortalFrame from '../ui/portal-frame.js';

import CaseAssignmentForm from "../ui/form-case-assignment.js";
//import {useCurrentCaseForCurrentUser} from "../../api/swrcasesforcurrentuser.js";
//import { useCurrentUserProfile } from "../../api/swrcurrentuserprofile.js";

//step 1 - import
import { useContext } from 'react';
import UserProfileContext from '../ui/userprofilecontext.js';
import PageSkeleton from "../ui/pageskeleton.js";


export default function ManageCaseAssignmentPage() {

  const title="Manage Case Assignment"
  //const userCase=useCurrentCaseForCurrentUser();

//Step 2 added new way
  const userProfileContext=useContext(UserProfileContext);

  //const userProfile=useCurrentUserProfile(); // comment out old way

  const userProfile = userProfileContext.userProfile  // add new way



  //if (userProfile.isLoading ) {
    if (userProfileContext.isLoading) {


    return (
      <PortalFrame title={title}>
        <PageSkeleton/>
      </PortalFrame>
    )
  }
  // const orgID=userCase.data.orgID
  // const orgName=userCase.data.orgName
  // const caseID=userCase.data.caseID
  // const caseName=userCase.data.caseName
  //console.log(`userProfile=${JSON.stringify(userProfile)}`)

  if(!userProfileContext.userProfile||!userProfileContext.userProfile.currentCase){
    return (
      <PortalFrame title={title}>
        You are not assigned to any cases
      </PortalFrame>
    )
  }

  //const orgID=userProfileContext.userProfile.currentOrg.orgID
  //const orgName=userProfileContext.userProfile.currentOrg.orgName
  //const caseID=userProfileContext.userProfile.currentCase.caseID
  //const caseName=userProfileContext.userProfile.currentCase.caseName


  const orgID=userProfile.currentOrg.orgID
  const orgName=userProfile.currentOrg.orgName
  const caseID=userProfile.currentCase.caseID
  const caseName=userProfile.currentCase.caseName

  
  
  return (
    <PortalFrame title={title} orgName={orgName} caseName={caseName}>
    <CaseAssignmentForm orgID={orgID} caseID={caseID}/>
    </PortalFrame>
  );
};