import React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
//import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
//import NotificationsIcon from '@mui/icons-material/Notifications';
import DrawerItemList from './drawer-itemlist';
import globals from '../../cfg/globals.json';
import MyUserProfileDialog from './dialog-my-user-profile';
//import useCurrentCaseForCurrentUser from '../../api/swrcasesforcurrentuser';



function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center"  {...props}>
            {'Copyright © '}
            <Link color="inherit" href={globals.GLOBAL_app_link}>
                {globals.GLOBAL_app_name}
            </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const drawerWidth = 275;



const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);


export default function PortalFrame(props) {
    const [open, setOpen] = React.useState(true);
    const [accountNotifications, setAccountNotifications] = React.useState(0)
    const [openUserProfileDialog, setOpenUserProfileDialog] = React.useState(false)
    
 
    const handleClickMyProfile = (data) => {
        // open the dialog
        setOpenUserProfileDialog(true);
    }

    const handleCancelMyProfile = (data) => {
        // open the dialog
        setOpenUserProfileDialog(false);
    }


    const toggleDrawer = () => {
        setOpen(!open);
    };

    // TODO remove, this demo shouldn't need to reset the theme.
    //const defaultTheme = createTheme();
    //const userCase=useCurrentCaseForCurrentUser();
    
    const title=props.title
    
    const context=props.orgName?"Organization: " + props.orgName+
                    (props.caseName?" | Case: "+props.caseName:"")
                    :""

    //console.log(`Org: ${props.orgName} Case: ${props.caseName} Context: ${context}`)
    //userCase.isLoading?"":"Organization: " + userCase.data.orgName + " | Case: "+userCase.data.caseName
    
    return (
            <Box sx={{ display: 'flex' }}>
                <MyUserProfileDialog open={openUserProfileDialog} onCancel={handleCancelMyProfile}/>
                <CssBaseline />
                <AppBar position="absolute" open={open}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {title}
                        </Typography>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 1 }}
                        >
                            {context}
                        </Typography>
                        <IconButton onClick={handleClickMyProfile} color="inherit">
                            <Badge  badgeContent={accountNotifications} color="secondary">
                                <AccountCircleIcon />
                            </Badge>
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <DrawerItemList/>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="1%" sx={{ mt: 4, mb: 4 }} >
                        {props.children}
                        <Copyright sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        
    );
}