import { post, get, put, del } from 'aws-amplify/api';
import useSWR from 'swr'




export async function postFindAccounts() {
    

  const payload={
    apiName: 'findaccts',
    path: '/findaccts',
    options: {
      body: {}
    }
  }

  


  const restOperation = post(payload); 

  
  const { body } = await restOperation.response;
  let json = await body.json();

  
  json = JSON.parseWithDate(JSON.stringify(json));

  return json



}

export default function useFindAccounts(shouldFetch = true) {
  const result = useSWR(shouldFetch ? '/findaccts' : null, (path) => postFindAccounts());

  return result
}


