

import '@aws-amplify/ui-react/styles.css';

import { Amplify } from 'aws-amplify';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { fetchAuthSession } from 'aws-amplify/auth';
import config from './amplifyconfiguration.json';
import AppRoutes from './AppRoutes';
import { ErrorBoundary } from "react-error-boundary";

import { UserProfileProvider } from './components/ui/userprofilecontext';
import { SnackbarProvider } from './components/ui/snackbar-context';
import { ReportProvider } from './components/ui/user-report-context';


//console.log(`before override config ${config.aws_user_pools_id}`)
//config.aws_user_pools_id="us-east-2_y2FBGTRJE"
//console.log(`after override config ${config.aws_user_pools_id}`)
//const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();


Amplify.configure(config);

function fallbackRender({ error, resetErrorBoundary }) {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre style={{ color: "red" }}>{error.message}</pre>
    </div>
  );
}

const logError = (error, info) => {
  // Do something with the error, e.g. log to an external API
  console.log(`Error: ${JSON.stringify(error)}`)
};

function App() {

  return (

    <div className="App">
      <ErrorBoundary
        fallbackRender={fallbackRender}
        onReset={(details) => {
          // Reset the state of your app so the error doesn't happen again
        }}
        onError={logError}
      >

        <UserProfileProvider>
          <SnackbarProvider>
            <ReportProvider>
            <AppRoutes />
            </ReportProvider>
          </SnackbarProvider>
        </UserProfileProvider>
      </ErrorBoundary>
    </div>
  );
}

export default withAuthenticator(App);
