import { mutate } from "swr";
import { mutateCaseNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";


export default async function mutateBankTransaction(currentUserProfile, data, action=MUTATE_UPDATE) {
    

    if (action != MUTATE_UPDATE){
        throw Error(`unsupported action: ${action}`)
    }

    
    if(!currentUserProfile || !currentUserProfile.currentOrg || !currentUserProfile.currentCase || !currentUserProfile.currentUser){
        throw Error(`pass a valid current user profile to this call`)
    }

    const orgID=currentUserProfile.currentOrg.orgID
    const caseID=currentUserProfile.currentCase.caseID
    const email=currentUserProfile.currentUser.email


    const update_mutation = 'WITH datetime() as dt MATCH (o:Organization {orgID:$orgID})<-[:BELONGS_TO]-(c:Case {caseID:$caseID})-[:HAS]->(ba:BankAccount {pk:$bank_account_pk})-[:HAS]->(bs:BankStatement {pk:$period_pk})-[:CONTAINS]->(t:Transaction {pk:$pk}) MERGE (t)-[r:AUDIT]->(th:TransactionHistory {_auditDate:dt}) set th=properties(t), th._auditDate=dt, th._auditUser=$currentUser, r._auditDate=dt, r._auditUser=$currentUser, t._updateDate=dt, t._updateUser=$currentUser, t._verified=$_verified, t.amount=$amount, t.txn_date=date(datetime($txn_date)), t.description=$description return t,r,th'
    const parms = { 
        "orgID": orgID,
        "caseID": caseID, 
        "currentUser": email,
        "bank_account_pk": data.bank_account_pk,
        "period_pk": data.period_pk,
        "pk": data.pk, 
        "_verified": data._verified,
        "amount": data.amount,
        "txn_date": data.txn_date,
        "description": data.description
    }

    

    
    let mutation = update_mutation
    
    const result = mutate(['BANKTRANSACTION', data.pk], mutateCaseNeo4j(mutation, parms))

    
    
    console.log(`in mutateCase: result=${JSON.stringify(result)}`)
    return result
}