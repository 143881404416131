import React, { useState } from 'react';
import { useGridApiContext } from '@mui/x-data-grid';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import ThumbDownAltIcon from '@mui/icons-material/ThumbDownAlt';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';

const CONFIRMED_VALUE="CONFIRMED"
const REJECTED_VALUE="REJECTED"
const OTHER_VALUE="OTHER"
const PROPOSED_VALUE="PROPOSED"
const POSSIBLE_VALUE="POSSIBLE"

const TransferDispositionButtonGroup = ({target_has_other_match, value, onChange}) => {
    return (
        <ToggleButtonGroup color="primary" value={value} exclusive onChange={onChange}>
          <ToggleButton disabled={target_has_other_match} value={CONFIRMED_VALUE}><ThumbUpAltIcon/></ToggleButton>
          <ToggleButton value={OTHER_VALUE}><QuestionMarkIcon/></ToggleButton>
          <ToggleButton value={REJECTED_VALUE}><ThumbDownAltIcon/></ToggleButton>
        </ToggleButtonGroup>
      );
}

export const TransferDispositionEditor = ({ id, value, type, field, row, target_has_other_match, processRowUpdate}) => {
    const [toggleValue, setToggleValue] = useState(
      value === CONFIRMED_VALUE || value === REJECTED_VALUE ? value : OTHER_VALUE
    );
    const apiRef = useGridApiContext();
  
    const handleChange = (event, newValue) => {
      if (newValue !== null) {
        setToggleValue(newValue);
        
        let updatedValue=newValue
        if (newValue === OTHER_VALUE) {
            updatedValue=type
          //apiRef.current.setEditCellValue({ id, field, value: type });
          //apiRef.current.setEditCellValue({ id, field: 'type', value: type || PROPOSED_VALUE });
        } 
        //console.log(`updating ${field} to ${JSON.stringify(updatedValue)}`)
        const updatedRow={...row, [field]: updatedValue }
        //console.log(`updating row to ${JSON.stringify(updatedRow)}`)
        //apiRef.current.updateRows([updatedRow]);
        processRowUpdate(updatedRow,row)
        

      }
    };
  
    return (<TransferDispositionButtonGroup target_has_other_match={target_has_other_match} value={toggleValue} onChange={handleChange}/>);
    
  };

export const TransferDispositionRenderer = ({ value }) => {
    let displayValue;
    if (value === CONFIRMED_VALUE || value === REJECTED_VALUE) {
      displayValue = value;
    } else if (value === PROPOSED_VALUE || value === POSSIBLE_VALUE) {
      displayValue = OTHER_VALUE;
    } else {
      displayValue = null;
    }
  
    return (<TransferDispositionButtonGroup value={displayValue} />)
    
  };
  
  
