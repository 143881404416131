import { post, get, put, del } from 'aws-amplify/api';

//require('./json.date-extensions')
    //JSON.UseDateParser();    



export async function postOcrolusBook(reqbody) {
    

        const payload={
          apiName: 'ocrbook',
          path: '/ocrbook',
          options: {
            body: reqbody
          }
        }

        // console.log('GOT HERE1 postHumanLoopTask')
        // console.log(`payload=${JSON.stringify(payload)}`)
        
    
    
        const restOperation = post(payload); 

        
        const { body } = await restOperation.response;
        let json = await body.json();
    
        // console.log('GOT HERE2 postHumanLoopTask')
        // console.log(`json=${JSON.stringify(json)}`)
        
        json = JSON.parseWithDate(JSON.stringify(json));
    
        return json
    
    

}
