import React, { useState, useEffect, Fragment, useRef } from 'react';
import ReportCrudGrid from './grid-editable-report-data';
import DRFAReport from './report-drfa';
import { currencyFormatter } from './type-utilities';
import { Box, Button } from '@mui/material';
import ReactToPrint from 'react-to-print';




export default function SampleDRFAReport(props){
    const reportData = props.reportData ? props.reportData : [];
    const [data, setData] = useState([]);
    const reportRef = useRef();
    
    useEffect(() => {
        const newData = formatData(reportData);
        //const newData = { personalInfo: personalInfo, categoryData: categoryData };
        setData(newData);
    }, [props.reportData]);

    const formatData = (reportData) => {
        const categoryData = reportData.map((category, index) => ({...category, id: index}));
        //console.log(`categoryData: ${JSON.stringify(categoryData)}`)
        return categoryData;
    }

    

    return (
        <Fragment>
      <Box 
        display="flex" 
        flexDirection="column" 
        height="100vh" // Use full viewport height
      >
       

          <DRFAReport data={data} />
        </Box>
        <Box mt={2}>
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary">
                Print Report
              </Button>
            )}
            content={() => reportRef.current} // Specify which component to print
          />
        </Box>
      
    </Fragment>

    )


}