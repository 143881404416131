// UserProfileContext.js
import React, { createContext, useContext, useState, useEffect, Fragment } from 'react';
import { useCurrentUserProfile } from '../../api/swrcurrentuserprofile';

const UserProfileContext = createContext();

export const UserProfileProvider = ({ children }) => {
  //const [userProfile, _setUserProfile] = useState();
  //const [isLoading, setIsLoading] = useState(true)

  const {data: userProfile, isLoading, error} = useCurrentUserProfile()

  // useEffect( () => {
  //   const getProfile = async () => {
  //     const storedProfile = localStorage.getItem('userProfile');

  //     if(storedProfile){
  //       setUserProfile(JSON.parse(storedProfile))
  //       return
  //     }

      
  //     //setIsLoading(true)
  //     getCurrentUserProfile().then( (profile) => setUserProfile(profile) )
  //     //setIsLoading(false)
  //   }

  //   getProfile()

  // },[]
  // )

  // const invalidate = ()=> {
  //   _setUserProfile(null)
  //   setIsLoading(true)
  // }

  // const setUserProfile = (profile) => {
    
  //   //console.log(`in user profile context, changing from ${JSON.stringify(userProfile)} \n to ${JSON.stringify(profile)}`)
  //   //setIsLoading(true)
  //   //_setUserProfile(profile)
  //   //localStorage.setItem('userProfile', JSON.stringify(profile));
  //   //setIsLoading(false)
  // }

  return (
    <UserProfileContext.Provider value={{ userProfile, isLoading }}>
      {children}
    </UserProfileContext.Provider>
  );
};


export default UserProfileContext;
