import { mutate } from "swr";
import { mutatePrimaryNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";
import { createOcrolusBook } from "./swrocrbookcall";
import { createCaseDatabaseSecret } from "./mutatecasedbsecret";

// the UI is expecting a result object called "record" for this query, do not change!
export const listCaseQuery="MATCH (record:Case)-[r:BELONGS_TO]->(org:Organization {orgID:$orgID}) RETURN record ORDER by record.caseName";

export default async function mutateCase(orgID, orgName, currentUserID, data, action=MUTATE_UPDATE, search_before_create=true) {
    
    if (!data.caseID) {
        // create a book in ocrolus and assign that uuid as the case ID
        const book_name=orgName + ' _ ' + data.caseName
        const result = await createOcrolusBook(book_name)
        //console.log(`ocrolus book call result=${JSON.stringify(result)}`)
        //console.log(`book id =${result['bookID']}`)
        data.caseID = result['bookID']
    }


    const update_mutation = 'WITH datetime() AS dt MATCH (o:Organization {orgID:$orgID})<-[rou:BELONGS_TO]-(u:User {userID:$userID}) MERGE (case:Case {caseID:$caseID}) set case={caseID:$caseID, caseName:$caseName}   MERGE (case)-[r:BELONGS_TO]->(o) MERGE (case)<-[ru:BELONGS_TO]-(u) set ru.lastDate=dt RETURN case'
    const delete_mutation = 'MATCH (user:User)-[ru]->(case:Case {caseID:$caseID})-[r]->(o:Organization {orgID:$orgID}) DELETE r DELETE ru DELETE case'
    const parms = { "orgID": orgID, "caseID": data.caseID, "caseName": data.caseName, "userID": currentUserID }

    console.log(`in mutateCase, parms=${JSON.stringify(parms)}`)

    

    if (action === MUTATE_UPDATE && search_before_create){
        //console.log('IN MUTATE SEARCH BEFORE CREATE AREA')
        
                const sb4create = 'MATCH (case:Case {caseName:$caseName})-[r:BELONGS_TO]->(org:Organization {orgID:$orgID}) WHERE case.caseID <> $caseID RETURN case'
                const sb4result = await mutate(['CASE', data.caseID], mutatePrimaryNeo4j(update_mutation, parms,sb4create))    

                if (action === MUTATE_UPDATE ){
                    const createCaseDatabaseSecretResult = await createCaseDatabaseSecret(orgID, data.caseID);
                    console.log(`createCaseDatabaseSecretResult=${JSON.stringify(createCaseDatabaseSecretResult)}`)
                }

    
        
        //console.log(JSON.stringify(sb4result))
        return sb4result
    }

    
    
    let mutation = action===MUTATE_DELETE?delete_mutation:update_mutation
    

    const result = await mutate(['CASE', data.caseID], mutatePrimaryNeo4j(mutation, parms))

    if (action === MUTATE_UPDATE ){
        const createCaseDatabaseSecretResult = await createCaseDatabaseSecret(orgID, data.caseID);
        console.log(`createCaseDatabaseSecretResult=${JSON.stringify(createCaseDatabaseSecretResult)}`)
    }
    
    //console.log(`in mutateCase: result=${JSON.stringify(result)}`)
    return result
}