import { mutate } from "swr";
import { mutatePrimaryNeo4j, queryPrimaryNeo4j } from "./pydbcall";
import { MUTATE_CREATE, MUTATE_UPDATE, MUTATE_DELETE } from "./mutate-actions";
import mutateTouchCaseAssignment from "./mutatetouchcaseassignment";



export async function mutateTouchUserOrgAssignment(orgID, userID) {


    const update_mutation = 'WITH datetime() as dt match (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(u:User {userID: $userID}) SET r.lastDate=dt return o,r,u'

    const parms = { "orgID": orgID, "userID": userID }


    let mutation = update_mutation

    //console.log(`in mutateCaseAssignment: mutation=${mutation}`)
    //console.log(`in mutateCaseAssignment: parms=${JSON.stringify(parms)}`)

    const result = mutate('CURRENT_USER_ORG', mutatePrimaryNeo4j(mutation, parms))

    const case_result = await mutateTouchUserOrgCaseAssignment(orgID, userID)


    //console.log(`in mutateCaseAssignment: result=${JSON.stringify(result)}`)
    return case_result
}

function clearUserProfileCache(){
    mutate('CURRENT_USER_PROFILE', undefined,   // update cache data to `undefined`
    { revalidate: true } // do not revalidate
    )
}
export async function mutateTouchUserOrgCaseAssignment(orgID, userID) {
    //const update_mutation = 'WITH datetime() as dt match (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(u:User {userID: $userID}) SET r.lastDate=dt return o as org, u as user'
    const query_new_case = 'MATCH (o:Organization {orgID: $orgID})<-[r:BELONGS_TO]-(u:User {userID: $userID}) WITH datetime() as dt,o,u MATCH (o)<-[ro:BELONGS_TO]-(c:Case)<-[ru:BELONGS_TO]-(u) return c as case order by ru.lastDate desc limit 1'

    let parms = { "orgID": orgID, "userID": userID }


    //console.log(`in mutateCase: mutation=${mutation}, parms=${parms}`)
    //const result = useSWR(qry, postNeo4j);
    

    let query_result = await queryPrimaryNeo4j(query_new_case, parms)

    if (query_result.records.length == 0) {
        clearUserProfileCache()
        
        return query_result
    }

    //console.log(`query_result=${JSON.stringify(query_result)}`)

    const caseID = query_result.records[0].case.caseID

    const result = await mutateTouchCaseAssignment(orgID, caseID, userID)
    
    clearUserProfileCache()

    return result
}