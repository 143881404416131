import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Paper from '@mui/material/Paper';
import React from 'react';

export default function StatementsPanel(props) {
    return (
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'row' }}>
            {props.children}
        </Paper>
    );
}