import React from 'react';
import { Button } from '@mui/material';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ data, fileName, sheetName }) => {

  const suggestedFileName=fileName?fileName:"Data Export"
  const exportToExcel = async () => {
    try {
      // Show save file dialog
      const handle = await window.showSaveFilePicker({
        suggestedName: suggestedFileName,
        types: [
          {
            description: 'Excel Files',
            accept: {
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
            },
          },
        ],
      });

      // Create a new workbook
      const workbook = XLSX.utils.book_new();
      
      // Convert the data to a worksheet
      const worksheet = XLSX.utils.json_to_sheet(data);
      
      // Append the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, sheetName?sheetName:'Sheet1');
      
      // Generate a binary string representation of the workbook
      const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' });
      
      // Convert the binary string to a Blob
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      
      // Create a writable stream and write the Blob
      const writable = await handle.createWritable();
      await writable.write(blob);
      await writable.close();
    } catch (err) {
      console.error('Error saving file:', err);
    }
  };

  // Helper function to convert a string to an array buffer
  const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xFF;
    }
    return buf;
  };

  return (
    <Button color="primary" startIcon={<FileDownloadIcon />} onClick={exportToExcel}>
      Export to Excel
    </Button>
    
  );
};

export default ExportToExcel;
