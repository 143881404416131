import React from 'react';
//import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from './title';
//import Chart from '../pages/dashboard-chart';


export default function ChartPaper(props) {

    return (
        
            <Paper
                sx={{
                    p: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    
                }}
            >
                <Title>{props.title}</Title>
                {props.children}
            </Paper>
        
    );
}