import { post, get, put, del } from 'aws-amplify/api';

//require('./json.date-extensions')
    //JSON.UseDateParser();    



export async function postHumanLoopTask(reqbody) {
    

        const payload={
          apiName: 'humanloopstate',
          path: '/hloop',
          options: {
            body: reqbody
          }
        }

        // console.log('GOT HERE1 postHumanLoopTask')
        // console.log(`payload=${JSON.stringify(payload)}`)
        
    
    
        const restOperation = post(payload); 

        
        const { body } = await restOperation.response;
        let json = await body.json();
    
        // console.log('GOT HERE2 postHumanLoopTask')
        // console.log(`json=${JSON.stringify(json)}`)
        
        json = JSON.parseWithDate(JSON.stringify(json));
    
        return json
    
    

}

export async function putHumanLoopTask(reqbody) {
    

  const payload={
    apiName: 'humanloopstate',
    path: '/hloop',
    options: {
      body: reqbody
    }
  }

  // console.log('GOT HERE1 putHumanLoopTask')
  // console.log(`payload=${JSON.stringify(payload)}`)
  


  const restOperation = put(payload); 

  
  const { body } = await restOperation.response;
  let json = await body.json();

  // console.log('GOT HERE2 putHumanLoopTask')
  // console.log(`json=${JSON.stringify(json)}`)
  
  json = JSON.parseWithDate(JSON.stringify(json));

  return json



}

export async function getHumanLoopTasks(orgID,caseID) {
    

  const payload={
    apiName: 'humanloopstate',
    path: `/hloop/${orgID}/${caseID}`,
    
  }

  // console.log('GOT HERE1 getHumanLoopTask')
  // console.log(`payload=${JSON.stringify(payload)}`)
  


  const restOperation = get(payload); 

  
  const { body } = await restOperation.response;
  let json = await body.json();

  // console.log('GOT HERE2 getHumanLoopTask')
  // console.log(`json=${JSON.stringify(json)}`)
  
  json = JSON.parseWithDate(JSON.stringify(json));

  // console.log('GOT HERE3 getHumanLoopTask')
  // console.log(`date json=${JSON.stringify(json)}`)
  return json



}

export async function getHumanLoopTaskByID(orgID,caseID,taskID) {
    

  const payload={
    apiName: 'humanloopstate',
    path: `/hloop/${orgID}/${caseID}/${taskID}`,
    
  }

  // console.log('GOT HERE1 getHumanLoopTaskByID')
  // console.log(`payload=${JSON.stringify(payload)}`)
  


  const restOperation = get(payload); 

  
  const { body } = await restOperation.response;
  let json = await body.json();

  // console.log('GOT HERE2 getHumanLoopTaskByID')
  // console.log(`json=${JSON.stringify(json)}`)
  
  json = JSON.parseWithDate(JSON.stringify(json));
  
  // console.log('GOT HERE3 getHumanLoopTaskByID')
  // console.log(`date json=${JSON.stringify(json)}`)
  return json



}

export async function deleteHumanLoopTaskByID(orgID,caseID,taskID) {
    

  const payload={
    apiName: 'humanloopstate',
    path: `/hloop/${orgID}/${caseID}/${taskID}`,    
  }

  // console.log(`DELETE payload=${JSON.stringify(payload)}`)
  


  const restOperation = del(payload); 

  
  const { body } = await restOperation.response;
  let json = await body.json();

  // console.log(`json=${JSON.stringify(json)}`)
  
  json = JSON.parseWithDate(JSON.stringify(json));
  
  // console.log(`date json=${JSON.stringify(json)}`)
  return json



}